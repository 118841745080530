import React from "react";
import DashboardCard from "../Components/DashboardCard/DashboardCard";
import twentyFour from "../../Assets/Icons/24.png";
import worker from "../../Assets/Icons/worker.png";
import past from "../../Assets/Icons/return-to-the-past.png";
import seven from "../../Assets/Icons/7.png";
import Slider from "react-slick";
import CardHeader from "../Components/CardHeader/CardHeader";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function TopCards({ defaultFormProps, formConfig, typeOptions, setValue, weeklyComplianceReport, cardTitleButton }) {
  const theme = useTheme();
  const WeeklyReportData = [
    {
      icon: twentyFour,
      colorClass: theme.palette.demo.weeklyreporttopcard1color,
      value: `${Math.round(weeklyComplianceReport?.apprenticeHours) || 0} Hours`,
      label: "Total Apprentice Hours",
      bg: theme.palette.demo.weeklyreporttopcard1bg
    },
    {
      icon: worker,
      colorClass: theme.palette.demo.weeklyreporttopcard2color,
      value: Math.round(weeklyComplianceReport?.activeWorkers) || 0,
      label: "Total Number of Workers",
      bg: theme.palette.demo.weeklyreporttopcard2bg
    },
    {
      icon: twentyFour,
      colorClass: theme.palette.demo.weeklyreporttopcard3color,
      value: `${Math.round(weeklyComplianceReport?.journeyMansHours) || 0} Hours`,
      label: "Total Journeyman hours",
      bg: theme.palette.demo.weeklyreporttopcard3bg
    },
    {
      icon: seven,
      colorClass: theme.palette.demo.weeklyreporttopcard4color,
      value: `${Math.round(weeklyComplianceReport?.totalLostOTJHours) || 0} Hours`,
      label: "Overall Workforce Hourse For Week",
      bg: theme.palette.demo.weeklyreporttopcard4bg
    },
    {
      icon: past,
      colorClass: theme.palette.demo.weeklyreporttopcard5color,
      value: `${Math.round(weeklyComplianceReport?.totalLostOTJHours) || 0} Hours`,
      label: "total Lost OTJ",
      bg: theme.palette.demo.weeklyreporttopcard5bg
    }
  ];

  return (
    <>
      <CardHeader
        formProps={defaultFormProps}
        formConfig={formConfig}
        typeOptions={typeOptions}
        cardStyle={{ marginTop: "20px" }}
        boxStyle={{ display: "flex", justifyContent: "space-between" }}
        setValue={setValue}
        title={"Weekly Compliance  Report"}
        cardTitleButton={cardTitleButton}
      />
      <div style={{ marginBottom: "20px", maxWidth: "100%" }}>
        <Slider {...settings}>
          {WeeklyReportData?.map((data, ind) => (
            <div key={data?.value}>
              <DashboardCard
                title={data?.value}
                value={data?.label}
                color={data?.colorClass}
                icon={data?.icon}
                bg={data?.bg}
                ind={ind}
                length={WeeklyReportData.length - 1}
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

TopCards.propTypes = {
  defaultFormProps: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  typeOptions: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  weeklyComplianceReport: PropTypes.object.isRequired
};
