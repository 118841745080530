import React, { useState, useEffect, useRef } from "react";
import DashBoardTable from "../ProGridTable/DashBoardTable";
import { getApi } from '../../../../utils/services'
import { GET_JTA_AVERAGE_GAUGE_DATA } from '../../../../utils/services/apiPath'

const formatNumber = (value) => {
  if (value === undefined || value === null) return "";
  return new Intl.NumberFormat("en-US").format(value);
};

const roundToTwoDecimals = (value) => {
  if (typeof value === "number") {
    return Math.round(value * 100) / 100;
  }
  return value;
};

const JtaAverageTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const hasFetchedData = useRef(false);
  const columns = [
    { field: "projectName", headerName: "Project" },
    { field: "partnerName", headerName: "Partner" },
    { field: "apprenticeHours", headerName: "Total Apprentice Hours"},
    { field: "lostOtj", headerName: "Total Lost OTJ" },
    { field: "totalHours", headerName: "Total Labor Hours" },
    { field: "jToA", headerName: "Journey to Apprentice Ratio" },
  ];

  const fetchJtaAverageData = async () => {
    setLoading(true);
    try {
      const { data, error } = await getApi(GET_JTA_AVERAGE_GAUGE_DATA);
      if (error) { throw new Error(error);}

      const rowsWithId = data.map((item, index) => ({
        id: `row-${index}`,
        projectName: item.projectName,
        partnerName: item.partnerName,
        apprenticeHours: roundToTwoDecimals(item.apprenticeHours),
        lostOtj: roundToTwoDecimals(item.lostOtj),
        totalHours: roundToTwoDecimals(item.totalHours),
        jToA: roundToTwoDecimals(item.jToA),
      }));
      setRows(rowsWithId);
    }  
    finally {
      setLoading(false);
    }
  };  
  useEffect(() => {
    if (!hasFetchedData.current) {
      hasFetchedData.current = true;
      fetchJtaAverageData();
    }
  }, []);


  return (
    <div style={{ margin: "20px" }}>
      <DashBoardTable
        columns={columns}
        rows={rows}
        loading={loading}
        paginationModel={{ page: 0, pageSize: 5 }}
        hideExport={false}
        checkboxSelection={false}
      />
    </div>
  );
};

export default JtaAverageTable;
