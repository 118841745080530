import React from "react";

const RtiReportIcon = () => {
  return (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.4 0C6.19565 0 6.95871 0.312778 7.52132 0.869527C8.08393 1.42628 8.4 2.18139 8.4 2.96875C8.4 3.75611 8.08393 4.51122 7.52132 5.06797C6.95871 5.62472 6.19565 5.9375 5.4 5.9375C4.60435 5.9375 3.84129 5.62472 3.27868 5.06797C2.71607 4.51122 2.4 3.75611 2.4 2.96875C2.4 2.18139 2.71607 1.42628 3.27868 0.869527C3.84129 0.312778 4.60435 0 5.4 0ZM19.2 0C19.9956 0 20.7587 0.312778 21.3213 0.869527C21.8839 1.42628 22.2 2.18139 22.2 2.96875C22.2 3.75611 21.8839 4.51122 21.3213 5.06797C20.7587 5.62472 19.9956 5.9375 19.2 5.9375C18.4044 5.9375 17.6413 5.62472 17.0787 5.06797C16.5161 4.51122 16.2 3.75611 16.2 2.96875C16.2 2.18139 16.5161 1.42628 17.0787 0.869527C17.6413 0.312778 18.4044 0 19.2 0ZM0 11.0846C0 8.89883 1.7925 7.125 4.00125 7.125H5.6025C6.19875 7.125 6.765 7.25488 7.275 7.48496C7.22625 7.75215 7.20375 8.03047 7.20375 8.3125C7.20375 9.73008 7.83375 11.0029 8.8275 11.875C8.82 11.875 8.8125 11.875 8.80125 11.875H0.79875C0.36 11.875 0 11.5188 0 11.0846ZM15.1988 11.875C15.1912 11.875 15.1838 11.875 15.1725 11.875C16.17 11.0029 16.7963 9.73008 16.7963 8.3125C16.7963 8.03047 16.77 7.75586 16.725 7.48496C17.235 7.25117 17.8013 7.125 18.3975 7.125H19.9988C22.2075 7.125 24 8.89883 24 11.0846C24 11.5225 23.64 11.875 23.2013 11.875H15.1988ZM8.4 8.3125C8.4 7.36767 8.77929 6.46153 9.45442 5.79343C10.1295 5.12533 11.0452 4.75 12 4.75C12.9548 4.75 13.8705 5.12533 14.5456 5.79343C15.2207 6.46153 15.6 7.36767 15.6 8.3125C15.6 9.25733 15.2207 10.1635 14.5456 10.8316C13.8705 11.4997 12.9548 11.875 12 11.875C11.0452 11.875 10.1295 11.4997 9.45442 10.8316C8.77929 10.1635 8.4 9.25733 8.4 8.3125ZM4.8 18.0092C4.8 15.2779 7.03875 13.0625 9.79875 13.0625H14.2013C16.9613 13.0625 19.2 15.2779 19.2 18.0092C19.2 18.5547 18.7537 19 18.1987 19H5.80125C5.25 19 4.8 18.5584 4.8 18.0092Z"
        fill="white"
      />
    </svg>
  );
};

export default RtiReportIcon;
