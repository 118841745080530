import "./App.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthWrapper from "./App/Components/AuthWrapper";
import { RouterProvider } from "react-router-dom";
import { Suspense, useEffect } from "react";
import Loading from "./App/Components/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { watchBuildVersion } from "./utils/watchForBuild";
import { handleApiRouterInitialize, routerSelector } from "./App/store/routerSlice";
import { useSelector, useDispatch } from "react-redux";

const App = () => {
  const emotionCache = createCache({
    key: "emotion-css-cache",
    prepend: true
  });

  const { router } = useSelector(routerSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    watchBuildVersion();
    dispatch(handleApiRouterInitialize());
  }, []);
  return (
    <Suspense fallback={<Loading />}>
      <CacheProvider value={emotionCache}>
        <AuthWrapper>
          <RouterProvider router={router} />
        </AuthWrapper>
        <ToastContainer theme="colored" />
      </CacheProvider>
    </Suspense>
  );
};

export default App;
