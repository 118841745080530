import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  minHeight: "auto",
  maxHeight: { xs: "auto", lg: "calc(100vh - 100px)" },
  overflowY: "auto",
  width: { xs: "calc(100% - 60px)" },
  maxWidth: { xs: "1500px" },
  border: "1px solid #ccc",
  borderRadius: 4,
  position: "relative", 
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)", 
  backgroundColor: "white", 
  zIndex: 1000, 
  padding: "20px"
};

export default function GenericModal({ children, open, setOpen, modalTitle ,onCloseAction }) {
  const handleClose = () => {
    setOpen(false);
    if (onCloseAction) {
      onCloseAction(); 
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={theme => ({ position: "absolute", right: 0, top: 0, color: theme.palette.grey[500] })}
        >
          <CloseIcon />
        </IconButton>
        {modalTitle && (
          <Grid item xs={12}>
            <Typography variant="h6" style={{ padding: "10px", paddingTop: "0px" }}>
              {modalTitle}
            </Typography>
            <Divider />
          </Grid>
        )}
        <div>{children}</div>
      </Box>
    </Modal>
  );
}

GenericModal.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired
};
