import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import ProMobileField from "../../../Components/Inputs/MobileField";
import ProEmailField from "../../../Components/Inputs/EmailField";
import ProDateField from "../../../Components/Inputs/DateField";
import ProCurrencyField from "../../../Components/Inputs/CurrencyField";
import ProFileUploadField from "../../../Components/Inputs/FileUploadField";
import ProCheckboxField from "../../../Components/Inputs/CheckboxField";
import ProSSNField from "../../../Components/Inputs/SSNField";
import {
  changeMode,
  changeModeForField,
  prepareDefaultValues,
  prepareInitialConfig,
  employeeProfileFormName,
  convertFileToBase64Model,
  formatPayloadDate
} from "../../../../utils/formHelper";
import {
  GET_ALL_LANGUAGE_TYPES,
  GET_ALL_MANAGER_TYPES,
  GET_ALL_NOTIFICATION_TYPES,
  GET_ALL_EMPLOYEE_STATUS_TYPES,
  GET_ALL_EMPLOYEE_TERM,
  GET_ALL_FRINGE_PAYMENT_METHOD,
  ADD_EMPLOYEE,
  SEND_APPRENTICE_APPLICATION_DOCUMENT
} from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { postApi } from "../../../../utils/services";
import Loading from "../../../Components/Loading";
import { defaultDropdownConfig, employmentStatusOptions, sexOptions, transFormModalData } from "../../../../utils/dropdownHelper";
import { employeesProfilePermissionSelector, employeesPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import ZipField from "../../../Components/Inputs/ZipField";
import { useSelector } from "react-redux";
import { decryptSSN, encryptSSN } from "../../../../utils/crypto";
import { EmployeeProfileFormConfing } from "../EmployeeData";

export default function AddForm() {
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(EmployeeProfileFormConfing));
  const [employeeProfileData, setEmployeeProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [languageType, setLanguageType] = useState([]);
  const [notificationType, setNotificationType] = useState([]);
  const [employeeStausType, setEmployeeStausType] = useState([]);
  const [employeeTermType, setEmployeeTermType] = useState([]);
  const [managerType, setManagerType] = useState([]);
  const [fringePaymentMethod, setFringePaymentMethod] = useState([]);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const { handleSubmit, control, setValue, watch, register } = useForm({ defaultValues: prepareDefaultValues(EmployeeProfileFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const {
    "Basic Information": employeeBasicInfo,
    "Apprentice Information": employeeApprenticeInfo,
    "Address Information": employeeAddessInfo,
    "Mailing Address Inforamtion": employeeMailingAddressInfo,
    "Additional Information": employeeAdditionalInfo
  } = usePermission(employeesProfilePermissionSelector, employeeProfileFormName);
  const { subModulePermissions } = useSelector(employeesPermissionSelector);
  const profilePermission = subModulePermissions?.EMPLOYEES_PROFILE?.canView;
  const [isSaving, setIsSaving] = useState(false);
  const isAppentice = watch("isAppentice");
  const employeeType = watch("employeeTypeId")
  const navigate = useNavigate();
  const { id } = useParams();
  const backAction = () => navigate("/employees");

  const onEdit = () => setFormConfig(changeMode("edit"));

  const onCancel = () => navigate("/employees")

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const sendApprenticeApplicationDocument = async (employeeGuid, email) => {
    if (employeeType != 4) {
      return
    }

    const { error } = await postApi(`${SEND_APPRENTICE_APPLICATION_DOCUMENT}?employeeGuid=${employeeGuid}`)
    if (error) return errorToast(error);
    successToast(`An email of the onboarding document was sent to ${email}.`)
  }

  const onSubmit = async values => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const base64ProfilePicture = await convertFileToBase64Model(values.profilePictureFile);
    if (employeeType == 4) {
      values.apprenticeProspectStatusTypeId = 1
    }
    const payload = {
      ...values,
      last4ofSSN: encryptSSN(values.last4ofSSN),
      raceTypeId: values.raceTypeId?.map(({ value }) => value) || null,
      veteranStatusTypeId: values.veteranStatusTypeId?.map(({ value }) => value) || null,
      invitationVerificationCode: null,
      managerId: values.manager,
      languageTypeId: values.languageType,
      notificationTypeId: values.notificationType,
      fringePaymentMethodTypeId: values.fringePaymentMethodType,
      birthDate: formatPayloadDate(values.birthDate),
      startDate: formatPayloadDate(values.startDate),
      endDate: formatPayloadDate(values.endDate),
      dateApprenticeshipBegins: formatPayloadDate(values.dateApprenticeshipBegins),
      profileMediaModel: base64ProfilePicture || null,
      profilePicture: base64ProfilePicture?.base64String || null,
      employeeId: null
    };
    const { data: employee, error } = await postApi(ADD_EMPLOYEE, payload);
    if (error) {
      setIsSaving(false);
      setStatus(STAUTES.IDLE);
      return errorToast(error);
    }
    await sendApprenticeApplicationDocument(employee?.guidId, values.email)
    setIsSaving(false);
    setStatus(STAUTES.IDLE);
    successToast(`Employee Added Successfully`);
    navigate(`/employees/${employee?.guidId}`);
  };

  const fetchAllLanguageType = async () => {
    const { data } = await postApi(GET_ALL_LANGUAGE_TYPES, defaultDropdownConfig);
    setLanguageType(data.map(item => ({ label: item.languageType, value: item.languageTypeId })));
  };

  const fetchAllNotificationType = async () => {
    const { data } = await postApi(GET_ALL_NOTIFICATION_TYPES, defaultDropdownConfig);
    setNotificationType(data.map(item => ({ label: item.notificationType, value: item.notificationTypeId })));
  };

  const fetchAllEmployeeStatusType = async () => {
    const { data } = await postApi(GET_ALL_EMPLOYEE_STATUS_TYPES, defaultDropdownConfig);
    setEmployeeStausType(data.map(item => ({ label: item.employeeStatusType, value: item.employeeStatusTypeId })));
  };

  const fetchAllEmployeeTermType = async () => {
    const { data } = await postApi(GET_ALL_EMPLOYEE_TERM, defaultDropdownConfig);
    setEmployeeTermType(data.map(item => ({ label: item.employmentTermType, value: item.employmentTermTypeId })));
  };

  const fetchAllFringePaymentMethod = async () => {
    const { data } = await postApi(GET_ALL_FRINGE_PAYMENT_METHOD, defaultDropdownConfig);
    setFringePaymentMethod(data.map(item => ({ label: item.fringePaymentMethodType, value: item.fringePaymentMethodTypeId })));
  };

  const fetchAllManager = async () => {
    const { data } = await postApi(GET_ALL_MANAGER_TYPES, defaultDropdownConfig);
    setManagerType(data.map(item => ({ label: item.employeeName, value: item.employeeId })));
  };

  useEffect(() => {
    fetchAllLanguageType();
    fetchAllNotificationType();
    fetchAllEmployeeStatusType();
    fetchAllEmployeeTermType();
    fetchAllFringePaymentMethod();
    fetchAllManager();
    onEdit();
  }, [id]);

  useEffect(() => {
    if (employeeType == 1 || employeeType == 4) {
      setValue("isAppentice", true)
    } else {
      setValue("isAppentice", false)
    }
  }, [employeeType])

  const defaultFormProps = { control, formValues: employeeProfileData, handleReadValueClick };
  return (
    <div className="partner-profile">
      <ProFormsLayout
        status={status}
        backAction={backAction}
        title={"Add Employee"}
        backLabel="Go Back to All Employees"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        isSaving={isSaving}
        canView={profilePermission}
        hideEdit={
          !(
            employeeBasicInfo.canEdit ||
            employeeApprenticeInfo.canEdit ||
            employeeAddessInfo.canEdit ||
            employeeMailingAddressInfo.canEdit ||
            employeeAdditionalInfo.canEdit
          )
        }
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
      >
        {!isLoading ? (
          <>
            {employeeBasicInfo.canView && (
              <FormSectionWrapper title={"BASIC INFORMATION"}>
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.employeeStatusTypeId}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.employeeStatusTypeId.perKey]}
                  options={employeeStausType}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.interalEmployeeID}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.interalEmployeeID.perKey]}
                />
                <ProTextInput {...defaultFormProps} {...formConfig.jobTitle} permission={employeeBasicInfo.fieldPerObj[formConfig.jobTitle.perKey]} />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.firstName}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.firstName.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.middleName}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.middleName.perKey]}
                />
                <ProTextInput {...defaultFormProps} {...formConfig.lastName} permission={employeeBasicInfo.fieldPerObj[formConfig.lastName.perKey]} />
                <ProMobileField
                  {...defaultFormProps}
                  {...formConfig.cellPhone}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.cellPhone.perKey]}
                />
                <ProMobileField
                  {...defaultFormProps}
                  {...formConfig.officePhone}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.officePhone.perKey]}
                />
                <ProMobileField
                  {...defaultFormProps}
                  {...formConfig.whatsApp}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.whatsApp.perKey]}
                />
                <ProEmailField {...defaultFormProps} {...formConfig.email} permission={employeeBasicInfo.fieldPerObj[formConfig.email.perKey]} />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.department}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.department.perKey]}
                />
                <ProSSNField
                  {...defaultFormProps}
                  {...formConfig.last4ofSSN}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.last4ofSSN.perKey]}
                />
                <ProDateField
                  {...defaultFormProps}
                  {...formConfig.birthDate}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.birthDate.perKey]}
                />
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.sex}
                  options={sexOptions}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.sex.perKey]}
                />
                <ProFileUploadField
                  {...defaultFormProps}
                  {...formConfig.profilePictureFile}
                  inputProps={{ accept: "image/*" }}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.profilePictureFile.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.yearsOfExperience}
                  permission={employeeBasicInfo.fieldPerObj[formConfig.yearsOfExperience.perKey]}
                />
                <div style={{ display: "none" }}>
                  <ProCheckboxField

                    {...defaultFormProps}
                    {...formConfig.isAppentice}
                    permission={employeeBasicInfo.fieldPerObj[formConfig.isAppentice.perKey]}
                  />
                </div>
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.employeeTypeId}
                  // TODO: Get the options from api call
                  options={
                    [
                      { label: "Apprentice", value: 1 },
                      { label: "Mentor", value: 2 },
                      { label: "Journeyman", value: 3 },
                      { label: "Apprentice Prospect", value: 4 },
                      { label: "Journey Worker Helper", value: 5 },
                    ]
                  }
                  // TODO: Do the permissions for this
                  //permission={employeeApprenticeInfo.fieldPerObj[formConfig.ethnicityTypeId.perKey]}
                  permission={employeeBasicInfo.fieldPerObj?.[formConfig.employeeTypeId.perKey] ?? { canView: true, canEdit: true }}
                />
              </FormSectionWrapper>
            )}
            {employeeAddessInfo.canView && (
              <FormSectionWrapper title={"ADDRESS INFORMATION"}>
                <ProTextInput {...defaultFormProps} {...formConfig.address} permission={employeeAddessInfo.fieldPerObj[formConfig.address.perKey]} />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.address2}
                  permission={employeeAddessInfo.fieldPerObj[formConfig.address2.perKey]}
                />
                <ProTextInput {...defaultFormProps} {...formConfig.city} permission={employeeAddessInfo.fieldPerObj[formConfig.city.perKey]} />
                <ProTextInput {...defaultFormProps} {...formConfig.state} permission={employeeAddessInfo.fieldPerObj[formConfig.state.perKey]} />
                <ZipField {...defaultFormProps} {...formConfig.zip} permission={employeeAddessInfo.fieldPerObj[formConfig.zip.perKey]} />
              </FormSectionWrapper>
            )}
            {employeeMailingAddressInfo.canView && (
              <FormSectionWrapper title={"MAILING ADDRESS INFORMATION"}>
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.mailingAddress}
                  permission={employeeMailingAddressInfo.fieldPerObj[formConfig.mailingAddress.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.mailingState}
                  permission={employeeMailingAddressInfo.fieldPerObj[formConfig.mailingState.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.mailingCity}
                  permission={employeeMailingAddressInfo.fieldPerObj[formConfig.mailingCity.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.mailingZip}
                  permission={employeeMailingAddressInfo.fieldPerObj[formConfig.mailingZip.perKey]}
                />
              </FormSectionWrapper>
            )}
            {employeeAdditionalInfo.canView && (
              <FormSectionWrapper title={"ADDITIONAL INFORMATION"}>
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.fringePaymentMethodType}
                  options={fringePaymentMethod}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.fringePaymentMethodType.perKey]}
                />
                <ProCurrencyField
                  {...defaultFormProps}
                  {...formConfig.hourlyRateAmount}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.hourlyRateAmount.perKey]}
                />
                <ProCurrencyField
                  {...defaultFormProps}
                  {...formConfig.fringeRateAmount}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.fringeRateAmount.perKey]}
                />
                <ProDateField
                  {...defaultFormProps}
                  {...formConfig.startDate}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.startDate.perKey]}
                />
                <ProDateField
                  {...defaultFormProps}
                  {...formConfig.endDate}
                  fieldProps={{ minDate: watch("startDate") }}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.endDate.perKey]}
                />
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.manager}
                  options={managerType}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.manager.perKey]}
                />
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.languageType}
                  options={languageType}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.languageType.perKey]}
                />
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.notificationType}
                  options={notificationType}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.notificationType.perKey]}
                />
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.employmentTermTypeId}
                  options={employeeTermType}
                  permission={employeeAdditionalInfo.fieldPerObj[formConfig.employmentTermTypeId.perKey]}
                />
              </FormSectionWrapper>
            )}
          </>
        ) : (
          <Loading></Loading>
        )}
      </ProFormsLayout>
    </div>
  );
}
