import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../Components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import ProSelectField from "../../../Components/Inputs/SelectField";
import {
  addJobTitleFormName,
  changeMode,
  changeModeForField,
  checkEditEnabled,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { getApi, postApi } from "../../../../utils/services";
import { ADD_JOB_TITLE, GET_ALL_JOB_CATEGORY, GET_JOB_TITLE_ID, UPDATE_JOB_TITLE } from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import usePermission from "../../../../utils/customHooks/usePermission";
import { R_JOB_TITLE, R_SETTINGS } from "../../../../utils/permissionReferenceConfig";

export const jobTitleFormConfig = {
  jobCategoryId: {
    label: "Job Category",
    perKey: "SelectJobCategory"
  },
  jobTitleName: {
    label: "Job Title",
    rules: { required: "Job Title is required!" },
    perKey: "JobTitleName"
  },
  jobTitleDescription: {
    label: "Job Description",
    fieldProps: { multiline: true, rows: 4 },
    rules: { required: "Job Description is required!" },
    perKey: "JobTitleDescription"
  }
};

export default function JobTitleForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const { [addJobTitleFormName]: formPermission } = usePermission(subModPermissionSelector(R_SETTINGS, R_JOB_TITLE), addJobTitleFormName);
  const { canView, canEdit, canAdd, fieldPerObj } = formPermission;
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(jobTitleFormConfig, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(jobTitleFormConfig) });
  const [jobTitleData, setJobTitleData] = useState({});
  const [jobCategories, setJobCategories] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);

  const backAction = () => navigate("/settings/job-title");

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { data, error } = await getApi(`${GET_JOB_TITLE_ID}/${id}`);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setJobTitleData(data);
    Object.keys(jobTitleFormConfig).forEach(field => setValue(field, data[field]));
  }, [setValue, id]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const onCancel = () => {
    if (isAddMode) backAction();
    else setFormConfig(changeMode());
  };

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      compensationTypeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      compensationAmount: 0,
      bonusTypeId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      bonusAmount: 0,
      startDate: null,
      endDate: null,
      ...jobTitleData,
      ...data
    };
    setStatus(STAUTES.LOADING);
    const { error } = await postApi(isAddMode ? ADD_JOB_TITLE : UPDATE_JOB_TITLE, payload);
    setIsSaving(false);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`Job Title ${isAddMode ? "added" : "updated"} successfully.`);
    backAction();
  };

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

  useEffect(() => {
    const getJobCategory = async () => {
      const { data, error } = await postApi(GET_ALL_JOB_CATEGORY, { orderByAscending: true, pageIndex: 1, pageSize: 100, searchString: "" });
      if (error) return errorToast(error);
      setJobCategories(data.map(cat => ({ label: cat.jobCategoryName, value: cat.jobCategoryId })));
    };
    getJobCategory();
  }, []);

  const defFormProps = { control, formValues: jobTitleData, isAddMode, handleReadValueClick };
  return (
    <Layout status={status} canView={canView && !(isAddMode && !canAdd)}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Job Title`}
        backLabel="Go Back to All Job Title"
        beingEdited={isEditModeOn}
        hideEdit={!canEdit}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProSelectField
            {...defFormProps}
            {...formConfig.jobCategoryId}
            permission={fieldPerObj[formConfig.jobCategoryId.perKey]}
            options={jobCategories}
          />
          <ProTextInput {...defFormProps} {...formConfig.jobTitleName} permission={fieldPerObj[formConfig.jobTitleName.perKey]} />
          <ProTextInput {...defFormProps} {...formConfig.jobTitleDescription} permission={fieldPerObj[formConfig.jobTitleDescription.perKey]} />
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
}
