import { Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "./DeleteCell.scss";
import ConfirmationModal from "../../ConfirmationModal";
import PropTypes from "prop-types";

export default function AddCell({ title, params, onAdd, disabled = false }) {
  const theme = useTheme();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const onConfirm = () => {
    onAdd(params.id);
    setShowConfirmationModal(false);
  };
  return (
    <>
      <Button
        className="proGrid-delete"
        variant="outlined"
        size="small"
        style={{
          border: theme.palette.progrid.cellbuttonborder,
          backgroundColor: theme.palette.progrid.cellbuttonbackgroundcolor,
          color: theme.palette.progrid.cellbuttoncolor
        }}
        onClick={openConfirmationModal}
        disabled={disabled}
      >
        <AddIcon fontSize="small" sx={{ color: theme.palette.progrid.cellbuttonborder }} />
      </Button>
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          title={title}
          message="Are you sure you want to add this record?"
          onConfirm={onConfirm}
          onCancel={() => setShowConfirmationModal(false)}
          confirmBtnText={"Add"}
        />
      )}
    </>
  );
}

AddCell.propTypes = {
  params: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string
};
