import React, { useState, useMemo, useEffect } from "react";
import Layout from "../../../Components/Layout";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import ProRadioGroup from "../../../Components/Inputs/RadioGroup";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import { checkEditEnabled, fieldTruePermission } from "../../../../utils/formHelper";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { getApi, postApi } from "../../../../utils/services";
import { POST_WPS_EMPLOYEE_MENTOR_SETTING, GET_WPS_EMPLOYEE_MENTOR_SETTING } from "../../../../utils/services/apiPath";

const WpsSettings = () => {
  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      userRole: "",
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [isLoading, setIsLoading] = useState(true);
  const isEditModeOn = useMemo(() => checkEditEnabled(true, {}), []);
  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(GET_WPS_EMPLOYEE_MENTOR_SETTING);
      if (response?.data) {
        const selectedRole = response.data.isMentorAllowed? "isMentorAllowed": response.data.isApprenticeAllowed? "isApprenticeAllowed": "";
        if (selectedRole) {
          setValue("userRole", selectedRole);
        }
      }
    } catch (error) {
      errorToast("Failed to fetch settings.");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);  
  
  const onSubmit = async () => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const userRole = getValues("userRole");
    const params = {
      isMentorAllowed: userRole === "isMentorAllowed",
      isApprenticeAllowed: userRole === "isApprenticeAllowed",
    };
    try {
      await postApi(POST_WPS_EMPLOYEE_MENTOR_SETTING, {}, {params});
      successToast("WPS Settings saved successfully.");
    } catch (error) {
      errorToast("Failed to save WPS Settings.");
    } finally {
      setIsSaving(false);
      setStatus(STAUTES.IDLE);
    }
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Layout canView={true}>
      <SettingFormsLayout
        title={"WPS Settings"}
        subTitle={"This setting adjusts who will initiate the entry of On The Job hours and allocate those hours to the Work Process Schedule within the Apprenticeship"}
        hideBackbutton={true}
        beingEdited={isEditModeOn}
        hideEdit={true}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <FormSectionWrapper>
            <ProRadioGroup
              name="userRole"
              label="Select User Role"
              control={control}
              options={[
                { id: "isApprenticeAllowed", label: "Employee" },
                { id: "isMentorAllowed", label: "Mentor" },
              ]}
              mode="edit"
              rules={{ required: "User role is required" }}
              permission={fieldTruePermission}
            />
          </FormSectionWrapper>
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
};

export default WpsSettings;