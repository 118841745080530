export const fileTypeOptions = [
  { id: "PDF", label: "PDF" },
  { id: "XLSX", label: "Excel" },
  { id: "CSV", label: "CSV" },
  { id: "Word", label: "Word" }
];

export const scheduleOptions = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" }
];

export const dailyFrequencyOptions = {
  weekly: [
    { label: "Every Monday", value: "monday" },
    { label: "Every Tuesday", value: "tuesday" },
    { label: "Every Wednesday", value: "wednesday" },
    { label: "Every Thursday", value: "thursday" },
    { label: "Every Friday", value: "friday" },
    { label: "Every Saturday", value: "saturday" },
    { label: "Every Sunday", value: "sunday" }
  ],
  monthly: [
    { label: "First Monday of the month", value: "firstMondayOfMonth" },
    { label: "First Tuesday of the month", value: "firstTuesdayOfMonth" },
    { label: "First Wednesday of the month", value: "firstWednesdayOfMonth" },
    { label: "First Thursday of the month", value: "firstThursdayOfMonth" },
    { label: "First Friday of the month", value: "firstFridayOfMonth" },
    { label: "First Saturday of the month", value: "firstSaturdayOfMonth" },
    { label: "First Sunday of the month", value: "firstSundayOfMonth" },
    { label: "First day of the month", value: "firstDayOfMonth" },
    { label: "Last day of the month", value: "lastDayOfMonth" },
    { label: "First day of the quarter", value: "firstDayOfQuarter" },
    { label: "Last day of the quarter", value: "lastDayOfQuarter" }
  ],
  yearly: [
    { label: "First Monday of the year", value: "firstMondayOfYear" },
    { label: "First Tuesday of the year", value: "firstTuesdayOfYear" },
    { label: "First Wednesday of the year", value: "firstWednesdayOfYear" },
    { label: "First Thursday of the year", value: "firstThursdayOfYear" },
    { label: "First Friday of the year", value: "firstFridayOfYear" },
    { label: "First Saturday of the year", value: "firstSaturdayOfYear" },
    { label: "First Sunday of the year", value: "firstSundayOfYear" },
    { label: "First day of the year", value: "firstDayOfYear" },
    { label: "Last day of the year", value: "lastDayOfYear" }
  ]
};
