import React from "react";
import TypeSettingForm from "../Pages/Settings/TypeSetting/TypeSettingForm";
import TypeSettingList from "../Pages/Settings/TypeSetting/TypeSettingList";
import JobCategoryForm from "../Pages/Settings/JobCategory/JobCategoryForm";
import JobCategoryList from "../Pages/Settings/JobCategory/JobCategoryList";
import Login from "../Pages/Login/Login";
import Identity from "../Pages/Login/Identity";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import PartnerList from "../Pages/Partners/PartnerList";
import PartnerDetail from "../Pages/Partners/PartnerDetail";
import JobTitleList from "../Pages/Settings/JobTitle/JobTitleList";
import JobTitleForm from "../Pages/Settings/JobTitle/JobTitleForm";
import EmployeeList from "../Pages/Employees/EmployeeList";
import EmployeeDetail from "../Pages/Employees/EmployeeDetail";
import ESignature from "../Pages/Employees/EmployeeDetail/ESignature";
import ProjectDetail from "../Pages/Projects/ProjectDetail";
import RapMgmtList from "../Pages/RapMgnt/RapMgmtList";
import RAPDetail from "../Pages/RapMgnt/RAPDetail";
import ProtectedRoute from "./ProtectedRoute";
import MyAccount from "../Pages/MyAccount";
import UserPermissions from "../Pages/UserPermissions/UserPermissions";
import UsersList from "../Pages/Settings/users/UsersList";
import UsersForm from "../Pages/Settings/users/UsersForm";
import ValidateOtp from "../Pages/ValidateOtp/ValidateOtp";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import VerifyAccount from "../Pages/VerifyAccount";
import LeadSourceForm from "../Pages/Settings/LeadSubSource/LeadSourceForm";
import LeadSourceList from "../Pages/Settings/LeadSubSource/LeadSourceList";
import DemoWeeklyReport from "../Demo/WeeklyReport/DemoWeeklyReport";
import DemoLostOtjHours from "../Demo/LostOtjHours/DemoLostOtjHours";
import DemoRtiReport from "../Demo/RtiReport/DemoRtiReport";
import DemoDashboard from "../Demo/Dashboard/DemoDashboard";
import Projects from "../Pages/Projects/routes/index";
import NewProject from "../Pages/Projects/routes/new";
import ODataReportViewer from "../Components/ReportViewers/ODataReportViewer";
import HTTPReportViewer from "../Components/ReportViewers/HTTPReportViewer";
import SponsorList from "../Pages/Settings/SponsorInfo/SponsorList";
import SponsorDetail from "../Pages/Settings/SponsorInfo/SponsorDetail";
import RapList from "../Pages/Apprentice180/RapManagement/RapList";
import Employee from "../Pages/Apprentice180/Employee/Employee";
import WpsSettings from "../Pages/Settings/WpsSettings/WpsSettings";
import NewEmployee from "../Pages/Employees/routes/new";
import RapDetail from "../Pages/Apprentice180/RapManagement/routes";
import NewRapInfo from "../Pages/Apprentice180/RapManagement/routes/new";
import ODataReportDesigner from "../Components/ReportViewers/ODataReportDesigner";
import ReportsTable from "../Pages/reports/ReportsTable";
import ReportSchedulesTable from "../Pages/reports/ReportSchedulesTable";

export const LOCAL_ROUTES = [
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/identity-callback",
    element: <Identity />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/resetpassword-otp",
    element: <ValidateOtp />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute Component={DemoDashboard} />
  },
  {
    path: "/partners",
    element: <ProtectedRoute Component={PartnerList} />
  },
  {
    path: "/partners/:id",
    element: <ProtectedRoute Component={PartnerDetail} />
  },
  {
    path: "/partners/:id/:tab",
    element: <ProtectedRoute Component={PartnerDetail} />
  },
  {
    path: "/settings/job-title/",
    element: <ProtectedRoute Component={JobTitleList} />
  },
  {
    path: "/settings/job-title/:id",
    element: <ProtectedRoute Component={JobTitleForm} />
  },
  {
    path: "/settings/lead-subsource-type/",
    element: <ProtectedRoute Component={LeadSourceList} />
  },
  {
    path: "/settings/lead-subsource-type/:id",
    element: <ProtectedRoute Component={LeadSourceForm} />
  },
  {
    path: "/settings/job-category/",
    element: <ProtectedRoute Component={JobCategoryList} />
  },
  {
    path: "/settings/job-category/:id",
    element: <ProtectedRoute Component={JobCategoryForm} />
  },
  {
    path: "/settings/users/",
    element: <ProtectedRoute Component={UsersList} />
  },
  {
    path: "/settings/users/:id",
    element: <ProtectedRoute Component={UsersForm} />
  },
  {
    path: "/settings/user-permission",
    element: <ProtectedRoute Component={UserPermissions} />
  },
  {
    path: "/settings/sponsor-info",
    element: <ProtectedRoute Component={SponsorList} />
  },
  {
    path: "/settings/sponsor-info/:id",
    element: <ProtectedRoute Component={SponsorDetail} />
  },
  {
    path: "/settings/:settingType/",
    element: <ProtectedRoute Component={TypeSettingList} />
  },
  {
    path: "/settings/:settingType/:id",
    element: <ProtectedRoute Component={TypeSettingForm} />
  },
  {
    path: "/employees",
    element: <ProtectedRoute Component={EmployeeList} />
  },
  {
    path: "/employees/new",
    element: <ProtectedRoute Component={NewEmployee} />
  },
  {
    path: "/employees/:id",
    element: <ProtectedRoute Component={EmployeeDetail} />
  },
  {
    path: "/employees/:id/:tab",
    element: <ProtectedRoute Component={EmployeeDetail} />
  },
  {
    path: "/employees/:id/:tab/add",
    element: <ProtectedRoute Component={ESignature} />
  },
  {
    path: "/projects",
    element: <ProtectedRoute Component={Projects} />
  },
  {
    path: "/projects/:id",
    element: <ProtectedRoute Component={ProjectDetail} />
  },
  {
    path: "/projects/new",
    element: <ProtectedRoute Component={NewProject} />
  },
  {
    path: "/projects/:id/:tab",
    element: <ProtectedRoute Component={ProjectDetail} />
  },
  {
    path: "/rap",
    element: <ProtectedRoute Component={RapMgmtList} />
  },
  {
    path: "/rap/:id/:tab",
    element: <ProtectedRoute Component={RAPDetail} />
  },
  {
    path: "/myaccount",
    element: <ProtectedRoute Component={MyAccount} />
  },
  {
    path: "/reports/rti-report",
    element: <ProtectedRoute Component={DemoRtiReport} />
  },
  {
    path: "/reports/weekly-compliance-report",
    element: <ProtectedRoute Component={DemoWeeklyReport} />
  },
  {
    path: "/reports/lost-report",
    element: <ProtectedRoute Component={DemoLostOtjHours} />
  },
  {
    path: "/verifylink",
    element: <VerifyAccount />
  },
  // Apprentice 180
  {
    path: "/apprentice-180/rap",
    element: <ProtectedRoute Component={RapList} />
  },
  {
    path: "/apprentice-180/employee",
    element: <ProtectedRoute Component={Employee} />
  },
  {
    path: "/apprentice-180/rap/info/new",
    element: <ProtectedRoute Component={NewRapInfo} />
  },
  {
    path: "/apprentice-180/rap/info/:id",
    element: <ProtectedRoute Component={RapDetail} />
  },
  {
    path: "/apprentice-180/rap/info/:id/:tab",
    element: <ProtectedRoute Component={RapDetail} />
  },
  // Reports
  {
    path: "/reports/weekly-compliance-report/export",
    element: <ProtectedRoute Component={HTTPReportViewer} props={{ fileName: "WeeklyComplianceReportLatest.mrt", path: "/weeklyComplianceReport" }} />
  },
  //Settings - AWS Settings
  {
    path: "/settings/wps-settings",
    element: <ProtectedRoute Component={WpsSettings} />
  },
  {
    path: "/report-designer",
    element: <ProtectedRoute Component={ODataReportDesigner} props={{ fileName: "WeeklyComplianceReportLatest.mrt" }} />
  },
  {
    path: "/reports-overview",
    element: <ProtectedRoute Component={ReportsTable} />
  },
  {
    path: "/report-schedules",
    element: <ProtectedRoute Component={ReportSchedulesTable} />
  }
]