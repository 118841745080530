import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { fieldTruePermission } from "../../../utils/formHelper.js";
import ProFormsLayout from "../ProForms/ProFormsLayout/index.jsx";
import FormSectionWrapper from "../ProForms/FormSectionWrapper/index.jsx";
import GenericModal from "../Modals/GenericModal.jsx";
import ProEmailField from "../Inputs/EmailField.jsx";
import ProSelectField from "../Inputs/SelectField.jsx";
import ProDateField from "../Inputs/DateField.jsx";
import { successToast, errorToast } from "../../../utils/toastHelper.js";
import { ACTIVE_PROJECTS_DROPDOWN, GET_ALL_APPRENTICE_LIST, GET_ALL_PARTNERS_RAP_DROPDOWN, PROJECT_PARTNERS, REPORT_SCHEDULES } from "../../../utils/services/apiPath.js";
import { getApi, postApi, putApi } from "../../../utils/services/index.js";
import { fileTypeOptions, scheduleOptions, dailyFrequencyOptions } from "./constants";
import { mapFrequencyToRunFields } from "./helpers";
import dayjs from "dayjs";
import ProRadioGroup from "../Inputs/RadioGroup.jsx";
import { Divider } from "@mui/material";
let utc = require("dayjs/plugin/utc.js")
dayjs.extend(utc)


const ScheduleReportModal = ({ isModalOpen, setIsModalOpen, reportId, reportScheduleId = "", isEditMode = false, setIsDoneEditing }) => {
  const [projectsList, setProjectsList] = useState([]);
  const [apprenticeList, setApprenticeList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [rapList, setRapList] = useState([]);
  const { control, setValue, watch, handleSubmit, getValues } = useForm({
    defaultValues: {
      emailList: "",
      runFrequency: scheduleOptions[0].value,
      dailyFrequency: "",
      starts: "",
      startDate: null,
      ends: "",
      endDate: null,
      fileType: [],
      projectId: null,
      partnerId: null,
      apprenticeId: null,
      startDateFilter: null,
      endDateFilter: null
    }
  });
  const project = watch("projectId");
  const partner = watch("partnerId");

  const fetchReportSchedule = async () => {
    try {
      const data = await getApi(`${REPORT_SCHEDULES}/${reportScheduleId}`)
      for (let k of Object.keys(getValues())) {
        if (k == "startDate" || k == "endDate") {
          continue;
        }
        if (k == "fileType") {
          setValue("fileType", data["reportFormat"])
        }
        if (data.hasOwnProperty(k)) {
          setValue(k, data[k])
        }
      }
    } catch (e) {
      errorToast("Unable to fetch report schedule")
    }
  }
  useEffect(() => {
    if (!isModalOpen) {
      return
    }
    fetchProjects();
    if (!isEditMode) {
      return
    }
    fetchReportSchedule()
  }, [isModalOpen, isEditMode])

  useEffect(() => {
    if (project) {
      fetchPartners();
    }
  }, [project]);

  useEffect(() => {
    if (project && partner) {
      fetchApprentice();
      // fetchPartnerRap();
    }
  }, [project, partner]);

  const fetchProjects = async () => {
    const data = await getApi(ACTIVE_PROJECTS_DROPDOWN);
    setValue("projectId", data[0]?.projectId);
    setProjectsList(data?.map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const fetchPartners = async () => {
    const data = await getApi(`${PROJECT_PARTNERS}?projectId = ${project} `);
    setValue("partnerId", data[0]?.clientGuid);
    setPartnersList(data?.map(item => ({ label: item.companyName, value: item.clientGuid })));
  };

  const fetchApprentice = async () => {
    // TODO: Should probably ask for an active apprentices endpoint in the new api
    const { data } = await getApi(`${GET_ALL_APPRENTICE_LIST}?projectGuid=${project}&partnerGuid=${partner}`);
    setValue("apprenticeId", data[0]?.apprenticeId);
    setApprenticeList(data?.map(item => ({ label: item.apprenticeName, value: item.apprenticeId })));
  };

  // const fetchPartnerRap = async () => {
  //   const { data } = await getApi(`${GET_ALL_PARTNERS_RAP_DROPDOWN}?projectGuid = ${project}& partnerGuid=${partner} `);
  //   if (data?.length > 0) {
  //     setValue("rapId", data[0]?.value);
  //     setRapList(data?.map(item => ({ label: item.label, value: item.value })));
  //   }
  // };

  const [isSaving, setIsSaving] = useState(false);
  const runFrequency = watch("runFrequency");


  const dailyFrequencyLabel = useMemo(() => {
    return (
      {
        weekly: "Weekly Frequency",
        monthly: "Monthly Frequency",
        yearly: "Yearly Frequency",
        daily: "Daily Frequency"
      }[runFrequency] || "Daily Frequency"
    );
  }, [runFrequency]);

  const dailyFrequencyPlaceholder = useMemo(() => {
    return (
      {
        weekly: "Select a weekly frequency",
        monthly: "Select a monthly frequency",
        yearly: "Select a yearly frequency",
        daily: "Select a daily frequency"
      }[runFrequency] || "Select a daily frequency"
    );
  }, [runFrequency]);

  const handleCancel = () => {
    setIsModalOpen(false);
    resetFormFields();
  }

  const formatDate = timestamp => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const getFilterText = ({ startDateFilter, endDateFilter, apprenticeId, projectId }) => {
    let filter = `?projectGuid=${projectId}&apprenticeGuid=${apprenticeId}`
    if (startDateFilter) {
      filter += `&dateFrom=${formatDate(startDateFilter)}`
    }
    if (endDateFilter) {
      filter += `&dateTo=${formatDate(endDateFilter)}`
    }
    return filter
  }

  const resetFormFields = () => {
    setValue("emailList", "");
    setValue("runFrequency", scheduleOptions[0].value);
    setValue("dailyFrequency", "");
    setValue("starts", "");
    setValue("startDate", null);
    setValue("ends", "");
    setValue("endDate", null);
    setValue("fileType", []);
  }

  const handleSave = async formData => {
    setIsSaving(true);
    const runFields = mapFrequencyToRunFields(formData.dailyFrequency);

    const getFormattedDateString = (date) => {
      return date.toISOString().split('T')[1].split('.')[0];
    };

    const midnightUTC = new Date();
    midnightUTC.setTime(0, 0, 0, 0);
    const reportParams = getFilterText(formData)

    const payload = {
      ...runFields,
      reportId: reportId,
      reportFormat: formData.fileType,
      compressFile: true,
      reportParams: "string",
      emailList: formData.emailList || "",
      runFrequency: formData.runFrequency || "Daily",
      runTime: runFrequency == "daily" ? dayjs.utc().format("HH:mm:ss") : getFormattedDateString(midnightUTC),
      //TODO: We don't want it to run by default. We will have an option to run at a specific time eventually
      // runSpecificDate: adjustToUTC(formData.startDate, currentTime) || null,
      startDate: dayjs.utc(formData.startDate).startOf("day") || null,
      endDate: dayjs.utc(formData.endDate).endOf("day") || null,
      isActive: true,
      reportParams: reportParams
    };

    if (isEditMode) {
      payload["reportScheduleId"] = reportScheduleId
    }

    try {
      const method = isEditMode ? putApi : postApi
      const url = isEditMode ? `${REPORT_SCHEDULES}/${reportScheduleId}` : REPORT_SCHEDULES
      const { error } = await method(url, payload);
      if (error) {
        throw new Error(error);
      }
      successToast("Schedule saved successfully!");
      setIsModalOpen(false);
      resetFormFields();
      if (isEditMode) {
        setIsDoneEditing(true)
      }
    } catch (err) {
      errorToast(err.message || "Failed to save the schedule. Please try again.");
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <GenericModal open={isModalOpen} setOpen={setIsModalOpen} modalTitle="Schedule Reports">
      <ProFormsLayout
        title="Schedule Details"
        beingEdited={true}
        onCancel={handleCancel}
        onSave={handleSubmit(handleSave)}
        hideEdit={true}
        isSaving={isSaving}
      >
        <div className="proFormSample-content">
          <FormSectionWrapper>
            <ProEmailField
              label="Email Address"
              name="emailList"
              mode="edit"
              rules={{ required: "Enter your email address" }}
              permission={fieldTruePermission}
              control={control}
              sx={{ width: "100%" }}
            />

            <ProSelectField
              label="Select Frequency"
              options={scheduleOptions}
              permission={fieldTruePermission}
              control={control}
              name="runFrequency"
              defaultValue={scheduleOptions[0].value}
              sx={{ width: "100%" }}
              shouldSort={false}
            />

            <ProSelectField
              label={dailyFrequencyLabel}
              placeholder={dailyFrequencyPlaceholder}
              options={
                runFrequency === "weekly"
                  ? dailyFrequencyOptions.weekly
                  : runFrequency === "monthly"
                    ? dailyFrequencyOptions.monthly
                    : runFrequency === "yearly"
                      ? dailyFrequencyOptions.yearly
                      : []
              }
              permission={fieldTruePermission}
              control={control}
              name="dailyFrequency"
              defaultValue=""
              disabled={runFrequency === "daily"}
              sx={{ width: "100%" }}
              shouldSort={false}
            />

            <ProDateField
              label="Select Start Date"
              permission={fieldTruePermission}
              control={control}
              name="startDate"
              mode="edit"
              sx={{ width: "100%" }}
            />
            <ProDateField
              label="Select End Date"
              permission={fieldTruePermission}
              control={control}
              name="endDate"
              mode="edit"
              sx={{ width: "100%" }}
            />

            <ProRadioGroup
              label="File Type"
              options={fileTypeOptions}
              permission={fieldTruePermission}
              control={control}
              name="fileType"
              sx={{ width: "100%" }}
            />
          </FormSectionWrapper>
          <Divider style={{ paddingBottom: "15px" }}>
            Filters
          </Divider>
          <FormSectionWrapper>
            <ProSelectField
              label="Project"
              options={projectsList}
              permission={fieldTruePermission}
              control={control}
              name="projectId"
              defaultValue={""}
              sx={{ width: "100%" }}
              shouldSort={false}
            />
            <ProSelectField
              label="Partner"
              options={partnersList}
              permission={fieldTruePermission}
              control={control}
              name="partnerId"
              defaultValue={""}
              sx={{ width: "100%" }}
              shouldSort={false}
            />
            <ProSelectField
              label="Apprentice"
              options={apprenticeList}
              permission={fieldTruePermission}
              control={control}
              name="apprenticeId"
              defaultValue={""}
              sx={{ width: "100%" }}
              shouldSort={false}
            />
            <ProDateField
              label="Select Start Date"
              permission={fieldTruePermission}
              control={control}
              name="startDateFilter"
              mode="edit"
              sx={{ width: "100%" }}
            />
            <ProDateField
              label="Select End Date"
              permission={fieldTruePermission}
              control={control}
              name="endDateFilter"
              mode="edit"
              sx={{ width: "100%" }}
            />
          </FormSectionWrapper>

        </div>
      </ProFormsLayout>
    </GenericModal>
  );
};

export default ScheduleReportModal;
