import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Components/Layout";
import DashboardFeed from "../Components/DashboardFeed/DashboardFeed";
import "../DemoStyles/index.scss";
import "./index.scss";
import JourneyToApprentice from "../Components/JourneyToApprentice/JourneyToApprentice";
import ProjectRisk from "../Components/ProjectRisk";
import DashboardJourneyRatio from "../Components/DashboardJourneyRatio";
import DashboardHeatMap from "../Components/DashboardHeatMap";
import { useWindowSize } from "../../../utils/customHooks/useWindowSize";
import { getApi } from "../../../utils/services";
import { DASHBOARD_FEED } from "../../../utils/services/apiPath";
import ProjectDetailModal from "../Components/ProjectDetailModal/ProjectDetailModal";

export default function DemoDashboard() {
  const [dashboardFeedData, setDashboardFeedData] = useState([]);
  const [hideHeatMap, setHideHeatMap] = useState(false);
  const [journeyWrapperHeight, setJourneyWrapperHeight] = useState(261);
  const { width, height } = useWindowSize(true);
  const journeyWrapperRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [feedData, setFeedData] = React.useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const getFeedData = async () => {
    try {
      const { error, data } = await getApi(DASHBOARD_FEED);
      if (!error) setDashboardFeedData(data?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) || []);
    } catch (error) {
      console.error("Error fetching dashboard feed data", error);
    }
  };

  useEffect(() => {
    if (journeyWrapperRef.current?.offsetHeight) {
      setJourneyWrapperHeight(journeyWrapperRef.current.offsetHeight + 3);
    }
  }, [width, height, journeyWrapperRef]);

  useEffect(() => {
    getFeedData();
  }, []);

  return (
    <Layout>
      <div className="demo-dashboard-wrapper">
        <div className="demo-container">
          <div className="demo-dashboard">
            <div className="demo-dashboard-left">
              <DashboardFeed feedJson={dashboardFeedData} handleOpen={handleOpen} setFeedData={setFeedData} />
            </div>
            <div className="demo-dashboard-right">
              <JourneyToApprentice journeyWrapperRef={journeyWrapperRef} />
              <div className="demo-dashboard-bottom" style={{ minHeight: `calc(100% - ${journeyWrapperHeight}px)` }}>
                <div className={`demo-dashboard-bottom-left ${hideHeatMap ? "with-hidden-heatmap" : ""}`}>
                  <DashboardJourneyRatio />
                  <ProjectRisk />
                </div>
                {!hideHeatMap && (
                  <div className="demo-dashboard-bottom-right">
                    <DashboardHeatMap setHideHeatMap={setHideHeatMap} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProjectDetailModal feedData={feedData} open={open} setOpen={setOpen} />
    </Layout>
  );
}
