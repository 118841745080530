import React, { useEffect, useState } from "react";
import JourneyRatioChart from "../../Charts/JourneyRatioChart";
import { getApi } from "../../../../utils/services";
import { GET_OVERALL_JOURNEY_TO_APPRENTICE_RATIO } from "../../../../utils/services/apiPath";
import { errorToast } from "../../../../utils/toastHelper";
import GenericModal from "../../../Components/Modals/GenericModal";
import JtaAverageTable from '../JtaAverageTable/JtaAverageTable';
import ProjectHoursTable from "../ProjectHoursTable/ProjectHoursTable";

export default function DashboardJourneyRatio() {
  const [journeyRatioData, setJourneyRatioData] = useState({});
  const [differenceInfo, setDifferenceInfo] = useState({
    class: "red",
    message: "0 Project below 15%",
    iconColor: "",
    rotateDeg: "0deg"
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalView, setCurrentModalView] = useState("JTA_AVERAGE");

  const getRatio = async () => {
    const { data, error } = await getApi(GET_OVERALL_JOURNEY_TO_APPRENTICE_RATIO);
    if (error) return errorToast(error);
    setJourneyRatioData(data);
    setDifferenceInfo({ rotateDeg: "0deg", class: "red", iconColor: "#CF0000", message: `0 Projects below 15%` });
  };

  useEffect(() => {
    getRatio();
  }, []);

  const handleChartClick = () => {
    setCurrentModalView("JTA_AVERAGE");
    setIsModalOpen(true);
  };

  const handleProjectsClick = () => {
    setCurrentModalView("PROJECT_HOURS");
    setIsModalOpen(true);
  };

  const getModalTitle = () => {
    return currentModalView === "JTA_AVERAGE" ? "JTA Average" : "Project Hours Overview";
  };

  return (
    <div className="djr-Wrapper">
      <div className="djr-header">
        <div className="djr-header-title">JTA Average</div>
      </div>
      <div className="djr-chart">
        <JourneyRatioChart ratio={journeyRatioData.journeyToApprenticeRatio || 0} onChartClick={handleChartClick}  />
      </div>
      <div className="djr-info-card-wrapper">
        <div className={`djr-info-card ${differenceInfo.class}`}>
          {differenceInfo.iconColor && (
            <div style={{ transform: `rotate(${differenceInfo.rotateDeg})` }}>
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.96927 11.6485L0.152807 0.471934C0.0137423 0.273089 0.156003 0 0.398652 0H16.0316C16.2742 0 16.4165 0.273089 16.2774 0.471934L8.46096 11.6485C8.34154 11.8192 8.08869 11.8192 7.96927 11.6485Z"
                  fill="#fff"
                />
              </svg>
            </div>
          )}
          <div className="djr-info-card-label" style={{ cursor: "pointer" }} onClick={handleProjectsClick}>{journeyRatioData.projectsBelowJtoATarget || 0} Project below 15%</div>
        </div>
        <div className="djr-info-card blue">
          <div className="djr-info-card-label">Number of Projects : {journeyRatioData.totalProjects}</div>
        </div>
      </div>
      <GenericModal open={isModalOpen} setOpen={setIsModalOpen} modalTitle={getModalTitle()}>
        {currentModalView === "JTA_AVERAGE" ? <JtaAverageTable /> : <ProjectHoursTable />}
      </GenericModal>
    </div>
  );
}
