import React from "react";
import ProGrid from "../ProGrid/v2";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";

const BackPaymentTable = (backPaymentData) => {
  const data = backPaymentData.backPaymentData;
  const { currentPage, pageSize, rowCount, setPagination, setSortModel } = usePageState();
  const columns = useColumn([
    {
      field: "prevailingWageRate",
      headerName: "Prevailing Wage Rate/Prevailing Wage Fringe",
      width:300
    },
    {
      field: "prevailingWageCombinedRate",
      headerName: "Prevailing Wage Combined Rate",
    },
    {
      field: "actualWageFringe",
      headerName: "Actual Wage Rate/Fringe"
    },
    {
      field: "actualCombinedRate",
      headerName: "Actual Combined Rate"
    },
    {
      field: "backPaymentRate",
      headerName: "Back Payment Rate"
    },
    {
      field: "backPaymentAmount",
      headerName: "Back Payment Amount "
    }
  ]);

  return (
    <ProGrid
      hideSearch={true}
      columns={columns}
      hideAddButton={true}
      hideExport={true}
      rows={data}
      options={{
        getRowId: row => row.id,
        rowCount: rowCount,
        paginationMode: "server",
        paginationModel: { pageSize: pageSize, page: currentPage },
        onPaginationModelChange: setPagination,
        sortingMode: "server",
        onSortModelChange: setSortModel
      }}
    />
  );
};

export default BackPaymentTable;
