import React from "react";
import TableComponent from "../Components/ProGridTable/TableComponent";
import { DateCell } from "../../Components/ProGrid/components/FormatCell";
import ReportingNameCell from "../Components/ProGridTable/ReportingNameCell";
import PropTypes from "prop-types";

const lastotjColumns = [
  { field: "apprenticeName", headerName: "Name", flex: 1, renderCell: params => <ReportingNameCell employeeGuid={params.row.employeeId} value={params.value} /> },
  { field: "jobRole", headerName: "Role", flex: 1 },
  { field: "employeeId", headerName: "Employee ID", flex: 1 },
  {
    field: "lostOtjDate",
    headerName: "Lost OTJ Date & Hours",
    flex: 2,
    renderCell: params => (
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        {params?.value?.map((entry, index) => (
          <div key={Number(index)} style={{ height: "24px", alignItems: "center", justifyContent: "flex-start" }}>
            <span style={{ maxWidth: "80px" }}>
              <DateCell value={entry.date} />
            </span>
            <span style={{ marginLeft: "10px" }}>{entry.time} Hours</span>
          </div>
        ))}
      </div>
    )
  },
  {
    field: "lostOTJReasonList",
    headerName: "Reason",
    flex: 2,
    renderCell: params => (
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        {params?.value?.map((reason, index) => (
          <div
            key={Number(index)}
            style={{
              backgroundColor: reason?.includes("No Mentor") ? "#e1e7ff" : "#ffe6e1",
              color: reason.includes("No Mentor") ? "#00008B" : "#8B0000",
              padding: "2px 10px",
              borderRadius: "5px",
              textAlign: "center",
              height: "24px"
            }}
          >
            {reason}
          </div>
        ))}
      </div>
    )
  },
  {
    field: "mentorNameList",
    headerName: "Mentor Name",
    flex: 2,
    renderCell: params => (
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        {params.row?.data?.map((row, index) => {
          if (row.mentorName === null) {
            const key = `${params.row.apprenticeName.split(' ').join('')}_${index}`
            return (
              <div style={{ height: "24px" }} key={key}>
                NA
              </div>
            )
          }

          return (
            <ReportingNameCell
              key={row.mentorId}
              employeeGuid={row.mentorId}
              value={row.mentorName}
            />
          )
        })}
      </div>
    )
  }
];

function LostOTJTable({ lostOTJHoursDetails, pageState, onPageChange }) {
  return (
    <TableComponent
      columns={lastotjColumns}
      rows={lostOTJHoursDetails}
      title={"Lost OTJ Hours"}
      hasBorder={false}
      loading={pageState.loading}
      toolSetButton={true}
      paginationProps={{
        rowCount: pageState.totalRecord,
        paginationMode: "server",
        paginationModel: { pageSize: pageState.pageSize, page: pageState.pageIndex },
        onPaginationModelChange: onPageChange
      }}
    />
  );
}

LostOTJTable.propTypes = {
  lostOTJHoursDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      employeeName: PropTypes.string.isRequired,
      lostHours: PropTypes.number.isRequired,
      reason: PropTypes.string
    })
  ).isRequired,
  pageState: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    totalRecord: PropTypes.number,
    pageSize: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired
  }).isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default LostOTJTable;
