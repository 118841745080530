import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Checkbox, Button, TextField, Divider } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { successToast, errorToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import { ADMIN_RAP_MENTOR_POOLS } from "../../../../utils/services/apiPath";
import { postApi, deleteApi, getApi } from "../../../../utils/services";
import { useParams } from "react-router-dom";
import { useTheme } from "../../../ContextStore/ThemeContext";
import ProButton from "../../../Components/ProButton";

const MentorPool = () => {
  const { id } = useParams();
  const { theme } = useTheme();

  const [availableMentors, setAvailableMentors] = useState([]);
  const [associatedMentors, setAssociatedMentors] = useState([]);
  const [selectedAvailable, setSelectedAvailable] = useState([]);
  const [selectedAssociated, setSelectedAssociated] = useState([]);
  const [availableSearch, setAvailableSearch] = useState("");
  const [associatedSearch, setAssociatedSearch] = useState("");
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [isSaving, setIsSaving] = useState(false);
  const [mentorAssociated, setMentorAssociated] = useState([]);
  const [mentorDisAssociated, setMentorDisAssociated] = useState([]);

  const handleToggle = (id, selectedList, setSelectedList) => {
    const newSelected = selectedList.includes(id) ? selectedList.filter(item => item !== id) : [...selectedList, id];
    setSelectedList(newSelected);
  };

  const moveMentors = (fromList, toList, selectedIds, setFromList, setToList, setSelected, isAssociated) => {
    const movingMentors = fromList.filter(mentor => selectedIds.includes(mentor.mentorId));
    const updatedFromList = fromList.filter(mentor => !selectedIds.includes(mentor.mentorId));
    setFromList(updatedFromList);
    setToList([...toList, ...movingMentors]);
    isAssociated ? setMentorAssociated(prev => [...prev, ...selectedIds]) : setMentorDisAssociated(prev => [...prev, ...selectedIds]);
    setSelected([]);
  };

  const getMentorPools = useCallback(async () => {
    try {
      const data = await getApi(`${ADMIN_RAP_MENTOR_POOLS}/${id}`);
      setAvailableMentors(data?.availableMentors || []);
      setAssociatedMentors(data?.rapAssociatedMentors || []);
    } catch (error) {
      errorToast("Failed to fetch mentor pools");
    }
  }, [id]);

  const saveMentorPools = async () => {
    if (isSaving) return;

    setIsSaving(true);
    setStatus(STAUTES.LOADING);

    try {
      const payload = { rapInfoId: id, mentorIds: mentorAssociated };
      const deletePayload = { rapInfoId: id, mentorIds: mentorDisAssociated };

      if (mentorAssociated.length > 0) await postApi(ADMIN_RAP_MENTOR_POOLS, payload);
      if (mentorDisAssociated.length > 0) await deleteApi(ADMIN_RAP_MENTOR_POOLS, { data: deletePayload });

      successToast("Mentor Pools saved successfully.");
      getMentorPools();
      setMentorAssociated([]);
      setMentorDisAssociated([]);
    } catch (error) {
      errorToast("Failed to save mentor pools");
    } finally {
      setIsSaving(false);
      setStatus(STAUTES.IDLE);
    }
  };

  useEffect(() => {
    getMentorPools();
  }, [getMentorPools]);

  const filterMentors = (mentors, search) => mentors.filter(mentor => mentor.mentorName.toLowerCase().includes(search.toLowerCase()));

  const handleSelectAll = (mentors, setSelected) => {
    setSelected(mentors.map(mentor => mentor.mentorId));
  };

  const renderMentorList = (mentors, search, setSearch, selected, setSelected, title, onSelectAll) => (
    <Box
      sx={{
        width: "45%",
        height: "480px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: 4,
        margin: 5
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: 1, background: "#DFF0FF" }}>
        <Typography variant="h8" align="left">
          {title}
        </Typography>
        <Button size="small" onClick={onSelectAll}>
          Select All
        </Button>
      </Box>
      <TextField
        fullWidth
        size="small"
        placeholder="Search"
        value={search}
        onChange={e => setSearch(e.target.value)} // Fix this line
        sx={{ mb: 2, mt: 2 }}
      />
      <List
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
          border: "1px solid #ccc",
          borderRadius: "4px"
        }}
      >
        {filterMentors(mentors, search).map(mentor => (
          <ListItem key={mentor.mentorId} dense onClick={() => handleToggle(mentor.mentorId, selected, setSelected)}>
            <ListIcon />
            <ListItemIcon>
              <Checkbox edge="center" checked={selected.includes(mentor.mentorId)} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText sx={{ color: theme === "light" ? "#000" : "#fff" }} primary={mentor.mentorName} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Box
        sx={{
          margin: 4
        }}
      >
        <Typography variant="h6" align="left" sx={{ color: theme === "light" ? "#000" : "#fff" }}>
          Mentor Pool
        </Typography>
        <Divider />
      </Box>
      <Box
        sx={{
          height: "620px",
          border: "2px solid #ccc",
          borderRadius: "8px",
          margin: 4
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2
          }}
        >
          {renderMentorList(availableMentors, availableSearch, setAvailableSearch, selectedAvailable, setSelectedAvailable, "Available Mentors", () =>
            handleSelectAll(filterMentors(availableMentors, availableSearch), setSelectedAvailable)
          )}

          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              onClick={() =>
                moveMentors(
                  availableMentors,
                  associatedMentors,
                  selectedAvailable,
                  setAvailableMentors,
                  setAssociatedMentors,
                  setSelectedAvailable,
                  true
                )
              }
              disabled={selectedAvailable.length === 0}
            >
              <ArrowForwardIcon />
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                moveMentors(
                  associatedMentors,
                  availableMentors,
                  selectedAssociated,
                  setAssociatedMentors,
                  setAvailableMentors,
                  setSelectedAssociated,
                  false
                )
              }
              disabled={selectedAssociated.length === 0}
            >
              <ArrowBackIcon />
            </Button>
          </Box>

          {renderMentorList(
            associatedMentors,
            associatedSearch,
            setAssociatedSearch,
            selectedAssociated,
            setSelectedAssociated,
            "Mentors Associated With the RAP",
            () => handleSelectAll(filterMentors(associatedMentors, associatedSearch), setSelectedAssociated)
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 4,
            gap: 2
          }}
        >
          {/* <ProButton variant="outlined" onClick={saveMentorPools}>
            Cancel
          </ProButton> */}
          <ProButton onClick={saveMentorPools}>Save</ProButton>
        </Box>
      </Box>
    </div>
  );
};

export default MentorPool;
