import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import usePageState from "../../../../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../../../../utils/constant";
import { errorToast, successToast } from "../../../../../../utils/toastHelper";
import { Box, Button, CircularProgress } from "@mui/material";
import { ADMIN_STUDENT_COURSES } from "../../../../../../utils/services/apiPath";
import { getApi, postApi } from "../../../../../../utils/services";
import useColumn from "../../../../../../utils/customHooks/useColumns";
import ProGrid from "../../../../../Components/ProGrid/v2";
import { dateColumnDef } from "../../../../../Components/ProGrid/components/FormatCell";

export default function CourseEnrollment() {
  const { id } = useParams();
  const [selectionModel, setSelectionModel] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { currentPage, pageSize, data: coursesData, status, sortModel, searchString, setPageState, setStatus } = usePageState();

  const onCancel = () => {
    setSelectionModel([]);
  };

  const onSave = async () => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = {
      courseIds: selectionModel,
    };
    const url = ADMIN_STUDENT_COURSES.replace(":employee_id", id)
    const { error } = await postApi(url, payload);
    setIsSaving(false);
    if (error) return errorToast(error);
    successToast("Employee has been successfully enrolled.");
    setStatus(STAUTES.IDLE);
  };

  const getAllCourses = useCallback(async () => {
    setStatus(STAUTES.LOADING);

    const url = ADMIN_STUDENT_COURSES.replace(":employee_id", id)
    const { data, totalRecords, error } = await getApi(url);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);

    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
    setSelectionModel(data.filter(item => item.isAlreadyApplied).map(item => item.id));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const handleSelectionChange = newSelectionModel => {
    setSelectionModel(newSelectionModel);
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  const columns = useColumn(
    [
      {
        field: "title",
        headerName: "Title",
        renderCell: params => {
          return <Box>{params.row.title.raw}</Box>;
        }
      },
      dateColumnDef({
        field: "dateCreated",
        headerName: "Created Date"
      })
    ],
    true
  );

  return (
    <>
      <ProGrid
        title="Courses"
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.id,
          pagination: false,
          style: { maxHeight: "500px" },
          rowSelectionModel: selectionModel,
          checkboxSelection: true,
          onRowSelectionModelChange: newSelectionModel => handleSelectionChange(newSelectionModel)
        }}
        columns={columns}
        hideAddButton
        hideExport
        rows={coursesData}
      />
      <div className="proForm-layout__footer">
        <div className="proForm-layout__footer__actions">
          <Button className="cancel-btn" variant="outlined" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSave} disabled={isSaving}>
            {isSaving ? (
              <>
                <CircularProgress size={24} />
                <span style={{ marginLeft: 10 }}>Save</span>
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </>
  );
}