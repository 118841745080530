import { Box, Button, Typography } from "@mui/material";
import React from "react";
import BackPaymentTable from "./BackPaymentTable";
import FormSectionWrapper from "../ProForms/FormSectionWrapper";
import InputWrapper from "../Inputs/InputWrapper";
import DateField from "../Inputs/DateField";
import ProSelectField from "../Inputs/SelectField";
import ProFileUploadField from "../Inputs/FileUploadField";
import { useForm } from "react-hook-form";
import { correctionDocumnetOptions, correctionReviewOptions } from "../../../utils/dropdownHelper";
import { putApi } from "../../../utils/services";
import { convertFileToBase64Model, renderFormattedDate, convertResponseDate } from "../../../utils/formHelper";
import { HISTORY_OF_UNDERPAYMENT_CORRECTION_REVIEW, HISTORY_OF_UNDERPAYMENT_CORRECTION_INFO } from "../../../utils/services/apiPath";

const BackPaymentCorrectionDetail = ({ data, closeBackPaymentModel }) => {
  const isCorrectionInfoEditable = !data.isProjectCreator || data.approvalStatus === "Denied" || data.correctionDate === null;
  const isCorrectionReviewApproved = data.approvalStatus === "Approved";

  const getValueByLabel = (options, label) => {
    const option = options.find(opt => opt.label === label);
    return option ? option.value : null;
  };
  const getLabelByValue = (options, value) => {
    const option = options.find(opt => opt.value === value);
    return option ? option.label : null;
  };
  const { control, handleSubmit } = useForm({
    defaultValues: {
      correctionDate: convertResponseDate(data?.correctionDate),
      correctionDocumentType: getValueByLabel(correctionDocumnetOptions, data.correctionDocumentType),
      correctionDocument: null,
      correctionReview: getValueByLabel(correctionReviewOptions, data.approvalStatus),
      decisionDate: convertResponseDate(data?.approvalOrRejectionDate)
    }
  });
  const downloadDocument = s3Url => {
    window.open(s3Url, "_blank");
  };
  const onSubmit = async formData => {
    try {
      if (isCorrectionInfoEditable) {
        const payloadCorrectionInfo = {
          correctionDate: formData.correctionDate,
          correctionDocumentType: getLabelByValue(correctionDocumnetOptions, formData.correctionDocumentType),
          documentMediaModel: await convertFileToBase64Model(formData.correctionDocument)
        };
        const responseCorrectionInfo = await putApi(
          `${HISTORY_OF_UNDERPAYMENT_CORRECTION_INFO}/${data?.historyOfUnderpaymentGuid}`,
          payloadCorrectionInfo
        );
      }
      if (data.isProjectCreator) {
        const payloadCorrectionReview = {
          approvalStatus: getLabelByValue(correctionReviewOptions, formData.correctionReview),
          approvedOrRejectedDate: formData.decisionDate
        };
        const responseCorrectionReview = await putApi(
          `${HISTORY_OF_UNDERPAYMENT_CORRECTION_REVIEW}/${data?.historyOfUnderpaymentGuid}`,
          payloadCorrectionReview
        );
      }
      closeBackPaymentModel();
    } catch (error) {
      console.error("Error-", error);
    }
  };

  const backPaymentData = [
    {
      id: data?.historyOfUnderpaymentGuid,
      prevailingWageRate: `$${data?.pwWageRate}/$${data?.pwWageFringe}`,
      prevailingWageCombinedRate: `$${data?.pwCombinedRate}`,
      actualWageFringe: `$${data?.actualWageRate}/$${data?.actualWageFringe}`,
      actualCombinedRate: `$${data?.actualCombinedRate}`,
      backPaymentRate: `$${data?.backPaymentRate}`,
      backPaymentAmount: `$${data?.backPaymentAmount}`
    }
  ];

  const readViewInput = (label, value) => {
    return (
      <InputWrapper>
        <div className="proInputWrapper-read-label">{label}</div>
        <div className="proInputWrapper-read-value">{value}</div>
      </InputWrapper>
    );
  };
  const downloadableDocument = (label, fileName, filePath) => {
    return (
      <InputWrapper>
        <div className="proInputWrapper-read-label">{label}</div>
        <div
          className="proInputWrapper-read-value"
          onClick={() => downloadDocument(filePath)}
          style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
        >
          {fileName}
        </div>
      </InputWrapper>
    );
  };

  return (
    <>
      <Box sx={{ marginY: "10px" }}>
        <Typography sx={{ fontWeight: "bold", padding: "0 1rem" }}>
          Pay Period End Date:&nbsp;<Typography component={"span"}>{renderFormattedDate(data.weekEndingDate)}</Typography>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0 1rem" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Project:&nbsp;<Typography component={"span"}>{data.projectName}</Typography>
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            Partner:&nbsp;<Typography component={"span"}>{data.partnerName}</Typography>
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0 1rem" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            Employee Name:&nbsp;<Typography component={"span"}>{data.employeeName}</Typography>
          </Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            Labor Classification:&nbsp;<Typography component={"span"}>{data.laborClassification}</Typography>
          </Typography>
        </Box>
        <Box>
          <BackPaymentTable backPaymentData={backPaymentData} />
        </Box>
        <Box sx={{ padding: "0 1rem" }}>
          <FormSectionWrapper title={"Correction Info"}>
            {isCorrectionReviewApproved || !isCorrectionInfoEditable ? (
              readViewInput("Correction Date", renderFormattedDate(data.correctionDate))
            ) : (
              <DateField
                label="Correction Date"
                name="correctionDate"
                control={control}
                mode="edit"
                permission={{ canView: true, canAdd: true, canEdit: true }}
              />
            )}
            {isCorrectionReviewApproved || !isCorrectionInfoEditable ? (
              readViewInput("Correction Document Type", data.correctionDocumentType)
            ) : (
              <ProSelectField
                label="Correction Document Type"
                name="correctionDocumentType"
                control={control}
                mode="edit"
                permission={{ canView: true, canAdd: true, canEdit: true }}
                options={correctionDocumnetOptions}
              />
            )}
            {isCorrectionReviewApproved || !isCorrectionInfoEditable ? (
              downloadableDocument("Correction Document", data.documentMediaModel?.fileName, data.documentMediaModel?.path)
            ) : (
              <ProFileUploadField
                label="Correction Document"
                name="correctionDocument"
                inputProps={{ accept: "file/*" }}
                control={control}
                mode="edit"
                permission={{ canView: true, canAdd: true, canEdit: true }}
              />
            )}
          </FormSectionWrapper>
          {data.isProjectCreator && (
            <FormSectionWrapper title={"Correction Review"}>
              {isCorrectionReviewApproved ? (
                readViewInput("Correction Review", data.approvalStatus)
              ) : (
                <ProSelectField
                  label="Correction Review"
                  name="correctionReview"
                  control={control}
                  mode="edit"
                  permission={{ canView: true, canAdd: true, canEdit: true }}
                  options={correctionReviewOptions}
                />
              )}
              {isCorrectionReviewApproved ? (
                readViewInput("Decision Date", renderFormattedDate(data.approvalOrRejectionDate))
              ) : (
                <DateField
                  label="Decision Date"
                  name="decisionDate"
                  control={control}
                  mode="edit"
                  permission={{ canView: true, canAdd: true, canEdit: true }}
                />
              )}
            </FormSectionWrapper>
          )}
        </Box>
      </Box>
      <Box sx={{ padding: "0 1rem" }}>
        {!isCorrectionReviewApproved && (
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        )}
      </Box>
    </>
  );
};

export default BackPaymentCorrectionDetail;
