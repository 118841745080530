import { AccessAlarm } from "@mui/icons-material";
import { headerMenus } from "../../../utils/MenusConfig";
import { decryptText, encryptText } from "../../../utils/crypto";
import { R_REPORTS, R_PAYROLL_REPORTS } from "../../../utils/permissionReferenceConfig";
import ReportsIcon from "../../Assets/IconComponents/Reports";

export const setPermissionToLocalStorage = permissions => {
  const encryptedPermission = encryptText(JSON.stringify(permissions));
  localStorage.setItem("permissions", encryptedPermission);
};

export const getPermissionFromLocalStorage = () => {
  const permission = localStorage.getItem("permissions");
  return permission ? decryptText(permission) : {};
};

export const addReferenceToPermission = permission => {
  const permissionObject = {};
  permission.forEach(item => {
    const moduleReference = item.moduleName.toUpperCase().split(" ").join("_");
    const subModulePermissionsObject = {};
    item.subModulePermissions.forEach(subItem => {
      const subModuleReference = `${moduleReference}_${subItem.subModuleName.toUpperCase().split(" ").join("_")}`;
      subModulePermissionsObject[subModuleReference] = {
        ...subItem,
        reference: subModuleReference
      };
    });
    permissionObject[moduleReference] = {
      ...item,
      reference: moduleReference,
      subModulePermissions: subModulePermissionsObject
    };
  });
  return permissionObject;
};

const groupByCategory = arr => {
  return arr.reduce((acc, obj) => {
    const category = obj.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(obj);
    return acc;
  }, {});
};

const defaultReportsHeaderTransformer = reportRoutes => {
  const grouped = groupByCategory(reportRoutes);
  const submenus = [];
  submenus.push({
    link: "/reports-overview",
    title: "Edit/Schedule Reports",
    reference: R_PAYROLL_REPORTS,
    hasArrow: false,
    ignorePermission: true,
    reloadDocument: true
  });
  submenus.push({
    link: "/report-schedules",
    title: "View Report Schedules",
    reference: R_PAYROLL_REPORTS,
    hasArrow: false,
    ignorePermission: true,
    hasBreak: true,
    reloadDocument: true
  });
  for (const [key, value] of Object.entries(grouped)) {
    const subMenuTitle = `${key}`;

    const submenu = {
      hasArrow: false,
      ignorePermission: true,
      link: "rti",
      title: subMenuTitle,
      reference: R_REPORTS,
      subMenus: value.map(sm => {
        return {
          hasArrow: false,
          ignorePermission: true,
          link: sm.path,
          title: sm.title,
          openInNewTab: true,
          reference: R_PAYROLL_REPORTS
        };
      })
    };
    submenus.push(submenu);
  }
  return submenus;
};

const customReportsHeaderTransformer = reportRoutes => {
  const grouped = groupByCategory(reportRoutes);
  const submenus = [];
  const menu = {
    to: "/custom",
    title: "Custom Reports",
    reference: R_REPORTS,
    hasArrow: true,
    icon: ReportsIcon
  };
  submenus.push({
    link: "/reports-overview?customOnly=true",
    title: "View/Add Custom Reports",
    hasArrow: false,
    ignorePermission: true,
    hasBreak: true,
    reloadDocument: true
  });
  for (const [key, value] of Object.entries(grouped)) {
    const subMenuTitle = key;

    const submenu = {
      hasArrow: false,
      ignorePermission: true,
      link: "rti",
      title: subMenuTitle,
      reference: R_REPORTS,
      subMenus: value.map(sm => {
        return {
          hasArrow: false,
          ignorePermission: true,
          link: sm.path,
          title: sm.title,
          openInNewTab: true,
          reference: R_PAYROLL_REPORTS
        };
      })
    };
    submenus.push(submenu);
  }
  if (reportRoutes.length <= 0) {
    submenus.push({
      hasArrow: false,
      ignorePermission: true,
      title: "No custom reports created"
    });
  }

  return { ...menu, isAccessible: true, subMenus: submenus };
};

export const headerPermissionTransformer = (permissions, customReportRoutes, defaultReportRoutes) => {
  const accesibleMenu = headerMenus.map(item => {
    const itemPermission = permissions[item.reference];
    if (item.title == "Reports") {
      item.subMenus = defaultReportsHeaderTransformer(defaultReportRoutes);
    }

    return {
      ...item,
      isAccessible: itemPermission?.canView || item.ignorePermission,
      subMenus: item.subMenus.filter(subItem => subItem.ignorePermission || itemPermission?.subModulePermissions?.[subItem.reference]?.canView)
    };
  });
  const customReportsMenu = customReportsHeaderTransformer(customReportRoutes);
  accesibleMenu.splice(-1, 0, customReportsMenu);
  return accesibleMenu.filter(item => item.isAccessible);
};
