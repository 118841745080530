import { useSelector } from "react-redux";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { R_PROJECTS, R_PROJECTS_LIST } from "../../../../utils/permissionReferenceConfig";
import Layout from "../../../Components/Layout";
import AddForm from "../ProjectDetail/AddForm";

export default function NewProject() {
  const { canView } = useSelector(subModPermissionSelector(R_PROJECTS, R_PROJECTS_LIST));
  return (
    <Layout canView={canView}>
      <AddForm />
    </Layout>
  )
}