import React, { useState, useRef, useEffect } from 'react'
import DashBoardTable from '../ProGridTable/DashBoardTable'
import { getApi } from '../../../../utils/services'
import { GET_JTA_RISK_GAUGE_DATA } from '../../../../utils/services/apiPath'

const JourneyApprenticeRisk = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const hasFetchedData = useRef(false);
  const columns = [
    { field: "project", headerName: "Project" },
    { field: "partnerName", headerName: "Partner" },
    { field: "totalJtoA", headerName: "Total JtoA" },
    { field: "otjHours", headerName: "Total OTJ Hours" },
    { field: "totalHours", headerName: "Total Labor Hours" },
    { field: "apprenticeCount", headerName: "Total Apprentice Count" },
    { field: "mentorCount", headerName: "Total Mentor Count" },
    { field: "totalLaborCount", headerName: "Total Labor Count" },
    { field: "participationRequirementMeet", headerName: "Participation Requirement Met?" },
  ];

  const fetchJTARiskData = async () => {
      setLoading(true);
      try {
        const { data, error } = await getApi(GET_JTA_RISK_GAUGE_DATA);
        if (error) { throw new Error(error);}

        const rowsWithId = data.map((item, index) => ({
          id: `row-${index}`,
          project: item.project,
          partnerName: item.partnerName,
          totalJtoA: item.totalJtoA,
          otjHours: item.otjHours,
          totalHours: item.totalHours,
          apprenticeCount: item.apprenticeCount,
          mentorCount: item.mentorCount,
          totalLaborCount: item.totalLaborCount,
          participationRequirementMeet: item.participationRequirementMeet,
        }));
        setRows(rowsWithId);
      }  
      finally {
        setLoading(false);
      }
  };  

  useEffect(() => {
    if (!hasFetchedData.current) {
      hasFetchedData.current = true;
      fetchJTARiskData();
    }
  }, []);

  return (
    <div style={{ marginTop: "20px" }}>
      <DashBoardTable
        columns={columns}
        rows={rows}
        getRowId={(row) => row.projectId}
        loading={loading}
        paginationModel={{ page: 0, pageSize: 5 }}
        hideExport={false}
        checkboxSelection={false}
      />
    </div>
  );
};

export default JourneyApprenticeRisk;