import ComplianceNavigatorBaseTable from "./ComplianceNavigatorBaseTable";
import { useCallback, useEffect, useState } from "react";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";
import ComplianceNavigatorActionButtons from "./ComplianceNavigatorActionButtons";
import PropTypes from "prop-types";
import UploadFieldsData from "./UploadFieldsData";
import { errorToast } from "../../../utils/toastHelper";
import { v4 as uuidV4 } from "uuid";
import { postApi } from "../../../utils/services";
import { GET_ALL_COMPLIANCE_INSIGHT_DATA, GET_ALL_COMPLIANCE_TABLE_DATA } from "../../../utils/services/apiPath";

const PrevailingWageSummaryTable = ({ tableKey, projectId, partnerId }) => {
  const {
    currentPage,
    pageSize,
    data: tableData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const [insightData, setInsightData] = useState();

  const fetchInsightData = async () => {
    const res = await postApi(`${GET_ALL_COMPLIANCE_INSIGHT_DATA}?key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`);
    setInsightData(res);
  };
  const columns = useColumn([
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "partnerName",
      headerName: "Partner Name"
    },
    {
      field: "jobTitle",
      headerName: "Job Title"
    },
    {
      field: "state",
      headerName: "State"
    },
    {
      field: "county",
      headerName: "County"
    },
    {
      headerName: "Actions",
      field: "actions",
      renderCell: params => <ComplianceNavigatorActionButtons params={params} />
    }
  ]);

  const getTableData = useCallback(async () => {
    const payload = {
      pageIndex: currentPage + 1,
      pageSize: pageSize,
      orderByAscending: sortModel.orderByAscending || true,
      searchString: searchString || "",
      orderCol: sortModel.orderCol || ""
    };
    const { data, totalRecords, error } = await postApi(`${GET_ALL_COMPLIANCE_TABLE_DATA}?key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`, payload);

    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data?.map(row => ({ ...row, id: uuidV4() })) || [] || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus])

  useEffect(() => {
    if (tableKey && partnerId && projectId) {
      fetchInsightData();
    }
  }, [projectId, partnerId, tableKey]);

  useEffect(() => {
    getTableData()
  }, [getTableData])

  const handleSave = (file, handleClose) => {
    console.log(file);
    handleClose();
  };

  return (
    <ComplianceNavigatorBaseTable
      columns={columns}
      title="Prevailing Wage"
      rows={tableData}
      insightData={insightData}
      tableKey={tableKey}
      rowCount={rowCount}
      status={status}
      setPagination={setPagination}
      setSortModel={setSortModel}
      searchString={searchString}
      setSearchString={setSearchString}
      getRowId={row => row.id}
      pageSize={pageSize}
      currentPage={currentPage}
      uploadPopUp={
        <UploadFieldsData
          form="PrevailingWageSummary"
          handleSave={handleSave}
        />
      }
    />
  );
};

PrevailingWageSummaryTable.propTypes = {
  tableKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired
};

export default PrevailingWageSummaryTable;
