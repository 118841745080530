import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import HighRiskIcon from "./HighRiskIcon";
import LowRiskIcon from "./LowRiskIcon";
import MedRiskIcon from "./MedRiskIcon";
import { GET_DASHBOARD_PROJECT_RISK_DATA } from "../../../../utils/services/apiPath";
import { getApi } from "../../../../utils/services";
import { errorToast } from "../../../../utils/toastHelper";
import GenericModal from "../../../Components/Modals/GenericModal";
import ProjectDocumentationRisKTable from "../ProjectDocumentationRisKTable";
import PrevailingWageRisk from "../PrevailingWageRisk";
import PartnerRisk from "../PartnerRisk";
import JourneyApprenticeRisk from "../JourneyApprenticeRisk";

const iconObj = {
  high: <HighRiskIcon />,
  medium: <MedRiskIcon />,
  low: <LowRiskIcon />
};

const ProjectRiskItem = ({ title, value, onClick }) => {
  return (
    <div className="djr-risk-card" onClick={() => onClick(title)} style={{ cursor: "pointer" }}>
      <div className="djr-risk-card-title-wrapper">
        <div className="djr-risk-card-title">{title}</div>
      </div>
      <div className="djr-risk-card-icon">{iconObj[value]}</div>
    </div>
  );
};

ProjectRiskItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOf(["high", "medium", "low"]).isRequired,
  onClick: PropTypes.func
};

export default function ProjectRisk() {
  const [projectRiskData, setProjectRiskData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [currentModalTitle, setCurrentModalTitle] = useState("");

  const handleItemClick = title => {
    setCurrentModalTitle(title);
    setIsModalOpen(true); 
  };

  const getModalContent = () => {
    switch (currentModalTitle) {
      case "Project Documentation Risk":
        return <ProjectDocumentationRisKTable />;
      case "Prevailing Wage Risk":
        return <PrevailingWageRisk />;
      case "Partner Risk":
        return <PartnerRisk />;
      case "Journey to Apprentice Risk":
        return <JourneyApprenticeRisk />;
      default:
        return <p>General Information</p>;
    }
  };

  const fetchProjectRiskData = async () => {
    const { data, error } = await getApi(GET_DASHBOARD_PROJECT_RISK_DATA);
    if (error) return errorToast(error);
    const mappedData = data.map(item => ({
      title: item.projectRiskTitle,
      value: item.projectRating.toLowerCase()
    }));
    setProjectRiskData(mappedData);
  };

  useEffect(() => {
    fetchProjectRiskData();
  }, []);

  return (
    <div className="djr-Wrapper project-rise-wrapper">
      <div className="djr-header with-dropdown">
        <div className="djr-header-title-wrapper">
          <div className="djr-header-title">Project Risk</div>
        </div>
      </div>
      <div className="djr-risk">
        {projectRiskData?.map((risk, index) => (
          <ProjectRiskItem key={Number(index)} title={risk.title} value={risk.value} onClick={handleItemClick} />
        ))}
      </div>
      <GenericModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        modalTitle={currentModalTitle} 
      >
        {getModalContent()} 
      </GenericModal>
    </div>
  );
}
