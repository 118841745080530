import RapInfoProfile from "../../Pages/Apprentice180/RapManagement/RapInfoProfile";

const getAppendixAformFields = (formData, approvedJobTitleOptions,rapInfo) => [
  {
    label: "Approved Job Title",
    value: formData.approvedJobTitle,
    key: "approvedJobTitle",
    type: "select",
    options: approvedJobTitleOptions || []
  },
  {
    label: "Sponsors",
    value: rapInfo?.sponsor,
    key: "sponsor",
    type: "input",
  },
  {
    label: "RAPID Code",
    value: rapInfo?.rapidCode,
    key: "rapidCode",
    type: "input",
  },
  {
    label: "Program Length",
    value: rapInfo?.programLengthYears,
    key: "programLengthYears",
    type: "input",
  }
];
const getAppendixDformFields = formData => [
  {
    label: "Project Name",
    value: formData.project,
    key: "project",
    type: "select",
    options: ["Wagon Wheel Flat II", "Other Project"]
  },
  {
    label: "Partner Name",
    value: formData.partner,
    key: "partner",
    type: "select",
    options: ["Stark Energy Solutions", "Other Partner"]
  },
  {
    label: "Job Title",
    value: formData.jobTitle,
    key: "jobTitle",
    type: "select",
    options: ["Plumber Operator", "Other Job"]
  },
  {
    label: "Sponsor",
    value: formData.sponsor,
    key: "sponsor",
    type: "select",
    options: ["Proscore"]
  },
  {
    label: "ONET Code",
    value: formData.onetCode,
    key: "onetCode",
    type: "input"
  }
];

const CLRformFields = formData => [
  {
    label: "Project Name",
    value: formData.project,
    key: "project",
    type: "select",
    options: ["Wagon Wheel Flat II", "Other Project"]
  },
  {
    label: "Partner Name",
    value: formData.partner,
    key: "partner",
    type: "select",
    options: ["Stark Energy Solutions", "Other Partner"]
  },
  {
    label: "Job Title",
    value: formData.jobTitle,
    key: "jobTitle",
    type: "select",
    options: ["Plumber Operator", "Other Job"]
  },
  {
    label: "State",
    value: formData.state,
    key: "sponsor",
    type: "select",
    options: ["Texas"]
  },
  {
    label: "County",
    value: formData.county,
    key: "sponsor",
    type: "select",
    options: ["Andrews"]
  }
];
const CPRformFields = formData => [
  {
    label: "Project Name",
    value: formData.project,
    key: "project",
    type: "select",
    options: ["Wagon Wheel Flat II", "Other Project"]
  },
  {
    label: "Partner Name",
    value: formData.partner,
    key: "partner",
    type: "select",
    options: ["Stark Energy Solutions", "Other Partner"]
  },
  {
    label: "Week Starting Date",
    value: formData.weekStartDate,
    key: "dateField",
    type: "date"
  },
  {
    label: "Week Ending Date",
    value: formData.weekEndDate,
    key: "dateField",
    type: "date"
  }
];

const sixSevenOneformFields = formData => [
  {
    label: "Project Name",
    value: formData.project,
    key: "project",
    type: "select",
    options: ["Wagon Wheel Flat II", "Other Project"]
  },
  {
    label: "Partner Name",
    value: formData.partner,
    key: "partner",
    type: "select",
    options: ["Stark Energy Solutions", "Other Partner"]
  },
  {
    label: "Apprentice Name",
    value: formData.apprenticeName,
    key: "partner",
    type: "select",
    options: ["Thora Aelson", "Other Apprentice"]
  },
  {
    label: "Job Title",
    value: formData.jobTitle,
    key: "jobTitle",
    type: "select",
    options: ["Plumber Operator", "Other Job"]
  },
  {
    label: "Sponsor",
    value: formData.sponsor,
    key: "sponsor",
    type: "select",
    options: ["Proscore"]
  }
];

const prevailingWageSummaryformFields = formData => [
  {
    label: "Project Name",
    value: formData.project,
    key: "project",
    type: "select",
    options: ["Wagon Wheel Flat II", "Other Project"]
  },
  {
    label: "Partner Name",
    value: formData.partner,
    key: "partner",
    type: "select",
    options: ["Stark Energy Solutions", "Other Partner"]
  },
  {
    label: "Apprentice Name",
    value: formData.apprenticeName,
    key: "partner",
    type: "select",
    options: ["Thora Aelson", "Other Apprentice"]
  },
  {
    label: "Job Title",
    value: formData.jobTitle,
    key: "jobTitle",
    type: "select",
    options: ["Plumber Operator", "Other Job"]
  },
  {
    label: "Sponsor",
    value: formData.sponsor,
    key: "sponsor",
    type: "select",
    options: ["Proscore"]
  }
];

const uploadHistoryOfUnderpaymentFormFields = formData => [
  {
    label: "Correction Date",
    value: formData.correctionDate,
    key: "correctionDate",
    type: "date"
  },
  {
    label: "Correction Document Type",
    value: formData.correctionDocumentType,
    key: "correctionDocumentType",
    type: "select",
    options: ["Check Stub", "Cancelled Check", "ACH Confirmation"]
  }
];

export const getFormFieldsByType = (type, formData,rapInfo) => {
  console.log(rapInfo);
  switch (type) {
    case "AppendixA":
      return getAppendixAformFields(formData, formData.approvedJobTitleOptions,rapInfo);
    case "AppendixD":
      return getAppendixDformFields(formData);
    case "CLRFiled":
      return CLRformFields(formData);
    case "CPR":
      return CPRformFields(formData);
    case "SixSevenOne":
      return sixSevenOneformFields(formData);
    case "PrevailingWageSummary":
      return prevailingWageSummaryformFields(formData);
    case "UploadHistoryOfUnderpayment":
      return uploadHistoryOfUnderpaymentFormFields(formData);
    default:
      throw new Error(`Unknown form type: ${type}`);
  }
};
