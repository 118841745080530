import React, { useCallback, useState, useEffect } from "react";
import ComplianceNavigatorBaseTable from "./ComplianceNavigatorBaseTable";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";
import PropTypes from "prop-types";
import { DateCell } from "../ProGrid/components/FormatCell";
import { Box, Button } from "@mui/material";
import GenericModal from "../Modals/GenericModal";
import BackPaymentCorrectionDetail from "./BackPaymentCorrectionDetail";
import { GET_ALL_HISTORY_OF_UNDERPAYMENT, HISTORY_OF_UNDERPAYMENT_SUMMARY } from "../../../utils/services/apiPath";
import { STAUTES } from "../../../utils/constant";
import { getApi } from "../../../utils/services";
import { createODataQuery } from "../../../utils/services/oData";
import { errorToast } from "../../../utils/toastHelper";

const HistoryOfUnderpaymentsAndProofOfCorrectionsAndFinesTable = ({ tableKey, projectId, partnerId }) => {
  const {
    currentPage,
    pageSize,
    data: historyData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const [isBackPaymentDetailOpen, setIsBackPaymentDetailOpen] = useState(false);
  const [selectedBackPaymentDetail, setSelectedBackPaymentDetail] = useState(null);
  const [insightSummary, setInsightSummary] = useState([]);

  const columns = useColumn([
    {
      field: "weekEndingDate",
      headerName: "PPE Date",
      renderCell: params => (
        <Box onClick={() => backPaymentDetails(params.row)}>
          <Button className="proGrid-name" variant="text" color="primary">
            <DateCell value={params.value} />
          </Button>
        </Box>
      )
    },
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "partnerName",
      headerName: "Partner"
    },
    {
      field: "employeeName",
      headerName: "Employee Name"
    },
    {
      field: "backPaymentAmount",
      headerName: "Back Payment Amount",
      width: 200
    },
    {
      field: "correctionDate",
      headerName: "Correction Date",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "correctionDocumentType",
      headerName: "Correction Document Type",
      width: 200
    },
    {
      field: "approvalStatus",
      headerName: "Approved/Denied"
    },
    {
      field: "approvalOrRejectionDate",
      headerName: "Decision Date",
      renderCell: params => <DateCell value={params.value} />
    }
  ]);

  const getHistoryOfUnderPaymentList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const searchableFields = ["projectName", "partnerName", "employeeName", "correctionDocumentType", "approvalStatus"];
    const { data, totalRecords, error } = await getApi(
      GET_ALL_HISTORY_OF_UNDERPAYMENT + createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage)
    );
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getHistoryOfUnderPaymentSummary = useCallback(async () => {
    const summary = await getApi(`${HISTORY_OF_UNDERPAYMENT_SUMMARY}?key=${tableKey}`);
    setInsightSummary(summary);
  }, []);

  const backPaymentDetails = data => {
    setSelectedBackPaymentDetail(data);
    setIsBackPaymentDetailOpen(true);
  };

  const closeBackPaymentModel = () => {
    setIsBackPaymentDetailOpen(false);
  };

  useEffect(() => {
    if (!isBackPaymentDetailOpen) getHistoryOfUnderPaymentList();
  }, [getHistoryOfUnderPaymentList, isBackPaymentDetailOpen]);

  useEffect(() => {
    if (!isBackPaymentDetailOpen) getHistoryOfUnderPaymentSummary();
  }, [isBackPaymentDetailOpen]);

  return (
    <>
      <ComplianceNavigatorBaseTable
        columns={columns}
        title="History Of Underpayments And Proof Of Corrections And Fines Table"
        rows={historyData}
        insightData={insightSummary}
        tableKey={"Underpayment Correction"}
        rowCount={rowCount}
        status={status}
        searchString={searchString}
        setPagination={setPagination}
        setSortModel={setSortModel}
        setSearchString={setSearchString}
        getRowId={row => row.historyOfUnderpaymentGuid}
        pageSize={pageSize}
        currentPage={currentPage}
      />
      <GenericModal open={isBackPaymentDetailOpen} setOpen={setIsBackPaymentDetailOpen} modalTitle={"Back Payment and Correction Detail"}>
        <BackPaymentCorrectionDetail data={selectedBackPaymentDetail} closeBackPaymentModel={closeBackPaymentModel} />
      </GenericModal>
    </>
  );
};

HistoryOfUnderpaymentsAndProofOfCorrectionsAndFinesTable.propTypes = {
  tableKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired
};

export default HistoryOfUnderpaymentsAndProofOfCorrectionsAndFinesTable;
