import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material";

const LostOTJWorkforceHours = ({ data }) => {
  const theme = useTheme();
  const options = {
    chart: {
      type: "column",
      marginTop: 50,
      backgroundColor: theme.palette.demo.lostotjbackgroundcolor
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: data.xAxisData,
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.demo.lostotjcolor
        }
      },
      lineColor: "#F8F8FF"
    },
    yAxis: {
      min: 0,
      title: {
        text: ""
      },
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.demo.lostotjcolor
        }
      }
    },
    series: [
      {
        name: "Hours",
        data: data.lostOtjData,
        color: "#1F88E6",
        stack: "data",
        borderWidth: 0
      }
    ],
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 40,
        dataLabels: {
          enabled: false
        }
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

LostOTJWorkforceHours.propTypes = {
  data: PropTypes.shape({
    xAxisData: PropTypes.arrayOf(PropTypes.string).isRequired,
    lostOtjData: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired
};

export default LostOTJWorkforceHours;
