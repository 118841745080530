import React from "react";
import "./SubHeader.scss";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Menu, MenuItem, Button, useTheme } from "@mui/material";
import { ExpandMore, KeyboardArrowRight } from "@mui/icons-material";

const formatKey = (key) => {
  return key.split(' ').join('-').toLowerCase()
}

const MuiSubMenuWrapper = ({ menuItemLabel, subMenus }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ color: theme.palette.subheaderv2.menuLabel, display: "flex", justifyContent: "space-between", padding: "10px", width: "100%" }}
      >
        <div>
          {menuItemLabel}
        </div>
        <KeyboardArrowRight fontSize="small" sx={{ display: "inline-block", verticalAlign: "middle", marginLeft: "10px" }} />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {subMenus.map((menu) => {
          return (
            <div>
              {menu.openInNewTab ?
                <Link to={`/${menu.link}`} target="_blank">
                  <MenuItem key={formatKey(menu.title)} sx={{ width: "100%" }}>{menu.title}</MenuItem>
                </Link>
                :
                <Link to={`${menu.link}`}>
                  <MenuItem key={formatKey(menu.title)} sx={{ width: "100%" }}>{menu.title}</MenuItem>
                </Link>
              }
            </div>
          )
        })}
      </Menu>
    </div>
  );
}

const MuiMenuWrapper = ({ dropdownTitle, Icon, hasArrow, href, subMenus }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigator = useNavigate()
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="tab"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        href={href}
      >
        <div className="tab_main">
          <Icon color={theme.palette.subheaderv2.icons} />
          <span className="menu-title">{dropdownTitle}</span>
          {hasArrow && <ExpandMore htmlColor={theme.palette.subheaderv2.icons} />}
        </div>
      </Button>
      {hasArrow &&
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {subMenus.map((menu) => {
            return (
              <div>
                {
                  menu.subMenus && menu.subMenus.length > 0 ?
                    <MuiSubMenuWrapper key={formatKey(menu.title)} menuItemLabel={menu.title} subMenus={menu.subMenus} />
                    :
                    <div>
                      <Link reloadDocument={menu.reloadDocument} to={`${menu.link}`} target={menu.openInNewTab ? "_blank" : ""}>
                        <MenuItem key={formatKey(menu.title)} sx={{ width: "100%" }}>{menu.title}</MenuItem>
                      </Link>
                      {menu.hasBreak && <hr />}
                    </div>
                }
              </div>
            )
          })}
        </Menu>
      }
    </>
  );
}

const SubHeaderV2 = ({ tabs }) => {
  return (
    <div className="subheader">
      {tabs.map(tab => (
        <div className="tab_main" key={formatKey(tab.title)}>
          <MuiMenuWrapper
            dropdownTitle={tab.title}
            Icon={tab.icon}
            hasArrow={tab.hasArrow}
            href={tab.hasArrow ? "#" : tab.to}
            subMenus={tab.subMenus}
          />
        </div>
      ))}
    </div>
  );
};

export default SubHeaderV2;

SubHeaderV2.propTypes = {
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func.isRequired
};