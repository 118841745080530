import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";
import { useForm } from "react-hook-form";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProMobileField from "../../../Components/Inputs/MobileField";
import {
  changeMode,
  changeModeForField,
  convertFileToBase64Model,
  fieldTruePermission,
  partnersProfile,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { postApi } from "../../../../utils/services";
import {
  ADD_PARTNER,
  DYNAMIC_DROPDOWN,
  GET_ALL_PARTNERS_TYPES,
  GET_CLIENT_SUBSOURCE,
  GET_PARTNER_BY_ID,
  UPDATE_PARTNER
} from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import ProCheckbox from "../../../Components/Inputs/CheckboxField";
import ProMultiSelectSelectField from "../../../Components/Inputs/MultiSelectField";
import ProFileUploadField from "../../../Components/Inputs/FileUploadField";
import Loading from "../../../Components/Loading";
import usePermission from "../../../../utils/customHooks/usePermission";
import { partnersProfilePermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { Box } from "@mui/material";
import DunsField from "../../../Components/Inputs/DunsField";
import ZipField from "../../../Components/Inputs/ZipField";
import UrlField from "../../../Components/Inputs/UrlField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { PartnerProfileFormConfing } from "../PartnerData";

export default function PartnerProfile() {
  const { id } = useParams();
  const [categoryTypesData, setCategoryTypesData] = useState([]);
  const [profileId] = useState("");
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(PartnerProfileFormConfing));
  const [partnerProfileData, setPartnerProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const navigate = useNavigate();
  const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(PartnerProfileFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const [sourceTypeData, setSourceTypeData] = useState([]);
  const [subSourceTypeData, setSubSourceTypeData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const copy = watch("copyMailingAddress");
  const { clientSourceTypeId } = watch();
  const {
    "Partner Information": partnerinfo,
    "Mailing Address": partnermailingaddress,
    "Billing Address": partnerbillingaddress
  } = usePermission(partnersProfilePermissionSelector, partnersProfile);

  const isAddMode = useMemo(() => id === "add", [id]);
  const backAction = () => navigate("/partners");

  const onEdit = () => setFormConfig(changeMode("edit"));

  const onCancel = () => (isAddMode ? navigate("/partners") : setFormConfig(changeMode()));

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const createPayload = async (data, profileId, id, copy) => {
    const base64ProfilePicture = await convertFileToBase64Model(data.logo);
    return {
      ...data,
      clientProfileId: profileId,
      clientId: id,
      clientTypeIds: data?.clientTypeId ? data?.clientTypeId?.map(({ value }) => value) : [],
      logoMediaModel: base64ProfilePicture || null,
      logo: base64ProfilePicture?.base64String || "",
      isOtherSubSource: clientSourceTypeId === "00000000-0000-0000-0000-000000000000",
      clientSourceTypeId: data?.clientSourceTypeId || null,
      clientSubSourceTypeId: data?.clientSubSourceTypeId || null,
      billingAddress1: copy ? data?.mailingAddress1 : data?.billingAddress1,
      billingAddress2: copy ? data?.mailingAddress2 : data?.billingAddress2,
      billingCity: copy ? data?.mailingCity : data?.billingCity,
      billingState: copy ? data?.mailingState : data?.billingState,
      billingEmail: copy ? data?.mailingEmail : data?.billingEmail,
      billingZip: copy ? data?.mailingZip : data?.billingZip
    };
  };

  const onSubmit = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const ClientModal = categoryTypesData.filter(item => item?.value === data?.clientMasterModel);
    setStatus(STAUTES.LOADING);
    if (ClientModal) {
      const payload = await createPayload(data, profileId, id, copy);
      const { error, data: partner } = await postApi(isAddMode ? ADD_PARTNER : UPDATE_PARTNER, payload);
      setStatus(STAUTES.IDLE);
      setIsSaving(false);
      if (error) return errorToast(error);
      successToast(`Partner ${isAddMode ? "Added" : "Updated"} Successfully`);
      if (isAddMode) {
        navigate(`/partners/${partner?.guidId}`);
      } else {
        setPartnerProfileData(data);
        onCancel();
      }
    }
  };

  const getPartnerProfileData = async id => {
    setFormConfig(changeMode("read"));
    setIsLoading(true);
    const { error, data: partners } = await postApi(GET_PARTNER_BY_ID, { clientId: id });
    if (error) {
      setIsLoading(false);
      return errorToast(error);
    }
    const { clientMasterModel } = partners;
    const { logoMediaModel } = partners;
    const data = {
      ...partners,
      clientTypeId: clientMasterModel,
      logo: logoMediaModel?.path
    };
    setPartnerProfileData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
    setIsLoading(false);
  };

  const getAllProfileTypes = async () => {
    const { data } = await postApi(GET_ALL_PARTNERS_TYPES, defaultDropdownConfig);
    const options = data.map(item => ({ label: item.clientType, value: item.clientTypeId }));
    setCategoryTypesData(options);
  };
  useEffect(() => {
    if (isAddMode) onEdit();
    if (!isAddMode) getPartnerProfileData(id);
    getAllProfileTypes();
    getAllSourceType();
  }, [id]);

  const getAllSourceType = async () => {
    const payload = ["CLIENTSOURCETYPE"];
    const data = await postApi(DYNAMIC_DROPDOWN, payload);
    setSourceTypeData(data[0]?.typeDropDowns?.map(item => ({ label: item.label, value: item.value })));
  };

  const getAllSubSourceType = async clientSourceTypeId => {
    const res = await postApi(`${GET_CLIENT_SUBSOURCE}?sourceId=${clientSourceTypeId}`);
    setSubSourceTypeData(res?.map(item => ({ label: item.label, value: item.value })));
  };

  useEffect(() => {
    if (clientSourceTypeId) getAllSubSourceType(clientSourceTypeId);
  }, [clientSourceTypeId]);

  const defaultFormProps = { control, formValues: partnerProfileData, handleReadValueClick };

  return (
    <div className="partner-profile">
      <ProFormsLayout
        status={status}
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Partner`}
        backLabel="Go Back to All Partners"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        hideEdit={!(partnerinfo.canEdit || partnerbillingaddress.canEdit || partnermailingaddress.canEdit)}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        {!isLoading ? (
          <>
            <FormSectionWrapper title={"PARTNER INFORMATION"}>
              <ProTextInput {...defaultFormProps} {...formConfig.companyName} permission={partnerinfo.fieldPerObj[formConfig.companyName.perKey]} />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.legalBusinessName}
                permission={partnerinfo.fieldPerObj[formConfig.legalBusinessName.perKey]}
              />
              <UrlField {...defaultFormProps} {...formConfig.website} permission={partnerinfo.fieldPerObj[formConfig.website.perKey]} />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.industrySector}
                permission={partnerinfo.fieldPerObj[formConfig.industrySector.perKey]}
              />
              <DunsField {...defaultFormProps} {...formConfig.dunsNumber} permission={partnerinfo.fieldPerObj[formConfig.dunsNumber.perKey]} />
              <ProTextInput {...defaultFormProps} {...formConfig.taxId} permission={partnerinfo.fieldPerObj[formConfig.taxId.perKey]} />
              <ProMultiSelectSelectField
                {...defaultFormProps}
                {...formConfig.clientTypeId}
                options={categoryTypesData}
                permission={partnerinfo.fieldPerObj[formConfig.clientTypeId.perKey]}
              />
              <ProMobileField
                {...defaultFormProps}
                {...formConfig.mainContactNumber}
                permission={partnerinfo.fieldPerObj[formConfig.mainContactNumber.perKey]}
              />
              <ProFileUploadField
                {...defaultFormProps}
                {...formConfig.logo}
                inputProps={{ accept: "image/*" }}
                permission={partnerinfo.fieldPerObj[formConfig.logo.perKey]}
              />
              <ProSelectField {...defaultFormProps} {...formConfig.clientSourceTypeId} options={sourceTypeData} permission={fieldTruePermission} />
              {clientSourceTypeId == "00000000-0000-0000-0000-000000000000" ? (
                <ProTextInput {...defaultFormProps} {...formConfig.otherSubSource} permission={fieldTruePermission} />
              ) : (
                <ProSelectField
                  {...defaultFormProps}
                  {...formConfig.clientSubSourceTypeId}
                  options={subSourceTypeData}
                  permission={fieldTruePermission}
                />
              )}
            </FormSectionWrapper>
            <FormSectionWrapper title={"MAILING ADDRESS"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.mailingAddress1}
                permission={partnermailingaddress.fieldPerObj[formConfig.mailingAddress1.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.mailingAddress2}
                permission={partnermailingaddress.fieldPerObj[formConfig.mailingAddress2.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.mailingCity}
                permission={partnermailingaddress.fieldPerObj[formConfig.mailingCity.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.mailingState}
                permission={partnermailingaddress.fieldPerObj[formConfig.mailingState.perKey]}
              />
              <ZipField
                {...defaultFormProps}
                {...formConfig.mailingZip}
                permission={partnermailingaddress.fieldPerObj[formConfig.mailingZip.perKey]}
              />
              {/* <ProEmailField
                {...defaultFormProps}
                {...formConfig.mailingEmail}
                permission={partnermailingaddress.fieldPerObj[formConfig.mailingEmail.perKey]}
              /> */}
            </FormSectionWrapper>
            {isAddMode && (
              <>
                <ProCheckbox {...defaultFormProps} {...formConfig.copyMailingAddress} checked={copy} />
                <Box sx={{ pb: 2 }} />
              </>
            )}
            {!copy && (
              <FormSectionWrapper title={"BILLING ADDRESS"}>
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.billingAddress1}
                  permission={partnerbillingaddress.fieldPerObj[formConfig.billingAddress1.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.billingAddress2}
                  permission={partnerbillingaddress.fieldPerObj[formConfig.billingAddress2.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.billingCity}
                  permission={partnerbillingaddress.fieldPerObj[formConfig.billingCity.perKey]}
                />
                <ProTextInput
                  {...defaultFormProps}
                  {...formConfig.billingState}
                  permission={partnerbillingaddress.fieldPerObj[formConfig.billingState.perKey]}
                />
                <ZipField
                  {...defaultFormProps}
                  {...formConfig.billingZip}
                  permission={partnerbillingaddress.fieldPerObj[formConfig.billingZip.perKey]}
                />
                {/* <ProEmailField
                  {...defaultFormProps}
                  {...formConfig.billingEmail}
                  permission={partnerbillingaddress.fieldPerObj[formConfig.billingEmail.perKey]}
                /> */}
              </FormSectionWrapper>
            )}
          </>
        ) : (
          <Loading />
        )}
      </ProFormsLayout>
    </div>
  );
}
