import React from "react";
import PropTypes from "prop-types";
import "../../GlobalStyles/_rewrite-mui.scss";
import RTIProgression from "../Charts/RTIProgression";
import RTIMonthlyCadence from "../Charts/RTIMonthlyCadence";
import DailyReport from "../Charts/DailyReport";
import { Box, Card, CardHeader, Divider, Grid, Typography, CircularProgress, useTheme } from "@mui/material";

export default function MiddleCharts({ rtiProgression, monthlyCadence, dailyReport, progressionLoader, cadenceLoader }) {
  const theme = useTheme();
  const formatDate = timestamp => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${month}/${day}`;
  };
  const lineColor = ["#002158", "#1f88e6", "#427EE0", "#00935E"];

  const processData = data => {
    let graphData = [];
    data?.forEach((item, index) => {
      let rtiData = [];
      item?.data?.forEach(rti => {
        rtiData.push(Number(rti?.totalRTI.toFixed(2)));
      });
      graphData.push({
        name: formatDate(item?.weekEndingDate),
        data: rtiData,
        color: lineColor[index],
        marker: {
          enabled: true,
          radius: 5,
          symbol: "circle",
          fillColor: "#FFFFFF",
          lineWidth: 2,
          lineColor: lineColor[index]
        }
      });
    });
    return graphData;
  };
  const chartData = processData(rtiProgression);
  const isRTIProgression = chartData?.length > 0;

  const processDataBar = data => {
    const monthNames = [];
    const totalRTIHours = [];
    const estimatedHours = [];

    data?.forEach(item => {
      monthNames.push(item.monthName);
      totalRTIHours.push(Number(item.totalRTI.toFixed(2)));
      estimatedHours.push(Number(item.estimatedHours.toFixed(2)));
    });

    return {
      monthNames: monthNames,
      totalRTIHours: totalRTIHours,
      estimatedHours: estimatedHours
    };
  };
  const monthlyCadenceData = processDataBar(monthlyCadence);

  return (
    <div>
      <Grid container mt={3} spacing={2} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <Card
            className="demo-chart-card"
            sx={{
              minHeight: "100%",
              border: `1px solid transparent`
            }}
          >
            <CardHeader
              title={
                <Typography fontSize={16} fontWeight="500" className="demo-chart-card-heading">
                  RTI Progression
                </Typography>
              }
            />
            <Divider />
            {progressionLoader && !isRTIProgression ? (
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {progressionLoader ? (
                  <CircularProgress />
                ) : (
                  <Typography fontSize={14} fontWeight="400" color={theme.palette.demo.rtireportcolor}>
                    No RTI Progression Data to Display
                  </Typography>
                )}
              </Box>
            ) : (
              <RTIProgression chartData={chartData} />
            )}
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <Card
            className="demo-chart-card"
            sx={{
              minHeight: "100%",
              border: `1px solid transparent`
            }}
          >
            <CardHeader
              title={
                <Typography fontSize={16} fontWeight="500" className="demo-chart-card-heading">
                  Monthly Cadence
                </Typography>
              }
            />
            <Divider />
            {cadenceLoader ? (
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <RTIMonthlyCadence monthlyCadenceData={monthlyCadenceData} />
            )}
          </Card>
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Card
            sx={{
              minHeight: "100%",
              border: `1px solid transparent`,
              backgroundColor: "#F6F5FF"
            }}
          >
            {/* <DailyReport dailyReport={dailyReport} /> */}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

MiddleCharts.propTypes = {
  rtiProgression: PropTypes.arrayOf(
    PropTypes.shape({
      weekEndingDate: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          totalRTI: PropTypes.number
        })
      )
    })
  ),
  monthlyCadence: PropTypes.arrayOf(
    PropTypes.shape({
      monthName: PropTypes.string,
      totalRTI: PropTypes.number,
      estimatedHours: PropTypes.number
    })
  ),
  dailyReport: PropTypes.object,
  progressionLoader: PropTypes.bool,
  cadenceLoader: PropTypes.bool
};
