import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import NotificationWarningIcon from "../../../Assets/IconComponents/NotificationWarningIcon";
import NotificationInfoIcon from "../../../Assets/IconComponents/NotificationInfoIcon";

const FeedListItemIcon = ({ eventCategoryTypeId }) => {
  if ([3, 4, 5].includes(eventCategoryTypeId)) {
    return <NotificationInfoIcon />;
  }

  return <NotificationWarningIcon />;
};

const FeedListItem = ({ info, handleOpen, setFeedData }) => {
  const { eventDescription, eventCategoryTypeId, createdDate } = info;
  const openProjectFeed = () => {
    if ([2, 6, 8, 9, 10, 11, 12].includes(eventCategoryTypeId)) {
      setFeedData(info);
      handleOpen();
    }
  };
  return (
    <div className="dashboard-feed-list-item">
      <div className="dashboard-feed-list-item-icon">
        <FeedListItemIcon eventCategoryTypeId={eventCategoryTypeId} />
      </div>
      <div className="dashboard-feed-list-item-detail" onClick={openProjectFeed}>
        <div className="detail-title">{eventDescription}</div>
        <div className="detail-date">{dayjs(createdDate).format("MM/DD/YYYY")}</div>
      </div>
    </div>
  );
};

FeedListItem.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }).isRequired
};

export default function FeedList({ feedJson, handleOpen, setFeedData }) {
  return (
    <div className="dashboard-feed-list">
      {feedJson?.map((feed, index) => (
        <FeedListItem key={Number(index)} info={feed} handleOpen={handleOpen} setFeedData={setFeedData} />
      ))}
    </div>
  );
}

FeedList.propTypes = {
  feedJson: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired
    })
  ).isRequired
};
