import React from "react";
import PropTypes from "prop-types";
import "../../GlobalStyles/_rewrite-mui.scss";
import WeeklyComplianceLostOTJ from "../Charts/WeeklyComplianceLostOTJ";
import WeeklyComplianceWorkforceHours from "../Charts/WeeklyComplianceWorkforceHours";
import { Card, CardHeader, Divider, Grid, Typography, Box, CircularProgress, useTheme } from "@mui/material";
import dayjs from "dayjs";

export default function MiddleCharts({ weeklyComplianceReport, loader }) {
  const theme = useTheme();
  const workforceHours = weeklyComplianceReport?.workforceHours;

  const processData = data => {
    const weekEndingDates = [];
    const otjHours = [];
    const lostOTJHours = [];
    data?.forEach(item => {
      weekEndingDates.push(dayjs(item.weekEndingDate, "YYYY-MM-DD").format("MMM DD"));
      otjHours.push(Number(item.otjHours.toFixed(2)));
      lostOTJHours.push(Number(item.lostOTJHours.toFixed(2)));
    });

    const graphData = {
      xAxisData: weekEndingDates,
      otjHoursData: otjHours,
      lostOtjData: lostOTJHours
    };
    return graphData;
  };

  const graphData = processData(weeklyComplianceReport?.lostOTJs);
  const isLostOtjData = graphData?.xAxisData?.length > 0;
  const isWorkforceHoursData = workforceHours?.journeyMansHours === 0 && workforceHours?.mentorHours === 0 && workforceHours?.apprenticeHours === 0;

  return (
    <div>
      <Grid container mt={3} spacing={2} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <Card
            className="demo-chart-card"
            sx={{
              minHeight: "100%",
              border: `1px solid transparent`
            }}
          >
            <CardHeader
              title={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="dashboard-feed-option-left">
                    <Typography fontSize={16} fontWeight="500" className="demo-chart-card-heading">
                      Lost OTJ
                    </Typography>
                  </div>
                </div>
              }
            />
            <Divider />
            {loader || !isLostOtjData ? (
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {loader ? (
                  <CircularProgress />
                ) : (
                  !isLostOtjData && (
                    <Typography fontSize={14} fontWeight="400" color={theme.palette.demo.weeklyreportcolor}>
                      No Lost OTJ Data to Display
                    </Typography>
                  )
                )}
              </Box>
            ) : (
              <WeeklyComplianceLostOTJ data={graphData} />
            )}
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <Card
            className="demo-chart-card"
            sx={{
              minHeight: "100%",
              border: `1px solid transparent`
            }}
          >
            <CardHeader
              title={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="dashboard-feed-option-left">
                    <Typography fontSize={16} fontWeight="500" className="demo-chart-card-heading">
                      Workforce Hours
                    </Typography>
                  </div>
                </div>
              }
            />
            <Divider />
            {loader || isWorkforceHoursData ? (
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {loader ? (
                  <CircularProgress />
                ) : (
                  isWorkforceHoursData && (
                    <Typography fontSize={14} fontWeight="400" color={theme.palette.demo.weeklyreportcolor}>
                      No Workforce Hours Data to Display
                    </Typography>
                  )
                )}
              </Box>
            ) : (
              <WeeklyComplianceWorkforceHours data={workforceHours} />
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

MiddleCharts.propTypes = {
  weeklyComplianceReport: PropTypes.shape({
    lostOTJs: PropTypes.arrayOf(
      PropTypes.shape({
        weekEndingDate: PropTypes.string,
        otjHours: PropTypes.number,
        lostOTJHours: PropTypes.number
      })
    ),
    workforceHours: PropTypes.shape({
      journeyMansHours: PropTypes.number,
      mentorHours: PropTypes.number,
      apprenticeHours: PropTypes.number
    })
  }),
  loader: PropTypes.bool
};
