import React from "react";
import PropTypes from "prop-types";

const ReportsIcon = ({ color = "none" }) => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 4V2C14 0.89 13.11 0 12 0H8C6.89 0 6 0.89 6 2V4H0V17C0 18.11 0.89 19 2 19H18C19.11 19 20 18.11 20 17V4H14ZM8 2H12V4H8V2ZM18 17H2V6H18V17ZM7 16L14.5 11L7 7V16Z"
        fill="white"
      />
    </svg>
  );
};

ReportsIcon.propTypes = {
  color: PropTypes.string
};

export default ReportsIcon;
