import { Stimulsoft } from "../../../utils/stimulsoftDashboard/Scripts/stimulsoft.viewer";
import { Stimulsoft as Designer } from "../../../utils/stimulsoftDashboard/Scripts/stimulsoft.designer";
import { BASE_STIMULUS_KEY, BASE_STIMULSOFT_URL } from "../../../utils/constant";
import { postApi, putApi } from "../../../utils/services";
import { REPORTS } from "../../../utils/services/apiPath";
import { reloadCustomReports } from "../../store/routerSlice";
import { useDispatch } from "react-redux";

const ODataReport = class {
  constructor({ width = "100%", height = "100vh", theme = Stimulsoft.Viewer.StiViewerTheme.Office2022WhiteBlue, reportFile, accessToken }) {
    this.viewerOptions = new Stimulsoft.Viewer.StiViewerOptions();
    this.viewerOptions.width = width;
    this.viewerOptions.height = height;
    this.viewerOptions.appearance.theme = theme;
    this.viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
    this.viewer.onBeginProcessData = event => {
      event.connectionString = BASE_STIMULSOFT_URL;
      event.headers.push({ key: "Authorization", value: `Bearer ${accessToken}` });
    };
    Stimulsoft.Base.StiLicense.key = BASE_STIMULUS_KEY;

    this.report = Stimulsoft.Report.StiReport.createNewDashboard();
    this.report.loadFile(reportFile);
    this.viewer.report = this.report;
  }

  viewer = () => {
    return this.viewer;
  };
};

const ODataReportS3 = class {
  constructor({
    width = "100%",
    height = "100vh",
    theme = Stimulsoft.Viewer.StiViewerTheme.Office2022WhiteBlue,
    reportFile,
    accessToken,
    filters,
    reportId,
    reportCategoryId,
    fromTemplate,
    reportName,
    filePath,
    isDefault
  }) {
    this.viewerOptions = new Stimulsoft.Viewer.StiViewerOptions();
    this.viewerOptions.width = width;
    this.viewerOptions.height = height;
    this.viewerOptions.appearance.theme = theme;
    this.viewer = new Stimulsoft.Viewer.StiViewer(this.viewerOptions, "StiViewer", false);
    this.viewer.onBeginProcessData = event => {
      event.connectionString = BASE_STIMULSOFT_URL;
      event.report.variables.entries.set("filtertext", { key: "filterText", value: filters });
      event.headers.push({ key: "Authorization", value: `Bearer ${accessToken}` });
    };
    Stimulsoft.Base.StiLicense.key = BASE_STIMULUS_KEY;

    this.report = Stimulsoft.Report.StiReport.createNewDashboard();
    this.report.saveTo;
    this.report.onBeginProcessData = event => {
      event.dataSource = "";
    };
    this.report.load(reportFile);
    this.viewer.report = this.report;

    let options = new Designer.Designer.StiDesignerOptions();
    options.appearance.fullScreenMode = true;

    this.designer = new Designer.Designer.StiDesigner(options, "StiDesigner", false);
    this.designer.onBeginProcessData = event => {
      event.connectionString = BASE_STIMULSOFT_URL;
      event.report.variables.entries.set("filtertext", { key: "filterText", value: filters });
      event.headers = [];
      event.headers.push({ key: "Authorization", value: `Bearer ${accessToken}` });
    };
    this.designer.onSaveReport = async function (args) {
      args.preventDefault = true;
      let report = args.report;
      const jsonString = report.saveToJsonString();
      const apiMethod = !isDefault ? putApi : postApi;
      const url = !isDefault ? `${REPORTS}/${reportId}` : REPORTS;

      const encoder = new TextEncoder();
      const byteArray = encoder.encode(jsonString);
      let base64Report = Stimulsoft.System.Convert.toBase64String(byteArray);
      try {
        await apiMethod(url, {
          dataType: "odata",
          paramString: "",
          reportCategoryId: reportCategoryId,
          reportName: reportName,
          reportFile: {
            base64String: base64Report,
            fileName: filePath.replace("/", "")
          }
        });
        if (isDefault) {
          alert("Successfuly created new custom report! Please switch to that report to continue editing.");
        }
      } catch (e) {
        console.log(e);
      }
    };
    this.designer.report = this.report;
  }

  viewer = () => {
    return this.viewer;
  };

  designer = () => {
    return this.designer;
  };
};

const HTTPReportS3 = class {
  constructor({
    width = "100%",
    height = "100vh",
    theme = Stimulsoft.Viewer.StiViewerTheme.Office2022WhiteBlue,
    reportFile,
    accessToken,
    filters,
    reportId,
    reportCategoryId,
    fromTemplate,
    reportName,
    filePath,
    isDefault
  }) {
    this.viewerOptions = new Stimulsoft.Viewer.StiViewerOptions();
    this.viewerOptions.width = width;
    this.viewerOptions.height = height;
    this.viewerOptions.appearance.theme = theme;
    this.viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
    this.viewer.onBeginProcessData = event => {
      event.connectionString = BASE_STIMULSOFT_URL;
      const pathname = new URL(event.pathData).pathname;
      event.pathData = `${BASE_STIMULSOFT_URL}${pathname}`;
      event.headers[0]["Authorization"] = `Bearer ${accessToken}`;
    };
    Stimulsoft.Base.StiLicense.key = BASE_STIMULUS_KEY;

    this.report = Stimulsoft.Report.StiReport.createNewDashboard();
    this.report.load(reportFile);
    this.report.dictionary.databases.list[0].headers[0]["value"] = `Bearer ${accessToken}`;
    this.viewer.report = this.report;

    let options = new Designer.Designer.StiDesignerOptions();
    options.appearance.fullScreenMode = true;

    this.designer = new Designer.Designer.StiDesigner(options, "StiDesigner", false);
    this.designer.onBeginProcessData = event => {
      event.connectionString = BASE_STIMULSOFT_URL;
      event.report.variables.entries.set("filtertext", { key: "filterText", value: filters });
      event.headers = [];
      event.headers.push({ key: "Authorization", value: `Bearer ${accessToken}` });
    };

    this.designer.onSaveReport = async function (args) {
      args.preventDefault = true;
      let report = args.report;
      const jsonString = report.saveToJsonString();
      const apiMethod = !isDefault ? putApi : postApi;
      const url = !isDefault ? `${REPORTS}/${reportId}` : REPORTS;

      const encoder = new TextEncoder();
      const byteArray = encoder.encode(jsonString);
      let base64Report = Stimulsoft.System.Convert.toBase64String(byteArray);
      try {
        await apiMethod(url, {
          dataType: "json",
          paramString: "",
          reportCategoryId: reportCategoryId,
          reportName: reportName,
          reportFile: {
            base64String: base64Report,
            fileName: filePath.replace("/", "")
          }
        });
        if (isDefault) {
          alert("Successfuly created new custom report! Please switch to that report to continue editing.");
        }
      } catch (e) {
        console.log(e);
      }
    };
    this.designer.report = this.report;
  }

  viewer = () => {
    return this.viewer;
  };

  designer = () => {
    return this.designer;
  };
};

const HTTPReport = class {
  constructor({ width = "100%", height = "100vh", theme = Stimulsoft.Viewer.StiViewerTheme.Office2022WhiteBlue, path, reportFile, accessToken }) {
    this.viewerOptions = new Stimulsoft.Viewer.StiViewerOptions();
    this.viewerOptions.width = width;
    this.viewerOptions.height = height;
    this.viewerOptions.appearance.theme = theme;
    this.viewer = new Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
    this.viewer.onBeginProcessData = event => {
      event.connectionString = BASE_STIMULSOFT_URL;
      event.pathData = `${BASE_STIMULSOFT_URL}${path}`;
      event.headers[0]["Authorization"] = `Bearer ${accessToken}`;
    };
    Stimulsoft.Base.StiLicense.key = BASE_STIMULUS_KEY;

    this.report = Stimulsoft.Report.StiReport.createNewDashboard();
    this.report.loadFile(reportFile);
    this.report.dictionary.databases.list[0].headers[0]["value"] = `Bearer ${accessToken}`;
    this.viewer.report = this.report;
  }

  viewer = () => {
    return this.viewer;
  };
};

export { ODataReport, HTTPReport, ODataReportS3, HTTPReportS3 };
