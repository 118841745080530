import React from "react";
import { TextField, Typography, Box, Icon } from "@mui/material";

const ClrTextInput = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  icon: IconComponent,
  multiline,
  rows,
  error,
  errorMessage,
  startAdornment = null
}) => {
  return (
    <>
      {label && (
        <Typography variant="h6" className="field-label">
          {label}
        </Typography>
      )}
      <Box display="flex" mt={1} gap={2}>
        <TextField
          fullWidth
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          multiline={multiline}
          rows={rows}
          error={error}
          helperText={error ? errorMessage : ""}
          className="input-fields"
          InputProps={{ startAdornment }}
        />
        {IconComponent && (
          <Box>
            <Icon component={IconComponent} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ClrTextInput;
