import React from "react";

const NotificationInfoIcon = props => (
  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.0771 27.7339C13.0832 27.7339 14.7614 26.3037 15.1468 24.4107H7.00732C7.3929 26.3037 9.0711 27.7339 11.0771 27.7339Z"
      fill="#008CBE"
    />
    <path
      d="M19.4691 17.2061C19.4403 17.2061 19.4137 17.2105 19.3849 17.2105C14.4988 17.2105 10.5233 13.2351 10.5233 8.34898C10.5233 6.42921 11.1436 4.65589 12.1849 3.20242V2.25643C12.1849 1.64389 11.6886 1.14879 11.077 1.14879C10.4657 1.14879 9.9694 1.64389 9.9694 2.25643V3.45291C6.21749 3.99345 3.32313 7.22024 3.32313 11.1182V14.2065C3.32313 16.3986 2.36274 18.4679 0.677851 19.8923C0.247045 20.2613 0 20.7973 0 21.3645C0 22.4336 0.869524 23.3031 1.93843 23.3031H20.2157C21.2848 23.3031 22.1543 22.4336 22.1543 21.3645C22.1543 20.7973 21.9073 20.2613 21.4653 19.8836C20.5923 19.1447 19.9189 18.2253 19.4691 17.2061Z"
      fill="#008CBE"
    />
    <path
      d="M20.1724 0C15.8494 0 12.3449 3.50457 12.3449 7.82754C12.3449 12.1508 15.8494 15.6551 20.1724 15.6551C24.4955 15.6551 28 12.1508 28 7.82754C28 3.50457 24.4955 0 20.1724 0ZM20.1724 3.91377C20.7129 3.91377 21.1509 4.35187 21.1509 4.89221C21.1509 5.4328 20.7129 5.87065 20.1724 5.87065C19.632 5.87065 19.194 5.4328 19.194 4.89221C19.194 4.35187 19.632 3.91377 20.1724 3.91377ZM21.6401 11.7413H18.7048C18.4346 11.7413 18.2156 11.5225 18.2156 11.2521C18.2156 10.9819 18.4346 10.7629 18.7048 10.7629H19.194V7.82754H18.7048C18.4346 7.82754 18.2156 7.60873 18.2156 7.33832C18.2156 7.06814 18.4346 6.8491 18.7048 6.8491H20.6617C20.9318 6.8491 21.1509 7.06814 21.1509 7.33832V10.7629H21.6401C21.9103 10.7629 22.1293 10.9819 22.1293 11.2521C22.1293 11.5225 21.9103 11.7413 21.6401 11.7413Z"
      fill="#008CBE"
    />
  </svg>
);

export default NotificationInfoIcon;
