import React from "react";
import PropTypes from "prop-types";
import "./DashboardCard.scss"

const DashboardCard = ({ icon, title, value, color, bg, ind, length }) => {
  return (
    <div
      className="dashboardCard-top"
      style={{
        backgroundColor: color,
        backgroundImage: `url(${bg})`,
        marginRight: ind === length ? "0px" : "10px",
      }}
    >
      <div className="dashboardCard-top_left">
        <img src={icon} alt={title} />
      </div>
      <div className="dashboardCard-top_right">
        <h5>{title}</h5>
        <h2>{value}</h2>
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
  bg: PropTypes.string,
  ind: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
};

export default DashboardCard;
