export const createODataQuery = (
  searchableFields,
  betweenFields = [],
  rawQuery = "",
  searchString,
  sortModel = null,
  pageSize,
  currentPage,
  pagination = true
) => {
  let query = [`$top=${pageSize}`, `$skip=${pageSize * currentPage}`, "$count=true"];
  if (!pagination) {
    query = ["$count=true"];
  }

  let filter = createODataFilter(searchableFields, betweenFields, searchString, rawQuery);
  if (filter != "") {
    query.push(`$filter=${filter}`);
  }

  let orderBy = sortModel?.orderCol ? `$orderBy=${sortModel.orderCol} ${sortModel.orderByAscending ? "asc" : "desc"}&` : "";
  if (orderBy != "") {
    query.push(orderBy);
  }
  return "?" + query.join("&");
};

// export const createODataFieldQuery = (searchableFields, sortModel, pageSize, currentPage) => {
//   let filter = createODataFilter(searchableFields, searchString);
//   let orderBy = sortModel?.orderCol ? `$orderBy=${sortModel.orderCol} ${sortModel.orderByAscending ? "asc" : "desc"}&` : "";

//   return `?${filter}$top=${pageSize}&$skip=${pageSize * currentPage}&${orderBy}$count=true`;
// };

export const createODataFilter = (searchableFields, betweenFields, searchString, query) => {
  let filter = [createODataEqualityFilter(searchableFields, searchString), createODataBetweenFilter(betweenFields), createODataRawFilter(query)];
  filter = filter.filter(f => f.length > 0);

  return filter.length === 0 ? "" : filter.join(" and ");
};

export const createODataRawFilter = query => {
  if (typeof query === "string") {
    return query.length === 0 ? "" : query;
  }

  if (typeof query === "object") {
    let queryParts = query.filter(f => f.length > 0);
    return query.length === 0 ? "" : queryParts.join(" and ");
  }
};

// export const createODataFieldQuery = (searchableFields, betweenFields, sortModel, pageSize, currentPage) => {
//   let filter = [createODataEqualityFilter(searchableFields, searchString), createODataBetweenFilter(betweenFields)];
//   let orderBy = sortModel?.orderCol ? `$orderBy=${sortModel.orderCol} ${sortModel.orderByAscending ? "asc" : "desc"}&` : "";

//   return `?$filter=${filter.join(" and ")}$top=${pageSize}&$skip=${pageSize * currentPage}&${orderBy}$count=true`;
// };

// export const createODataFilter = (searchableFields, searchString) => {
//   return searchString ? `$filter=${searchableFields.map(field => `contains(tolower(${field}), '${searchString.toLowerCase()}')`).join(" or ")}` : "";
// };

export const createODataEqualityFilter = (searchableFields, searchString) => {
  return searchString ? `(${searchableFields.map(field => `contains(tolower(${field}), '${searchString.toLowerCase()}')`).join(" or ")})` : "";
};

export const createODataBetweenFilter = betweenFields => {
  return betweenFields
    ? `${betweenFields.map(between => `${between.field} ge ${between.startDate} and ${between.field} le ${between.endDate} `)}`
    : "";
};
