import React from "react";
import PropTypes from "prop-types";
import "../../GlobalStyles/_rewrite-mui.scss";
import LostOTJWorkforceHours from "../Charts/LostOTJWorkforceHours";
import LostOTJ from "../Charts/LostOTJ";
import { Card, CardHeader, Divider, Grid, Typography } from "@mui/material";

export default function MiddleCharts({ lostOtj, workforceHours }) {
  const processLostOTJData = data => {
    const weekNames = [];
    const totalLostOTJHours = [];

    data?.forEach(item => {
      weekNames.push(item.dayOfWeek);
      totalLostOTJHours.push(Number(item.totalLostOTJHours.toFixed(2)));
    });

    return {
      xAxisData: weekNames,
      lostOtjData: totalLostOTJHours
    };
  };
  const lostOtjData = processLostOTJData(lostOtj);

  const processWorkforceHoursData = data => {
    const weekNames = [];
    const totalHours = [];

    data?.forEach(item => {
      weekNames.push(item.dayOfWeek);
      totalHours.push(Number(item.totalHours.toFixed(2)));
    });

    return {
      xAxisData: weekNames,
      lostOtjData: totalHours
    };
  };
  const workforceHoursData = processWorkforceHoursData(workforceHours);

  return (
    <div>
      <Grid container mt={3} spacing={2} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <Card
            className="demo-chart-card"
            sx={{
              minHeight: "100%",
              border: `1px solid transparent`
            }}
          >
            <CardHeader
              title={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="dashboard-feed-option-left">
                    <Typography fontSize={16} fontWeight="500" className="demo-chart-card-heading">
                      Total Workforce Hours
                    </Typography>
                  </div>
                </div>
              }
            />
            <Divider />
            <LostOTJWorkforceHours data={workforceHoursData} />
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <Card
            className="demo-chart-card"
            sx={{
              minHeight: "100%",
              border: `1px solid transparent`
            }}
          >
            <CardHeader
              title={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="dashboard-feed-option-left">
                    <Typography fontSize={16} fontWeight="500" className="demo-chart-card-heading">
                      Lost OTJ
                    </Typography>
                  </div>
                </div>
              }
            />
            <Divider />
            <LostOTJ data={lostOtjData} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

MiddleCharts.propTypes = {
  lostOtj: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string,
      totalLostOTJHours: PropTypes.number
    })
  ),
  workforceHours: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string,
      totalHours: PropTypes.number
    })
  )
};
