import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ProSelectField from "../../Components/Inputs/SelectField";
import DateField from "../../Components/Inputs/DateField";
import { useForm } from "react-hook-form";
import { ADD_APPENDIX_D_FORM, PROJECT_JOB_TITLES, GET_ALL_PROJECT, GET_ALL_PARTNERS_DROPDOWN } from "../../../utils/services/apiPath";
import { getApi, postApi } from "../../../utils/services";
import { convertFileToBase64Model, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { Box, Button, Grid, IconButton, Modal, Typography, useTheme } from "@mui/material";
import { Close, FileUpload } from "@mui/icons-material";
import fileUpload from "../../Assets/Icons/FileUpload.png";
import ProButton from "../ProButton";

const uploadPopupContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};

const davisBaconFormFields = {
  projectId: {
    label: "Project Name",
    perKey: "Project",
    placeholder: "Select Project",
    rules: { required: "Project Name is Required!" }
  },
  partnerId: {
    label: "Partner Name",
    perKey: "Partner",
    placeholder: "Select Partners",
    rules: { required: "Partner Name is Required!" }
  },
  jobTitleId: {
    label: "Job Title",
    perKey: "jobTitleId",
    placeholder: "Select Job Title",
    rules: { required: "Job Title is Required!" }
  },
  sponsor: {
    label: "Sponsor",
    perKey: "sponsor",
    placeholder: "Select Sponsor",
    rules: { required: "Sponsor is Required!" },
    mode: "read"
  },
  signingDate: {
    label: "Signing Date",
    perKey: "signingDate",
    defaultValue: null,
    placeholder: "Signing Date",
    rules: { required: "Signing Date is Required!" }
  }
};

export default function UploadFileAppendixD({ title, getDetailData }) {
  const [formConfig] = useState(prepareInitialConfig(davisBaconFormFields, "edit"));
  const { handleSubmit, control, watch, setValue } = useForm({ defaultValues: prepareDefaultValues(davisBaconFormFields) });
  const [projectsList, setProjectsList] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);
  const [sponsorList, setSponsorList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const project = watch("projectId");
  const partner = watch("partnerId");
  const jobTitle = watch("jobTitleId");
  const signingDate = watch("signingDate");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDrop = event => {
    event.preventDefault();
    setErrorMessage("");
    const file = event.dataTransfer.files[0];
    if (file && file.type !== "application/pdf") {
      setErrorMessage("Only PDF files are allowed.");
      return;
    }
    setFile(file);
  };

  const handleFileChange = event => {
    setErrorMessage("");
    const file = event.target.files[0];
    if (file && file.type !== "application/pdf") {
      setErrorMessage("Only PDF files are allowed.");
      return;
    }
    setFile(file);
  };

  const fetchProjects = async () => {
    const { data } = await getApi(GET_ALL_PROJECT);
    setProjectsList(data?.filter(item => item.projectId !== "All").map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const fetchPartners = async () => {
    const res = await getApi(`${GET_ALL_PARTNERS_DROPDOWN}/${project}`);
    setPartnersList(res?.filter(item => item.clientGuid !== "All").map(item => ({ label: item.companyName, value: item.clientGuid })));
  };

  const fetchJobTitlesAndSponsors = async () => {
    const data = await getApi(`${PROJECT_JOB_TITLES}?projectGuid=${project}&partnerGuid=${partner}`);
    setJobTitleList(data?.map(item => ({ label: item.projectJobTitleName, value: item.projectJobTitleGuid })));
    setSponsorList(
      Array.from(new Map(data?.map(item => [item.projectJobTitleGuid, { label: item.projectSponsorName, value: item.projectJobTitleGuid }])).values())
    );
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (project) {
      fetchPartners();
    }
  }, [project]);

  useEffect(() => {
    if (partner) {
      fetchJobTitlesAndSponsors();
    }
  }, [partner]);

  useEffect(() => {
    if (jobTitle) {
      setValue("sponsor", jobTitle);
    }
  }, [jobTitle]);

  const defaultFormProps = { control };
  const handleSave = async () => {
    if (!file) {
      setErrorMessage("Appendix D Form (PDF) is Required!");
      return;
    }
    const payload = {
      projectId: project,
      jobTitleId: jobTitle,
      partnerId: partner,
      signingDate: signingDate,
      documentMediaModel: await convertFileToBase64Model(file)
    };
    const res = await postApi(ADD_APPENDIX_D_FORM, payload);
    getDetailData();
    handleClose();
  };
  return (
    <>
      <Button id="upload-files" variant="outlined" size="small" sx={{ width: "150px" }} onClick={handleOpen}>
        Upload
        <FileUpload />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={uploadPopupContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                {title}
              </Typography>
              <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 16, top: 16 }}>
                <Close />
              </IconButton>
            </Grid>
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.projectId}
              options={projectsList}
              permission={{ canView: true, canAdd: true, canEdit: true }}
            />
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.partnerId}
              options={partnersList}
              permission={{ canView: true, canAdd: true, canEdit: true }}
            />
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.jobTitleId}
              options={jobTitleList}
              permission={{ canView: true, canAdd: true, canEdit: true }}
            />
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.sponsor}
              options={sponsorList}
              permission={{ canView: true, canAdd: true, canEdit: true }}
            />
            <DateField {...defaultFormProps} {...formConfig.signingDate} permission={{ canView: true, canAdd: true, canEdit: true }} />
            <Grid item xs={12}>
              <Box
                onDrop={handleDrop}
                sx={{
                  border: `1px dashed ${theme.palette.compliancenavigator.baseuploadmodal.boxBorder}`,
                  padding: "40px",
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: theme.palette.compliancenavigator.baseuploadmodal.boxBackground
                }}
              >
                <input type="file" onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
                <img src={fileUpload} alt="file" />
                <label htmlFor="fileInput">
                  <Typography variant="body1" fontWeight={"bold"} sx={{ color: theme.palette.compliancenavigator.baseuploadmodal.typographyColor }}>
                    {file ? `File Uploaded: ${file.name}` : "Drag & Drop File Here"}
                  </Typography>
                </label>
                <p style={{ color: "red" }}>{errorMessage}</p>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}>
              <ProButton variant="outlined" color="secondary" onClick={handleClose}>
                {"Cancel"}
              </ProButton>
              <ProButton onClick={handleSubmit(handleSave)}>{"Save"}</ProButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

UploadFileAppendixD.propTypes = {
  title: PropTypes.string,
  getDetailData: PropTypes.func
};
