import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailPageLayout from "../../../Components/Layout/DetailPageLayout";
import Layout from "../../../Components/Layout";
import ProjectProfile from "./ProjectProfile";
import ProjectInfo from "./ProjectInfo";
import ProjectJobs from "./ProjectJobs";
import ProfileIcon from "../../../Assets/IconComponents/ProfileIcon";
import ContactIcon from "../../../Assets/IconComponents/ContactIcon";
import ProjectEmployees from "./ProjectEmployees";
import { GET_PROJECT } from "../../../../utils/services/apiPath";
import { errorToast } from "../../../../utils/toastHelper";
import { getApi } from "../../../../utils/services";
import { projectsPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";
import { ProjectCertifiedPayroll } from "./ProjectCertifiedPayroll";
import MentorAndApprentice from "./MentorAndApprentice";
import DailyTimeAllocation from "./DailyWpsTimeAllocation";
// import DailyAttendance from "./DailyAttendance";

export default function ProjectDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [projectProfileData, setProjectProfileData] = useState({});
  const { subModulePermissions } = useSelector(projectsPermissionSelector);
  const profilePermission = subModulePermissions?.PROJECTS_PROFILE?.canView;
  const projectInfoPermission = subModulePermissions?.PROJECTS_MY_PROJECT_INFO?.canView;
  const projectJobsPermission = subModulePermissions?.PROJECTS_JOBS?.canView;
  const projectEmployeePermission = subModulePermissions?.PROJECTS_EMPLOYEES?.canView;
  const projectCertifiedPayrollPermission = subModulePermissions?.PROJECTS_CERTIFIED_PAYROLL?.canView;
  const projectMentorApprenticePermission = subModulePermissions?.["PROJECTS_MENTOR-APPRENTICE"]?.canView;
  const projectWpsTimeAllocationPermission = subModulePermissions?.PROJECTS_WPS_TIME_ALLOCATION?.canView;

  const isAddMode = useMemo(() => id === "add", [id]);
  const backAction = () => {
    navigate("/projects");
  };

  const tabs = isAddMode
    ? [
        {
          to: "/",
          title: "Profile",
          key: "projectprofile",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon,
          canView: profilePermission
        }
      ]
    : [
        {
          to: "/",
          title: "Profile",
          key: "projectprofile",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon,
          canView: profilePermission
        },
        {
          to: "/projectinfo",
          title: "My Project Info",
          key: "projectinfo",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon,
          canView: projectInfoPermission
        },
        {
          to: "/projectjobs",
          title: "Jobs",
          key: "projectjobs",
          isActive: false,
          hasArrow: false,
          icon: ContactIcon,
          canView: projectJobsPermission
        },
        {
          to: "/projectemployees",
          title: "Employees",
          key: "projectemployees",
          isActive: false,
          hasArrow: false,
          icon: ContactIcon,
          canView: projectEmployeePermission
        },
        // {
        //   to: "/projectattendence",
        //   title: "Daily Attendance",
        //   key: "projectattendence", 
        //   isActive: false,
        //   hasArrow: false,
        //   icon: ContactIcon,
        //   canView: true, 
        // },
        {
          to: "/mentor-apprentice",
          title: "Mentor-Apprentice",
          key: "mentor-apprentice",
          isActive: false,
          hasArrow: false,
          icon: ContactIcon,
          canView: projectMentorApprenticePermission
        },
        {
          to: "/wps-time-allocation",
          title: "WPS Time Allocation",
          key: "wps-time-allocation",
          isActive: false,
          hasArrow: false,
          icon: ContactIcon,
          canView: projectWpsTimeAllocationPermission
        },
        {
          to: "/projectcertifiedpayrollreport",
          title: "Certified Payroll",
          key: "projectcertifiedpayrollreport",
          isActive: false,
          hasArrow: false,
          icon: ContactIcon,
          canView: projectCertifiedPayrollPermission
        }
      ];

  const getProjectProfileData = async id => {
    const { error, data: project } = await getApi(`${GET_PROJECT}/${id}`);
    if (error) return errorToast(error);
    setProjectProfileData(project);
  };

  useEffect(() => {
    if (!isAddMode) getProjectProfileData(id);
  }, [id]);

  const handleTabClick = tab => {
    navigate(`/projects/${id}${tab.to}`);
  };

  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab?.toLowerCase())?.title;
    setActiveTab(selectedTab || "Profile");
  }, [tab]);

  return (
    <Layout>
      <DetailPageLayout
        backAction={backAction}
        title={isAddMode ? "ADD PROJECT - " : "PROJECT INFORMATION"}
        subTitle={isAddMode ? "Fill in the below to add project" : `${projectProfileData.projectName || ""}`}
        backLabel="Go back to Projects"
        activeTab={activeTab}
        tabs={tabs.filter(tab => tab.canView)}
        handleTabClick={handleTabClick}
      >
        {activeTab === "Profile" && <ProjectProfile />}
        {activeTab === "My Project Info" && <ProjectInfo />}
        {activeTab === "Jobs" && <ProjectJobs />}
        {activeTab === "Employees" && <ProjectEmployees />}
        {/* {activeTab === "Daily Attendance" && <DailyAttendance />} */}
        {activeTab === "Certified Payroll" && <ProjectCertifiedPayroll />}
        {activeTab === "Mentor-Apprentice" && <MentorAndApprentice />}
        {activeTab === "WPS Time Allocation" && <DailyTimeAllocation />}
      </DetailPageLayout>
    </Layout>
  );
}
