import ClickableCard from "../../../Components/Cards/ClickableCard";
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Chip, useTheme } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import ProscoreShield from "../../../Assets/Icons/apple-touch-icon.png";
import GenericModal from "../../../Components/Modals/GenericModal";
import { useState } from "react";

const CardBody = ({ total, available, chipText, title, tableComponent,fetchAllComplianceDocument }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const theme = useTheme();
  return (
    <>
      {available < total ? (
        <Cancel color="error" style={{ width: "69px", height: "69px", marginBottom: "10px" }} />
      ) : (
        <img src={ProscoreShield} style={{ width: "69px", height: "69px", marginBottom: "10px" }} />
      )}
      <Typography className="chip-container project-proportion">
        {chipText?.length > 0 && <Chip label={chipText} sx={{ fontSize: "16px", fontWeight: "600", marginBottom: "15px", color: theme.palette.text.chip }} />}
      </Typography>
      <Typography
        className="card-title"
        sx={{ textDecoration: "underline", cursor: "pointer", fontSize: "20px", fontWeight: "600", color: theme.palette.text.title }}
        onClick={handleOpen}
      >
        {title}
      </Typography>
      <GenericModal modalTitle={title} modalDescription={"hello"} open={open} setOpen={setOpen} onCloseAction={fetchAllComplianceDocument}>
        {tableComponent()}
      </GenericModal>
    </>
  );
};

CardBody.propTypes = {
  total: PropTypes.number,
  available: PropTypes.number,
  chipText: PropTypes.string,
  title: PropTypes.string,
  tableComponent: PropTypes.func,
};

const ComplianceNavigatorCards = ({ cards, toggleSelectedCards, selectedCards,fetchAllComplianceDocument }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} direction="row">
        {cards.map((card, idx) => {
          let isSelected = selectedCards.includes(card.title);
          return (
            <Grid item xs={4} sm={4} md={4} key={`compliance-nav-card-${idx}`}>
              <ClickableCard
                isSelected={isSelected}
                style={{ height: '100%' }}
                checkbox_id={card.title.split(" ").join("_").toLowerCase()}
                onChangeAction={() => toggleSelectedCards(card.title)}
              >
                <CardBody {...card} fetchAllComplianceDocument={fetchAllComplianceDocument}/>
              </ClickableCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

ComplianceNavigatorCards.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      total: PropTypes.number,
      available: PropTypes.number,
      chipText: PropTypes.string,
      title: PropTypes.string,
      tableComponent: PropTypes.func,
    })
  ),
  toggleSelectedCards: PropTypes.func,
  selectedCards: PropTypes.arrayOf(PropTypes.string),
};

export default ComplianceNavigatorCards;
