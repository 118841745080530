import ComplianceNavigatorBaseTable from "./ComplianceNavigatorBaseTable";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";
import { COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES, GET_ALL_COMPLIANCE_INSIGHT_DATA, GET_ALL_COMPLIANCE_TABLE, GET_ALL_COMPLIANCE_TABLE_DATA } from "../../../utils/services/apiPath";
import { useEffect, useState, useCallback } from "react";
import { getApi, postApi } from "../../../utils/services";
import PropTypes from "prop-types";
import UploadFieldsData from "./UploadFieldsData";
import { errorToast } from "../../../utils/toastHelper";
import { v4 as uuidV4 } from "uuid";
import { createODataQuery } from "../../../utils/services/oData";

const CLRFiledTable = ({ tableKey, projectId, partnerId }) => {
  const {
    currentPage,
    pageSize,
    data: tableData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn([
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "partnerName",
      headerName: "Partner Name"
    },
    {
      field: "jobTitle",
      headerName: "Job Title"
    },
    {
      field: "state",
      headerName: "State"
    },
    {
      field: "county",
      headerName: "County"
    }
  ]);

  const [insightData, setInsightData] = useState();

  const fetchInsightData = async () => {
    const res = await getApi(`${COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES}?key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`);
    setInsightData(res);
  };

  useEffect(() => {
    if (tableKey && partnerId && projectId) {
      fetchInsightData();
    }
  }, [projectId, partnerId, tableKey]);

  const handleSave = (file, handleClose) => {
    console.log(file);
    handleClose();
  };

  const getTableData = useCallback(async () => {
    const payload = {
      pageIndex: currentPage + 1,
      pageSize: pageSize,
      orderByAscending: sortModel.orderByAscending || true,
      searchString: searchString || "",
      orderCol: sortModel.orderCol || ""
    };
    const searchableFields = ["partnerName","projectName","jobTitle"];
   const { data, totalRecords, error } = await getApi(`${GET_ALL_COMPLIANCE_TABLE + createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage)}&key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`, payload);

    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data?.map(row => ({ ...row, id: uuidV4() })) || [] || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus])

  useEffect(() => {
    getTableData()
  }, [getTableData])

  return (
    <ComplianceNavigatorBaseTable
      columns={columns}
      title="List of CLR"
      rows={tableData}
      insightData={insightData}
      tableKey={tableKey}
      rowCount={rowCount}
      status={status}
      setPagination={setPagination}
      setSortModel={setSortModel}
      searchString={searchString}
      setSearchString={setSearchString}
      getRowId={row => row.id}
      pageSize={pageSize}
      currentPage={currentPage}
      uploadPopUp={
        <UploadFieldsData
          form="CLRFiled"
          handleSave={handleSave}
        />
      }
    />
  );
};

CLRFiledTable.propTypes = {
  tableKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired
};

export default CLRFiledTable;
