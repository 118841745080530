import React from "react";
import TableComponent from "../Components/ProGridTable/TableComponent";
import { FormatHoursMinutesCell, PercentageCell } from "../../Components/ProGrid/components/FormatCell";
import PropTypes from "prop-types";
import ReportingNameCell from "../Components/ProGridTable/ReportingNameCell";

const columns = [
  {
    field: "apprenticeName",
    headerName: "Apprentice Name",
    renderCell: params => <ReportingNameCell employeeGuid={params.row.employeeGuid} value={params.value} />
  },
  { field: "minimumTimeRequirement", headerName: "Minimum Time Requirement", renderCell: params => <FormatHoursMinutesCell value={params.value} /> },
  { field: "timeInCourse", headerName: "Total Time In Course", renderCell: params => <FormatHoursMinutesCell value={params.value} /> },
  { field: "rtiTitle", headerName: "Course Module" },
  { field: "completionPercentage", headerName: "Completion Percentage", renderCell: params => <PercentageCell value={params.value} /> },
  { field: "courseStatus", headerName: "Status" }
];

export default function BottomTable({ apprenticeDetails, onPageChange, pageState }) {
  return (
    <div style={{ margin: "20px 0" }}>
      <TableComponent
        columns={columns}
        rows={apprenticeDetails}
        title="List of Apprentice"
        toolSetButton={true}
        loading={pageState.loading}
        onPageChange={pageState => onPageChange(pageState)}
        paginationProps={{
          rowCount: pageState.totalRecord,
          paginationMode: "server",
          paginationModel: { pageSize: pageState.pageSize, page: pageState.pageIndex },
          onPaginationModelChange: onPageChange,
          getRowHeight: () => "auto",
          getEstimatedRowHeight: () => 180
        }}
      />
    </div>
  );
}

BottomTable.propTypes = {
  apprenticeDetails: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageState: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    totalRecord: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired
  }).isRequired
};
