import { useCallback, useEffect, useState } from "react";
import { STAUTES } from "../../../utils/constant";
import ProGrid from "../../Components/ProGrid/ProGrid";
import { deleteApi, getApi } from "../../../utils/services";
import { REPORT_SCHEDULES } from "../../../utils/services/apiPath";
import { createODataQuery } from "../../../utils/services/oData";
import usePageState from "../../../utils/customHooks/usePageState";
import useColumn from "../../../utils/customHooks/useColumns";
import { errorToast, successToast } from "../../../utils/toastHelper";
import Layout from "../../Components/Layout";
import { DateCell } from "../../Components/ProGrid/components/FormatCell";
import GridCellExpand from "../../Components/ProGrid/components/GridCellExpand";
import StandardToolbar from "../../Components/Toolbars/StandardToolbar";
import { Button } from "@mui/material";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import ScheduleReportModal from "../../Components/ScheduleReportModal";

const EditDeleteReportSchedule = ({ params, setRowDeleted, setIsDoneEditing }) => {
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false)
  const { reportScheduleId, reportId } = params?.row
  const onDelete = async () => {
    try {
      await deleteApi(`${REPORT_SCHEDULES}/${reportScheduleId}`)
      setRowDeleted(true)
      successToast("Successfully deleted report schedule")
    } catch (e) {
      errorToast("Unable to delete schedule")
    }
  }

  return (
    <div style={{ "display": "flex", "flexDirection": "row", "gap": "5px" }}>
      <DeleteCell title="Delete" params={params} onDelete={onDelete} />
      <Button variant="outlined" onClick={() => setIsScheduleModalOpen(true)}>Edit</Button>
      <ScheduleReportModal isModalOpen={isScheduleModalOpen} setIsModalOpen={setIsScheduleModalOpen} reportId={reportId} isEditMode setIsDoneEditing={setIsDoneEditing} reportScheduleId={reportScheduleId} />
    </div >
  )
}

const ReportSchedulesTable = () => {
  const {
    currentPage,
    pageSize,
    data: reportSchedules,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const [rowDeleted, setRowDeleted] = useState(false)
  const [isDoneEditing, setIsDoneEditing] = useState(false)

  const formatRunDays = (data) => {
    data.forEach((d) => {
      d["runDays"] = ""
      if (d["runMon"]) {
        d["runDays"] += "M"
      }
      if (d["runTue"]) {
        d["runDays"] += "T"
      }
      if (d["runWed"]) {
        d["runDays"] += "W"
      }
      if (d["runThu"]) {
        d["runDays"] += "R"
      }
      if (d["runFri"]) {
        d["runDays"] += "F"
      }
      if (d["runSat"]) {
        d["runDays"] += "S"
      }
      if (d["runSun"]) {
        d["runDays"] += "U"
      }
    })
  }
  const fetchReportSchedules = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const filterFields = ["reportName"]
    const { data, totalRecords, error } = await getApi(REPORT_SCHEDULES + createODataQuery(filterFields, null, "isActive eq true", searchString, sortModel, pageSize, currentPage));
    formatRunDays(data)
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    fetchReportSchedules();
  }, [fetchReportSchedules]);

  useEffect(() => {
    fetchReportSchedules()
    setRowDeleted(false)
  }, [rowDeleted])

  useEffect(() => {
    fetchReportSchedules()
    setIsDoneEditing(false)
  }, [isDoneEditing])

  const columns = useColumn([
    {
      field: "reportName",
      width: 300,
      headerName: "Report Name",
    },
    {
      field: "reportFormat",
      headerName: "Report Format",
      width: 200
    },
    {
      field: "reportParams",
      headerName: "Filter Params",
      width: 200,
      renderCell: (params) => <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    },
    {
      field: "emailList",
      headerName: "Email List",
      width: 200,
      renderCell: (params) => <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    },
    {
      field: "runFrequency",
      headerName: "Run Frequency"
    },
    {
      field: "runDays",
      headerName: "Run Days"
    },
    {
      field: "startDate",
      headerName: "Start Date",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "endDate",
      headerName: "End Date",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "reportScheduleId",
      headerName: "Actions",
      renderCell: params => <EditDeleteReportSchedule params={params} setRowDeleted={setRowDeleted} setIsDoneEditing={setIsDoneEditing} />,
      width: 150
    },
  ]);
  return (
    <Layout>
      <ProGrid
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={reportSchedules}
        searchMode={!!searchString}
        toolbar={StandardToolbar({
          toolbarTitle: "Report Schedules",
          searchModel: { defaultValue: searchString, handleDebounce: setSearchString },
          showAddButton: false
        })}
        options={{
          getRowId: row => row.reportScheduleId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>

  );
}

export default ReportSchedulesTable