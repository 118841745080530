import ToolbarBase from "./ToolbarBase";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'
import GridSerch from "../ProGrid/components/GridSerch";
import { GridToolbarExport } from "@mui/x-data-grid-pro";
import ProButton from "../ProButton";

export default function StandardToolbar({
  toolbarTitle,
  showAddButton = true,
  addButtonOverride,
  addButtonUrl,
  addButtonTitle,
  searchModel
}) {
  const navigation = useNavigate();

  const addButtonAction = () => {
    navigation(addButtonUrl);
  };

  return () => (
    <ToolbarBase title={toolbarTitle}>
      <div className="proGrid-search-wrapper">
        <GridSerch {...searchModel} />
      </div>
      <div className="proGrid-filter">
        <GridToolbarExport className="export-icon" />
        {showAddButton &&
          <ProButton onClick={addButtonOverride ? () => addButtonOverride() : addButtonAction}>{addButtonTitle}</ProButton>
        }
      </div>
    </ToolbarBase>
  );
}

StandardToolbar.propTypes = {
  toolbarTitle: PropTypes.string,
  addButtonOverride: PropTypes.func,
  addButtonUrl: PropTypes.string,
  addButtonTitle: PropTypes.string,
  searchModel: PropTypes.object
};