import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import ProSelectField from "../Inputs/SelectField";
import { fieldTruePermission } from "../../../utils/formHelper";
import ProButton from "../ProButton";
import { FileDownload } from "@mui/icons-material";

export default function ComplianceNavigatorHeader({ formProps, formConfig, typeOptions }) {
  return (
    <Box
      sx={{
        display: { md: "flex", sm: "block" },
        justifyContent: "space-between",
        marginBottom: "15px",
        width: { md: "100%" }
      }}
    >
      <Box
        sx={{
          display: { md: "flex", sm: "block" },
          width: { sm: "100%", md: "30%" }
        }}
      >
        {Object.keys(typeOptions).map((key) => (
          <Box
            key={key} 
            sx={{
              width: { xs: "100%", md: "50%" },
              mb: { xs: 2, md: 0 },
              mr: { md: 2 }
            }}
          >
            <ProSelectField
              {...formProps}
              value={typeOptions[key]?.[0] ?? ""}
              {...formConfig[key]}
              options={typeOptions[key] ?? []}
              permission={fieldTruePermission}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

ComplianceNavigatorHeader.propTypes = {
  formProps: PropTypes.object,
  formConfig: PropTypes.object, 
  typeOptions: PropTypes.objectOf(PropTypes.array), 
};
