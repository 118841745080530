import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";
import DetailHeader from "../../DetailHeader";
import "./index.scss";
import EditButton from "../../Inputs/EditButton";
import { useWindowSize } from "../../../../utils/customHooks/useWindowSize";

export default function SettingFormsLayout({
  children,
  title,
  subTitle,
  onCancel,
  onSave,
  onEdit,
  hideEdit,
  backAction,
  backLabel,
  beingEdited,
  hideBackbutton,
  hideCancel = false,
  isSaving 
}) {
  const { isMobile } = useWindowSize();
  return (
    <div className="proForm-layout proSettingForm">
      <DetailHeader
        title={title}
        subTitle={subTitle}
        backAction={backAction}
        backLabel={backLabel}
        isMobile={isMobile}
        hideBackbutton={hideBackbutton}
        editButton={<EditButton onClick={onEdit} isMobile={isMobile} beingEdited={beingEdited} hideEdit={hideEdit} />}
      />
      <div className="proForm-layout__content__wrapper">
        <div className="proSettingForm-content">{children}</div>
      </div>
      {beingEdited && !hideEdit && (
        <div className="proForm-layout__footer">
          <div className="proForm-layout__footer__actions">
            {!hideCancel && (
              <Button className="cancel-btn" variant="outlined" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button variant="contained" onClick={onSave} disabled={isSaving}>
            {isSaving ? (
                <>
                  <CircularProgress size={24} />
                  <span style={{ marginLeft: 10 }}>Save</span>
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

SettingFormsLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  hideEdit: PropTypes.bool,
  backAction: PropTypes.func,
  backLabel: PropTypes.string,
  beingEdited: PropTypes.bool,
  hideBackbutton: PropTypes.bool,
  hideCancel: PropTypes.bool,
  isSaving: PropTypes.bool
};
