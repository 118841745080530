import React from "react";

export default function LowRiskIcon() {
  return (
    <svg width="162" height="78" viewBox="0 0 162 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M161.351 77.3399H0C1.78502 34.3249 37.2217 0 80.6755 0C124.129 0 159.566 34.3249 161.351 77.3399Z"
        fill="url(#paint0_linear_1917_3419)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0637 77.3032H146.241C144.79 42.3322 115.98 14.4262 80.6526 14.4262C45.3249 14.4262 16.5149 42.3322 15.0637 77.3032Z"
        fill="white"
      />
      <path d="M118.106 24.8613L84.6587 70.4864L78.9557 65.6979L118.106 24.8613Z" fill="#323232" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.243 77.6692H48.6452C49.3488 60.7144 63.3166 47.1849 80.4443 47.1849C97.572 47.1849 111.54 60.7144 112.243 77.6692Z"
        fill="url(#paint1_linear_1917_3419)"
      />
      <defs>
        <linearGradient id="paint0_linear_1917_3419" x1="161.351" y1="38.6699" x2="0" y2="38.6699" gradientUnits="userSpaceOnUse">
          <stop stop-color="#850000" />
          <stop offset="0.1" stop-color="#F3040C" />
          <stop offset="1" stop-color="#518D09" />
        </linearGradient>
        <linearGradient id="paint1_linear_1917_3419" x1="112.243" y1="62.4271" x2="48.6452" y2="62.4271" gradientUnits="userSpaceOnUse">
          <stop stop-color="#850000" />
          <stop offset="0.1" stop-color="#F3040C" />
          <stop offset="1" stop-color="#518D09" />
        </linearGradient>
      </defs>
    </svg>
  );
}
