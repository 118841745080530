import { Grid, MenuItem, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";
import ComplianceNavigatorBaseUploadModal from "./ComplianceNavigatorBaseUploadModal";
import useFormData from "./useFormData";
import { getInitialFormData } from "./formDataConfig";
import { getFormFieldsByType } from "./uploadFormFields";
import { useEffect, useState } from "react";
import {
  ADD_COMPLIANCE_NAVIGATOR_APPENDIXA,
  ADMIN_RAP_INFO,
  COMPLIANCE_NAVIGATOR_MISSING_APPENDIXA,
  RAP_INFO_BYID
} from "../../../utils/services/apiPath";
import { getApi, postApi } from "../../../utils/services";
import { errorToast, successToast } from "../../../utils/toastHelper";
import { convertFileToBase64, convertFileToBase64Model } from "../../../utils/formHelper";

const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";

const ariaLabel = { "aria-label": "description" };

export default function UploadFieldsData({ form, title = "Appendix A - File Upload",  approvedJobTitleOptions,getTableData }) {
  const { formData, handleChange, handleDateChange } = useFormData(getInitialFormData(form));
  const [rapInfo, setRapInfo] = useState(null);
  const [formFields, setFormFields] = useState([]);

  const fetchAppendixRapInfo = async () => {
    try {
      const res = await getApi(`${RAP_INFO_BYID}/${formData.approvedJobTitle}`);
      setRapInfo(res);
    } catch (error) {
      console.error("Error fetching RAP info:", error);
    }
  };

  useEffect(() => {
      fetchAppendixRapInfo();
  }, [formData.approvedJobTitle]);

  useEffect(() => {
    setFormFields(getFormFieldsByType(form, { ...formData, approvedJobTitleOptions }, rapInfo));
  }, [rapInfo, formData, approvedJobTitleOptions, form]);

  const handleClose = () => {
    setRapInfo(null);
  };

  const handleFileUpload = async (file, handleModalClose) => {
    if (!file) {
      console.error("No file selected.");
      return;
    }
    try {
      const base64String = await convertFileToBase64(file);

      const payload = {
        rapInfoId: formData?.approvedJobTitle,
        documentMediaModel: {
          base64String: base64String,
          fileName: file.name
        }
      };
      const response = await postApi(ADD_COMPLIANCE_NAVIGATOR_APPENDIXA, payload);
      if(response.rapDocumentsGuid){
      successToast("Appendix A uploaded successfully");
      handleModalClose();
      setRapInfo(null);
      getTableData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ComplianceNavigatorBaseUploadModal title={title} onSave={handleFileUpload} onClose={handleClose}>
      <Grid container spacing={2}>
        {formFields?.map(({ label, value, key, type, options }) => (
          <Grid item xs={4} key={key}>
            {type === "select" && (
              <TextField select label={label} value={value} onChange={handleChange(key)} fullWidth>
                {options?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {type === "input" && (
              <TextField
                inputProps={ariaLabel}
                fullWidth
                label={value ? "" : label} // Hide label if value exists
                value={value}
                onChange={handleChange(key)}
                InputLabelProps={{ shrink: Boolean(value) }} // Shrink label if value exists
              />
            )}

            {type === "date" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={label}
                  value={value}
                  onChange={handleDateChange(key)}
                  inputFormat={DEFAULT_DATE_FORMAT}
                  slotProps={{
                    textField: {
                      fullWidth: true
                    }
                  }}
                />
              </LocalizationProvider>
            )}
          </Grid>
        ))}
      </Grid>
    </ComplianceNavigatorBaseUploadModal>
  );
}

UploadFieldsData.propTypes = {
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      type: PropTypes.oneOf(["select", "input", "date"]),
      options: PropTypes.arrayOf(PropTypes.string)
    })
  ),
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSave: PropTypes.func
};
