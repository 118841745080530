import React, { forwardRef, useState } from "react";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Alert, Slide, Dialog, Collapse, Button, Avatar, FormHelperText, useTheme } from "@mui/material";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { postApi } from "../../../utils/services";
import { AUTH_FORGOT_PASS } from "../../../utils/services/apiPath";
import { fieldTruePermission } from "../../../utils/formHelper";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const forgotPasswordConfig = {
  username: {
    name: "username",
    label: "Username",
    rules: { required: "Username is required!" }
  }
};

const ForgotPassword = () => {
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [helperText, setHelperText] = useState("");
  const theme = useTheme();

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/dashboard");
  };
  const { handleSubmit, control } = useForm({});

  const handleClick = async payload => {
    const { error, message } = await postApi(AUTH_FORGOT_PASS, {}, { params: { username: payload.username, source: "web" } });
    if (error) return setHelperText(error);
    setMessage(message);
    handleOpenDialog();
    setHelperText("");
  };

  return (
    <>
      <ProPreLoginLayout heading="Forgot Password">
        <div className="proLogin">
          <div className="proLogin_form">
            <div className="proLogin_form_input">
              <ProTextInput {...forgotPasswordConfig.username} permission={fieldTruePermission} control={control} />
            </div>
            {helperText && (
              <FormHelperText error className="proLogin_form_error needBottomSpace">
                {helperText}
              </FormHelperText>
            )}
            <p className="proLogin_form_info">Enter username to retrieve account details</p>
            <div className="proLogin_form_button">
              <ProButton style={{ width: "100%" }} onClick={handleSubmit(handleClick)}>
                Send me a new password
              </ProButton>
            </div>
            <div className="proLogin_form_link">
              <NavLink to="/">Back to Login</NavLink>
            </div>
          </div>
        </div>
      </ProPreLoginLayout>
      <Dialog
        className="proLogin_dialog"
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box sx={{ background: theme.palette.pages.forgetpassword.bgcolor, px: 4, pb: 4, pt: 10 }}>
          <Avatar className="proLogin_dialog_avatar">
            <CheckTwoToneIcon />
          </Avatar>

          {message && (
            <Collapse in={true}>
              <Alert>{message}</Alert>
            </Collapse>
          )}

          <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleCloseDialog}>
            {"Continue to login"}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
