import React from "react";
import PropTypes from "prop-types";
import { DataGridPro, GridToolbarExport } from "@mui/x-data-grid-pro";
import "./DashBoardTable.scss";
import ToolSet from "../../../Assets/IconComponents/ToolSet";
import { Skeleton } from "@mui/material";

function CustomToolbars({
  title = "",
  hideExport = false,
  additionalButtons,
  toolSetButton,
  hasBorder = true
}) {
  return (
    title && (
      <div className={`proGrid-title-wrapper ${hasBorder ? "proGrid-title-wrapper-with-border" : "proGrid-title-wrapper-no-border"}`}>
        <div className="proGrid-title">{title}</div>
        <div className="proGrid-filter">
          {additionalButtons}
          {toolSetButton && <ToolSet />}
          {!hideExport && <GridToolbarExport className="export-icon" />}
        </div>
      </div>
    )
  );
}

CustomToolbars.propTypes = {
  title: PropTypes.string,
  hideExport: PropTypes.bool,
  hasBorder: PropTypes.bool,
  additionalButtons: PropTypes.node,
  toolSetButton: PropTypes.node
};

export default function DashBoardTable({
  columns,
  rows,
  title,
  loading = false,
  hideExport = false,
  additionalButtons = null,
  toolSetButton = null,
  hasBorder = true,
  checkboxSelection = false,
  paginationModel = { page: 0, pageSize: 5 }
}) {
  return (
    <div className="new-dashboard-grid">
      {loading ? <Skeleton variant="rectangular" width={"100%"} height={400} /> :
        <DataGridPro
        loading={loading}
        rows={rows}
        sortingOrder={["desc", "asc"]}
        getRowId={(row) => row.projectGuid || row.id || row.clientDashboardFeedId}
        columns={columns.map(column => ({
          ...column,
          flex: 1
        }))}
        slots={{
          toolbar: CustomToolbars
        }}
        slotProps={{
          toolbar: {
            title,
            hideExport,
            additionalButtons,
            toolSetButton,
            hasBorder
          }
        }}
        initialState={{
          pagination: {
            paginationModel
          }
        }}
        checkboxSelection={checkboxSelection}
      />}
    </div>
  );
}

DashBoardTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  title: PropTypes.string,
  hideExport: PropTypes.bool,
  loading:PropTypes.bool,
  hasBorder: PropTypes.bool,
  additionalButtons: PropTypes.node,
  toolSetButton: PropTypes.node,
  checkboxSelection: PropTypes.bool,
  paginationModel: PropTypes.object
};
