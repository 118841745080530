import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { handleIdentityLogin, loginSelector } from "../../store/features/auth/authSlice";
import { IDENTITY_CLIENT_ID } from "../../../utils/constant";

const getCode = searchParams => {
  if (searchParams.has("code")) {
    return searchParams.get("code");
  }
  return "";
};

const Identity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(loginSelector);
  const [searchParams] = useSearchParams();
  const [setHelperText] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      if (location.state?.from) navigate(location.state.from.pathname + location.state.from.search, { replace: true });
      else navigate("/dashboard");
    } else {
      dispatch(
        handleIdentityLogin({ code: getCode(searchParams), identityClientId: IDENTITY_CLIENT_ID }, error => {
          console.log("Identity -> error", error);
        })
      );
    }
  }, [isAuthenticated]);
};

export default Identity;
