import React, { useEffect, useState } from "react"
import PropTypes from "prop-types";
import ProjectOverviewTable from "../ProjectOverviewTable";
import HeatMapByPartner from "../../Charts/HeatMapByPartner";
import { Divider, Skeleton } from "@mui/material";
import { getApi } from "../../../../utils/services";
import { GET_JOURNEY_TO_APPRENTICE_BY_PARTNER_LIST, GET_PROJECT_OVERVIEW_LIST } from "../../../../utils/services/apiPath";
import { errorToast } from "../../../../utils/toastHelper";
import dayjs from "dayjs";

export default function DashboardHeatMap({ setHideHeatMap }) {
  const [heatMapData, setHeatMapData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [projectOverviewData, setProjectOverviewData] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [heatMapLoading, setHeatMapLoading] = useState(true);

  const getHeatMapData = async () => {
    const { data, error } = await getApi(GET_JOURNEY_TO_APPRENTICE_BY_PARTNER_LIST);
    if (error) return errorToast(error);
    const apprenticeJourneyData = data.map(({ companyName, data: mapInfo }) => {
      return { companyName, data: mapInfo.slice(-12) };
    });
    const dates = [];
    apprenticeJourneyData.forEach(item => {
      item.data.forEach(dataItem => {
        if (!dates.includes(dataItem.weekEndingDate)) dates.push(dataItem.weekEndingDate);
      });
    });
    dates.sort((a, b) => dayjs(a, "YYYY-MM-DD").unix() - dayjs(b, "YYYY-MM-DD").unix());
    const seriesData = [];
    const companyName = [];
    if (apprenticeJourneyData[apprenticeJourneyData.length - 1]?.data[0]) {
      const firstCompany = apprenticeJourneyData[apprenticeJourneyData.length - 1].data[0].globalClientGuid;
      const firstWeekendingDate = apprenticeJourneyData[apprenticeJourneyData.length - 1].data[0].weekEndingDate;
      setSelectedClient(firstCompany);
      setSelectedDate(firstWeekendingDate);
    }
    apprenticeJourneyData.forEach((item, index) => {
      companyName.push(item.companyName);
      item.data.forEach(dataItem => {
        const dateIndex = dates.indexOf(dataItem.weekEndingDate);
        seriesData.push({
          x: dateIndex,
          y: index,
          value: +dataItem.journeyToApprenticeRatio,
          date: dataItem.weekEndingDate,
          clientId: dataItem.globalClientGuid
        });
      });
    });
    setHideHeatMap(apprenticeJourneyData.length === 0);
    setHeatMapLoading(false);
    setHeatMapData({ companyName, dates, formattedDates: dates.map(date => dayjs(date, "YYYY-MM-DD").format("DD MMM")), seriesData });
  };

  const getProjectOverviewData = async () => {
    setLoading(true);
    const { data, error } = await getApi(GET_PROJECT_OVERVIEW_LIST, { params: { weekEndingDate: selectedDate, globalClientGuid: selectedClient } });
    setLoading(false);
    if (error) return errorToast(error);
    setProjectOverviewData(data.map(item => ({ ...item, isDummy: !item.projectName })).filter((item, i) => i < 5 || !item.isDummy));
  };

  const onPointSelect = event => {
    setSelectedClient(event.clientId);
    setSelectedDate(event.date);
  };

  useEffect(() => {
    getHeatMapData();
  }, []);

  useEffect(() => {
    if (selectedDate && selectedClient) getProjectOverviewData();
  }, [selectedDate, selectedClient]);

  return (
    <div className="djr-Wrapper" style={{ margin: "0 auto 3px" }}>
      <div className="djr-header with-dropdown">
        <div className="djr-header-title-wrapper">
          <div className="djr-header-subtitle">Journey to Apprentice</div>
          <div className="djr-header-title">By Partner</div>
        </div>
      </div>
      <div className="djr-heat-map">
        {heatMapLoading ? <Skeleton variant="rectangular" width={"100%"} height={400} /> :
          <HeatMapByPartner heatMapData={heatMapData} onPointSelect={onPointSelect} />}
      </div>
      <Divider />
      <ProjectOverviewTable
        projectOverviewData={projectOverviewData}
        loading={loading}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
}

DashboardHeatMap.propTypes = {
  setHideHeatMap: PropTypes.func.isRequired,
};