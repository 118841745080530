import React, { useMemo } from "react";
import { DataGridPro, GridPagination, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";
import "./index.scss";
import ProButton from "../ProButton";
import { Box, useTheme } from "@mui/material";
import "../../GlobalStyles/variables.scss";
import "../../GlobalStyles/mixins.scss";
import DebounceInput from "../Inputs/DebounceInput";
import PropTypes from "prop-types";

function CustomToolbar({
  title = "",
  addButtonLabel = "",
  importExcelLabel = "",
  downloadSampleExcelLabel = "",
  addButtonAction = () => {},
  importExcelAction = () => {},
  downloadSampleExcelAction = () => {},
  hideExport = false,
  hideAddButton = false,
  showImportExcel = false,
  showDownloadSampleExcel = false,
  hideSearch = false,
  searchModel = {}
}) {
  return () => (
    <GridToolbarContainer>
      <div className="proGrid-title-wrapper">
        <div className="proGrid-title">{title}</div>
        {!hideSearch && <div className="proGrid-search-wrapper">{<DebounceInput placeholder="Search..." {...searchModel} />}</div>}
        <div className="proGrid-filter">
          {!hideExport && <GridToolbarExport className="export-icon" />}
          {showDownloadSampleExcel && (
            <ProButton variant="outlined" onClick={downloadSampleExcelAction}>
              {downloadSampleExcelLabel}
            </ProButton>
          )}
          {showImportExcel && (
            <ProButton variant="outlined" onClick={importExcelAction}>
              {importExcelLabel}
            </ProButton>
          )}
          {!hideAddButton && <ProButton onClick={addButtonAction}>{addButtonLabel}</ProButton>}
        </div>
      </div>
    </GridToolbarContainer>
  );
}

function Pagination({ page, onPageChange, className, count, rowsPerPage }) {
  const pageCount = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);
  const theme = useTheme();
  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      sx={{
        "& .MuiPaginationItem-page": {
          color: theme.palette.progrid.pagination
        },
        "& .MuiPaginationItem-page.Mui-selected": {
          color: "white"
        }
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomNoRowsOverlay() {
  return (
    <div className="proGrid-no-rows">
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </div>
  );
}

export default function CollapsedGrid({
  columns = [],
  rows = [],
  loading = false,
  options = {},
  addButtonLabel = "",
  addButtonAction = () => {},
  hideExport = false,
  hideSearch = false,
  title = "",
  searchMode,
  hideAddButton = false,
  showImportExcel = false,
  showDownloadSampleExcel = false,
  importExcelAction = () => {},
  downloadSampleExcelAction = () => {},
  importExcelLabel = "",
  downloadSampleExcelLabel = "",
  hideIfNoData = false,
  searchModel
}) {
  const proColumns = columns.map(column => ({
    headerClassName: "proGrid-header",
    ...(!column.width && { flex: 1 }),
    ...column
  }));
  if (hideIfNoData && !rows.length) return null;
  return (
    <div className="rs-grid-wrapper collapsed">
      <DataGridPro
        columnHeaderHeight={44}
        rowHeight={44}
        columns={proColumns}
        rows={rows}
        loading={loading}
        classes={{
          cell: "proGrid-table-cell"
        }}
        sortingOrder={["desc", "asc"]}
        slots={{
          toolbar: CustomToolbar({
            title,
            addButtonLabel,
            importExcelLabel,
            downloadSampleExcelLabel,
            addButtonAction,
            importExcelAction,
            downloadSampleExcelAction,
            hideExport,
            hideAddButton,
            showImportExcel,
            showDownloadSampleExcel,
            hideSearch,
            searchModel
          }),
          pagination: CustomPagination,
          noRowsOverlay: CustomNoRowsOverlay
        }}
        pagination={!searchMode && !!rows.length}
        {...options}
      />
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

CollapsedGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  options: PropTypes.object,
  addButtonLabel: PropTypes.string,
  addButtonAction: PropTypes.func,
  hideExport: PropTypes.bool,
  hideSearch: PropTypes.bool,
  title: PropTypes.string,
  searchMode: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  showImportExcel: PropTypes.bool,
  showDownloadSampleExcel: PropTypes.bool,
  importExcelAction: PropTypes.func,
  downloadSampleExcelAction: PropTypes.func,
  importExcelLabel: PropTypes.string,
  downloadSampleExcelLabel: PropTypes.string,
  hideIfNoData: PropTypes.bool,
  searchModel: PropTypes.object
};
