import { Download, FileUpload, Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { downloadFile } from "../../../utils/formHelper";
import ViewFile from "./ViewFile";
import { useState } from "react";

const ComplianceNavigatorActionButtons = ({ params, uploadPerRowEnabled = false, uploadPopUp }) => {
  const file = params?.row?.documentMediaModel?.path;
  const [openModal, setOpenModal] = useState(false);
  const [fileToView, setFileToView] = useState("");

  const handleViewFile = event => {
    event.stopPropagation();
    if (file) {
      setOpenModal(true);
      setFileToView(file);
    } else {
      console.error("File path is missing or invalid.");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFileToView("");
  };

  const iconStyle = {
    color: file ? "inherit" : "grey",
    cursor: file ? "pointer" : "default"
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={() => downloadFile(file)} onKeyDown={e => e.stopPropagation()} disabled={!file} style={iconStyle}>
          <Download />
        </IconButton>
        <IconButton onClick={handleViewFile} disabled={!file} onKeyDown={e => e.stopPropagation()} style={iconStyle}>
          <Visibility />
        </IconButton>
        {uploadPerRowEnabled &&
          <IconButton>
            {uploadPopUp}
          </IconButton>
        }
      </Box>
      {openModal && <ViewFile openModal={openModal} handleCloseModal={handleCloseModal} path={fileToView} />}
    </>
  );
};

ComplianceNavigatorActionButtons.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.shape({
      documentMediaModel: PropTypes.shape({
        path: PropTypes.string
      })
    })
  }).isRequired
};

export default ComplianceNavigatorActionButtons;
