import React, { useState, useEffect, useRef } from "react";
import DashBoardTable from "../ProGridTable/DashBoardTable";
import { getApi } from '../../../../utils/services'
import { GET_PREVAILING_WAGE_GAUGE_DATA } from '../../../../utils/services/apiPath'

const PrevailingWageRisk = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const hasFetchedData = useRef(false);
  const columns = [
    { field: "projectName", headerName: "Project" },
    { field: "partnerName", headerName: "Partner" },
    { field: "pwJobsCount", headerName: "# of Prevailing Wage Jobs" },
    // { field: "clrCounts", headerName: "Number  of Conformance Labor Requests/ Approved CLRs" },
    { field: "pwUnderpaymentsCount", headerName: "Prevailing Wage Underpayment" }
  ];

  const fetchPrevailingWageRiskData = async () => {
      setLoading(true);
      try {
        const { data, error } = await getApi(GET_PREVAILING_WAGE_GAUGE_DATA);
        if (error) { throw new Error(error);}

        const rowsWithId = data.map((item, index) => ({
          ...item,
          id: item.projectId || `fallback-id-${index}`,
        }));
        setRows(rowsWithId);
      }  
      finally {
        setLoading(false);
      }
  };  
  useEffect(() => {
    if (!hasFetchedData.current) {
      hasFetchedData.current = true;
      fetchPrevailingWageRiskData();
    }
  }, []);

  
  return (
    <div style={{ marginTop: "20px" }}>
      <DashBoardTable columns={columns} rows={rows} paginationModel={{ page: 0, pageSize: 5 }} hideExport={false} checkboxSelection={false} loading={loading} />
    </div>
  );
};

export default PrevailingWageRisk;
