import React, { useRef, useState } from "react";
import { Pwc } from "../../../Components/ModuleComponent/ProjectJobs/pwc";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { BASE_PWC_WSS_API_URL, BASE_WS_API_TOKEN, CONTRUCTION_TYPE } from "../../../../utils/constant";
import PropTypes from "prop-types";
import ProjectJobsForm from "./ProjectJobsForm";
import { InitiateClr } from "../../../Components/ModuleComponent/ProjectJobs/InitiateClr";

const ProjectJobsPwc = ({
  formConfig,
  defaultFormProps,
  jobTitle,
  addJobPermisison,
  wsPayload,
  assignRAPInfoGuids,
  assignRAPWageScaleGuids,
  watch,
  getValues,
  setValue,
  wageRate,
  jobSearchOptions,
  setWageRateConcented,
  isEditModeOn,
  getPWCFilePath,
  getJobSearchOption,
  isFetchLabels,
  trigger,
  setError,
  clearErrors,
  isNoRecordOnClassification,
  clrInfoMessage,
  onSaveClr,
  closeCrlEmailModel
}) => {
  const ws = useRef(null);
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openAcknowledgment, setOpenAcknowledgment] = React.useState(false);
  const [openClr, setOpenClr] = React.useState(false);
  const [isNoStateOrCountyData, setIsNoStateOrCountyData] = useState(false);
  const [isWagesLoader, setIsWagesLoader] = useState(false);
  const [chatBotFringes, setChatBotFringes] = useState(null);
  const [chatBotRates, setChatBotRates] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [footNotes, setFootNotes] = useState({});
  const [chatBotMatchScore, setChatBotMatchScore] = useState(null);
  const [jobTitlePW, setJobTitlePW] = useState("");
  const [jobTitleOptionsArray, setJobTitleOptionsArray] = useState([]);
  const [operatorPW, setOperatorPW] = useState("");
  const [responsibleUpkeep, setResponsibleUpkeep] = useState("");
  const [additionalRateQuestion, setAdditionalRateQuestion] = useState([]);
  const [answerString, setAnswerString] = useState("");
  const [isQueValidate, setIsQueValidate] = useState(false);
  const [pwGeneralDecisionNumber, setPWGeneralDecisionNumber] = useState("");
  const [pwSamFilename, setPWSamFilename] = useState("");
  const [pwSamFilenamePath, setPWSamFilenamePath] = useState("");

  const jobSelection = (index, field, newValue) => {
    jobTitleOptionsArray[index].jobTitle = newValue;
    setIsWagesLoader(true);
    getJobTitleIds(newValue, field?.jobTitleKey, field?.source);
  };

  const getJobTitleLabel = jobTitleId => {
    const jobTitleObj = jobTitle.find(item => item.value === jobTitleId);
    return jobTitleObj?.label;
  };

  const getDropdownValue = value => {
    let returnValue = value;
    if (typeof value === "string") {
      if (value === "") {
        returnValue = "None";
      } else {
        returnValue = value;
      }
    } else if (typeof value === "object" && !Array.isArray(value)) {
      returnValue = JSON.stringify(value);
    } else if (typeof value === "object" && Array.isArray(value)) {
      returnValue = value.join(", ");
    }
    return returnValue;
  };

  const getJobTitleIds = (value, key, source) => {
    let ids = [];
    source?.forEach(dropdown => {
      if (getDropdownValue(dropdown._source[key]) === value.label) {
        ids.push(dropdown._id);
      }
    });
    ws.current.send(JSON.stringify({ _id: ids }));
  };

  const getOtherFootnotes = otherFootnotes => {
    let footnotes = "";
    Object.keys(otherFootnotes).map(otherkey => {
      footnotes += `(${otherkey}). ${otherFootnotes[otherkey]}`;
      return null;
    });
    return footnotes;
  };

  const checkWageRates = (rates, minimumRates) => {
    return Number(rates) > Number(minimumRates) ? rates : minimumRates;
  };

  const ansAdditionalRateQuestion = () => {
    if (answerString !== "") {
      setIsQueValidate(false);
      setIsWagesLoader(true);
      ws.current.send(JSON.stringify({ additional_rate_answer: Number(answerString) }));
    } else {
      setIsQueValidate(true);
    }
  };

  const handleOpen = () => {
    const values = getValues();
    clearErrors(["jobTitleId", "externalJobTitle", "jobDescription", "typeOfJob", "IsCraftTradeLaborPosition"]);
    if (
      (values.jobTitleId || values.externalJobTitle) &&
      values.jobDescription &&
      values.typeOfJob &&
      values.IsCraftTradeLaborPosition &&
      values.payTypePosition !== "Salary"
    ) {
      setOpen(true);
      setIsNoStateOrCountyData(false);
      setAdditionalRateQuestion([]);
      setErrorMsg(null);
      let jobTitlePW = "";

      if (values.isExternalJobTitle) {
        jobTitlePW = values.externalJobTitle;
      } else {
        jobTitlePW = getJobTitleLabel(values.jobTitleId);
      }
      setJobTitlePW(jobTitlePW);
      setOperatorPW(
        values.typeOfOperator ? (values.isOperator ? `Yes, ${values.typeOfOperator}` : `No, ${values.typeOfOperator}`) : values.typeOfOperator
      );

      let responsibleUpkeep = "";
      if (values.isOperator) {
        responsibleUpkeep = values.isResponsibleForUpkeep ? "Yes" : "No";
      }
      setResponsibleUpkeep(responsibleUpkeep);

      if (wsPayload?.state && wsPayload?.county) {
        const chatbotUrl = `${BASE_PWC_WSS_API_URL}get-final-rate?token=${BASE_WS_API_TOKEN}`;
        ws.current = new WebSocket(chatbotUrl);

        ws.current.addEventListener("open", () => {
          ws.current.send(
            JSON.stringify({
              state: wsPayload.state,
              county: wsPayload.county,
              constructionType: CONTRUCTION_TYPE,
              jobnameAndInformation: jobTitlePW,
              counties: null,
              cities: wsPayload.city || null,
              jobLatitude: wsPayload.jobLatitude || null,
              jobLongitude: wsPayload.jobLongitude || null,
              distance: null,
              is_operator: values.isOperator,
              job_category: values.typeOfOperator,
              input_job_names: values.isOperator ? values.typeOfEquipment || [] : values.typeOfJob || [],
              is_responsible_for_upkeep: values.isResponsibleForUpkeep,
              job_description: values.jobDescription,
              is_external_job: values.isExternalJobTitle || false
            })
          );
        });

        setChatBotFringes(null);
        setChatBotRates(null);
        setIsWagesLoader(true);
        setFootNotes({});

        ws.current.addEventListener("message", event => {
          const receivedMessage = JSON.parse(event.data);
          if (receivedMessage?.is_final_answer) {
            if (receivedMessage?.results?.[0]) {
              const _source = receivedMessage.results[0]?._source;
              if (_source) {
                setChatBotFringes(Number(_source?.Fringe).toFixed(2));
                setChatBotRates(Number(checkWageRates(_source?.Rate, _source?.minimumWage)).toFixed(2));
                setChatBotMatchScore(Number(receivedMessage?.percentage_match));
                setPWGeneralDecisionNumber(receivedMessage?.general_decision_number);
                setPWSamFilename(_source["filename"]);
                setPWSamFilenamePath(receivedMessage?.filepath);
                setFootNotes(_source?.Footnotes);
              }
            } else {
              setErrorMsg(receivedMessage?.message);
            }
            setIsWagesLoader(false);
          } else {
            setIsWagesLoader(true);
            if (receivedMessage?.additional_rate_question) {
              setAdditionalRateQuestion(quetions => {
                return [...quetions, { question: receivedMessage?.additional_rate_question }];
              });
              setIsWagesLoader(false);
            }
          }
        });

        ws.current.addEventListener("close", () => {
          setErrorMsg("Connection Closed! Please reset it.");
          setIsWagesLoader(false);
          console.log("WebSocket connection closed");
        });
      } else {
        setIsNoStateOrCountyData(true);
      }
    } else {
      trigger(["jobTitleId", "externalJobTitle", "jobDescription", "typeOfJob"]);
      if (!values.IsCraftTradeLaborPosition)
        setError("IsCraftTradeLaborPosition", { type: "manual", message: "Select position a Craft / Trade Labor Position." });
      if (values.payTypePosition && values.payTypePosition === "Salary")
        setError("IsCraftTradeLaborPosition", {
          type: "manual",
          message: "Craft/Trade labor position cannot have a salaried pay type. Please select hourly"
        });
    }
  };

  const handleClose = () => {
    ws.current?.close();
    setChatBotMatchScore(null);
    setChatBotRates(null);
    setFootNotes({});
    setChatBotFringes(null);
    setErrorMsg(null);
    setOpen(false);
    setJobTitleOptionsArray([]);
    setAdditionalRateQuestion([]);
  };

  const handleChatbotSubmit = () => {
    if (chatBotRates) {
      setValue("wageRate", chatBotRates);
      setValue("fringe", chatBotFringes);
      setValue("pwAiConfidenceMatchScore", chatBotMatchScore);
      setValue("pwTotalHourlyComp", (Number(chatBotRates) + Number(chatBotFringes)).toFixed(2));
      setValue("footnoteSickDays", footNotes?.sickLeave || "");
      setValue("footnoteVacationDays", footNotes?.vacation || "");
      setValue("footnoteHolidays", footNotes?.holiday || "");
      setValue("footnoteOthers", getOtherFootnotes(footNotes?.otherFootnotes));
      setValue("generalDecisionNumber", pwGeneralDecisionNumber);
      setValue("samFileName", pwSamFilename);
      setValue("samFilePath", pwSamFilenamePath);
      setOpen(false);
      ws.current?.close();
      setChatBotMatchScore(null);
      setErrorMsg(null);
      setJobTitleOptionsArray([]);
      setAdditionalRateQuestion([]);
    } else {
      console.log("Wait for few seconds! Loading Rates.");
    }
  };

  const resetPWC = () => {
    handleClose();
    setTimeout(() => {
      handleOpen();
    }, 500);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleCloseAcknowledgment = () => {
    setOpenAcknowledgment(false);
  };

  const handleAcknowledgmentConcented = () => {
    setWageRateConcented(true);
    setOpenAcknowledgment(false);
  };

  const handleCloseClr = () => {
    setOpenClr(false);
  };

  const handleOpenClr = () => {
    setOpenClr(true);
  };

  return (
    <>
      <Pwc
        renderData={{
          isWagesLoader: isWagesLoader,
          chatBotFringes: chatBotFringes,
          chatBotRates: chatBotRates,
          isNoStateOrCountyData: isNoStateOrCountyData,
          errorMsg: errorMsg,
          footNotes: footNotes,
          chatBotMatchScore: chatBotMatchScore
        }}
        searchInput={{
          jobTitlePW: jobTitlePW,
          constructionType: CONTRUCTION_TYPE,
          wsPayload: wsPayload,
          operatorPW: operatorPW,
          responsibleUpkeep: responsibleUpkeep,
          values: getValues()
        }}
        jobSelection={jobSelection}
        jobTitleOptionsArray={jobTitleOptionsArray}
        open={open}
        handleClose={handleClose}
        handleChatbotSubmit={handleChatbotSubmit}
        resetPWC={resetPWC}
        additionalRateQuestion={additionalRateQuestion}
        ansAdditionalRateQuestion={ansAdditionalRateQuestion}
        setAnswerString={setAnswerString}
        isQueValidate={isQueValidate}
      />

      <InitiateClr
        open={openClr}
        onClose={handleCloseClr}
        onSaveClr={onSaveClr}
        closeCrlEmailModel={closeCrlEmailModel}
        searchInput={{
          jobTitlePW: getValues().isExternalJobTitle ? getValues().externalJobTitle : getJobTitleLabel(getValues().jobTitleId),
          constructionType: CONTRUCTION_TYPE,
          wsPayload: wsPayload,
          operatorPW: operatorPW,
          responsibleUpkeep: responsibleUpkeep,
          values: getValues()
        }}
      />

      <Dialog open={openWarning} onClose={handleCloseWarning} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Wage Rate and Fringe value are below compliance standards. You cannot proceed until values are more than prevailing wage.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseWarning} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAcknowledgment}
        onClose={handleCloseAcknowledgment}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Acknowledgment!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Wage Rate and Fringe value are above Prevailing Wage rates but below Market Rate, You can proceed with value entered but need to click
            "Yes".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleAcknowledgmentConcented} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <ProjectJobsForm
        formConfig={formConfig}
        defaultFormProps={defaultFormProps}
        jobTitle={jobTitle}
        addJobPermisison={addJobPermisison}
        wsPayload={wsPayload}
        assignRAPInfoGuids={assignRAPInfoGuids}
        assignRAPWageScaleGuids={assignRAPWageScaleGuids}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
        wageRate={wageRate}
        jobSearchOptions={jobSearchOptions}
        handleOpen={handleOpen}
        isEditModeOn={isEditModeOn}
        getPWCFilePath={getPWCFilePath}
        getJobSearchOption={getJobSearchOption}
        isFetchLabels={isFetchLabels}
        isNoRecordOnClassification={isNoRecordOnClassification}
        handleOpenClr={handleOpenClr}
        clrInfoMessage={clrInfoMessage}
        jobTitlePW={getValues().isExternalJobTitle ? getValues().externalJobTitle : getJobTitleLabel(getValues().jobTitleId)}
      />
    </>
  );
};

export default ProjectJobsPwc;

ProjectJobsPwc.propTypes = {
  defaultFormProps: PropTypes.object.isRequired,
  formConfig: PropTypes.shape({
    assignRAPInfoGuid: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    assignRAPWageScaleGuid: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    occupationTitle: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    onetCode: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    isExternalJobTitle: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    externalJobTitle: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    jobTitleId: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    numberOfPositions: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    jobDescription: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    payTypePosition: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    perDiem: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    IsCraftTradeLaborPosition: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    IsRole: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    salaryAmount: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    marketRate: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    pwAiConfidenceMatchScore: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    wageRate: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    fringe: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    pwTotalHourlyComp: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    actualWageRate: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    actualFringe: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    actualTotalHourlyComp: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    isApprenticeable: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  addJobPermisison: PropTypes.shape({
    fieldPerObj: PropTypes.objectOf(
      PropTypes.shape({
        perKey: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  assignRAPInfoGuids: PropTypes.array.isRequired,
  assignRAPWageScaleGuids: PropTypes.array.isRequired,
  jobTitle: PropTypes.array.isRequired,
  wsPayload: PropTypes.shape({
    state: PropTypes.any,
    jobLongitude: PropTypes.any,
    county: PropTypes.any,
    city: PropTypes.any,
    jobLatitude: PropTypes.any
  }).isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  jobSearchOptions: PropTypes.array.isRequired,
  wageRate: PropTypes.string.isRequired,
  setWageRateConcented: PropTypes.any.isRequired
};
