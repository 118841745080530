const urlBuilder = (url, queryParameters) => {
  let finalUrl = url + "?";
  for (const [queryParam, value] of Object.entries(queryParameters)) {
    if (value) {
      finalUrl += `${queryParam}=${value}&`;
    }
  }
  return finalUrl;
};

export { urlBuilder };
