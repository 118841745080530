import React from "react";
import PropTypes from "prop-types";
import { COLOR } from "../../../utils/constant";

const EditIcon = ({ withBg }) => {
  if (withBg) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} fill="none">
        <rect width={44} height={44} fill={COLOR.editButtonBg} rx={22} />
        <path
          stroke={COLOR.editButton}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M22 30h9M26.5 13.5a2.121 2.121 0 0 1 3 3L17 29l-4 1 1-4 12.5-12.5Z"
        />
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <path
        stroke={COLOR.editButton}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 20h9M16.5 3.5a2.121 2.121 0 1 1 3 3L7 19l-4 1 1-4L16.5 3.5Z"
      />
    </svg>
  );
};

EditIcon.propTypes = {
  withBg: PropTypes.string
};

export default EditIcon;
