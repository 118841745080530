import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material";

const RTIProgression = ({ chartData }) => {
  const theme = useTheme();
  const options = {
    chart: {
      type: "line",
      marginTop: 50,
      backgroundColor: theme.palette.demo.rtireportbackgroundcolor
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.demo.rtireportcolor
        }
      },
      lineColor: "#F8F8FF"
    },
    yAxis: {
      min: 0,
      title: {
        text: ""
      },
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.demo.rtireportcolor
        }
      },
      lineColor: "#F8F8FF"
    },
    series: chartData,
    plotOptions: {
      line: {
        lineWidth: 2,
        color: "#D3D3D3"
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: "horizontal",
      align: "right",
      verticalAlign: "top",
      floating: true,
      itemStyle: {
        color: theme.palette.demo.rtireportcolor,
        fontWeight: "normal"
      },
      itemHoverStyle: {
        color: theme.palette.demo.rtireportcolor
      },
      y: -10
    }
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

RTIProgression.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  ).isRequired
};

export default RTIProgression;
