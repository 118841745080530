import { useDispatch, useSelector } from "react-redux";
import useColumn from "../../../utils/customHooks/useColumns";
import Layout from "../../Components/Layout";
import { reloadCustomReports, routerSelector } from "../../store/routerSlice";
import { Link, useSearchParams } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ScheduleReportModal from "../../Components/ScheduleReportModal";
import { DataGridPro, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid-pro";

const ReportActionsCell = ({ params }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { reportId, designerPath, reportCategoryId, title: reportName, filePath, isDefault } = params.row
  const scheduleOptions = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Yearly", value: "yearly" }
  ];
  const linkUrl = `/${designerPath}?reportId=${reportId}&reportCategoryId=${reportCategoryId}&fromTemplate=${false}&reportName=${reportName}&filePath=${filePath}&isDefault=${isDefault}`
  return (
    <div style={{ display: "flex", gap: 5 }}>
      <Button variant="contained" onClick={() => setIsModalOpen(true)}>Schedule</Button>
      {/*  TODO: Add fromTemplate to api when mrt templates are made      */}
      <Link target="_blank" to={linkUrl}>
        <Button variant="contained">Edit</Button>
      </Link>
      <ScheduleReportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} scheduleOptions={scheduleOptions} reportId={reportId} />
    </div>
  )
}

const NoResultsOverlay = () => {
  return (
    <Stack className="proGrid-no-rows">
      <div>
        To create a custom report start by editing a standard report or template.
      </div>
      <Link to={"/reports-overview"} reloadDocument>
        <Button>View Editable Reports</Button>
      </Link>
    </Stack>
  )
}

const ReportsTable = () => {
  const { defaultReportRoutes, customReportRoutes } = useSelector(routerSelector)
  const tableData = defaultReportRoutes.concat(customReportRoutes)
  const reportTypes = [...new Set(defaultReportRoutes.map((report) => report.category))]
  const [rows, setRows] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const customOnly = searchParams.get("customOnly")
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  const formatTableData = () => {
    let rows = []
    rows = reportTypes.map((rt) => {
      return { hierarchy: [rt], path: rt, isTopLevel: true }
    })
    const transformedRows = tableData.map((report) => {
      return (
        {
          hierarchy: [report.category, `${report.title}${report.isDefault ? "" : " "}`],
          ...report
        }
      )
    })
    setRows(rows.concat(transformedRows))
  }
  const [filterModel, setFilterModel] = useState({
    items: [customOnly ? { field: "isDefault", operator: "contains", value: "false" } : { field: "isDefault", operator: "isAnyOf", value: ["true", "false"] }]
  })

  useEffect(() => {
    dispatch(reloadCustomReports());
  }, [])

  useEffect(() => {
    formatTableData()
  }, [defaultReportRoutes, customReportRoutes])

  const button = (params) => {
    return (
      <Link to={`/${params.row.path}`} target="_blank">
        <Typography variant="body2" color={"blue"}>
          View Report
        </Typography>
      </Link>
    )
  }

  const columns = useColumn(
    [
      {
        field: "title",
        headerName: "Report",
        renderCell: params => params.row.isTopLevel ? <></> : button(params)
      },
      {
        field: "category",
        headerName: "Category"
      },
      {
        field: "isDefault",
        headerName: "Report Type",
        renderCell: params => params.row.isTopLevel ? <></> : <Typography variant="body1">{params.row.isDefault ? "Standard" : "Custom"}</Typography>
      },
      {
        field: "reportId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 300,
        renderCell: params => params.row.isTopLevel ? <></> : <ReportActionsCell params={params} />
      }
    ]
  )
  const proColumns = columns.map(column => ({
    headerClassName: "proGrid-header",
    ...(!column.width && { flex: 1 }),
    ...column
  }));
  const getTreeDataPath = (row) => row.hierarchy;
  const groupingColDef = {
    headerName: "Report Category",
    headerClassName: "proGrid-header",
    width: 300
  };
  return (
    <Layout>
      <div className="rs-grid-wrapper v2" style={{ display: "flex", flexDirection: "column" }}>
        <DataGridPro
          apiRef={apiRef}
          treeData
          getTreeDataPath={getTreeDataPath}
          classes={{
            cell: "proGrid-table-cell"
          }}
          sortingOrder={["desc", "asc"]}
          columnHeaderHeight={44}
          rowHeight={44}
          columns={proColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          slots={{
            toolbar: GridToolbar,
            noResultsOverlay: NoResultsOverlay
          }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
          rows={rows}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
          getRowId={row => row.path}
          pagination
          groupingColDef={groupingColDef}
        />
      </div>
    </Layout>
  )
}

export default ReportsTable;