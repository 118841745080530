import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useWindowSize } from "../../../../utils/customHooks/useWindowSize";
import EditButton from "../../Inputs/EditButton";
import { ErrorBox } from "../../Helpers/ErrorBox";

export default function ProFormsLayout({
  canView = true,
  children,
  title,
  beingEdited,
  onCancel,
  onSave,
  onEdit,
  hideEdit,
  isSaving,
  disableSaveBtn = false
}) {
  const { isMobile } = useWindowSize();
  if (!canView) {
    return (
      <div>
        <ErrorBox message="You don't have permission to view this page." />
      </div>
    );
  }
  return (
    <div className="proForm-layout">
      <div className="proForm-layout__header">
        <h2>{title}</h2>
        <EditButton onClick={onEdit} isMobile={isMobile} beingEdited={beingEdited} hideEdit={hideEdit} />
      </div>
      <div className="proForm-layout__content__wrapper">
        <div className="proForm-layout__content">{children}</div>
      </div>
      <div className="proForm-layout__footer">
        <div className="proForm-layout__footer__actions">
          {beingEdited && (
            <>
              <Button className="cancel-btn" variant="outlined" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="contained" onClick={onSave} disabled={isSaving || disableSaveBtn}>
                {isSaving ? (
                  <>
                    <CircularProgress size={24} />
                    <span style={{ marginLeft: 10 }}>Save</span>
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

ProFormsLayout.propTypes = {
  canView: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  beingEdited: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  hideEdit: PropTypes.bool,
  isSaving: PropTypes.bool,
  disableSaveBtn:PropTypes.bool
};
