import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import FormGridInsideSectionLayout from "../../../Components/ProForms/ProFormsLayout/FormGridInsideSectionLayout.jsx";
import ProGrid from "../../../Components/ProGrid/ProGrid.jsx";
import { STAUTES } from "../../../../utils/constant";
import usePageState from "../../../../utils/customHooks/usePageState";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import PropTypes from "prop-types";
import {
  GET_ALL_PARTNERS_CONTACTS,
  GET_ALL_PROJECTPARTNER,
  GET_ALL_PARTNERS,
  ADD_PROJECTPARTNER,
  GET_ALL_CONTRACTTYPE,
  UPDATE_PROJECTPARTNER,
  DELETE_PROJECTPARTNER,
  GET_PROJECTPARTNER
} from "../../../../utils/services/apiPath";
import { postApi, getApi, deleteApi } from "../../../../utils/services";
import { ProjectPartnerConfig } from "../projectData.js";
import { changeMode, checkEditEnabled, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import ProSelectInput from "../../../Components/Inputs/SelectField";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import usePermission from "../../../../utils/customHooks/usePermission";
import { R_PROJECTS, R_PROJECTS_PROFILE } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import useColumn from "../../../../utils/customHooks/useColumns";
import DateField from "../../../Components/Inputs/DateField.jsx";

export default function ProjectPartners({ projectId, globalEditMode, fromAddScreen, addScreenProjectId, setPartnerSaved }) {
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(ProjectPartnerConfig));
  const [contractType, setContractType] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientContact, setClientContact] = useState([]);
  const [actionData, setActionData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const isEditModeOn = useMemo(() => checkEditEnabled(false, formConfig), [formConfig]);

  const { "Project Partners": projectPartnersPermission } = usePermission(subModPermissionSelector(R_PROJECTS, R_PROJECTS_PROFILE), [
    "Project Partners"
  ]);

  const { currentPage, pageSize, data: documentData, rowCount, status, setPageState, setPagination, setStatus } = usePageState();
  const { handleSubmit, control, setValue, watch, getValues } = useForm({ defaultValues: prepareDefaultValues(ProjectPartnerConfig) });
  const client = watch("client");

  const getProjectClientContractors = useCallback(async ({ addScreenId = '' }) => {
    if (projectId !== null || addScreenId !== '') {
      setStatus(STAUTES.LOADING);
      const payload = {
        pageIndex: currentPage + 1,
        pageSize: pageSize,
        orderByAscending: false,
        searchString: ""
      };
      const url = `${GET_ALL_PROJECTPARTNER}?projectId=${addScreenId || projectId}`
      const { data, totalRecords, error } = await postApi(url, payload);

      setStatus(STAUTES.IDLE);
      if (error) return errorToast(error);
      setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
    }
  }, [currentPage, pageSize, setPageState, setStatus]);

  const getClientContractorType = async () => {
    const { data } = await postApi(`${GET_ALL_CONTRACTTYPE}`, defaultDropdownConfig);
    setContractType(data?.map(item => ({ label: item.clientContractType, value: item.clientContractTypeId })) || []);
  };

  const getClientList = useCallback(async () => {
    const { data } = await postApi(`${GET_ALL_PARTNERS}`, defaultDropdownConfig);
    setClients(data?.map(item => ({ label: item.companyName, value: item.clientId })) || []);
  }, [0]);

  const handleReadValueClick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const defaultFormProps = { control, formValues: actionData, handleReadValueClick };

  const handleAddUpdateRow = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      projectId: projectId || addScreenProjectId,
      clientId: data.client,
      clientContactId: data.clientContact,
      clientContractTypeId: data.contractType,
      projectClientContractId: actionData?.projectClientContractId || null
    };

    const { error } = await postApi(actionData?.projectClientContractId ? UPDATE_PROJECTPARTNER : ADD_PROJECTPARTNER, payload);
    setIsSaving(false);
    if (error) return errorToast(error);
    getProjectClientContractors({ addScreenId: addScreenProjectId });
    resetForm();
    successToast(`Project Partner ${!actionData?.projectClientContractId ? "Added" : "Updated"} Successfully`);
  };

  useEffect(() => {
    if (!fromAddScreen || addScreenProjectId === '') return;

    // For submitting an empty form in the add screen
    const formValues = getValues()
    if (formValues.client === null && formValues.clientContact === null && formValues.contractType === null) {
      return
    }

    handleAddUpdateRow(getValues())
    setPartnerSaved(true)
  }, [addScreenProjectId])

  const handleEditRow = async event => {
    const { data, error } = await getApi(`${GET_PROJECTPARTNER}/${event}`);
    if (error) return errorToast(error);
    if (!data) return;
    setActionData(data);
    setValue("client", data.clientId);
    setValue("contractType", data.clientContractTypeId);
    setValue("clientContact", data.clientContactId);
    getClientContact(data.clientId);
  };

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const resetForm = () => {
    setActionData({});
    setFormConfig(changeMode());
    Object.keys(formConfig).forEach(field => setValue(field, null));
  };

  useEffect(() => {
    if (!fromAddScreen) {
      getProjectClientContractors({});
    }
    getClientContractorType();
    getClientList();
  }, [getProjectClientContractors, currentPage, pageSize]);

  const cancelAddUpdateRow = () => {
    resetForm();
  };

  const getClientContact = async option => {
    const { data } = await postApi(`${GET_ALL_PARTNERS_CONTACTS}?clientId=${option}`, defaultDropdownConfig);
    setClientContact(data?.map(item => ({ label: item.firstName + " " + item.lastName, value: item.clientContactId })) || []);
  };

  const onDelete = async projectClientContractId => {
    const { error } = await deleteApi(DELETE_PROJECTPARTNER, { data: [{ projectClientContractId: projectClientContractId }] });
    if (error) return errorToast(error);
    getProjectClientContractors({});
    successToast("Partner deleted successfully.");
  };

  useEffect(() => {
    if (globalEditMode) onEdit();
    else resetForm();
  }, [globalEditMode]);

  useEffect(() => {
    if (client) getClientContact(client);
  }, [client]);

  const columns = useColumn(
    [
      {
        field: "companyName",
        headerName: "Partner",
        renderCell: params => <NameCell params={params} onClick={() => handleEditRow(params.row.projectClientContractId)} />
      },
      {
        field: "clientContact",
        headerName: "Partner Contact"
      },
      {
        field: "clientContractType",
        headerName: "Partner Type"
      },

      {
        field: "projectClientContractId",
        headerName: "Actions",

        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    projectPartnersPermission.canDelete
  );

  return (
    <>
      <div className="proFormGridInsideSectionLayout-wrapper">
        <FormGridInsideSectionLayout
          showGrid={true}
          beingEdited={fromAddScreen ? false : isEditModeOn}
          onEdit={onEdit}
          onCancel={cancelAddUpdateRow}
          onSave={handleSubmit(handleAddUpdateRow)}
          hideEdit={!projectPartnersPermission.canEdit}
          isSaving={isSaving}
        >
          <Grid container spacing={2}>
            <ProSelectInput
              {...defaultFormProps}
              {...formConfig.client}
              options={clients}

              permission={projectPartnersPermission.fieldPerObj[formConfig.client.perKey]}
            />
            <ProSelectInput
              {...defaultFormProps}
              {...formConfig.clientContact}
              options={clientContact}
              permission={projectPartnersPermission.fieldPerObj[formConfig.clientContact.perKey]}
            />
            <ProSelectInput
              {...defaultFormProps}
              {...formConfig.contractType}
              options={contractType}
              permission={projectPartnersPermission.fieldPerObj[formConfig.contractType.perKey]}
            />
            {/* TODO: Make this real if needed once demo is over */}
            {/* <DateField control={control} {...formConfig.estimatedStartDate} />
            <DateField control={control} {...formConfig.documentSubmissionStartDate} /> */}
          </Grid>
        </FormGridInsideSectionLayout>
      </div>
      <ProGrid
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.projectClientContractId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          pageSizeOptions: [10]
        }}
        hideIfNoData={true}
        hideSearch={true}
        columns={columns}
        hideExport
        hideAddButton={true}
        rows={documentData || []}
      />
    </>
  );
}

ProjectPartners.propTypes = {
  projectId: PropTypes.string,
  globalEditMode: PropTypes.bool
};
