import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DashBoardTable from "../ProGridTable/DashBoardTable";
import DateField from "../../../Components/Inputs/DateField";
import { fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import NameCell from "../../../Components/ProGrid/components/NameCell";

const renderCell = type => params => {
  if (params.row.isDummy) return <span className="centerAlign"></span>;
  if (type === "hours") return <span className="centerAlign">{params.value ?? 0} Hours</span>;
  if (type === "number") return <span className="centerAlign">{params.value ?? 0}</span>;
  if (type === "percentage") return <span className="centerAlign">{params.value ?? 0}%</span>;
  return <span className="centerAlign">{params.value}</span>;
};

const sortComparator = (v1, v2, param1, param2) => {
  const sortModifier = param1.api.getSortModel()[0]?.sort === "asc" ? 1 : -1;
  if (param1.api.getRowParams(param1.id).row.isDummy) return sortModifier * 1;
  if (param2.api.getRowParams(param2.id).row.isDummy) return sortModifier * -1;
  return gridStringOrNumberComparator(v1, v2, param1, param2);
};

const columns = [
  {
    field: "projectName",
    headerName: "Project Name",
    renderCell: params => (
      <NameCell params={params} getRedirectUrl={params => `/projects/${params.row.projectGuid}`} />
    ),
    sortComparator,
    headerAlign: "center",
    minWidth: 125
  },
  {
    field: "journeyToApprenticeRatio",
    headerName: "J to A%",
    renderCell: renderCell("percentage"),
    sortComparator,
    headerAlign: "center"
  },
  {
    field: "apprenticeCount",
    headerName: "Apprentice Count",
    renderCell: renderCell("number"),
    sortComparator,
    headerAlign: "center",
    minWidth: 90
  },
  {
    field: "apprenticeHours",
    headerName: "Apprentice Hours Worked",
    renderCell: renderCell("hours"),
    sortComparator,
    headerAlign: "center",
    minWidth: 105
  },
  {
    field: "rtiHours",
    headerName: "RTI",
    renderCell: renderCell("hours"),
    sortComparator,
    headerAlign: "center"
  },
  {
    field: "otjHours",
    headerName: "OTJ Total",
    sortComparator,
    headerAlign: "center",
    renderCell: renderCell("hours"),
  },
  {
    field: "mentorCount",
    headerName: "Mentor Count",
    renderCell: renderCell("number"),
    sortComparator,
    headerAlign: "center",
    minWidth: 100
  },
  {
    field: "totalHours",
    headerName: "Total Labor Hours",
    renderCell: renderCell("hours"),
    sortComparator,
    headerAlign: "center",
    minWidth: 90
  },
  {
    field: "lostOTJHours",
    headerName: "Lost OTJ",
    renderCell: renderCell("hours"),
    sortComparator,
    headerAlign: "center"
  }
];

const projectOverviewConfig = {
  weekEndingDate: {
    label: "Weekending Date",
    rules: { required: "Date is required!" }
  }
};

export default function WageOverviewTable({ projectOverviewData, loading }) {
  const { control } = useForm({ defaultValues: prepareDefaultValues(projectOverviewConfig) });
  const [formConfig] = useState(prepareInitialConfig(projectOverviewConfig, "edit"));
  const [filtered, setFiltered] = useState([])

  const getAdditionalButtons = () => (
    <div style={{ width: "200px" }}>
      <DateField control={control} {...formConfig.weekEndingDate} shouldDisableDate={false} permission={fieldTruePermission} />
    </div>
  );

  //TODO: Remove once the demo is over AND the api is returning non empty projects
  useEffect(() => {
    let filtered = projectOverviewData?.filter((data) => data.projectName && data.projectName !== '') || []
    setFiltered(filtered)
  }, [projectOverviewData])

  return (
    <div>
      <div className="djr-header with-dropdown without-border">
        <div className="djr-header-title-wrapper">
          <div className="djr-header-subtitle">Select the Partner from above heat map to Generate the Project overview</div>
        </div>
      </div>
      <div style={{ padding: "0 16px" }}>
        <DashBoardTable
          loading={loading}
          columns={columns}
          // rows={projectOverviewData || []}
          rows={filtered || []}
          title={"Project Overview"}
          hasBorder={false}
          hideExport={true}
          minColumnWidth={null}
          // ToDo: enable this once the demo is over
          // additionalButtons={getAdditionalButtons()}
        />
      </div>
    </div>
  );
}

WageOverviewTable.propTypes = {
  projectOverviewData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};
