import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";

export default function ToolbarBase({
  title = "",
  titleSize,
  children
}) {
  return (
    <GridToolbarContainer>
      <div className="proGrid-title-wrapper">
        {titleSize ? <Typography variant={titleSize}>{title}</Typography> :
          <div className="proGrid-title">{title}</div>
        }
        {children}
      </div>
    </GridToolbarContainer>
  );
}