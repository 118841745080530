import ProGrid from "../../../Components/ProGrid/ProGrid";
import PropTypes from "prop-types";
import { STAUTES } from "../../../../utils/constant";
import useColumn from "../../../../utils/customHooks/useColumns";
import ToolbarBase from "../../../Components/Toolbars/ToolbarBase";
import GridSerch from "../../../Components/ProGrid/components/GridSerch";
import { ADD_ENVERUS_PROJECTLIST, GET_PROJECT, GET_ENVERUS_PROJECTLIST } from "../../../../utils/services/apiPath";
import usePageState from "../../../../utils/customHooks/usePageState";
import { useEffect, useCallback, useState } from "react";
import { postApi, getApi } from "../../../../utils/services";
import { GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro";
import { Select, MenuItem, FormControl, InputLabel, Button } from "@mui/material";
import AddCell from "../../../Components/ProGrid/components/AddCell";
import { formatPayloadDate, prepareDefaultValues, prepareInitialConfig, fieldTruePermission } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import DateField from "../../../Components/Inputs/DateField";
import { ClearIcon } from "@mui/x-date-pickers-pro";
import { errorToast } from "../../../../utils/toastHelper";

function ProjectsToolbar({ title = "", setProjectStatus, projectStatus, searchModel = {}, resetForm }) {
  return () => (
    <ToolbarBase title={title}>
      <div style={{ display: "flex", flexDirection: "row", lineHeight: "normal" }}>
        <Button onClick={e => resetForm(e)}>
          <ClearIcon />
          Reset Form
        </Button>
        <GridToolbarFilterButton style={{ marginRight: "5px" }} />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Project Status</InputLabel>
          <Select
            sx={{ fontSize: "12px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Project Status..."
            value={projectStatus}
            onChange={e => setProjectStatus(e.target.value)}
            style={{ height: "50px", width: "150px" }}
          >
            <MenuItem value={"Operating"}>Operating</MenuItem>
            <MenuItem value={"System Impact Study"}>System Impact Study</MenuItem>
            <MenuItem value={"Facility Study"}>Facility Study</MenuItem>
            <MenuItem value={"Project Suspended"}>Project Suspended</MenuItem>
          </Select>
        </FormControl>
        <div className="proGrid-search-wrapper" style={{ margin: "5px" }}>
          <GridSerch {...searchModel} />
        </div>
      </div>
    </ToolbarBase>
  );
}

function ProjectsFilterPanel({ stateSearchModel, citySearchModel, control, formConfig, fromDate, toDate, resetFilters }) {
  const disableDate = type => date => {
    if (type === "from") return date.isAfter(toDate);
    else return date.isBefore(fromDate);
  };

  return () => (
    <div className="proGrid-filter" style={{ display: "flex", flexDirection: "column", lineHeight: "normal" }}>
      <InputLabel id="demo-simple-select-state" style={{ margin: "5px" }}>
        State
      </InputLabel>
      <GridSerch {...stateSearchModel} />

      <InputLabel id="demo-simple-select-city" style={{ margin: "5px" }}>
        City
      </InputLabel>
      <GridSerch {...citySearchModel} />

      <div style={{ margin: "15px" }}>
        <div className="date-select" style={{ display: "flex" }}>
          <DateField control={control} {...formConfig.fromDate} shouldDisableDate={disableDate("from")} permission={fieldTruePermission} />
          <DateField control={control} {...formConfig.toDate} shouldDisableDate={disableDate("to")} permission={fieldTruePermission} />
        </div>
      </div>
      <Button
        type="submit"
        onClick={e => {
          e.preventDefault();
          resetFilters();
        }}
      >
        Reset Filters
        <ClearIcon />
      </Button>
    </div>
  );
}

const getEnvProjectConfig = () => {
  return {
    fromDate: {
      label: "From Date",
      defaultValue: null
    },
    toDate: {
      label: "To Date",
      defaultValue: null
    }
  };
};

export default function EnverusProjectsTable({ setResetForm, setEnverusFormData }) {
  const gridRef = useGridApiRef();
  const {
    currentPage,
    pageSize,
    data: enverusData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const { control, watch, reset } = useForm({ defaultValues: prepareDefaultValues(getEnvProjectConfig()) });
  const [formConfig] = useState(prepareInitialConfig(getEnvProjectConfig(), "edit"));
  const [projectStatus, setProjectStatus] = useState("Operating");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const stateSearchModel = { defaultValue: state, handleDebounce: setState, closestClass: "proGrid-search-wrapper", placeholder: "State..." };
  const citySearchModel = { defaultValue: city, handleDebounce: setCity, closestClass: "proGrid-search-wrapper", placeholder: "City..." };
  useEffect(() => { }, [fromDate, toDate]);

  const handleCreateProject = async row => {
    if (!row) {
      return errorToast("Unable to fill form for selected row.")
    }
    setResetForm(true)
    setEnverusFormData(row)
  };

  const resetFilters = () => {
    setCity("");
    setState("");
    setProjectStatus("Operating");
    reset();
  };

  const resetForm = e => {
    e.preventDefault();
    setResetForm(true);
  };

  const columns = useColumn([
    {
      field: "projectGuid",
      headerName: "Actions",
      renderCell: params => <AddCell params={params} onAdd={() => handleCreateProject(params?.row)} />,
      width: 80
    },
    {
      field: "enverusId",
      headerName: "External Id",
      width: 80
    },
    {
      field: "projectName",
      headerName: "Project Name",
      width: 200
    },
    {
      field: "country",
      headerName: "Country"
    },
    {
      field: "stateProvince",
      headerName: "State"
    },
    {
      field: "county",
      headerName: "County"
    },
    {
      field: "city",
      headerName: "City"
    },
    {
      field: "zipCode",
      headerName: "Zip Code"
    },
    {
      field: "address",
      headerName: "Address"
    },
    {
      field: "projectStatus",
      headerName: "Status"
    },
    dateColumnDef({
      field: "constructionDate",
      headerName: "Construction Date"
    })
  ]);

  const fetchEnverusProjects = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: 6,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "projectName",
      dateFrom: formatPayloadDate(fromDate),
      dateTo: formatPayloadDate(toDate),
      stateProvince: state,
      city: city,
      projectStatus: projectStatus,
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ENVERUS_PROJECTLIST, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus, state, projectStatus, city, fromDate, toDate]);

  useEffect(() => {
    fetchEnverusProjects();
  }, [fetchEnverusProjects]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "20px" }}>
      <ProGrid
        columns={columns}
        loading={status === STAUTES.LOADING}
        rows={enverusData}
        searchMode={!!searchString}
        toolbar={ProjectsToolbar({
          title: "If desired, select published project",
          searchModel: { defaultValue: searchString, handleDebounce: setSearchString },
          setProjectStatus: setProjectStatus,
          projectStatus,
          resetForm
        })}
        options={{
          getRowId: row => row.envProjectGuid,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel,
          apiRef: gridRef
        }}
        filterPanel={ProjectsFilterPanel({
          citySearchModel: citySearchModel,
          stateSearchModel: stateSearchModel,
          fromDate,
          toDate,
          control,
          formConfig,
          resetFilters
        })}
      />
    </div>
  );
}

EnverusProjectsTable.propTypes = {
  setProjectData: PropTypes.func.isRequired,
  setResetForm: PropTypes.func.isRequired
};