import { Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "./DeleteCell.scss";
import ConfirmationModal from "../../ConfirmationModal";
import PropTypes from "prop-types";

export default function DeleteCell({ title, params, onDelete, disabled = false }) {
  const theme = useTheme();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const onConfirm = () => {
    onDelete(params.id);
    setShowConfirmationModal(false);
  };
  return (
    <>
      <Button
        className="proGrid-delete"
        variant="outlined"
        size="small"
        style={{
          border: theme.palette.progrid.cellbuttonborder,
          backgroundColor: theme.palette.progrid.cellbuttonbackgroundcolor,
          color: theme.palette.progrid.cellbuttoncolor
        }}
        onClick={openConfirmationModal}
        disabled={disabled}
      >
        <DeleteOutlineIcon fontSize="small" sx={{ color: theme.palette.progrid.cellbuttonborder }} />
      </Button>
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          title={title}
          message="Are you sure you want to delete this record?"
          onConfirm={onConfirm}
          onCancel={() => setShowConfirmationModal(false)}
          confirmBtnText={"Delete"}
        />
      )}
    </>
  );
}

DeleteCell.propTypes = {
  params: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string
};
