import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";
import { useForm } from "react-hook-form";
import ProTextInput from "../../../Components/Inputs/TextField";
import DateField from "../../../Components/Inputs/DateField";
import ProSelectInput from "../../../Components/Inputs/SelectField";
import { useNavigate, useParams } from "react-router-dom";
import CheckBoxGroup from "../../../Components/Inputs/CheckBoxGroup";
import { ProjectInfoConfig } from "../projectData.js";
import ToggleField from "../../../Components/Inputs/ToggleButton";
import TimeField from "../../../Components/Inputs/TimeField";
import ProRadioGroup from "../../../Components/Inputs/RadioGroup";
import { Grid } from "@mui/material";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { UPDATE_PROJECT_INFO, GET_PROJECT_INFO, ADD_PROJECT_INFO, DYNAMIC_DROPDOWN } from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";
import {
  changeMode,
  changeModeForField,
  checkEditEnabled,
  convertResponseDate,
  convertResponseDateTime,
  convertTimetoResponse,
  fieldTruePermission,
  formatPayloadDate,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import {
  CourseContentDeliveryOptions,
  ElearningHoursPaidOptions,
  RelatedInstructionIsProvidedOptions,
  TrackingMethod,
  fringePayModeOptions,
  payrollTypeOptions,
  timekeepingOptions,
  weekdaysOptions,
  yesNoOptions
} from "../../../../utils/dropdownHelper.js";
import usePermission from "../../../../utils/customHooks/usePermission";
import { R_PROJECTS, R_PROJECTS_INFO } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import ProjectContactInfo from "./ProjectContactInfo.jsx";
import DialogModal from "../../../Components/DialogModel/index.js";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

export default function ProjectInfo() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(ProjectInfoConfig));
  const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(ProjectInfoConfig) });
  const [isSaving, setIsSaving] = useState(false);
  const [projectProfileData, setProjectProfileData] = useState({});
  const [projectInfoId, setProjectInfoId] = useState(null);
  const [timeKeepingPeriodArray] = useState([]);
  const [globalEditMode, setGlobalEditMode] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [uploadTimesheetData, setUploadTimesheetData] = useState([]);
  const autoGenerate = watch("isCertifiedPayrollAutoGenarate");
  const token = localStorage.getItem("accessToken");
  const tokenData = jwtDecode(token);

  const { "My Project Information": projectInformationPermission, "Apprentice Mgmt": apprenticeMgmtPermission } = usePermission(
    subModPermissionSelector(R_PROJECTS, R_PROJECTS_INFO),
    ["My Project Information", "Apprentice Mgmt"]
  );

  const isworkforceStartDate = watch("workforceStartDate");

  const handleDateValidation = date => {
    return date ? dayjs(date).isBefore(dayjs(isworkforceStartDate), "day") : false;
  };

  const isEditModeOn = useMemo(() => checkEditEnabled(false, formConfig), [formConfig]);

  const backAction = () => navigate("/projects");

  const onEdit = () => {
    setGlobalEditMode(true);
    setFormConfig(changeMode("edit"));
  };

  const onCancel = () => {
    setGlobalEditMode(false);
    setFormConfig(changeMode());
  };
  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  useEffect(() => {
    if (isEditModeOn && !autoGenerate) {
      setConfirmation(true);
    }
  }, [autoGenerate]);

  const onSubmit = async values => {
    if (isSaving) return;
    setIsSaving(true);
    const {
      adaptiveElearning,
      courseContentDelivery,
      learningHoursPaid,
      maxPaidHoursPerDay,
      fringe,
      wageRate,
      learningOrClassHoursPaid,
      relatedInstructionProvidedHours,
      rtiWeeklyExpectationHours,
      preferredRTIDays,
      //apprenticeHoursTracking,
      certifiedPayrollEmailAddress,
      totalWorkforceHours,
      typicalshiftdays,
      shiftStartTime,
      shiftEndTime,
      preferredDayStartTime,
      preferredDayEndTime,
      workforceStartDate,
      workForceEndDate,
      uploadDailyTimeSheet,
      ...rest
    } = values;
    const payload = {
      ...rest,
      projectId: id,
      projectInfoId: projectInfoId,
      uploadDailyTimeSheet: 2,
      ownerCertifiedPayrollSignatoryEmail: null,
      percentageApprenticeWork: totalWorkforceHours,
      typicalShiftDay: typicalshiftdays?.length > 0 ? Array.from(new Set(typicalshiftdays)).toString() : "",
      shiftStartTime: convertTimetoResponse(shiftStartTime),
      shiftEndTime: convertTimetoResponse(shiftEndTime),
      mobileAppTimeKeepingManagement: timeKeepingPeriodArray?.length > 0 ? Array.from(new Set(timeKeepingPeriodArray)).toString() : "",
      payPeriodBegin: values.daysofWeekBegin,
      payPeriodEnd: values.daysofWeekEnd,
      workforceStartDate: formatPayloadDate(workforceStartDate),
      workForceEndDate: formatPayloadDate(workForceEndDate),
      apprenticeManagementModel: {
        fringe,
        wageRate,
        courseContentDelivery,
        //apprenticeHoursTracking,
        maxPaidHoursPerDay,
        learningHoursPaid,
        learningOrClassHoursPaid,
        relatedInstructionProvidedHours,
        rtiWeeklyExpectationHours,
        rtiWeeklyExpectationMinutes: values.rtiWeeklyExpectationMins,
        isAdaptive: adaptiveElearning,
        startTime: convertTimetoResponse(preferredDayStartTime),
        endTime: convertTimetoResponse(values.preferredDaysEndTime),
        preferredDays: preferredRTIDays?.length > 0 ? Array.from(new Set(preferredRTIDays)).toString() : ""
      },
      payrollOn: "1",
      provideTimeKeepingOn: "1",
      isCertifiedPayrollAutoGenarate: true
    };
    const { error } = await postApi(projectInfoId ? UPDATE_PROJECT_INFO : ADD_PROJECT_INFO, payload);
    setIsSaving(false);
    if (error) errorToast(error);
    if (projectInfoId) {
      setProjectProfileData(values);
      onCancel();
    } else navigate("/projects");
    successToast("Project edited successfully.");
  };

  const getProjectProfileData = useCallback(
    async id => {
      try {
        const { error, data: project } = await postApi(GET_PROJECT_INFO, { projectId: id });
        if (error) {
          errorToast(error);
          return;
        }
        if (!project) return;

        const learningHoursPaidValue = getLearningHoursPaidValue(project?.apprenticeManagementModel.learningHoursPaid);
        const data = transformProjectData(project, learningHoursPaidValue);

        setProjectProfileData(data);
        setProjectInfoId(project.projectInfoId);
        updateFormFields(data, formConfig, setValue);
      } catch (error) {
        console.error("Error fetching project data:", error);
        errorToast("Error fetching project data. Please try again later.");
      }
    },
    [formConfig, setValue]
  );

  const getLearningHoursPaidValue = learningHoursPaid => {
    if (learningHoursPaid === true) return true;
    if (learningHoursPaid === false) return false;
    return null;
  };

  const transformProjectData = (project, learningHoursPaidValue) => {
    const { apprenticeManagementModel = {} } = project || {};
    return {
      ...project,
      shiftEndTime: convertResponseDateTime(project?.shiftEndTime),
      shiftStartTime: convertResponseDateTime(project?.shiftStartTime),
      totalWorkforceHours: project?.percentageApprenticeWork,
      workforceStartDate: convertResponseDate(project?.workforceStartDate),
      workForceEndDate: convertResponseDate(project?.workforceEndDate),
      certifiedPayrollEmailAddress: project?.ownerCertifiedPayrollSignatoryEmail,
      typicalshiftdays: splitAndFilter(project?.typicalShiftDay),
      rtiWeeklyExpectationHours: apprenticeManagementModel.rtiWeeklyExpectationHours || null,
      rtiWeeklyExpectationMins: apprenticeManagementModel.rtiWeeklyExpectationMinutes || null,
      adaptiveElearning: apprenticeManagementModel.isAdaptive || false,
      courseContentDelivery: apprenticeManagementModel.courseContentDelivery || null,
      // apprenticeHoursTracking: apprenticeManagementModel.apprenticeHoursTracking || null,.
      apprenticeHoursTracking: "Manual Timesheet",
      preferredDays: apprenticeManagementModel.preferredDays || null,
      maxPaidHoursPerDay: apprenticeManagementModel.maxPaidHoursPerDay || null,
      wageRate: apprenticeManagementModel.wageRate || null,
      fringe: apprenticeManagementModel.fringe || null,
      preferredDayStartTime: convertResponseDateTime(apprenticeManagementModel.startTime),
      preferredDaysEndTime: convertResponseDateTime(apprenticeManagementModel.endTime),
      learningHoursPaid: learningHoursPaidValue,
      learningOrClassHoursPaid: apprenticeManagementModel.learningOrClassHoursPaid || null,
      preferredRTIDays: splitAndFilter(apprenticeManagementModel.preferredDays),
      relatedInstructionProvidedHours: apprenticeManagementModel.relatedInstructionProvidedHours || null,
      daysofWeekBegin: project?.payPeriodBegin,
      daysofWeekEnd: project?.payPeriodEnd
    };
  };

  const splitAndFilter = str => str?.split(",").filter(v => v) || [];

  const updateFormFields = (data, formConfig, setValue) => {
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const handleConfirmModal = () => {
    setConfirmation(false);
  };

  const handleCancelModal = () => {
    setConfirmation(false);
    setValue("isCertifiedPayrollAutoGenarate", true);
  };

  const getUploadTimeSheetDropDown = async () => {
    const payload = ["uploaddailytimesheettype"];
    const data = await postApi(DYNAMIC_DROPDOWN, payload);
    setUploadTimesheetData(data[0]?.typeDropDowns?.map(item => ({ label: item.label, value: item.intValue })));
  };

  useEffect(() => {
    getProjectProfileData(id);
    getUploadTimeSheetDropDown();
  }, [getProjectProfileData, id]);

  const defaultFormProps = { control, formValues: projectProfileData, handleReadValueClick };
  const courseContentDelivery = watch("courseContentDelivery");

  return (
    <div className="partner-profile">
      <ProFormsLayout
        backAction={backAction}
        title={`Edit Project`}
        backLabel="Go Back to All Projects"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        hideEdit={!(projectInformationPermission.canEdit || apprenticeMgmtPermission.canEdit)}
        isSaving={isSaving}
      >
        <div className="proFormSample-content">
          {projectInformationPermission.canView ? (
            <FormSectionWrapper title={"My Project Information"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.totalWorkforceHours}
                permission={projectInformationPermission.fieldPerObj[formConfig.totalWorkforceHours.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.moreThanFourEmployees}
                options={yesNoOptions}
                permission={projectInformationPermission.fieldPerObj[formConfig.moreThanFourEmployees.perKey]}
              />
              <Grid md={4} xs={12}></Grid>
              <CheckBoxGroup
                {...defaultFormProps}
                {...formConfig.typicalshiftdays}
                options={weekdaysOptions}
                permission={projectInformationPermission.fieldPerObj[formConfig.typicalshiftdays.perKey]}
              />
              <TimeField
                {...defaultFormProps}
                {...formConfig.shiftStartTime}
                permission={projectInformationPermission.fieldPerObj[formConfig.shiftStartTime.perKey]}
              />
              <TimeField
                {...defaultFormProps}
                {...formConfig.shiftEndTime}
                permission={projectInformationPermission.fieldPerObj[formConfig.shiftEndTime.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.employeeCount}
                permission={projectInformationPermission.fieldPerObj[formConfig.employeeCount.perKey]}
              />
              <ProRadioGroup
                {...defaultFormProps}
                {...formConfig.daysofWeekBegin}
                options={weekdaysOptions}
                permission={projectInformationPermission.fieldPerObj[formConfig.daysofWeekBegin.perKey]}
              />
              <ProRadioGroup
                {...defaultFormProps}
                {...formConfig.daysofWeekEnd}
                options={weekdaysOptions}
                permission={projectInformationPermission.fieldPerObj[formConfig.daysofWeekEnd.perKey]}
              />
              <DateField
                {...defaultFormProps}
                {...formConfig.workforceStartDate}
                permission={projectInformationPermission.fieldPerObj[formConfig.workforceStartDate.perKey]}
              />
              <DateField
                {...defaultFormProps}
                {...formConfig.workForceEndDate}
                permission={projectInformationPermission.fieldPerObj[formConfig.workForceEndDate.perKey]}
                shouldDisableDate={handleDateValidation}
              />
              {/* <ProSelectInput
                {...defaultFormProps}
                {...formConfig.provideTimeKeepingOn}
                options={timekeepingOptions}
                permission={projectInformationPermission.fieldPerObj[formConfig.provideTimeKeepingOn.perKey]}
              /> */}
              {/* <ProSelectInput
                {...defaultFormProps}
                {...formConfig.payrollOn}
                options={payrollTypeOptions}
                permission={projectInformationPermission.fieldPerObj[formConfig.payrollOn.perKey]}
              /> */}
              {/* <ToggleField {...defaultFormProps} {...formConfig.isCertifiedPayrollAutoGenarate} permission={fieldTruePermission} /> */}
              {/* <ProSelectInput
                {...defaultFormProps}
                {...formConfig.uploadDailyTimeSheet}
                options={uploadTimesheetData}
                permission={fieldTruePermission}
              /> */}
              <ToggleField {...defaultFormProps} {...formConfig.adminAssignDailyApprenticeMentor} permission={fieldTruePermission} />
              <ToggleField {...defaultFormProps} {...formConfig.adminAssignDailyWPS} permission={fieldTruePermission} />
              <ProRadioGroup {...defaultFormProps} {...formConfig.fringePayMode} options={fringePayModeOptions} permission={fieldTruePermission} />
            </FormSectionWrapper>
          ) : null}

          <FormSectionWrapper title={"Project Contacts"}>
            <ProjectContactInfo globalEditMode={globalEditMode} />
          </FormSectionWrapper>

          {apprenticeMgmtPermission.canView ? (
            <FormSectionWrapper title={"Apprentice Management"}>
              <ToggleField
                {...defaultFormProps}
                {...formConfig.adaptiveElearning}
                permission={apprenticeMgmtPermission.fieldPerObj[formConfig.adaptiveElearning.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.courseContentDelivery}
                options={CourseContentDeliveryOptions}
                permission={apprenticeMgmtPermission.fieldPerObj[formConfig.courseContentDelivery.perKey]}
              />
              {/* <ProSelectInput
                {...defaultFormProps}
                {...formConfig.apprenticeHoursTracking}
                options={TrackingMethod}
                permission={apprenticeMgmtPermission.fieldPerObj[formConfig.apprenticeHoursTracking.perKey]}
              /> */}
              {String(courseContentDelivery) === "1" && (
                <>
                  <ProSelectInput
                    {...defaultFormProps}
                    {...formConfig.learningHoursPaid}
                    options={ElearningHoursPaidOptions}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.learningHoursPaid.perKey]}
                  />
                  <ProSelectInput
                    {...defaultFormProps}
                    {...formConfig.relatedInstructionProvidedHours}
                    options={RelatedInstructionIsProvidedOptions}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.relatedInstructionProvidedHours.perKey]}
                  />
                  <CheckBoxGroup
                    {...defaultFormProps}
                    {...formConfig.preferredRTIDays}
                    options={weekdaysOptions}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.preferredRTIDays.perKey]}
                  />
                  <TimeField
                    {...defaultFormProps}
                    {...formConfig.preferredDayStartTime}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.preferredDayStartTime.perKey]}
                  />
                  <TimeField
                    {...defaultFormProps}
                    {...formConfig.preferredDaysEndTime}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.preferredDaysEndTime.perKey]}
                  />
                </>
              )}

              {String(courseContentDelivery) === "2" && (
                <>
                  <ProTextInput
                    {...defaultFormProps}
                    {...formConfig.maxPaidHoursPerDay}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.maxPaidHoursPerDay.perKey]}
                  />
                  <ProTextInput
                    {...defaultFormProps}
                    {...formConfig.rtiWeeklyExpectationHours}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.rtiWeeklyExpectationHours.perKey]}
                  />
                  <ProTextInput
                    {...defaultFormProps}
                    {...formConfig.rtiWeeklyExpectationMins}
                    permission={apprenticeMgmtPermission.fieldPerObj[formConfig.rtiWeeklyExpectationMins.perKey]}
                  />
                </>
              )}
            </FormSectionWrapper>
          ) : null}
        </div>
      </ProFormsLayout>
      <DialogModal
        show={confirmation}
        title="Confirm"
        message={`Please acknowledge that by uploading a Certified Payroll report, you are acknowledging that ProScore did not generate the Certified Payroll Report for this pay period and that ProScore is not responsible for the accuracy and completeness of the contents of the uploaded Certified Payroll Report. As such, any audits, inquiries, or exception filing for this Certified Payroll Report will be directed back to ${tokenData?.GlobalClientName}`}
        onConfirm={handleConfirmModal}
        onCancel={handleCancelModal}
      />
    </div>
  );
}
