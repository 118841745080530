import React, { useMemo } from "react";
import TableComponent from "../Components/ProGridTable/TableComponent";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import ReportingNameCell from "../Components/ProGridTable/ReportingNameCell";

const renderNamePosition = params => {
  return (
    <ReportingNameCell employeeGuid={params.row.employeeId} value={params.value}>
      <span style={{ color: "#999" }}>{params.row.position}</span>
    </ReportingNameCell>
  );
};

const CustomHeader = ({ columnName, date }) => {
  const day = date?.split?.("T")[0] || "";
  const formattedDate = day ? dayjs(day, "YYYY-MM-DD").format("MMM DD YYYY") : "";
  return (
    <div style={{ textAlign: "left", lineHeight: "20px" }}>
      <div style={{ fontWeight: "bold" }}>{columnName}</div>
      <div style={{ color: "#999" }}>{formattedDate}</div>
    </div>
  );
};

CustomHeader.propTypes = {
  columnName: PropTypes.string.isRequired,
  date: PropTypes.string
};

const headerRenderer = date => params => {
  return <CustomHeader columnName={params.colDef.headerName} date={date} />;
};

const columns = [
  {
    field: "employeeName",
    headerName: "Name & Position",
    renderHeader: () => <div style={{ textAlign: "left", fontWeight: "bold" }}>Name & Position</div>,
    renderCell: renderNamePosition
  }, 
  {
    field: "status",
    headerName: "Status",
    renderHeader: () => <div style={{ textAlign: "left", fontWeight: "bold" }}>Status</div>,
    renderCell: () => (
      <div>
        <p style={{ border: "1px solid #00935E", borderRadius: "20px", padding: "1px 16px", color: "#00935E" }}>Active</p>
      </div>
    )
  },
  { field: "week13", isWeekField: true, headerName: "Week 13" },
  { field: "week12", isWeekField: true, headerName: "Week 12" },
  { field: "week11", isWeekField: true, headerName: "Week 11" },
  { field: "week10", isWeekField: true, headerName: "Week 10" },
  { field: "week9", isWeekField: true, headerName: "Week 9" },
  { field: "week8", isWeekField: true, headerName: "Week 8" },
  { field: "week7", isWeekField: true, headerName: "Week 7" },
  { field: "week6", isWeekField: true, headerName: "Week 6" },
  { field: "week5", isWeekField: true, headerName: "Week 5" },
  { field: "week4", isWeekField: true, headerName: "Week 4" },
  { field: "week3", isWeekField: true, headerName: "Week 3" },
  { field: "week2", isWeekField: true, headerName: "Week 2" },
  { field: "week1", isWeekField: true, headerName: "Week 1" }
];

export default function BottomTable({ apprenticeHoursReportList, onPageChange, pageState }) {
  const tableColumns = useMemo(() => {
    if (!apprenticeHoursReportList.length) return columns;

    const apprenticeInfo = apprenticeHoursReportList[0];
    return columns.map(({ isWeekField, ...column }) => {
      if (!isWeekField) return column;
      return {
        ...column,
        renderHeader: headerRenderer(apprenticeInfo[`${column.field}Date`])
      };
    });
  }, [apprenticeHoursReportList]);

  if (!apprenticeHoursReportList.length) {
    return null;
  }

  return (
    <div style={{ margin: "20px 0" }}>
      <TableComponent
        columns={tableColumns}
        rows={apprenticeHoursReportList}
        loading={pageState.loading}
        onPageChange={pageState => onPageChange(pageState)}
        paginationProps={{
          rowCount: pageState.totalRecord,
          paginationMode: "server",
          paginationModel: { pageSize: pageState.pageSize, page: pageState.pageIndex },
          onPaginationModelChange: onPageChange,
          getRowHeight: () => "auto",
          getEstimatedRowHeight: () => 180
        }}
      />
    </div>
  );
}

BottomTable.propTypes = {
  apprenticeHoursReportList: PropTypes.arrayOf(
    PropTypes.shape({
      employeeName: PropTypes.string.isRequired,
      position: PropTypes.string,
      status: PropTypes.string.isRequired,
      week13: PropTypes.number,
      week12: PropTypes.number,
      week11: PropTypes.number,
      week10: PropTypes.number,
      week9: PropTypes.number,
      week8: PropTypes.number,
      week7: PropTypes.number,
      week6: PropTypes.number,
      week5: PropTypes.number,
      week4: PropTypes.number,
      week3: PropTypes.number,
      week2: PropTypes.number,
      week1: PropTypes.number
    })
  ).isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageState: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    totalRecord: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired
  }).isRequired
};