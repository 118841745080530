import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material";

const WeeklyComplianceWorkforceHours = ({ data }) => {
  const theme = useTheme();
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      backgroundColor: theme.palette.demo.weeklyreportbackgroundcolor
    },
    title: {
      text: ""
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>"
    },
    accessibility: {
      point: {
        valueSuffix: "%"
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        size: "80%",
        cursor: "pointer",
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4
          }
        }
      }
    },
    series: [
      {
        name: "Hours",
        data: [
          { name: "Journeyman", y: Number(data?.journeyMansHours.toFixed(2)), color: "#1F88E6" },
          { name: "Mentor", y: Number(data?.mentorHours.toFixed(2)), color: "#3D7101" },
          { name: "Apprentice", y: Number(data?.apprenticeHours.toFixed(2)), color: "#0507B9" }
        ],
        borderWidth: 0
      }
    ],
    legend: {
      layout: "horizontal",
      align: "right",
      verticalAlign: "top",
      floating: true,
      y: -10,
      itemStyle: {
        color: theme.palette.demo.weeklyreportcolor,
        fontWeight: "normal"
      },
      itemHoverStyle: {
        color: theme.palette.demo.weeklyreportcolor
      }
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

WeeklyComplianceWorkforceHours.propTypes = {
  data: PropTypes.shape({
    journeyMansHours: PropTypes.number.isRequired,
    mentorHours: PropTypes.number.isRequired,
    apprenticeHours: PropTypes.number.isRequired
  }).isRequired
};

export default WeeklyComplianceWorkforceHours;
