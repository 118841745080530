import { Box, Button, Grid, IconButton, Modal, Typography, useTheme } from "@mui/material";
import { Close, FileUpload } from "@mui/icons-material";
import React, { useState } from "react";
import fileUpload from "../../Assets/Icons/FileUpload.png";
import PropTypes from "prop-types";

const useBaseModalToggleHook = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return { open, handleOpen, handleClose };
};


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};

export default function ComplianceNavigatorBaseUploadModal({ onSave, title, children,onClose }) {
  const { open, handleOpen, handleClose } = useBaseModalToggleHook();
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const handleDragOver = event => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleModalClose = () => {
    setFile(null);
    onSave(null);
    handleClose(); 
  };
  

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    setIsDragging(false);
    const uploadedFile = event.dataTransfer.files[0];
    setFile(uploadedFile);
    console.log(uploadedFile);
  };

  const handleFileChange = event => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    console.log(uploadedFile);
  };

  const handleSave = () => {
    onSave(file, handleModalClose);
  };

  return (
    <>
      <Button id="upload-files" variant="outlined" size="small" sx={{ width: "150px" }} onClick={handleOpen}>
        Upload
        <FileUpload />
      </Button>
      <Modal open={open} onClose={()=>{
        handleModalClose();
        onClose()
      }}>
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                {title}
              </Typography>
              <IconButton aria-label="close" onClick={()=>{
                handleModalClose();
                onClose()
              }} sx={{ position: "absolute", right: 16, top: 16 }}>
                <Close />
              </IconButton>
            </Grid>
            {children}
            <Grid item xs={12}>
              <Box
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                sx={{
                  border: `1px dashed ${theme.palette.compliancenavigator.baseuploadmodal.boxBorder}`,
                  padding: "40px",
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: theme.palette.compliancenavigator.baseuploadmodal.boxBackground
                }}
              >
                <input type="file" onChange={handleFileChange} style={{ display: "none" }} id="fileInput" />
                <img src={fileUpload} alt="file" />
                <label htmlFor="fileInput">
                  <Typography variant="body1" fontWeight={"bold"} sx={{ color: theme.palette.compliancenavigator.baseuploadmodal.typographyColor }}>
                    {file ? `File Uploaded: ${file.name}` : "Drag & Drop File Here"}
                  </Typography>
                </label>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={()=>{
                  handleModalClose();
                  onClose()
                }}
                sx={{
                  marginRight: "15px",
                  border: theme.palette.compliancenavigator.baseuploadmodal.buttonBorder,
                  borderRadius: "6px",
                  color: theme.palette.compliancenavigator.baseuploadmodal.buttonColor
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

ComplianceNavigatorBaseUploadModal.propTypes = {
  onSave: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node
};
