import React from "react";
import PropTypes from "prop-types";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "./TableComponent.scss";
import ToolSet from "../../../Assets/IconComponents/ToolSet";
import { useTheme } from "@mui/material";

function CustomToolbars({ title = "", hideExport = false, additionalButtons, toolSetButton, hasBorder = true }) {
  const theme = useTheme();
  return (
    title && (
      <div className={`proGrid-title-wrapper ${hasBorder ? "proGrid-title-wrapper-with-border" : "proGrid-title-wrapper-no-border"}`}>
        <div className="proGrid-title">{title}</div>
        <div className="proGrid-filter">
          {additionalButtons}
          {toolSetButton && <ToolSet fill={theme.palette.demo.progridtablefill} />}
          {!hideExport && <GridToolbarExport className="export-icon" />}
        </div>
      </div>
    )
  );
}

CustomToolbars.propTypes = {
  title: PropTypes.string,
  hideExport: PropTypes.bool,
  hasBorder: PropTypes.bool,
  additionalButtons: PropTypes.node,
  toolSetButton: PropTypes.node
};

export default function TableComponent({
  columns,
  rows,
  title,
  hideExport = false,
  additionalButtons = null,
  toolSetButton = null,
  hasBorder = true,
  checkboxSelection = false,
  paginationModel = { page: 0, pageSize: 5 },
  loading = false,
  paginationProps = {}
}) {
  return (
    <div className="report-grid">
      <DataGrid
        rows={rows}
        loading={loading}
        columns={columns.map(column => ({
          ...column,
          flex: 1,
          minWidth: 180
        }))}
        slots={{
          Toolbar: CustomToolbars
        }}
        slotProps={{
          toolbar: {
            title,
            hideExport,
            toolSetButton,
            additionalButtons,
            hasBorder
          }
        }}
        initialState={{
          pagination: {
            paginationModel
          }
        }}
        checkboxSelection={checkboxSelection}
        {...(paginationProps || {})}
      />
    </div>
  );
}

TableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  title: PropTypes.string,
  hideExport: PropTypes.bool,
  hasBorder: PropTypes.bool,
  additionalButtons: PropTypes.node,
  toolSetButton: PropTypes.node,
  checkboxSelection: PropTypes.bool,
  paginationModel: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number
  }),
  loading: PropTypes.bool,
  paginationProps: PropTypes.object
};
