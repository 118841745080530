import { Card, CardContent, Checkbox } from "@mui/material";

const ClickableCard = ({ checkbox_id, children, style, onChangeAction, isSelected }) => {
  return (
    <Card
      variant="outlined"
      style={style}
      sx={{
        padding: 2,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: isSelected ? "#D2FFD4" : "#fff", 
        transition: "background-color 0.3s ease", 
      }}
    >
      <CardContent sx={{ padding: "0" }}>
        {children}
      </CardContent>
    </Card>
  );
};

export default ClickableCard;
