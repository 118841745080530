import React from "react";
import TableComponent from "../Components/ProGridTable/TableComponent";
import { Card, Grid } from "@mui/material";
import PropTypes from "prop-types";
import LostOTJTable from "./LostOTJTable";
import WageChangingTable from "./WageChangingTable";
import ReportingNameCell from "../Components/ProGridTable/ReportingNameCell";

const CustomHeader = ({ columnName, date }) => {
  return (
    <div style={{ textAlign: "center", lineHeight: "20px" }}>
      <div style={{ fontWeight: "bold" }}>{columnName}</div>
      <div style={{ color: "#999" }}>{date}</div>
    </div>
  );
};

CustomHeader.propTypes = {
  columnName: PropTypes.string.isRequired,
  date: PropTypes.string
};

CustomHeader.defaultProps = {
  date: "N/A"
};

const columns = [
  { field: "firstName", headerName: "First Name", renderCell: params => <ReportingNameCell employeeGuid={params.row.employeeGuid} value={params.value} /> },
  { field: "lastName", headerName: "Last Name" },
  { field: "rapName", headerName: "RAP Name" },
  { field: "otjPayPeriod", headerName: "OTJ This Pay Period" },
  { field: "rtiPayPeriod", headerName: "RTI This Pay Period" },
  { field: "apprenticeYear", headerName: "Apprentice Year" },
  { field: "totalOTJ", headerName: "Total OTJ/OTJ Required" },
  { field: "totalRTI", headerName: "RTI/RTI Required" }
];

export default function BottomTable({ wageChangeDetails, lostOTJHoursDetails, onPageChange, pageState, payPeriodSummaryData }) {
  return (
    <>
      <div>
        <Grid container mt={3} spacing={2} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item sm={12} md={6} lg={6} xs={12}>
            <Card
              className="cardHeader"
              sx={{
                minHeight: "100%",
                border: `1px solid transparent`,
                padding: "10px 20px"
              }}
            >
              <LostOTJTable
                lostOTJHoursDetails={lostOTJHoursDetails}
                pageState={pageState.lostOTJHours}
                onPageChange={pageState => onPageChange("lostOTJHours", pageState)}
              />
            </Card>
          </Grid>
          <Grid item sm={12} md={6} lg={6} xs={12}>
            <Card
              className="cardHeader"
              sx={{
                minHeight: "100%",
                border: `1px solid transparent`,
                padding: "10px 20px"
              }}
            >
              <WageChangingTable
                wageChangeDetails={wageChangeDetails}
                pageState={pageState.wageChange}
                onPageChange={pageState => onPageChange("wageChange", pageState)}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
      <div style={{ margin: "20px 0" }}>
        <TableComponent
          columns={columns}
          rows={payPeriodSummaryData}
          title="Pay Period Summary OTJ and RTI"
          loading={pageState.payPeriodSummary.loading}
          toolSetButton={true}
          pageState={pageState.payPeriodSummary}
          onPageChange={pageState => onPageChange("payPeriodSummary", pageState)}
        />
      </div>
    </>
  );
}

BottomTable.propTypes = {
  wageChangeDetails: PropTypes.array.isRequired,
  lostOTJHoursDetails: PropTypes.array.isRequired,
  payPeriodSummaryData: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageState: PropTypes.shape({
    lostOTJHours: PropTypes.object.isRequired,
    wageChange: PropTypes.object.isRequired,
    payPeriodSummary: PropTypes.shape({
      loading: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired
};
