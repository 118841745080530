import React from "react";
import ReadOnlyInput from "./ReadOnlyInput";
import InputWrapper from "./InputWrapper";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import ReactInputMask from "react-input-mask";
import PropTypes from "prop-types";

export default function DunsField({
  name,
  mode,
  label,
  rules,
  control,
  isAddMode,
  fieldProps = {},
  size = DEFAULT_SIZE,
  startAdornment = null,
  ...rest
}) {
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed))
    return <ReadOnlyInput name={name} label={label} size={size} type="mobile" {...rest} />;
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <ReactInputMask mask="99-999-9999" maskChar="" value={value} onBlur={onBlur} onChange={onChange}>
            {() => (
              <TextField
                variant="outlined"
                fullWidth
                label={label}
                placeholder={label}
                InputLabelProps={{ shrink: true }}
                helperText={error?.message || null}
                error={!!error}
                {...fieldProps}
              />
            )}
          </ReactInputMask>
        )}
      />
    </InputWrapper>
  );
}

DunsField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  handleReadValueClick: PropTypes.func,
  size: PropTypes.string,
  fieldProps: PropTypes.object,
  startAdornment: PropTypes.node,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
