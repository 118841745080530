import React, { forwardRef, useEffect, useState } from "react";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Alert, Slide, Dialog, Collapse, Button, Avatar, FormHelperText, useTheme } from "@mui/material";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import { postApi } from "../../../utils/services";
import { AUTH_VALIDATE_OTP, AUTH_VERIFY_LINK } from "../../../utils/services/apiPath";
import { fieldTruePermission } from "../../../utils/formHelper";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const validateOTPConfig = {
  Otp: {
    name: "Otp",
    label: "OTP",
    rules: { required: "OTP is required!" }
  }
};

const ValidateOtp = () => {
  const url = new URL(window.location.href);
  const key = url.searchParams.get("key");
  const [openAlert] = useState(true);
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [clientGuid, setClientGuid] = useState("");
  const { state } = useLocation();
  const theme = useTheme();

  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/dashboard");
  };
  const { handleSubmit, control } = useForm({});

  const handleClick = async formData => {
    if (clientGuid) {
      const payload = {
        otp: formData?.Otp,
        userGuid: clientGuid
      };
      const { error, success, message } = await postApi(AUTH_VALIDATE_OTP, payload);
      if (success === true) navigate("/reset-password", { state: { clientGuid: clientGuid } });
      if (error) return setHelperText(error);
      setMessage(message);
      handleOpenDialog();
      setHelperText("");
    }
  };

  const handleVerifyLink = async key => {
    const { error, message, data } = await postApi(AUTH_VERIFY_LINK, {}, { params: { url: key } });
    if (error) return setHelperText(error);
    setMessage(message);
    setClientGuid(data);
    setHelperText("");
  };

  useEffect(() => {
    if (key) handleVerifyLink(key);
  }, [key]);

  useEffect(() => {
    if (state) setClientGuid(state?.clientGuid);
  }, [state]);

  return (
    <>
      <ProPreLoginLayout heading="OTP">
        <div className="proLogin">
          <p className="proLogin_form_info" style={{ color: theme.palette.pages.validateotp.prologincolor }}>
            Fill in the fields below to reset your account password.
          </p>
          <div className="proLogin_form">
            <div className="proLogin_form_input">
              <ProTextInput {...validateOTPConfig.Otp} permission={fieldTruePermission} control={control} />
            </div>
            {helperText && (
              <FormHelperText error className="proLogin_form_error needBottomSpace">
                {helperText}
              </FormHelperText>
            )}
            <div className="proLogin_form_button" style={{ marginBottom: "10px" }}>
              <ProButton style={{ width: "100%" }} onClick={handleSubmit(handleClick)}>
                Validate OTP
              </ProButton>
            </div>
          </div>
        </div>
      </ProPreLoginLayout>
      <Dialog
        className="proLogin_dialog"
        open={openDialog}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
      >
        <Box sx={{ background: theme.palette.pages.validateotp.prologinbgcolor, px: 4, pb: 4, pt: 10 }}>
          <Avatar className="proLogin_dialog_avatar">
            <CheckTwoToneIcon />
          </Avatar>

          {message && (
            <Collapse in={openAlert}>
              <Alert>{message}</Alert>
            </Collapse>
          )}

          <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleCloseDialog}>
            {"Continue to login"}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ValidateOtp;
