import React from "react";
import InputWrapper from "./InputWrapper";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";

export default function ProRadioGroup({ name, mode, rules, label, options, control, isAddMode, handleReadValueClick, size = DEFAULT_SIZE, ...rest }) {
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  const theme = useTheme();
  const cellStyles = { color: theme.palette.inputs.radioGroup.cellStyles };
  if (!canView || (isAddMode && !canAdd)) return null;
  const handleValueClick = () => {
    if (mode === "read" && canEdit) handleReadValueClick(name);
  };
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl className="proCheckbox" variant="standard">
            <FormLabel sx={cellStyles}>{label}</FormLabel>
            <RadioGroup row name="row-radio-buttons-group" value={value} onChange={onChange}>
              {options.map(({ id, label }) => (
                <FormControlLabel
                  value={id}
                  key={id}
                  label={label}
                  disabled={!editModeAllowed}
                  control={<Radio size="small" />}
                  onClick={handleValueClick}
                />
              ))}
            </RadioGroup>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </InputWrapper>
  );
}

ProRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  isAddMode: PropTypes.bool,
  handleReadValueClick: PropTypes.func,
  size: PropTypes.string,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};