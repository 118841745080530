import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePageState from "../../../../../../utils/customHooks/usePageState";
import dayjs from "dayjs";
import ProTextInput from "../../../../../Components/Inputs/TextField";
import useColumn from "../../../../../../utils/customHooks/useColumns";
import NameCell from "../../../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../../../Components/ProGrid/components/DeleteCell";
import DateField from "../../../../../Components/Inputs/DateField";
import {
  convertResponseDate,
  fieldTruePermission,
  formatPayloadDate,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import TabGridAndFormLayout from "../../../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../../../Components/ProGrid/v2";
import { STAUTES } from "../../../../../../utils/constant";
import { errorToast, successToast } from "../../../../../../utils/toastHelper";
import { dateColumnDef } from "../../../../../Components/ProGrid/components/FormatCell";
import { getApi, postApi, deleteApi, putApi } from "../../../../../../utils/services";
import { useParams } from "react-router-dom";
import { createODataQuery } from "../../../../../../utils/services/oData";
// import {
//   GET_ALL_RTI_EMPLOYEERAPASSIGNED,
//   GET_ALL_RTI_TRACKER,
//   DELETE_RTI_PROGRESS,
//   ADD_RTI_PROGRESS,
//   UPDATE_RTI_PROGRESS,
//   GET_EMPLOYEERAP_RTI_DROPDOWN,
//   GET_RTI_PROGRESS_BY_ID,
//   GET_CURRENT_COMPLETION
// } from "../../../../utils/services/apiPath";
import { RAP_RTI_DROPDOWN, APPRENTICE_RAP_ASSIGNMENT, APPRENTICE_RTI_PROGRESS_TRACKER } from "../../../../../../utils/services/apiPath";
import ProSelectField from "../../../../../Components/Inputs/SelectField";

const rapRtiFormConfing = {
  rapTitle: {
    label: "RAP Title"
  },
  currentProject: {
    label: "Current Project"
  },
  rtiDescription: {
    label: "RAP RTI",
    rules: { required: "RAP RTI is required!" }
  },
  currentCompletionRate: {
    label: "Current Completion Rate"
  },
  timeinCourse: {
    label: "Time In Course",
    type: "number",
    rules: { required: "Time In Course is required!" }
  },
  progressDate: {
    label: "Progress Date",
    rules: { required: "Progress Date is required!" },
    defaultValue: dayjs()
  }
};

export default function RtiTracker() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [rapRtiData, setRapRtiData] = useState({});
  const [actionData, setActionData] = useState({});
  const [isAddMode, setIsAddMode] = useState(false);
  const [rapRtiDropDownData, setRapRtiDropDownData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: rtisData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "rtiTitle",
        headerName: "RAP RTI ",
        renderCell: params => <NameCell params={params} onClick={onRtiClick} />
      },
      dateColumnDef({
        field: "progressDate",
        headerName: "Progress Date"
      }),
      {
        field: "progressPercentage",
        headerName: "Progress Percentage"
      },
      {
        field: "timeInCourse",
        headerName: "Time In Course"
      },
      {
        field: "rtiProgressTrackerId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const [formConfig, setFormConfig] = useState(prepareInitialConfig(rapRtiFormConfing));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(rapRtiFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "edit" } }), {}));
  };

  const handleReadValueclick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "read" } }), {}));
  };

  const onCancel = () => {
    resetForm();
  };

  const onRtiClick = useCallback(async params => {
    setActionData(params.row);
    const url = APPRENTICE_RTI_PROGRESS_TRACKER.replace(":employee_id", id)

    const data = await getApi(`${url}/${params.row.rtiProgressTrackerId}`);
    const rapAssignment = await getApi(APPRENTICE_RAP_ASSIGNMENT.replace(":employee_id", id));
    const formatted = {
      rtiDescription: data?.rapRtiId,
      currentCompletionRate: data?.progressPercentage,
      progressPercentage: data?.progressPercentage,
      timeinCourse: data?.timeInCourse,
      progressDate: convertResponseDate(data?.progressDate),
      rapTitle: rapAssignment?.approvedJobTitle,
      currentProject: rapAssignment?.projectName
    };
    setRapRtiData(formatted);
    Object.keys(formConfig).forEach(field => setValue(field, formatted[field]));
    setShowGrid(false);
  });

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
    getAllRapAssigned();
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = {
      ...data,
      rapRtiId: data?.rtiDescription,
      progressDate: formatPayloadDate(data?.progressDate),
      progressPercentage: "3.33",
      timeInCourse: data?.timeinCourse,
      rtiProgressTrackerId: actionData?.rtiProgressTrackerId ?? null
    };
    const baseUrl = APPRENTICE_RTI_PROGRESS_TRACKER.replace(":employee_id", id)
    const methodApi = !actionData?.rtiProgressTrackerId ? postApi : putApi;
    const { error } = await methodApi(!actionData?.rtiProgressTrackerId ? baseUrl : `${baseUrl}/${actionData.rtiProgressTrackerId}`, payload);
    setIsSaving(false);
    if (error) return errorToast(error);
    successToast(`Partners Documents successfully.`);
    setStatus(STAUTES.IDLE);
    getAllRapRti();
    setShowGrid(true);
  };

  const onDelete = async rtiProgressTrackerId => {
    const { error } = await deleteApi(`${APPRENTICE_RTI_PROGRESS_TRACKER.replace(":employee_id", id)}/${rtiProgressTrackerId}`);
    if (error) return errorToast(error);
    getAllRapRti();
    setShowGrid(true);
    successToast("RAP RTI deleted successfully.");
  };

  const getAllRapRti = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const searchableFields = [
      "rtiTitle",
      "cast(progressDate, 'Edm.String')",
      "cast(progressPercentage, 'Edm.String')",
      "cast(timeInCourse, 'Edm.String')",
    ];
    const url = APPRENTICE_RTI_PROGRESS_TRACKER.replace(":employee_id", id)

    const { data, totalRecords, error } = await getApi(url + createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage));
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllRapAssigned = async () => {
    setStatus(STAUTES.LOADING);
    const data = await getApi(APPRENTICE_RAP_ASSIGNMENT.replace(":employee_id", id));
    const formatted = {
      rapTitle: data?.approvedJobTitle,
      currentProject: data?.projectName,
      rtiDescription: data?.rapRtiId
    };
    setRapRtiData(prevData => {
      if (JSON.stringify(prevData) !== JSON.stringify(formatted)) {
        return formatted;
      }
      return prevData;
    });
    Object.keys(formConfig).forEach(field => setValue(field, formatted[field]));
    setStatus(STAUTES.IDLE);
  };

  const getAllRapRtiDropdown = async () => {
    setStatus(STAUTES.LOADING);

    const { data, error } = await getApi(RAP_RTI_DROPDOWN + createODataQuery([], [], "", searchString, sortModel, pageSize, currentPage) + `&employeeId=${id}`);
    if (error) return errorToast("Unable to fetch dropdown items for this employee.")
    setRapRtiDropDownData(data?.map(item => ({ label: item.label, value: item.value })));
    setStatus(STAUTES.IDLE);
  };

  useEffect(() => {
    getAllRapRti();
    if (isAddMode) onEdit();
  }, [getAllRapRti]);

  useEffect(() => {
    getAllRapRtiDropdown();
  }, [])

  const defaultFormProps = { control, formValues: rapRtiData, isAddMode, handleReadValueclick };

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.rtiProgressTrackerGuid ? "Edit" : "Add"} RTI Tracker`}
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      backLabel={"Go Back to All RTI Tracker"}
      canView={true}
      hideEdit={false}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="RTI Tracker"
          loading={status === STAUTES.LOADING}
          columns={columns}
          rows={rtisData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add RTI Progress"
          hideExport={false}
          hideAddButton={false}
          addButtonAction={addButtonAction}
          options={{
            getRowId: row => row.rtiProgressTrackerId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput {...defaultFormProps} {...formConfig.rapTitle} mode="read" permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.currentProject} mode="read" permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.currentCompletionRate} mode="read" permission={fieldTruePermission} />
          <ProSelectField {...defaultFormProps} {...formConfig.rtiDescription} options={rapRtiDropDownData} permission={fieldTruePermission} />
          {/* <PercentField {...defaultFormProps} {...formConfig.progressPercentage} permission={fieldTruePermission} /> */}
          <ProTextInput {...defaultFormProps} {...formConfig.timeinCourse} permission={fieldTruePermission} />
          <DateField {...defaultFormProps} {...formConfig.progressDate} permission={fieldTruePermission} />
        </>
      )}
    </TabGridAndFormLayout>
  );
}