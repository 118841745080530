import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailPageLayout from "../../../Components/Layout/DetailPageLayout";
import Layout from "../../../Components/Layout";
import ProfileIcon from "../../../Assets/IconComponents/ProfileIcon";
import DashboardIcon from "../../../Assets/IconComponents/Dashboard";
import RAPApprentice from "./RAPApprentice";
import RapStatus from "./RapStatus";
import RAPWageScale from "./RAPWageScale";
import MentorPool from "../../Apprentice180/RapManagement/MentorPool";
import { GET_ALL_RAPSTATUS_LIST_SECTION } from "../../../../utils/services/apiPath";
import { getApi } from "../../../../utils/services";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import { rapPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";

export default function RAPDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [rapStatusData, setRapStatusData] = useState([]);
  const { subModulePermissions } = useSelector(rapPermissionSelector);
  const wageScalePermission = subModulePermissions?.RAP_MGMT_WAGE_SCALE?.canView;
  const apprenticePermission = subModulePermissions?.RAP_MGMT_APPRENTICE?.canView;
  const rapStatusPermission = subModulePermissions?.RAP_MGMT_RAP_STATUS?.canView;
  const backAction = () => {
    navigate("/rap");
  };

  const tabs = [
    {
      to: "/RAPStatus",
      title: "RAP Status",
      key: "RAPStatus",
      hasArrow: false,
      icon: DashboardIcon,
      canView: rapStatusPermission
    },
    {
      to: "/apprentice",
      title: "Apprentice",
      key: "apprentice",
      hasArrow: false,
      icon: ProfileIcon,
      canView: apprenticePermission
    },
    {
      to: "/mentor-pool",
      title: "Mentor Pool",
      key: "mentor-pool",
      hasArrow: false,
      icon: ProfileIcon,
      canView: wageScalePermission
    },
    {
      to: "/wagescale",
      title: "Wage Scale",
      key: "wagescale",
      hasArrow: false,
      icon: ProfileIcon,
      canView: wageScalePermission
    }
  ];

  const handleTabClick = tab => {
    navigate(`/rap/${id}${tab.to}`);
  };
  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab)?.title;
    setActiveTab(selectedTab || "RAPStatus");
  }, [tab]);

  useEffect(() => {
    const getRapStatusList = async () => {
      const response = await getApi(`${GET_ALL_RAPSTATUS_LIST_SECTION}?assignRAPId=${id}`, defaultDropdownConfig);
      setRapStatusData(response.data || []);
    };
    getRapStatusList();
  }, []);

  return (
    <Layout>
      <DetailPageLayout
        backAction={backAction}
        backLabel={"Go back to RAP Management"}
        tabs={tabs.filter(tab => tab.canView)}
        title="ASSIGN RAP INFO"
        subTitle={rapStatusData[0]?.jobTitle}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      >
        {activeTab === "RAP Status" && rapStatusPermission && <RapStatus />}
        {activeTab === "Apprentice" && apprenticePermission && <RAPApprentice />}
        {activeTab === "Mentor Pool" && wageScalePermission && <MentorPool />}
        {activeTab === "Wage Scale" && wageScalePermission && <RAPWageScale />}
      </DetailPageLayout>
    </Layout>
  );
}
