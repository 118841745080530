import React, { useEffect, useMemo, useState, useCallback } from "react";
import { createODataQuery } from "../../../../utils/services/oData";
import { changeMode, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProTextInput from "../../../Components/Inputs/TextField";
import usePageState from "../../../../utils/customHooks/usePageState";
import { useNavigate, useParams } from "react-router-dom";
import useColumn from "../../../../utils/customHooks/useColumns";
import { postApi, deleteApi, putApi, getApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import { ADMIN_WAGE_RATES } from "../../../../utils/services/apiPath";
import ProGrid from "../../../Components/ProGrid/v2";
const wageRateFormConfing = {
  fromHours: {
    label: "From Hours",
    perKey: "FromHours",
    rules: {
      required: "From Hours is required!",
      pattern: {
        value: /^\d+$/,
        message: "From Hours must be a numbers only."
      }
    }
  },
  toHours: {
    label: "To Hours",
    perKey: "ToHours",
    rules: {
      required: "To Hours is required!",
      pattern: {
        value: /^\d+$/,
        message: "To Hours must be a numbers only."
      }
    }
  },
  wageRate: {
    label: "Wage Rate ",
    perKey: "WageRate",
    rules: {
      required: "Wage Rate is Required!",
      pattern: {
        value: /^[+-]?\d*(\.\d+)?$/,
        message: "Wage Rate must be a numbers only."
      }
    }
  }
};

export default function WageRate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(wageRateFormConfing));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(wageRateFormConfing) });
  const readOnly = localStorage.getItem("viewingReadOnlyRapInfo") == "true"
  const defaultFormProps = { control };
  const [isAddMode, setIsAddMode] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [actionData, setActionData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: rapWageListData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "startHours",
        headerName: "Start Hours",
        renderCell: params => <NameCell params={params} onClick={onDocumentClick} disabled={readOnly} />
      },
      {
        field: "endHours",
        headerName: "End Hours"
      },
      {
        field: "wageRate",
        headerName: "Wage Rate"
      },
      {
        field: "rapInfoId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} disabled={readOnly} />
      }
    ],
    true
  );

  const onDelete = useCallback(async params => {
    const { error } = await deleteApi(`${ADMIN_WAGE_RATES}/${params}`);
    if (error) return errorToast(error);
    successToast("Successfully deleted selected RAP WAGE Rate");
    getRapWagesList();
  });

  const onDocumentClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    setIsAddMode(false);
    const data = {
      fromHours: params.row.startHours,
      toHours: params.row.endHours,
      wageRate: params.row.wageRate,
      rapWageRateId: params.row.rapWageRateGuid
    };
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };
  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
  };
  const resetForm = () => { };
  const onCancel = () => {
    setShowGrid(true);
    resetForm();
  };

  const getRapWagesList = useCallback(async () => {
    const searchableFields = [
      "cast(startHours, 'Edm.String')",
      "cast(endHours, 'Edm.String')",
      "cast(wageRate, 'Edm.String')",
    ];
    const { data, totalRecords, error } = await getApi(ADMIN_WAGE_RATES + createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage));
    if (error) return errorToast(error);

    setPageState(prevPageInfo => ({
      ...prevPageInfo,
      data: data || [],
      rowCount: totalRecords
    }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      startHours: data?.fromHours,
      endHours: data?.toHours,
      wageRate: data?.wageRate,
      rapInfoId: id,
    };
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADMIN_WAGE_RATES : `${ADMIN_WAGE_RATES}/${actionData?.rapWageRateId}`, payload);
    if (error) return errorToast(error);
    successToast(`Wage Rates Uploaded successfully.`);
    setIsSaving(false);
    navigate(0)
  };

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const onEdit = () => setFormConfig(changeMode("edit"));

  useEffect(() => {
    getRapWagesList()
  }, [getRapWagesList])

  useEffect(() => {
    if (!isAddMode) onEdit();
  }, [id]);

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.rapWageRateGuid ? "Edit" : "Add"} Wage Rate`}
      backAction={() => onCancel()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={true}
      hideEdit={readOnly}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Wage Rates"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.rapWageRateId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          rows={rapWageListData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Wage Rates"
          hideExport={false}
          hideAddButton={false}
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput {...defaultFormProps} {...formConfig.fromHours} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.toHours} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.wageRate} permission={fieldTruePermission} />
        </>
      )}
    </TabGridAndFormLayout>
  );
}