import React, { useEffect, useState } from "react";
import { HTTPReportS3 } from "./StimulsoftReports";
import { S3_REPORTS_URL } from "../../../utils/constant";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const HTTPReportDesigner = ({ fileName }) => {
  const accessToken = localStorage.getItem("accessToken")
  const [searchParams, setSearchParams] = useSearchParams()
  const reportId = searchParams.get("reportId")
  const reportCategoryId = searchParams.get("reportCategoryId")
  const fromTemplate = searchParams.get("fromTemplate")
  const reportName = searchParams.get("reportName")
  const filePath = searchParams.get("filePath")
  const isDefault = searchParams.get("isDefault")
  const [reportObject, setReportObject] = useState(null)
  let instance = axios.create({
    headers: {
    },
  });

  const getReport = async () => {
    try {
      const { data } = await instance.get(`${S3_REPORTS_URL}${fileName}`)
      setReportObject(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getReport()
  }, [])

  useEffect(() => {
    if (reportObject) {
      let filters = ''
      if (searchParams.has("$filter")) {
        filters = searchParams.get("$filter")
      }

      let report = new HTTPReportS3({
        reportFile: reportObject,
        accessToken: accessToken,
        filters: filters,
        reportId: reportId,
        reportCategoryId: reportCategoryId,
        fromTemplate: fromTemplate,
        reportName: reportName,
        filePath: filePath,
        isDefault: isDefault == "true"
      });
      report.designer.renderHtml("designer");
    }
  }, [reportObject])

  return <div id="designer"></div>;
};

export default HTTPReportDesigner;