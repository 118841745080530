export const mapFrequencyToRunFields = (frequency) => {
  const frequencyMapping = {
    monday: { runMon: true },
    tuesday: { runTue: true },
    wednesday: { runWed: true },
    thursday: { runThu: true },
    friday: { runFri: true },
    saturday: { runSat: true },
    sunday: { runSun: true },

    firstMondayOfMonth: { runMon: true },
    firstTuesdayOfMonth: { runTue: true },
    firstWednesdayOfMonth: { runWed: true },
    firstThursdayOfMonth: { runThu: true },
    firstFridayOfMonth: { runFri: true },
    firstSaturdayOfMonth: { runSat: true },
    firstSundayOfMonth: { runSun: true },
    firstDayOfMonth: { runFirstOfMonth: true},
    lastDayOfMonth: { runLastOfMonth: true },

    firstDayOfQuarter: { runFirstOfQuarter: true },
    lastDayOfQuarter: { runLastOfQuarter: true },

    firstMondayOfYear: { runMon: true },
    firstTuesdayOfYear: { runTue: true },
    firstWednesdayOfYear: { runWed: true },
    firstThursdayOfYear: { runThu: true },
    firstFridayOfYear: { runFri: true },
    firstSaturdayOfYear: { runSat: true },
    firstSundayOfYear: { runSun: true },
    firstDayOfYear: { runFirstOfYear: true },
    lastDayOfYear: { runLastOfYear: true },
  };

  const runFields = initializeRunFields();

  if (frequency && frequencyMapping[frequency]) {
    Object.assign(runFields, frequencyMapping[frequency]);
  }
  return runFields;
};
export const initializeRunFields = () => ({
  runMon: false,
  runTue: false,
  runWed: false,
  runThu: false,
  runFri: false,
  runSat: false,
  runSun: false,
  runFirstOfMonth: false,
  runLastOfMonth: false,
  runFirstOfQuarter: false,
  runLastOfQuarter: false,
  runFirstOfYear: false,
  runLastOfYear: false,
})