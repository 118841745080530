import { Grid, Box } from "@mui/material";
import ComplianceNavigatorCards from "./ComplianceNavigatorCards";
import { useEffect, useState } from "react";
import ComplianceSummary from "../../../Components/ComplianceNavigator/ComplianceSummary";
import ComplianceNavigatorHeader from "../../../Components/ComplianceNavigator/ComplianceNavigatorHeader";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { getApi } from "../../../../utils/services";
import { COMPLIANCE_DOCUMENTS, GET_ALL_PARTNERS_DROPDOWN, GET_ALL_PROJECT } from "../../../../utils/services/apiPath";
import Loading from "../../../Components/Loading";
import AppendixATable from "../../../Components/ComplianceNavigator/AppendixATable";
import AppendixDTable from "../../../Components/ComplianceNavigator/AppendixDTable";
import SixSevenOneTable from "../../../Components/ComplianceNavigator/SixSevenOneTable";
import PrevailingWageSummaryTable from "../../../Components/ComplianceNavigator/PrevailingWageSummaryTable";
import CPRTable from "../../../Components/ComplianceNavigator/CPRTable";
import CLRFiledTable from "../../../Components/ComplianceNavigator/CLRFiledTable";
import HistoryOfUnderpaymentsAndProofOfCorrectionsAndFinesTable from "../../../Components/ComplianceNavigator/HistoryOfUnderpaymentsAndProofOfCorrectionsAndFinesTable";
import NintyDayCertificates from "../../../Components/ComplianceNavigator/NintyDayCertificates";
import RatioManagement from "../../../Components/ComplianceNavigator/RatioManagement";

const complianceConfig = {
  projectId: {
    label: "Project",
    perKey: "Project",
    placeholder: "All Project"
  },
  partnerId: {
    label: "Partner",
    perKey: "Partner",
    placeholder: "All Partners"
  }
};

const ComplianceNavigator = () => {
  const [cards, setCards] = useState([]);
  const [summary, setSummary] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const isAddMode = true;
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(complianceConfig));
  const { control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(complianceConfig) });
  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));
  const onEdit = () => setFormConfig(changeMode("edit"));
  const [projectsList, setProjectsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const project = watch("projectId");
  const partner = watch("partnerId");

  const typeOptions = {
    projectId: projectsList,
    partnerId: partnersList
  };

  const fetchProjects = async () => {
    const { data } = await getApi(GET_ALL_PROJECT);
    setValue("projectId", data[0]?.projectId);
    setProjectsList(data?.map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const fetchPartners = async () => {
    const res = await getApi(`${GET_ALL_PARTNERS_DROPDOWN}/${project}`);
    setValue("partnerId", res[0]?.clientGuid);
    setPartnersList(res?.map(item => ({ label: item.companyName, value: item.clientGuid })));
  };

  const fetchAllComplianceDocument = async () => {
    setLoading(true);
    const { complianceDocument, summary } = await getApi(`${COMPLIANCE_DOCUMENTS}?projectId=${project}&partnerId=${partner}`);
    setLoading(false);
    const formatted = getTableComponent(complianceDocument);
    setCards(formatted);
    setSummary(summary);
  };

  const getTableComponent = complianceDocument => {
    const tableComponents = {
      AppendixA: <AppendixATable tableKey="AppendixA" projectId={project} partnerId={partner} />,
      AppendixD: <AppendixDTable tableKey="AppendixD" projectId={project} partnerId={partner} />,
      671: <SixSevenOneTable tableKey="671" projectId={project} partnerId={partner} />,
      PrevailingWageSummary: <PrevailingWageSummaryTable tableKey="PrevailingWageSummary" projectId={project} partnerId={partner} />,
      CLRFiled: <CLRFiledTable tableKey="CLRFiled" projectId={project} partnerId={partner} />,
      CPR: <CPRTable tableKey="CPR" projectId={project} partnerId={partner} />,
      HistoryOfUnderpayment: (
        <HistoryOfUnderpaymentsAndProofOfCorrectionsAndFinesTable tableKey="HistoryOfUnderpayment" projectId={project} partnerId={partner} />
      ),
      RatioManagement: <RatioManagement tableKey="RatioManagement" projectId={project} partnerId={partner} />,
      DavisBaconForm: <NintyDayCertificates tableKey="DavisBaconForm" projectId={project} partnerId={partner} />
    };
    let tables = [];
    complianceDocument?.map(table => {
      let tempTable = {
        ...table,
        tableComponent: () => tableComponents[table?.key],
        chipText: `Available: ${table.available}/${table.total}`
      };
      tables?.push(tempTable);
    });
    return tables;
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (project) {
      fetchPartners();
    }
  }, [project]);

  useEffect(() => {
    if (project && partner) {
      fetchAllComplianceDocument();
    }
  }, [project, partner]);

  useEffect(() => {
    onEdit();
  }, []);

  const toggleSelectedCards = cardTitle => {
    setSelectedCards(prevSelectedCards => {
      if (prevSelectedCards.includes(cardTitle)) {
        return prevSelectedCards.filter(title => title !== cardTitle);
      } else {
        return [...prevSelectedCards, cardTitle];
      }
    });
  };

  const defaultFormProps = { control, formValues: {}, isAddMode, handleReadValueClick };

  return (
    <div style={{ margin: "25px auto", padding: "0 1rem", maxWidth: 1440 }}>
      <ComplianceNavigatorHeader formProps={defaultFormProps} formConfig={formConfig} typeOptions={typeOptions} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} direction={"row"}>
          <Grid item xs={9}>
            {loading ? (
              <Loading />
            ) : (
              <ComplianceNavigatorCards cards={cards} toggleSelectedCards={toggleSelectedCards} selectedCards={selectedCards} fetchAllComplianceDocument={fetchAllComplianceDocument}/>
            )}
          </Grid>
          <Grid item xs={3}>
            <ComplianceSummary summaryJson={summary} cardCount={cards.length} projectCount={projectsList.length}/>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ComplianceNavigator;
