import ComplianceNavigatorBaseTable from "./ComplianceNavigatorBaseTable";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";
import {
  COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES,
  COMPLIANCE_NAVIGATOR_APPENDIXA,
  COMPLIANCE_NAVIGATOR_MISSING_APPENDIXA,
  GET_ALL_COMPLIANCE_INSIGHT_DATA,
  GET_ALL_COMPLIANCE_TABLE,
  GET_ALL_COMPLIANCE_TABLE_DATA
} from "../../../utils/services/apiPath";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { getApi, postApi } from "../../../utils/services";
import ComplianceNavigatorActionButtons from "./ComplianceNavigatorActionButtons";
import PropTypes from "prop-types";
import UploadFieldsData from "./UploadFieldsData";
import { errorToast } from "../../../utils/toastHelper";
import { v4 as uuidV4 } from "uuid";
import { createODataQuery } from "../../../utils/services/oData";

const AppendixATable = ({ tableKey, projectId, partnerId }) => {
  const {
    currentPage,
    pageSize,
    data: tableData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn([
    {
      field: "rapJobTitle",
      headerName: "Approved Job Title"
    },
    {
      field: "sponsorName",
      headerName: "Sponsors"
    },
    {
      field: "rapidCode",
      headerName: "RAPID Code"
    },
    {
      field: "programLength",
      headerName: "Program Length"
    }
    // {
    //   headerName: "Actions",
    //   field: "actions",
    //   renderCell: params => <ComplianceNavigatorActionButtons params={params} />
    // }
  ]);

  const [insightData, setInsightData] = useState();

  const fetchInsightData = async () => {
    const res = await getApi(`${COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES}?key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`);
    setInsightData(res);
  };

  useEffect(() => {
    if (tableKey && partnerId && projectId) {
      fetchInsightData();
    }
  }, [projectId, partnerId, tableKey]);

  const getTableData = useCallback(async () => {
    const payload = {
      pageIndex: currentPage + 1,
      pageSize: pageSize,
      orderByAscending: sortModel.orderByAscending || true,
      searchString: searchString || "",
      orderCol: sortModel.orderCol || ""
    };
    const searchableFields = ["sponsorName"];
    const { data, totalRecords, error } = await getApi(
      `${
        COMPLIANCE_NAVIGATOR_APPENDIXA + createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage)
      }&key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`,
      payload
    );

    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data?.map(row => ({ ...row, id: uuidV4() })) || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  const [approvedJobTitleOptions, setApprovedJobTitleOptions] = useState([]);

  useEffect(() => {
    const fetchApprovedJobTitles = async () => {
      try {
        const response = await getApi(`${COMPLIANCE_NAVIGATOR_MISSING_APPENDIXA}?projectId=${projectId}&partnerId=${partnerId}`);
        if (response) {
          setApprovedJobTitleOptions(
            response?.map(item => ({
              label: item.label,
              value: item.value.toString() 
            }))
          );          
        }
      } catch (error) {
      errorToast(error);
      }
    };

    fetchApprovedJobTitles();
  }, [projectId, partnerId]);

  return (
    <ComplianceNavigatorBaseTable
      columns={columns}
      title="List of Appendix A"
      rows={tableData}
      insightData={insightData}
      tableKey={tableKey}
      rowCount={rowCount}
      status={status}
      searchString={searchString}
      setPagination={setPagination}
      setSortModel={setSortModel}
      setSearchString={setSearchString}
      getRowId={row => row.id}
      pageSize={pageSize}
      currentPage={currentPage}
      uploadPopUp={<UploadFieldsData form="AppendixA" approvedJobTitleOptions={approvedJobTitleOptions} getTableData={getTableData}/>}
    />
  );
};

AppendixATable.propTypes = {
  tableKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired
};

export default AppendixATable;
