import React from "react";
import { Modal, Box, Typography, Divider } from "@mui/material";
import PropTypes from "prop-types";
import ProButton from "../../ProButton";

export const Email = ({ openEmail, handleCloseEmail, emailBody }) => {
  const container = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 1
  };
  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    fontSize: "14px",
    lineHeight: "1.5"
  };

  return (
    <Modal open={openEmail} onClose={handleCloseEmail}>
      <Box sx={container}>
        <Box mb={1}>
          <Typography variant="body1" sx={{ ...fontStyle, fontSize: 20 }}>
            Sent Email
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2, maxHeight: "400px", overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: emailBody }} />
        <Box sx={{ textAlign: "right", pt: 2 }}>
          <ProButton className="cancel-btn" variant="outlined" color="secondary" sx={{ marginRight: "10px" }} onClick={handleCloseEmail}>
            Close
          </ProButton>
        </Box>
      </Box>
    </Modal>
  );
};

Email.propTypes = {
  openEmail: PropTypes.bool,
  handleCloseEmail: PropTypes.func.isRequired,
  emailBody: PropTypes.string
};
