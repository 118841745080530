import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProMobileField from "../../../Components/Inputs/MobileField";
import ProEmailField from "../../../Components/Inputs/EmailField";
import ZipField from "../../../Components/Inputs/ZipField";
import Layout from "../../../Components/Layout";
import { ADMIN_SPONSOR_INFO } from "../../../../utils/services/apiPath";
import { changeMode, changeModeForField, fieldTruePermission, fieldDisabledPermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import UrlField from "../../../Components/Inputs/UrlField";
import { putApi, postApi, getApi } from "../../../../utils/services";

const SponsorInfoFormConfig = {
  firstName: {
    label: "Sponsor's First Name",
    perKey: "firstName",
    rules: { required: "Sponsor's First Name Required!" }
  },
  middleName: {
    label: "Middle Name",
    perKey: "middleName"
  },
  companyName: {
    label: "Company Name",
    perKey: "companyName",
    rules: { required: "Company Name Required!" }
  },
  lastName: {
    label: "Last Name",
    perKey: "lastName"
  },
  address1: {
    label: "Address 1",
    perKey: "address",
    rules: { required: "Address is Required!" }
  },
  address2: {
    label: "Address 2",
    perKey: "address2",
  },
  state: {
    label: "State",
    perKey: "state",
    rules: { required: "State is Required!" }
  },
  city: {
    label: "City",
    perKey: "city",
    rules: { required: "City is Required!" }
  },
  zipcode: {
    label: "Zip Code",
    perKey: "zipcode",
    rules: { required: "Zipcode is Required!" }
  },
  phoneNumber: {
    label: "Telephone Number",
    perKey: "phoneNumber",
    rules: { required: "Telephone Number Required!" }
  },
  cellPhoneNumber: {
    label: "Cellphone Number",
    perKey: "cellPhoneNumber",
    rules: { required: "Cellphone Number Required!" }
  },
  email: {
    label: "Email",
    perKey: "email",
    rules: { required: "Email ID Required!" }
  },
  businessAddress1: {
    label: "Address 1",
    perKey: "businessAddress1"
  },
  businessAddress2: {
    label: "Address 2",
    perKey: "businessAddress2"
  },
  businessState: {
    label: "State",
    perKey: "businessState"
  },
  businessCity: {
    label: "City",
    perKey: "businessCity"
  },
  businessZipcode: {
    label: "Zip Code",
    perKey: "businessZipcode"
  },
  contactFirstName: {
    label: "First Name",
    perKey: "contactFirstName",
    rules: { required: "First Name is Required!" }
  },
  contactMiddleName: {
    label: "Middle Name",
    perKey: "contactMiddleName"
  },
  contactLastName: {
    label: "Last Name",
    perKey: "contactLastName"
  },
  contactEmail: {
    label: "Email",
    perKey: "contactEmail",
    rules: { required: "Email is Required!" }
  },
  contactPhoneNumber: {
    label: "Phone Number",
    perKey: "contactPhoneNumber",
    rules: { required: "Phone Number is Required!" }
  },
  firmWebPage: {
    label: "Webpage",
    perKey: "firmWebPage"
  }
};

export default function SponsorDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [sponsorInfoData, setSponsorInfoData] = useState({});
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(SponsorInfoFormConfig));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(SponsorInfoFormConfig) });
  const [isSaving, setIsSaving] = useState(false);
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const backAction = () => navigate(-1);
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => (isAddMode ? backAction() : setFormConfig(changeMode()));

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async data => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = { ...data };
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADMIN_SPONSOR_INFO : `${ADMIN_SPONSOR_INFO}/${id}`, payload);
    setIsSaving(false);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`Sponsor Info ${isAddMode ? "Added" : "Updated"} Successfully`);
    backAction();
  };

  const getSponsorInfoData = async id => {
    try {
      const data = await getApi(`${ADMIN_SPONSOR_INFO}/${id}`);
      setSponsorInfoData(data);
      Object.keys(formConfig).forEach(field => setValue(field, data[field]));
    } catch (error) {
      return errorToast("Unable to retrieve Sponsor information");
    }
  };

  useEffect(() => {
    if (isAddMode) onEdit();
    if (!isAddMode) getSponsorInfoData(id);
  }, [id]);

  const defaultFormProps = { control, formValues: sponsorInfoData, isAddMode, handleReadValueClick };

  const appliedPermission = sponsorInfoData?.readOnly ? fieldDisabledPermission : fieldTruePermission;

  return (
    <Layout>
      <SettingFormsLayout
        status={status}
        backAction={backAction}
        title={isAddMode ? "Add Sponsor Info" : " Sponsor Information"}
        backLabel="Go Back Sponsor Info List"
        beingEdited={isEditModeOn}
        hideEdit={sponsorInfoData?.readOnly}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <FormSectionWrapper title={"Sponsor's Basic Information"}>
          <ProTextInput {...defaultFormProps} {...formConfig.firstName} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.middleName} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.lastName} permission={appliedPermission} />
          <ProMobileField {...defaultFormProps} {...formConfig.phoneNumber} permission={appliedPermission} />
          <ProMobileField {...defaultFormProps} {...formConfig.cellPhoneNumber} permission={appliedPermission} />
          <ProEmailField {...defaultFormProps} {...formConfig.email} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.companyName} permission={appliedPermission} />
        </FormSectionWrapper>
        <FormSectionWrapper title={"Address"}>
          <ProTextInput {...defaultFormProps} {...formConfig.address1} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.address2} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.state} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.city} permission={appliedPermission} />
          <ZipField {...defaultFormProps} {...formConfig.zipcode} permission={appliedPermission} />
        </FormSectionWrapper>
        <FormSectionWrapper title={"Business Address"}>
          <ProTextInput {...defaultFormProps} {...formConfig.businessAddress1} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.businessAddress2} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.businessState} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.businessCity} permission={appliedPermission} />
          <ZipField {...defaultFormProps} {...formConfig.businessZipcode} permission={appliedPermission} />
        </FormSectionWrapper>
        <FormSectionWrapper title={"Contact Information"}>
          <ProTextInput {...defaultFormProps} {...formConfig.contactFirstName} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.contactMiddleName} permission={appliedPermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.contactLastName} permission={appliedPermission} />
          <ProMobileField {...defaultFormProps} {...formConfig.contactPhoneNumber} permission={appliedPermission} />
          <ProEmailField {...defaultFormProps} {...formConfig.contactEmail} permission={appliedPermission} />
          <UrlField {...defaultFormProps} {...formConfig.firmWebPage} permission={appliedPermission} />
        </FormSectionWrapper>
      </SettingFormsLayout>
    </Layout>
  );
}
