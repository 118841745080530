import React, { useEffect, useMemo, useState, useCallback, useId } from "react";
import Layout from "../../../../Components/Layout";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import {  Grid } from "@mui/material";
import ProSelectField from "../../../../Components/Inputs/SelectField";
import ProTextInput from "../../../../Components/Inputs/TextField";
import ProCheckbox from "../../../../Components/Inputs/CheckboxField";
import { STAUTES } from "../../../../../utils/constant";
import {
  base64ToBlob,
  changeMode,
  changeModeForField,
  checkEditEnabled,
  downloadFile,
  fieldTruePermission,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../../utils/formHelper";
import ProFormsLayout from "../../../../Components/ProForms/ProFormsLayout";
import {
  ADMIN_RAP_INFO,
  GET_ADMIN_INDUSTRY_TYPES,
  GET_ADMIN_JOB_CATEGORIES,
  GET_RAP_JOB_TITLES,
  GET_SPONSOR_INFORMATION,
  GET_SSA_STATES,
} from "../../../../../utils/services/apiPath";
import { getApi, postApi, putApi } from "../../../../../utils/services";
import { errorToast, successToast } from "../../../../../utils/toastHelper";

const rapInfoFormConfing = {
  jobTitle: {
    label: "Job Title",
    perKey: "jobTitle",
    rules: { required: "Job Title is required!" }
  },
  approvedJobTitle: {
    label: "Approved Job Title",
    perKey: "approvedJobTitle"
  },
  industry: {
    label: "Industry",
    perKey: "industry",
    rules: { required: "Industry is required!" }
  },
  category: {
    label: "Category",
    perKey: "category",
    rules: { required: "Category is required!" }
  },
  apprenticeshipApproach: {
    label: "Apprenticeship Approach",
    perKey: "apprenticeshipApproach"
  },
  sponsor: {
    label: "Sponsor",
    perKey: "sponsor",
    rules: { required: "Sponsor is required!" }
  },
  summary: {
    label: "Summary",
    perKey: "summary"
  },
  description: {
    label: "Description",
    perKey: "description"
  },
  rapidCode: {
    label: "RAPIDS Code",
    perKey: "rapidCode"
  },
  isDOL: {
    label: "Is DOL",
    perKey: "isDOL",
    defaultValue: false
  },
  SSAState: {
    label: "SSA State",
    perKey: "SSAState",
    rules: { required: "SSA State is required!" }
  },
  programLength: {
    label: "Program Length (Years)",
    perKey: "programLength",
    rules: { required: "Program Length is required!" }
  },
  isOTJ: {
    label: "Is OTJ Track By Third Party",
    perKey: "isOTJ",
    defaultValue: null
  },
  isRTI: {
    label: "Is RTI Track By Third Party",
    perKey: "isRTI",
    defaultValue: null
  }
};

const ApprenticeshipApproachDropDown = [{ label: "Time-based" }, { label: "Competency-based" }, { label: "Hybrid" }];

export default function RapInfoProfile() {
  const { id } = useParams();
  const uniqueId = useId();
  const navigate = useNavigate();
  const isAddMode = true;
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [rapInfoData, setRapInfoData] = useState({});
  const [jobTitleDropDown, setJobTitleDropDown] = useState([]);
  const [industryDropDown, setIndustryDropDown] = useState([]);
  const [jobCategoryDropDown, setJobCategoryDropDown] = useState([]);
  const [ssaStateDropDown, setSsaStateDropDown] = useState([]);
  const [apprenticeshipApproachDropDown, setApprenticeshipApproachDropDown] = useState([]);
  const [sponsorDropDown, setSponsorDropDown] = useState([]);
  const [otjHoursArray, setOtjHoursArray] = useState([]);
  const [copyOtjHoursArray, setCopyOtjHoursArray] = useState([]);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(rapInfoFormConfing, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(rapInfoFormConfing) });
  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);
  const [isSaving, setIsSaving] = useState(false);

  const programLength = watch("programLength");
  const backAction = () => navigate("/apprentice-180/rap");
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => {
    if (isAddMode) backAction();
    else {
      setFormConfig(changeMode("read"));
      setOtjHoursArray(JSON.parse(JSON.stringify(copyOtjHoursArray)));
      setValue("programLength", copyOtjHoursArray.length);
    }
  };

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { data } = await getApi(`${ADMIN_RAP_INFO}?$filter=rapInfoId eq ${id}`);
    setStatus(STAUTES.IDLE);

    const rapInfo = {
      ...data[0],
      rapidCode: data[0]?.rapidCode,
      programLength: data[0]?.programLengthYears,
      industry: data[0].industryType,
      category: data[0]?.category,
      jobTitle: data[0]?.rapJobTitle,
      sponsor: data[0]?.sponsor,
      SSAState: data[0]?.ssaState
    };
    if (data[0]?.programLengthYears) {
      const otjHoursArray = Array.from({ length: data[0].programLengthYears }, (_, i) => ({
        OTJHoursYear: data[0]?.rapProgram?.otjHoursYear?.[i] || "",
        eLearningHoursYear: data[0]?.rapProgram?.eLearningHoursYear?.[i] || ""
      }));
      setOtjHoursArray(otjHoursArray);
      setCopyOtjHoursArray(JSON.parse(JSON.stringify(otjHoursArray)));
    }
    setRapInfoData(rapInfo);
    Object.keys(formConfig).forEach(field => setValue(field, rapInfo[field]));
  }, [setValue, id]);

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async data => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const otj = otjHoursArray?.map(ot => parseInt(ot.OTJHoursYear));
    const eLearn = otjHoursArray?.map(ot => parseInt(ot.eLearningHoursYear));
    const payload = {
      rapJobTitleId: data?.jobTitle,
      approvedJobTitle: data?.approvedJobTitle,
      industryTypeId: data?.industry,
      categoryId: data?.category,
      rapidCode: data?.rapidCode,
      summary: data?.summary,
      description: data?.description,
      programLengthYears: data?.programLength,
      isDol: data?.isDOL,
      ssaStateId: data?.SSAState,
      sponsorId: data?.sponsor,
      apprenticeshipApproach: data?.apprenticeshipApproach,
      isRtiTrackedByThirdParty: data?.isRTI,
      isOjtTrackedByThirdParty: data?.isOTJ,
      rapProgram: {
        otjHoursYear: otj,
        eLearningHoursYear: eLearn,
        rapInfoId: isAddMode ? null : id
      }
    };
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADMIN_RAP_INFO : UPDATE_RAP_INFO, payload);
    console.log(error);
    setIsSaving(false);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`RAP Info ${isAddMode ? "Added" : "Updated"} Successfully`);
    backAction();
  };

  const getAllJobTitles = async () => {
    setStatus(STAUTES.LOADING);
    const { data } = await getApi(GET_RAP_JOB_TITLES);
    setJobTitleDropDown(data);
    setStatus(STAUTES.IDLE);
  };

  const getAllIndustry = async () => {
    setStatus(STAUTES.LOADING);
    const { data } = await getApi(GET_ADMIN_INDUSTRY_TYPES);
    setIndustryDropDown(data);
    setStatus(STAUTES.IDLE);
  };

  const getAllJobCategory = async () => {
    setStatus(STAUTES.LOADING);
    const { data } = await getApi(GET_ADMIN_JOB_CATEGORIES);
    setJobCategoryDropDown(data);
    setStatus(STAUTES.IDLE);
  };

  const getAllSSAStates = async () => {
    setStatus(STAUTES.LOADING);
    const { data } = await getApi(GET_SSA_STATES);
    setSsaStateDropDown(data?.map(item => ({ label: item.label, value: item.value })));
    setStatus(STAUTES.IDLE);
  };

  const getAllApprenticeshipApproachDropDown = async () => {
    setApprenticeshipApproachDropDown(ApprenticeshipApproachDropDown.map(item => ({ label: item.label, value: item.label })));
  };

  const getAllSponsorDropDown = async () => {
    const { data } = await getApi(GET_SPONSOR_INFORMATION);
    setSponsorDropDown(data);
  };

  const handleInputChange = (e, index, fieldIndex) => {
    const { value } = e.target;
    const updatedArray = [...otjHoursArray];

    if (index >= 0 && index < updatedArray.length) {
      if (fieldIndex === 1 || fieldIndex === 2) {
        updatedArray[index][fieldIndex === 1 ? "OTJHoursYear" : "eLearningHoursYear"] = value;
        setOtjHoursArray(updatedArray);
      } else {
        console.error(`Invalid fieldIndex ${fieldIndex}`);
      }
    } else {
      console.error(`Index ${index} is out of bounds for otjHoursArray`);
    }
  };

  useEffect(() => {
    if (programLength) {
      setOtjHoursArray(
        Array.from({ length: programLength }, (_, index) => {
          const { OTJHoursYear = "", eLearningHoursYear = "" } = otjHoursArray[index] || {};
          return { OTJHoursYear, eLearningHoursYear };
        })
      );
    }
  }, [programLength]);

  useEffect(() => {
    getAllJobTitles();
    getAllIndustry();
    getAllJobCategory();
    getAllSSAStates();
    getAllApprenticeshipApproachDropDown();
    getAllSponsorDropDown();
  }, []);

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

  const defaultFormProps = { control, formValues: rapInfoData, isAddMode, handleReadValueclick };
  return (
    <Layout>
      <ProFormsLayout
        status={status}
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Rap Info`}
        backLabel="Go Back to Rap Info"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProSelectField {...defaultFormProps} {...formConfig?.jobTitle} permission={fieldTruePermission} options={jobTitleDropDown} />
          <ProTextInput {...defaultFormProps} {...formConfig.approvedJobTitle} permission={fieldTruePermission} />
          <ProSelectField {...defaultFormProps} {...formConfig.industry} permission={fieldTruePermission} options={industryDropDown} />
          <ProSelectField {...defaultFormProps} {...formConfig.category} permission={fieldTruePermission} options={jobCategoryDropDown} />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.apprenticeshipApproach}
            permission={fieldTruePermission}
            options={apprenticeshipApproachDropDown}
          />
          <ProSelectField {...defaultFormProps} {...formConfig.sponsor} permission={fieldTruePermission} options={sponsorDropDown} />
          <ProTextInput {...defaultFormProps} {...formConfig.summary} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.description} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.rapidCode} permission={fieldTruePermission} />
          <ProCheckbox {...defaultFormProps} {...formConfig.isDOL} permission={fieldTruePermission} />
          <ProSelectField {...defaultFormProps} {...formConfig.SSAState} permission={fieldTruePermission} options={ssaStateDropDown} />
          <ProCheckbox {...defaultFormProps} {...formConfig.isOTJ} permission={fieldTruePermission} />
          <ProCheckbox {...defaultFormProps} {...formConfig.isRTI} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.programLength} permission={fieldTruePermission} type="number" />
          {!!programLength &&
            otjHoursArray.map((_, index) => (
              <div key={uniqueId} style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
                <div style={{ width: "33.3%", padding: "16px 0 0 16px" }}>
                  <ProTextInput
                    size={12}
                    fieldProps={{ value: _.OTJHoursYear, onChange: e => handleInputChange(e, index, 1) }}
                    control={control}
                    formValues={{ [`OTJHoursYear${index + 1}`]: _.OTJHoursYear }}
                    mode={formConfig.programLength.mode}
                    name={`OTJHoursYear${index + 1}`}
                    label={`OTJ Hours Year ${index + 1}`}
                    permission={fieldTruePermission}
                  />
                </div>
                <div style={{ width: "33.3%", padding: "16px 0 0 16px" }}>
                  <ProTextInput
                    size={12}
                    fieldProps={{ value: _.eLearningHoursYear, onChange: e => handleInputChange(e, index, 2) }}
                    control={control}
                    formValues={{ [`eLearningHoursYear${index + 1}`]: _.eLearningHoursYear }}
                    mode={formConfig.programLength.mode}
                    name={`eLearningHoursYear${index + 1}`}
                    label={`Learning Hours Year ${index + 1}`}
                    permission={fieldTruePermission}
                    isSaving={isSaving}
                  />
                </div>
              </div>
            ))}
        </Grid>
      </ProFormsLayout>
    </Layout>
  );
}
