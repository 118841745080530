import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material";

const WeeklyComplianceLostOTJ = ({ data }) => {
  const theme = useTheme();
  const options = {
    chart: {
      type: "line",
      marginTop: 50,
      backgroundColor: theme.palette.demo.weeklyreportbackgroundcolor
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: data.xAxisData,
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.demo.weeklyreportcolor
        }
      },
      lineColor: "#F8F8FF"
    },
    yAxis: {
      min: 0,
      title: {
        text: ""
      },
      labels: {
        style: {
          color: theme.palette.demo.weeklyreportcolor
        }
      },
      gridLineWidth: 1,
      gridLineColor: "#e6e6e6",
      gridLineDashStyle: "Dot",
      lineColor: "#F8F8FF"
    },
    series: [
      {
        name: "OTJ Hours",
        data: data.otjHoursData,
        color: "#1F88E6",
        marker: {
          enabled: true,
          radius: 5,
          symbol: "circle",
          fillColor: "#FFFFFF",
          lineWidth: 1.5,
          lineColor: "#1F88E6"
        }
      },
      {
        name: "Lost OTJ",
        data: data.lostOtjData,
        color: "#0507B9",
        marker: {
          enabled: true,
          radius: 5,
          symbol: "circle",
          fillColor: "#FFFFFF",
          lineWidth: 1.5,
          lineColor: "#0507B9"
        }
      }
    ],
    plotOptions: {
      line: {
        lineWidth: 1.5,
        color: "#D3D3D3"
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: "horizontal",
      align: "right",
      verticalAlign: "top",
      floating: true,
      y: -10,
      itemStyle: {
        color: theme.palette.demo.weeklyreportcolor,
        fontWeight: "normal"
      },
      itemHoverStyle: {
        color: theme.palette.demo.weeklyreportcolor
      }
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

WeeklyComplianceLostOTJ.propTypes = {
  data: PropTypes.shape({
    xAxisData: PropTypes.arrayOf(PropTypes.string).isRequired,
    otjHoursData: PropTypes.arrayOf(PropTypes.number).isRequired,
    lostOtjData: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired
};

export default WeeklyComplianceLostOTJ;
