import React from "react";

const RTIViewIcon = () => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="28" height="28" rx="3.5" fill="white" stroke="#00B0EE"/>
    <path d="M19.2428 10.2501C19.2668 10.2501 19.2903 10.2429 19.3102 10.2296C19.3302 10.2163 19.3458 10.1973 19.355 10.1751C19.3642 10.1529 19.3666 10.1285 19.3619 10.1049C19.3572 10.0814 19.3456 10.0597 19.3286 10.0428L16.1003 6.81447C16.0834 6.79747 16.0617 6.7859 16.0382 6.78121C16.0146 6.77652 15.9902 6.77892 15.968 6.78811C15.9458 6.7973 15.9268 6.81287 15.9135 6.83285C15.9002 6.85283 15.8931 6.87631 15.8931 6.90033V9.64292C15.8931 9.80395 15.957 9.95838 16.0709 10.0722C16.1848 10.1861 16.3392 10.2501 16.5002 10.2501H19.2428Z" fill="#00B0EE"/>
    <path d="M19.5357 11.4643H16.5C16.0169 11.4643 15.5536 11.2724 15.2121 10.9308C14.8705 10.5892 14.6786 10.1259 14.6786 9.64286V6.6087C14.6788 6.52911 14.6633 6.45024 14.6331 6.37663C14.6028 6.30302 14.5583 6.2361 14.5021 6.17971C14.4459 6.12331 14.3792 6.07855 14.3057 6.04798C14.2322 6.01741 14.1534 6.00164 14.0738 6.00156C12.6517 6.00082 9.21429 6 9.21429 6C9.05482 6 8.89692 6.0314 8.74959 6.09242C8.60227 6.15344 8.4684 6.24289 8.35565 6.35565C8.24289 6.4684 8.15344 6.60227 8.09242 6.74959C8.0314 6.89692 8 7.05482 8 7.21429V21.7857C8 22.1078 8.12793 22.4166 8.35566 22.6443C8.58338 22.8721 8.89224 23 9.21429 23H18.9286C19.2506 23 19.5595 22.8721 19.7872 22.6443C20.0149 22.4166 20.1429 22.1078 20.1429 21.7857V12.0714C20.1429 11.9104 20.0789 11.756 19.965 11.6421C19.8512 11.5283 19.6967 11.4643 19.5357 11.4643ZM17.6924 17.7464C17.2377 18.2575 16.68 18.6666 16.0559 18.9467C15.4318 19.2268 14.7555 19.3716 14.0714 19.3716C13.3874 19.3716 12.711 19.2268 12.087 18.9467C11.4629 18.6666 10.9052 18.2575 10.4505 17.7464C10.25 17.5212 10.1391 17.2301 10.1391 16.9286C10.1391 16.627 10.25 16.336 10.4505 16.1108C10.9052 15.5996 11.4629 15.1906 12.087 14.9105C12.7111 14.6303 13.3874 14.4855 14.0714 14.4855C14.7555 14.4855 15.4318 14.6303 16.0559 14.9105C16.68 15.1906 17.2377 15.5996 17.6924 16.1108C17.8929 16.336 18.0038 16.627 18.0038 16.9286C18.0038 17.2301 17.8929 17.5212 17.6924 17.7464Z" fill="#00B0EE"/>
    <path d="M15.1127 17.2213C14.8759 17.2219 14.6468 17.1369 14.4677 16.982C14.2886 16.8271 14.1715 16.6126 14.138 16.3782C14.1045 16.1438 14.1569 15.9051 14.2855 15.7063C14.4141 15.5074 14.6102 15.3617 14.8378 15.296C14.4807 15.1309 14.0798 15.0861 13.6951 15.1682C13.3104 15.2503 12.9627 15.4549 12.7042 15.7514C12.4457 16.0478 12.2903 16.4201 12.2613 16.8124C12.2323 17.2047 12.3313 17.5958 12.5435 17.9271C12.7557 18.2583 13.0695 18.5118 13.438 18.6495C13.8065 18.7872 14.2097 18.8017 14.5871 18.6909C14.9645 18.58 15.2958 18.3497 15.5312 18.0346C15.7666 17.7195 15.8935 17.3365 15.8928 16.9431C15.8928 16.9089 15.8846 16.877 15.8827 16.8433C15.7915 16.9607 15.6748 17.0557 15.5413 17.1212C15.4079 17.1867 15.2613 17.2209 15.1127 17.2213Z" fill="#00B0EE"/>
    </svg>
    
  );
};

export default RTIViewIcon;
