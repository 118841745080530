import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ProGrid from "../../../Components/ProGrid/ProGrid";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import Layout from "../../../Components/Layout";
import usePageState from "../../../../utils/customHooks/usePageState";
import { GET_ALL_PROJECTS, DELETE_PROJECT } from "../../../../utils/services/apiPath";
import { postApi, deleteApi } from "../../../../utils/services";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";
import { R_PROJECTS, R_PROJECTS_COMPLIANCE_NAVIGATOR, R_PROJECTS_LIST } from "../../../../utils/permissionReferenceConfig";
import useColumn from "../../../../utils/customHooks/useColumns";
import ToolbarBase from "../../../Components/Toolbars/ToolbarBase";
import GridSerch from "../../../Components/ProGrid/components/GridSerch";
import ProButton from "../../../Components/ProButton";
import { GridToolbarExport } from "@mui/x-data-grid-pro";
import ComplianceNavigator from "../ProjectDetail/ComplianceNavigator";

function ProjectsToolbar({
  title = "",
  addButtonLabel = "",
  addButtonAction = () => {},
  hideAddButton = false,
  hideExport = false,
  searchModel = {}
}) {
  return () => (
    <ToolbarBase title={title}>
      <div className="proGrid-search-wrapper">
        <GridSerch {...searchModel} />
      </div>
      {!hideExport && <GridToolbarExport className="export-icon" />}
      <div className="proGrid-filter">{!hideAddButton && <ProButton onClick={addButtonAction}>{addButtonLabel}</ProButton>}</div>
    </ToolbarBase>
  );
}

export default function Projects() {
  const navigation = useNavigate();
  const {
    currentPage,
    pageSize,
    data: projectData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { canView, canAdd, canExport, canDelete } = useSelector(subModPermissionSelector(R_PROJECTS, R_PROJECTS_LIST));
  const partnersCompliancePermission = useSelector(subModPermissionSelector(R_PROJECTS, R_PROJECTS_COMPLIANCE_NAVIGATOR));

  const columns = useColumn(
    [
      {
        field: "projectName",
        headerName: "Project Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={params => params.row.projectId} />
      },
      {
        field: "city",
        headerName: "City"
      },
      {
        field: "primaryCounties",
        headerName: "County"
      },
      {
        field: "state",
        headerName: "State"
      },
      {
        field: "projectId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    canDelete
  );

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_PROJECT, { data: [{ projectId: id }] });
    if (error) return errorToast(error);
    getProjects();
    successToast("Project deleted successfully.");
  };

  const addButtonAction = () => {
    navigation({
      pathname: `/projects/new`
    });
  };

  const getProjects = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "projectName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PROJECTS, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <Layout canView={canView}>
      {partnersCompliancePermission?.canView && <ComplianceNavigator />}
      <ProGrid
        columns={columns}
        addButtonAction={addButtonAction}
        loading={status === STAUTES.LOADING}
        rows={projectData}
        searchMode={!!searchString}
        options={{
          getRowId: row => row.projectId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
        toolbar={ProjectsToolbar({
          title: "Projects",
          searchModel: { defaultValue: searchString, handleDebounce: setSearchString },
          addButtonLabel: "+ Add Project",
          addButtonAction: addButtonAction,
          hideAddButton: !canAdd,
          hideExport: !canExport
        })}
      />
    </Layout>
  );
}
