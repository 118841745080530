import React, { useCallback, useState, useEffect } from "react";
import ComplianceNavigatorBaseTable from "./ComplianceNavigatorBaseTable";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";
import PropTypes from "prop-types";
import UploadFileDavisBacon from "./UploadFileDavisBacon";
import { DAVIS_BACON_FORM_DETAIL, COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES } from "../../../utils/services/apiPath";
import { STAUTES } from "../../../utils/constant";
import { getApi } from "../../../utils/services";
import { createODataQuery } from "../../../utils/services/oData";
import { errorToast } from "../../../utils/toastHelper";
import { DateCell } from "../ProGrid/components/FormatCell";

const NintyDayCertificates = ({ tableKey, projectId, partnerId }) => {
  const {
    currentPage,
    pageSize,
    data: balconData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const [insightSummary, setInsightSummary] = useState([]);

  const columns = useColumn([
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "partnerName",
      headerName: "Partner"
    },
    {
      field: "employeeName",
      headerName: "Apprentice Name"
    },
    {
      field: "issuedDate",
      headerName: "Certificate Date",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "expiryDate",
      headerName: "Expiration Date",
      renderCell: params => <DateCell value={params.value} />
    }
  ]);

  const getDetailData = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const searchableFields = ["projectName", "partnerName", "employeeName"];
    const { data, totalRecords, error } = await getApi(
      `${DAVIS_BACON_FORM_DETAIL}${createODataQuery(
        searchableFields,
        [],
        "",
        searchString,
        sortModel,
        pageSize,
        currentPage
      )}&projectId=${projectId}&partnerId=${partnerId}`
    );
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const fetchInsightData = async () => {
    const res = await getApi(`${COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES}?key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`);
    setInsightSummary(res);
  };

  useEffect(() => {
    getDetailData();
    fetchInsightData();
  }, [getDetailData]);

  return (
    <ComplianceNavigatorBaseTable
      columns={columns}
      title="Davis Bacon Forms Table"
      rows={balconData}
      insightData={insightSummary}
      tableKey={tableKey}
      rowCount={rowCount}
      status={status}
      setPagination={setPagination}
      setSortModel={setSortModel}
      searchString={searchString}
      setSearchString={setSearchString}
      getRowId={row => row.davisBaconFormGuid}
      pageSize={pageSize}
      currentPage={currentPage}
      uploadPopUp={<UploadFileDavisBacon title="Davis Bacon Form - File Upload" getDetailData={getDetailData} fetchInsightData={fetchInsightData} />}
    />
  );
};

NintyDayCertificates.propTypes = {
  tableKey: PropTypes.string.isRequired
};

export default NintyDayCertificates;
