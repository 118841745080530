import React from "react";
import PropTypes from "prop-types";
import "./DashboardFeed.scss";
import FeedOptions from "./FeedOptions";
import FeedList from "./FeedList";
import { Skeleton, Stack } from "@mui/material";

export default function DashboardFeed({ feedJson, handleOpen, setFeedData}) {
  return (
    <div className="dashboard-feed">
      <div className="dashboard-feed-title">ProScore Feed</div>
      <div className="dashboard-feed-content">
        {feedJson?.length === 0 ? <Skeleton variant="rectangular" width={'100%'} height={'calc(100% - 60px)'} sx={{ marginTop: '10px' }} /> :
          <FeedList feedJson={feedJson} handleOpen={handleOpen} setFeedData={setFeedData} />}
      </div>
    </div>
  );
}

DashboardFeed.propTypes = {
  feedJson: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      date: PropTypes.string
    })
  ).isRequired
};