import React, { forwardRef, useCallback, useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Paper,
  Slide,
  Stack,
  Typography,
  styled,
  Autocomplete,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ProGrid from "../../Components/ProGrid/ProGrid";
import NameCell from "../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import Layout from "../../Components/Layout";
import { STAUTES } from "../../../utils/constant";
import { GET_ALL_EMPLOYEES_V2, DELETE_EMPLOYEE, UPLOAD_EXCEL_EMPLOYEES, ASSIGN_RAP_DROPDOWNS, RAP_INFOS_DROPDOWNS } from "../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../utils/toastHelper";
import usePageState from "../../../utils/customHooks/usePageState";
import { getApi, postApi, deleteApi } from "../../../utils/services";
import { R_EMPLOYEES, R_EMPLOYEES_LIST } from "../../../utils/permissionReferenceConfig";
import useColumn from "../../../utils/customHooks/useColumns";
import { subModPermissionSelector } from "../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { employeeApprenticeNavigatorFormName, formatKeys, formatPayloadDate, modifyEmployeeImportData } from "../../../utils/formHelper";
import EmployeeListToolbar from "../../Components/Toolbars/EmployeeListToolbar";
import { createODataQuery } from "../../../utils/services/oData";
import { ExpandMore } from "@mui/icons-material";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Employees = [
  "First Name",
  "Middle Name",
  "Last Name",
  "Job Title",
  "Cell Phone",
  "Office Phone",
  "Whatsapp Number",
  "Birth Date",
  "Hourly Rate Amount",
  "Fringe Rate Amount",
  "Email",
  "Address",
  "Address2",
  "City",
  "State",
  "Zip",
  "Last Four Digit SSN",
  "Internal EmployeeID"
];

const fields = Employees?.map(fieldName => ({
  label: fieldName,
  key: fieldName,
  fieldType: {
    type: "input"
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const betweenFilter = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  return [{ field: "modifiedDate", startDate: formatPayloadDate(thirtyDaysAgo), endDate: formatPayloadDate(today) }];
};

const applicationStatuses = [
  { cardTitle: "Application Sent", filterSearch: "application sent" },
  { cardTitle: "Application Received", filterSearch: "application received" },
  { cardTitle: "Additional Documents Requested", filterSearch: "additional documents requests" },
  { cardTitle: "Additional Documents Received", filterSearch: "additional documents received" },
  { cardTitle: "Interview Scheduled", filterSearch: "interview scheduled" },
  { cardTitle: "Interview Complete", filterSearch: "interview complete" },
  { cardTitle: "Approved (Last 30 days)", betweenFields: betweenFilter("accepted"), filterSearch: "approved" },
  { cardTitle: "Rejected (Last 30 Days)", betweenFields: betweenFilter("rejected"), filterSearch: "rejected" },
  {
    cardTitle: "671s Without signatures",
    query: "formDocuments/$count($filter=isApprenticeSigned eq false and isProScoreSigned eq false and formName eq 'ETA Form Document') gt 0"
  },
  { cardTitle: "All Apprentices", filterSearch: "true" },
  { cardTitle: "All Employees" }
];

export default function EmployeeList() {
  const {
    currentPage,
    pageSize,
    data: employeesData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const [selectedCard, setSelectedCard] = useState("All Employees");
  const [selectedRaps, setSelectedRaps] = useState([]);
  const [cardCounts, setCardCounts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const { canView, canAdd, canExport, canDelete, ...rest } = useSelector(subModPermissionSelector(R_EMPLOYEES, R_EMPLOYEES_LIST));
  const { formPermissions = [] } = rest || {};
  const apprenticeNavigatorPermission = formPermissions?.find(permission => permission.formName === employeeApprenticeNavigatorFormName) || {};

  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [betweenFields, setBetweenFields] = useState([]);
  const [query, setQuery] = useState("");
  const [rapDropdownOptions, setRapDropdownOptions] = useState([]);
  const [applicationStatusCards] = useState(applicationStatuses);
  const [rapQuery, setRapQuery] = useState("");
  const columns = useColumn(
    [
      {
        field: "firstName",
        headerName: "First Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={params => params.row.employeeId} />
      },
      {
        field: "lastName",
        headerName: "Last Name"
      },
      {
        field: "jobTitle",
        headerName: "Job Title"
      },
      {
        field: "projectName",
        headerName: "Project Name"
      },
      {
        field: "employeeStatusType",
        headerName: "Status"
      },
      {
        field: "employeeType",
        headerName: "Type"
      },
      {
        field: "apprenticeProspectStatusType",
        headerName: "Apprentice Status"
      },
      {
        field: "employeeId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    canDelete
  );

  const clearFilters = () => {
    setFilterSearch("");
    setBetweenFields([]);
    setQuery("");
  };

  const toggleSelectedRaps = (event, selected) => {
    if (selected.length === 0) {
      setRapQuery("");
      setSelectedRaps([]);
      return;
    }
    const selectedGuids = selected.map((selection) => selection.value)
    setSelectedRaps(selected)
    setRapQuery(`rapInfoId in (${selectedGuids.join()})`)
  }

  const RapMultiSelect = () => {
    return (
      <Stack>
        <Autocomplete
          multiple
          id="tags-standard"
          options={rapDropdownOptions}
          getOptionLabel={(option) => `${option.label}`}
          onChange={(e, value) => toggleSelectedRaps(e, value)}
          value={selectedRaps}
          renderInput={params => <TextField {...params} variant="standard" label="Apprentices By RAP" placeholder="RAP" />}
        />
      </Stack>
    );
  };

  const handleOpenDialogPopup = () => {
    setIsOpen(true);
  };

  const handleCloseDialogPopup = () => {
    setIsOpen(false);
  };

  const handleSubmit = async ({ validData }) => {
    const excelData = formatKeys(validData);
    const { error } = await postApi(UPLOAD_EXCEL_EMPLOYEES, modifyEmployeeImportData(excelData));
    if (error) return errorToast(error);
    handleOpenDialog();
    setMessage("Added Excel Data Successfully.");
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    getEmployeeList();
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_EMPLOYEE, { data: [{ employeeId: id }] });
    if (error) return errorToast(error);
    successToast("Employee deleted successfully.");
    getEmployeeList();
  };

  const getEmployeeList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const searchableFields = [
      "firstName",
      "lastName",
      "jobTitle",
      "projectName",
      "employeeStatusType",
      "apprenticeProspectStatusType",
      "cast(isApprentice, 'Edm.String')"
    ];
    const { data, totalRecords, error } = await getApi(
      GET_ALL_EMPLOYEES_V2 +
        createODataQuery(
          searchableFields,
          betweenFields,
          [query, rapQuery],
          searchString.length == 0 ? filterSearch : searchString,
          sortModel,
          pageSize,
          currentPage
        )
    );
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus, filterSearch, query, rapQuery]);

  useEffect(() => {
    clearFilters();
    setSelectedCard("All Employees");
  }, [searchString]);

  const getRapDropDown = async () => {
    const { data, error } = await getApi(RAP_INFOS_DROPDOWNS)
    if (error) return errorToast(error);

    setRapDropdownOptions(data);
  };

  const setFilters = ({ query = "", betweenFields = [], filterSearch = "" }) => {
    if (filterSearch) {
      setFilterSearch(filterSearch);
    }

    if (query) {
      setQuery(query);
    }

    if (betweenFields) {
      setBetweenFields(betweenFields);
    }
  };

  const cardClick = title => {
    if (title == selectedCard || searchString.length > 0) {
      return;
    }
    clearFilters();
    setSelectedCard(title);
  };

  const getEmployeeListOnLoad = async () => {
    const promises = [];
    try {
      applicationStatusCards.forEach((card, i) => {
        const { cardTitle, query, filterSearch, betweenFields } = card;
        const searchableFields = [
          "firstName",
          "lastName",
          "jobTitle",
          "projectName",
          "employeeStatusType",
          "apprenticeProspectStatusType",
          "cast(isApprentice, 'Edm.String')"
        ];
        const queryString = query ? query : "";
        const betweenFieldsArray = betweenFields ? betweenFields : [];
        const filterSearchString = filterSearch ? filterSearch : "";
        promises.push(
          getApi(
            GET_ALL_EMPLOYEES_V2 +
              createODataQuery(
                searchableFields,
                betweenFieldsArray,
                [queryString, rapQuery],
                searchString.length == 0 ? filterSearchString : searchString,
                sortModel,
                0,
                currentPage
              )
          )
        );
      });
      const res = await Promise.all(promises);
      const counts = [];
      res.forEach((v, i) => {
        counts[i] = v.totalRecords;
      });
      setCardCounts(counts);
    } catch (error) {
      return errorToast(error);
    }
  };

  useEffect(() => {
    const card = applicationStatusCards.filter(card => card.cardTitle == selectedCard)[0];
    setFilters(card);
  }, [selectedCard]);

  useEffect(() => {
    getEmployeeListOnLoad();
  }, [rapQuery, searchString]);

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList]);

  useEffect(() => {
    getRapDropDown();
    getEmployeeListOnLoad();
  }, []);

  return (
    <Layout canView={canView}>
      {/* {apprenticeNavigatorPermission.canView && (
        <div className="rs-grid-wrapper v2">
          <Accordion defaultExpanded style={{ padding: "5px", marginTop: "10px", marginBottom: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Apprentice Navigator</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} direction={"row"}>
                {applicationStatusCards.map((obj, i) => {
                  return (
                    <Grid key={obj.cardTitle} item xs={4}>
                      {
                        <Paper
                          id={obj.cardTitle}
                          onClick={e => cardClick(obj.cardTitle)}
                          elevation={3}
                          sx={{
                            textAlign: "justify",
                            width: "100%",
                            fontSize: "15px",
                            fontWeight: obj.cardTitle === selectedCard ? "bold" : "normal",
                            backgroundColor: obj.cardTitle === selectedCard ? "#1f88e6" : "#ffff",
                            color: obj.cardTitle === selectedCard ? "#ffff" : null
                          }}
                        >
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ whiteSpace: "nowrap", paddingTop: "15px", paddingBottom: "15px", paddingLeft: "15px" }}>
                              {obj.cardTitle}
                            </div>
                            <b style={{ paddingTop: "15px", paddingRight: "15px", paddingBottom: "15px" }}>({cardCounts[i]})</b>
                          </div>
                        </Paper>
                      }
                    </Grid>
                  );
                })}
                <Grid item xs={4}>
                  <RapMultiSelect />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )} */}
      <ProGrid
        title="Employees"
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.employeeId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
        columns={columns}
        hideAddButton={!canAdd}
        hideExport={!canExport}
        rows={employeesData}
        searchMode={!!searchString}
        toolbar={EmployeeListToolbar({
          importExcelAction: handleOpenDialogPopup,
          searchModel: { defaultValue: searchString, handleDebounce: setSearchString }
        })}
      />
      <ReactSpreadsheetImport isOpen={isOpen} onClose={handleCloseDialogPopup} onSubmit={handleSubmit} fields={fields} />
      <DialogWrapper open={openDialog} maxWidth="sm" fullWidth TransitionComponent={Transition} keepMounted onClose={handleCloseDialog}>
        <Box sx={{ padding: "20px" }}>
          <Typography fontSize={15} fontWeight={600}>
            {message}
          </Typography>
          <Button fullWidth size="large" variant="contained" sx={{ mt: 2 }} onClick={handleCloseDialog}>
            Close
          </Button>
        </Box>
      </DialogWrapper>
    </Layout>
  );
}
