import React, { useEffect, useState } from "react";
import { ODataReportS3 } from "./StimulsoftReports";
import { S3_REPORTS_URL } from "../../../utils/constant";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const ODataReportViewer = ({ fileName }) => {
  const accessToken = localStorage.getItem("accessToken")
  const [searchParams, setSearchParams] = useSearchParams()
  const [reportObject, setReportObject] = useState(null)
  let instance = axios.create({
    headers: {
    },
  });

  const getReport = async () => {
    try {
      const { data } = await instance.get(`${S3_REPORTS_URL}${fileName}`)
      setReportObject(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getReport()
  }, [])

  useEffect(() => {
    if (reportObject) {
      let filters = ''
      if (searchParams.has("$filter")) {
        filters = searchParams.get("$filter")
      }

      let report = new ODataReportS3({
        reportFile: reportObject,
        accessToken: accessToken,
        filters: filters
      });
      report.viewer.renderHtml("viewer");
    }
  }, [reportObject])

  return <div id="viewer"></div>;
};

export default ODataReportViewer;