import DetailPageLayout from "../../../Components/Layout/DetailPageLayout.jsx";
import ProjectPartners from "./ProjectPartners.jsx";
import React, { useEffect, useState } from "react";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";
import { useForm } from "react-hook-form";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProSelectInput from "../../../Components/Inputs/SelectField";
import ProButton from "../../../Components/ProButton";
import DateField from "../../../Components/Inputs/DateField";
import { useNavigate } from "react-router-dom";
import { ProjectProfileConfig, profileFormNames } from "../projectData.js";
import { STAUTES } from "../../../../utils/constant";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import EnverusProjectsTable from "./EnverusProjectsTable.jsx";
import FileUploadField from "../../../Components/Inputs/FileUploadField.jsx";

import ProjectScheduling from "././ProjectSchedule";
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, Stack, Typography, TextField, MenuItem } from "@mui/material";
import {
  ADD_PROJECT,
  GETALL_WORKTYPE,
  GETALL_PROJECTDOCUMENTTYPE,
  DYNAMIC_DROPDOWN
} from "../../../../utils/services/apiPath";
import { postApi } from "../../../../utils/services";
import {
  changeMode,
  convertResponseDate,
  convertFileToBase64,
  formatPayloadDate,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import usePermission from "../../../../utils/customHooks/usePermission";
import { R_PROJECTS, R_PROJECTS_PROFILE } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import {
  timeKeeping,
  communitySolarOptions,
  coLocatedProjectOptions,
  projectKickoff,
  interconnectionApproval,
  defaultDropdownConfig,
  documentType
} from "../../../../utils/dropdownHelper.js";
import ZipField from "../../../Components/Inputs/ZipField.jsx";
import dayjs from "dayjs";

export default function AddForm() {
  const navigate = useNavigate();
  const [projectProfileData, setProjectProfileData] = useState({});
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [projectId, setProjectId] = useState("");
  const [workTypeList, setWorkTypeList] = useState([]);
  const [projectDocumentTypeList, setProjectDocumentTypeList] = useState([]);
  const [clients, setClients] = useState([]);
  const [projectNameWarning, setProjectNameWarning] = useState("");
  const [openWarning, setOpenWarning] = React.useState(false);
  const [documentFileName, setDocumentFileName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentPath, setDocumentPath] = useState("");
  const [globalEditMode, setGlobalEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [projectData, setProjectData] = useState(null)
  const [partnerSaved, setPartnerSaved] = useState(false)
  const [scheduleSaved, setScheduleSaved] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(ProjectProfileConfig, "edit"));
  const [enverusFormData, setEnverusFormData] = useState({})
  const { handleSubmit, control, setValue, watch, reset } = useForm({ defaultValues: prepareDefaultValues(ProjectProfileConfig) });
  const {
    "Project Name and Address": projectInformationNameAndAddressPermission,
    "Project Ownership(Partners)": projectOwnerShipPermission,
    "Project Details": projectDetailsPermission,
    "Project Partners": projectPartnersPermission,
    "Project Details(Required Info)": projectDetailsRequiredInfoPermission,
    "Project Site : Module Info": projectSiteModuleInfoPermission,
    "Project Site : Structure Info": projectSiteStructedInfoPermission,
    "Project Site : Inverter Detail": projectSiteInverterDetailPermission,
    "Project Site : Material & Equipment Scheduling Info": projectSiteMaterialPermission,
    "Project Site : Interconnection Info": projectSiteInterconnectionInfoPermission,
    "Project Site : Permitting & Entitlement Detail": projectSitePermittingAndEntitlementDetailPermission
  } = usePermission(subModPermissionSelector(R_PROJECTS, R_PROJECTS_PROFILE), profileFormNames);

  const defaultFormProps = { control };
  const isTargetConstructionStartDate = watch("targetConstructionStartDate");

  const handleDateValidation = date => {
    return date ? dayjs(date).isBefore(dayjs(isTargetConstructionStartDate), "day") : false;
  };

  const getClientList = async () => {
    const payload = ["PARTNER"];
    const data = await postApi(DYNAMIC_DROPDOWN, payload);
    setClients(data[0]?.typeDropDowns);
  };

  const getWorkTypeList = async () => {
    const { data } = await postApi(`${GETALL_WORKTYPE}`, defaultDropdownConfig);
    setWorkTypeList(data?.map(item => ({ label: item.workType, value: item.workTypeId })) || []);
  };

  const getAllProjectDocumentType = async () => {
    const { data } = await postApi(`${GETALL_PROJECTDOCUMENTTYPE}`, defaultDropdownConfig);
    setProjectDocumentTypeList(data?.map(item => ({ label: item.projectDocumentType, value: item.projectDocumentTypeId })) || []);
  };

  const handleWarningClickOpen = () => {
    setOpenWarning(true);
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  useEffect(() => {
    reset()
    setResetForm(false)
  }, [resetForm])

  const AddNewProjectName = async (searchString, payload) => {
    if (!searchString) return;
    const trimmedString = searchString.trim();
    setValue("projectName", trimmedString);
    if (trimmedString) {
      const { error, data } = await postApi(ADD_PROJECT, { ...payload, projectName: trimmedString });
      if (error) {
        if (error === "Exists") {
          setProjectNameWarning(trimmedString);
          handleWarningClickOpen();
          return false
        }
        errorToast(error)
        return false
      }
      return data
    }
  };

  const onSubmit = async _values => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = {
      ..._values,
      projectAcres: _values?.projectAcresSize,
      countiesInConstructionSite: _values?.constructionSiteCounties,
      primaryCounties: _values?.primaryCounties,
      workId: _values?.workId,
      targetConstructionStartDate: formatPayloadDate(_values.targetConstructionStartDate),
      actualConstructionStartDate: formatPayloadDate(_values.actualConstructionStartDate),
      targetCODDate: formatPayloadDate(_values.targetCODDate),
      kickoffSeason: _values?.projectKickoff,
      acCapacity: _values?.projectCapacityAC,
      dcCapacity: _values?.projectCapacityDC,
      tableOrRowCount: _values?.rowCount,
      dcRatio: _values?.DCRatio,
      acRatio: _values?.ACRatio,
      isRowRequired: _values?.ROWRequired,
      projectDocumentTypeId: _values?.projectDocumentType,
      coLocatedProject: _values?.coLocatedProject !== "" ? _values?.coLocatedProject : null,
      firstPowerYear: _values?.firstPowerYear !== "" ? _values?.firstPowerYear : null,
      firstPowerDate: formatPayloadDate(_values.firstPowerDate),
      projectLastUpdatedDate: formatPayloadDate(_values.projectLastUpdatedDate),
      projectId: projectId
    };

    if (documentFileName !== "") {
      payload["documentMediaModel"] = {
        base64String: documentUrl,
        fileName: documentFileName
      };
    }

    const result = await AddNewProjectName(payload.projectName, payload)
    if (!result) {
      setStatus(STAUTES.IDLE);
      setIsSaving(false);
      return
    }

    setStatus(STAUTES.IDLE);
    setIsSaving(false);
    successToast("Project Added Successfully");
    setProjectId(result.guidId)
  };

  useEffect(() => {
    if (!projectId) return;

    navigate(`/projects/${projectId}`);
  }, [projectId])

  const handleDownload = () => {
    const file = documentPath;
    const link = document.createElement("a");
    link.href = file;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    link.click();
  };

  const backAction = () => {
    navigate("/projects");
  };

  const onEdit = () => {
    setGlobalEditMode(true);
    setFormConfig(changeMode("edit"));
  };

  const updateFormFields = (data) => {
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  useEffect(() => {
    if (resetForm) {
      return
    }
    const data = {
      ...enverusFormData,
      address1: enverusFormData?.address,
      primaryCounties: enverusFormData?.county,
      state: enverusFormData?.stateProvince
    }
    updateFormFields(data)
  }, [enverusFormData, resetForm])

  const formatProjectData = data => ({
    ...data,
    projectEPC: data.projectEPCItem?.value || null,
    projectOwner: data.projectOwnerItem?.value || null,
    projectDeveloper: data.projectDevelperItem?.value || null,
    actualConstructionStartDate: convertResponseDate(data.actualConstructionStartDate),
    projectKickoff: data.kickoffSeason,
    firstPowerDate: convertResponseDate(data.firstPowerDate),
    projectLastUpdatedDate: convertResponseDate(data.projectLastUpdatedDate),
    targetConstructionStartDate: convertResponseDate(data.targetConstructionStartDate),
    targetCODDate: convertResponseDate(data.targetCODDate),
    projectAcresSize: data.projectAcres,
    projectCapacityAC: data.acCapacity,
    projectCapacityDC: data.dcCapacity,
    constructionSiteCounties: data.countiesInConstructionSite,
    rowCount: data.tableOrRowCount,
    DCRatio: data.dcRatio,
    ACRatio: data.acRatio,
    ROWRequired: data.isRowRequired,
    projectDocumentType: data?.projectDocumentType?.projectDocumentTypeId
  });

  const populateFormValues = projectData => {
    Object.keys(formConfig).forEach((field) => {
      let data = projectData[field]
      if (data !== null && data !== undefined) {
        if (field === 'communitySolar' || field === 'coLocatedProject') {
          data = data === 'No' ? false : true
        }

        setValue(field, data)
      }
    })
  };

  const processProjectData = () => {
    const formattedData = formatProjectData(projectData);
    setProjectProfileData(formattedData);
    populateFormValues(formattedData);
  };

  useEffect(() => {
    if (projectData === null) return;

    processProjectData()
  }, [projectData])


  useEffect(() => {
    onEdit();
    getWorkTypeList();
    getAllProjectDocumentType();
    getClientList();
  }, [])

  return (
    <DetailPageLayout
      backAction={backAction}
      title="ADD PROJECT - "
      subTitle="Fill in the below to add project"
      backLabel="Go back to Projects"
    >
      <div className="partner-profile">
        <ProFormsLayout
          status={status}
          isSaving={isSaving}
          backAction={backAction}
          title={"Add Project"}
          backLabel="Go Back to All Projects"
          beingEdited={true}
          onEdit={onEdit}
          onSave={handleSubmit(onSubmit)}
        >
          <FormSectionWrapper collapsible title={"Existing Published Projects"}>
            <EnverusProjectsTable setResetForm={setResetForm} setEnverusFormData={setEnverusFormData} />
          </FormSectionWrapper>
          {projectInformationNameAndAddressPermission.canView && (
            <FormSectionWrapper collapsible title={"Project Name & Address"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectName}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.projectName.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.internalProjectId}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.internalProjectId.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.address1}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.address1.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.address2}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.address2.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.city}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.city.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.state}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.state.perKey]}
              />
              <ZipField
                {...defaultFormProps}
                {...formConfig.zipCode}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.zipCode.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.primaryCounties}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.primaryCounties.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.country}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.country.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.nercRegion}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.nercRegion.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.isoTerritory}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.isoTerritory.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.lat}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.lat.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.long}
                permission={projectInformationNameAndAddressPermission.fieldPerObj[formConfig.long.perKey]}
              />
            </FormSectionWrapper>
          )}
          {projectOwnerShipPermission.canView && (
            <FormSectionWrapper collapsible title={"Project Ownership (Partners)"}>
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.projectOwner}
                options={clients}
                permission={projectOwnerShipPermission.fieldPerObj[formConfig.projectOwner.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.projectDeveloper}
                options={clients}
                permission={projectOwnerShipPermission.fieldPerObj[formConfig.projectDeveloper.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.projectEPC}
                options={clients}
                permission={projectOwnerShipPermission.fieldPerObj[formConfig.projectEPC.perKey]}
              />
            </FormSectionWrapper>
          )}

          {projectPartnersPermission.canView && (
            <FormSectionWrapper collapsible title={"Project Partners"}>
              <ProjectPartners globalEditMode={globalEditMode} setGlobalEditMode={setGlobalEditMode} addScreenProjectId={projectId} fromAddScreen setPartnerSaved={setPartnerSaved} />
            </FormSectionWrapper>
          )}

          {projectDetailsPermission.canView && (
            <FormSectionWrapper collapsible title={"Project Details"}>
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.workId}
                options={workTypeList}
                permission={projectDetailsPermission.fieldPerObj[formConfig.workId.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.projectKickoff}
                options={projectKickoff}
                permission={projectDetailsPermission.fieldPerObj[formConfig.projectKickoff.perKey]}
              />
              <DateField
                {...defaultFormProps}
                {...formConfig.actualConstructionStartDate}
                permission={projectDetailsPermission.fieldPerObj[formConfig.actualConstructionStartDate.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectType}
                permission={projectDetailsPermission.fieldPerObj[formConfig.projectType.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectStatus}
                permission={projectDetailsPermission.fieldPerObj[formConfig.projectStatus.perKey]}
              />
              <ProTextInput {...defaultFormProps} {...formConfig.eiaId} permission={projectDetailsPermission.fieldPerObj[formConfig.eiaId.perKey]} />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.dockets}
                permission={projectDetailsPermission.fieldPerObj[formConfig.dockets.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.batteryType}
                permission={projectDetailsPermission.fieldPerObj[formConfig.batteryType.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.communitySolar}
                options={communitySolarOptions}
                permission={projectDetailsPermission.fieldPerObj[formConfig.communitySolar.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.coLocatedProject}
                options={coLocatedProjectOptions}
                permission={projectDetailsPermission.fieldPerObj[formConfig.coLocatedProject.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectCapacityMW}
                permission={projectDetailsPermission.fieldPerObj[formConfig.projectCapacityMW.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectClassification}
                permission={projectDetailsPermission.fieldPerObj[formConfig.projectClassification.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.powerPurchaser}
                permission={projectDetailsPermission.fieldPerObj[formConfig.powerPurchaser.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.powerPurchaseAgreementCapacityMW}
                permission={projectDetailsPermission.fieldPerObj[formConfig.powerPurchaseAgreementCapacityMW.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.powerPurchaseAgreementYears}
                permission={projectDetailsPermission.fieldPerObj[formConfig.powerPurchaseAgreementYears.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.supplier}
                permission={projectDetailsPermission.fieldPerObj[formConfig.supplier.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.unitType}
                permission={projectDetailsPermission.fieldPerObj[formConfig.unitType.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.unitSupplied}
                permission={projectDetailsPermission.fieldPerObj[formConfig.unitSupplied.perKey]}
              />
              <DateField
                {...defaultFormProps}
                {...formConfig.firstPowerDate}
                permission={projectDetailsPermission.fieldPerObj[formConfig.firstPowerDate.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.firstPowerYear}
                permission={projectDetailsPermission.fieldPerObj[formConfig.firstPowerYear.perKey]}
              />
              <DateField
                {...defaultFormProps}
                {...formConfig.projectLastUpdatedDate}
                permission={projectDetailsPermission.fieldPerObj[formConfig.projectLastUpdatedDate.perKey]}
              />
            </FormSectionWrapper>
          )}
          {projectDetailsRequiredInfoPermission.canView && (
            <FormSectionWrapper collapsible title={"Project Details (Requested Info)"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectAcresSize}
                permission={projectDetailsRequiredInfoPermission.fieldPerObj[formConfig.projectAcresSize.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.constructionSiteCounties}
                permission={projectDetailsRequiredInfoPermission.fieldPerObj[formConfig.constructionSiteCounties.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.totalConstructionDays}
                permission={projectDetailsRequiredInfoPermission.fieldPerObj[formConfig.totalConstructionDays.perKey]}
              />
              <DateField
                {...defaultFormProps}
                {...formConfig.targetConstructionStartDate}
                permission={projectDetailsRequiredInfoPermission.fieldPerObj[formConfig.targetConstructionStartDate.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectCapacityAC}
                permission={projectDetailsRequiredInfoPermission.fieldPerObj[formConfig.projectCapacityAC.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.projectCapacityDC}
                permission={projectDetailsRequiredInfoPermission.fieldPerObj[formConfig.projectCapacityDC.perKey]}
              />
              <DateField
                {...defaultFormProps}
                {...formConfig.targetCODDate}
                permission={projectDetailsRequiredInfoPermission.fieldPerObj[formConfig.targetCODDate.perKey]}
                shouldDisableDate={handleDateValidation}
              />
              {/* TODO: We need to make this functional when the demo is over + have the permission work */}
              {/* <ProSelectInput
                {...defaultFormProps}
                {...formConfig.documentType}
                options={documentType}
                permission={projectDetailsPermission.fieldPerObj[formConfig.projectKickoff.perKey]}
              /> */}
            </FormSectionWrapper>
          )}
          {projectSiteModuleInfoPermission.canView && (
            <FormSectionWrapper collapsible defaultClose title={"Project Site: Module Info"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.moduleSelected}
                permission={projectSiteModuleInfoPermission.fieldPerObj[formConfig.moduleSelected.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.moduleType}
                permission={projectSiteModuleInfoPermission.fieldPerObj[formConfig.moduleType.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.modulQuantity}
                permission={projectSiteModuleInfoPermission.fieldPerObj[formConfig.modulQuantity.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.stringSizes}
                permission={projectSiteModuleInfoPermission.fieldPerObj[formConfig.stringSizes.perKey]}
              />
            </FormSectionWrapper>
          )}
          {projectSiteStructedInfoPermission.canView && (
            <FormSectionWrapper collapsible defaultClose title={"Project Site: Structure Info"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.structureSelected}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.structureSelected.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.structureManufacturer}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.structureManufacturer.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.rowCount}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.rowCount.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.pileAmount}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.pileAmount.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.pileType}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.pileType.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.pileEmbedmentDepth}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.pileEmbedmentDepth.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.blowMinCountRange}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.blowMinCountRange.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.blowMaxCountRange}
                permission={projectSiteStructedInfoPermission.fieldPerObj[formConfig.blowMaxCountRange.perKey]}
              />
            </FormSectionWrapper>
          )}
          {projectSiteInverterDetailPermission.canView && (
            <FormSectionWrapper collapsible defaultClose title={"Project Site: Inverter Detail"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.inverterSelected}
                permission={projectSiteInverterDetailPermission.fieldPerObj[formConfig.inverterSelected.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.inverterType}
                permission={projectSiteInverterDetailPermission.fieldPerObj[formConfig.inverterType.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.inverterQuantity}
                permission={projectSiteInverterDetailPermission.fieldPerObj[formConfig.inverterQuantity.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.DCRatio}
                permission={projectSiteInverterDetailPermission.fieldPerObj[formConfig.DCRatio.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.ACRatio}
                permission={projectSiteInverterDetailPermission.fieldPerObj[formConfig.ACRatio.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.materialProcurement}
                permission={projectSiteInverterDetailPermission.fieldPerObj[formConfig.materialProcurement.perKey]}
              />
            </FormSectionWrapper>
          )}
          {projectSiteMaterialPermission.canView && (
            <FormSectionWrapper collapsible defaultClose title={"Project Site: Material & Equipment Scheduling Info"}>
              <ProjectScheduling globalEditMode={globalEditMode} setGlobalEditMode={setGlobalEditMode} addScreenProjectId={projectId} projectId={""} fromAddScreen setScheduleSaved={setScheduleSaved} />
            </FormSectionWrapper>
          )}

          {projectSiteInterconnectionInfoPermission.canView && (
            <FormSectionWrapper collapsible defaultClose title={"Project Site: Interconnection Info"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectingUtility}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectingUtility.perKey]}
              />

              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.interconnectionApproval}
                options={interconnectionApproval}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectionApproval.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectionTiming}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectionTiming.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectionVoltage}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectionVoltage.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectPoint}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectPoint.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectDistance}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectDistance.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.ROWRequired}
                options={timeKeeping}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.ROWRequired.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectQueueStatus}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectQueueStatus.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.substationCongestion}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.substationCongestion.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectionUtilityProcess}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectionUtilityProcess.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.interconnectNetworkUpgrades}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.interconnectNetworkUpgrades.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.dataConnectionAvailability}
                permission={projectSiteInterconnectionInfoPermission.fieldPerObj[formConfig.dataConnectionAvailability.perKey]}
              />
            </FormSectionWrapper>
          )}

          {projectSitePermittingAndEntitlementDetailPermission.canView && (
            <FormSectionWrapper collapsible defaultClose title={"Project Site: Permitting & Entitlement Detail"}>
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.permittingAuthority}
                permission={projectSitePermittingAndEntitlementDetailPermission.fieldPerObj[formConfig.permittingAuthority.perKey]}
              />
              <ProTextInput
                {...defaultFormProps}
                {...formConfig.heightLimit}
                permission={projectSitePermittingAndEntitlementDetailPermission.fieldPerObj[formConfig.heightLimit.perKey]}
              />
              <ProSelectInput
                {...defaultFormProps}
                {...formConfig.projectDocumentType}
                options={projectDocumentTypeList}
                permission={projectSitePermittingAndEntitlementDetailPermission.fieldPerObj[formConfig.projectDocumentType.perKey]}
              />
              <FileUploadField
                {...defaultFormProps}
                {...formConfig.projectDocumentFile}
                permission={projectSitePermittingAndEntitlementDetailPermission.fieldPerObj[formConfig.projectDocumentFile.perKey]}
                inputProps={{ accept: "/*" }}
                fieldProps={{
                  onChange: async event => {
                    try {
                      setValue("projectDocumentFile", event);
                      const file = event;
                      setDocumentFileName(file.name);
                      if (!file) {
                        setDocumentUrl("");
                        setDocumentFileName("");
                        return;
                      }
                      const cleanedDataUrl = await convertFileToBase64(file);
                      setDocumentUrl(cleanedDataUrl);
                    } catch (error) {
                      errorToast(error);
                    }
                  }
                }}
              />
              <Grid item xs={12} md={6}>
                {documentPath && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle1">Download File:</Typography>
                    <Typography
                      onClick={handleDownload}
                      sx={{
                        cursor: "pointer",
                        color: "blue"
                      }}
                      variant="body1"
                    >
                      {documentFileName}
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </FormSectionWrapper>
          )}
        </ProFormsLayout>
        <Dialog open={openWarning} onClose={handleWarningClose} aria-describedby="alert-dialog-slide-description-warning">
          <DialogTitle>{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description-warning">
              {`"${projectNameWarning}" project already exists. Please choose a different name for the new project.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ProButton variant="outlined" onClick={handleWarningClose}>
              Close
            </ProButton>
          </DialogActions>
        </Dialog>
      </div>
    </DetailPageLayout>
  );
}