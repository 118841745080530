import React, { useEffect, useState } from "react";
import { HTTPReportS3 } from "./StimulsoftReports";
import { S3_REPORTS_URL } from "../../../utils/constant";
import axios from "axios";

const HTTPReportViewer = ({ fileName }) => {
  const accessToken = localStorage.getItem("accessToken")
  const [reportObject, setReportObject] = useState(null)
  let instance = axios.create({
    headers: {
    },
  });

  const getReport = async () => {
    try {
      const { data } = await instance.get(`${S3_REPORTS_URL}${fileName}`)
      setReportObject(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getReport()
  }, [])

  useEffect(() => {
    if (reportObject) {
      let report = new HTTPReportS3({
        reportFile: reportObject,
        accessToken: accessToken,
      });
      report.viewer.renderHtml("viewer");
    }
  }, [reportObject])

  return <div id="viewer"></div>;
};

export default HTTPReportViewer;