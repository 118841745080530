import React, { useCallback, useEffect, useMemo, useState } from "react";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { useForm } from "react-hook-form";
import { deleteApi, postApi } from "../../../../utils/services";
import {
  ADD_PARTNER_SOCIAL_MEDIA,
  DELETE_PARTNER_SOCIAL_MEDIA,
  GET_ALL_PARTNER_SOCIALMEDIA_LIST,
  GET_ALL_SOCIAL_MEDIA_TYPES,
  GET_PARTNER_SOCIAL_MEDIA,
  UPDATE_PARTNER_SOCIAL_MEDIA
} from "../../../../utils/services/apiPath";
import { useParams } from "react-router-dom";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import WebLinkCell from "../../../Components/ProGrid/components/WebLinkCell";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import usePartnersPermission from "../../../../utils/customHooks/usePartnersPermission";
import { partnersSocialMediaPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../../utils/constant";
import useColumn from "../../../../utils/customHooks/useColumns";
import UrlField from "../../../Components/Inputs/UrlField";
import { PartnerSocialMediaFormConfing } from "../PartnerData";

const requiredFormNames = ["Add Social Media", "Social Media List"];

export default function PartnerSocialMedia() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(PartnerSocialMediaFormConfing));
  const [partnerSocialMediaData, setPartnerSocialMediaData] = useState({});
  const [actionData, setActionData] = useState({});
  const [socialMediaTypesData, setSocialMediaTypesData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: socialMediaData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const { "Add Social Media": addSocialMediaPermission, "Social Media List": socialMediaListPermission } = usePartnersPermission(
    partnersSocialMediaPermissionSelector,
    requiredFormNames
  );

  const columns = useColumn(
    [
      {
        field: "socialMediaName",
        headerName: "Social Media Type",
        renderCell: params => <NameCell params={params} onClick={onSocialMediaClick} />
      },
      {
        field: "link",
        headerName: "Link",
        renderCell: params => <WebLinkCell params={params} />
      },
      {
        field: "clientSocialMediaId",
        headerName: "Actions",
        width: 80,
        isDeleteColumn: true,
        disableExport: true,
        renderCell: params => <DeleteCell title={"Delete Partner Social Media"} params={params} onDelete={onDelete} />
      }
    ],
    socialMediaListPermission?.canDelete
  );

  const { handleSubmit, control, setValue, clearErrors } = useForm({ defaultValues: prepareDefaultValues(PartnerSocialMediaFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const defaultFormProps = { control, formValues: partnerSocialMediaData, handleReadValueClick };

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
    clearErrors();
  };

  const onCancel = () => {
    if (actionData?.clientSocialMediaId) {
      setFormConfig(changeMode("read"));
    } else {
      resetForm();
    }
  };

  const onSocialMediaClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    getPartnerSocialMediaData(params.row);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    let payload = {
      socialMediaId: data?.socialMediaType,
      link: data?.link,
      clientId: id,
      clientSocialMediaId: actionData?.clientSocialMediaId ? actionData?.clientSocialMediaId : null
    };
    const { data: partnerSocialMedia, error } = await postApi(
      actionData?.clientSocialMediaId ? UPDATE_PARTNER_SOCIAL_MEDIA : ADD_PARTNER_SOCIAL_MEDIA,
      payload
    );
    setIsSaving(false);
    if (error) return errorToast(error);
    getPartnetSocialmediaList();
    getPartnerSocialMediaById(partnerSocialMedia?.guidId || actionData?.clientSocialMediaId);
    setShowGrid(false);
    successToast(`Partner Social Media ${actionData?.clientSocialMediaId ? "updated" : "added"} successfully.`);
  };

  const getPartnerSocialMediaById = async id => {
    setFormConfig(changeMode("read"));
    const payload = {
      clientSocialMediaId: id
    };
    const { error, data: clientSocialMediaId } = await postApi(GET_PARTNER_SOCIAL_MEDIA, payload);
    if (error) return errorToast(error);
    const data = {
      socialMediaType: clientSocialMediaId?.socialMediaId,
      link: clientSocialMediaId?.link
    };
    setActionData(clientSocialMediaId);
    setPartnerSocialMediaData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_PARTNER_SOCIAL_MEDIA, { data: [{ clientSocialMediaId: id }] });
    if (error) return errorToast(error);
    getPartnetSocialmediaList();
    setShowGrid(true);
    successToast("Partner Social Media deleted successfully.");
  };

  const getPartnerSocialMediaData = async updateData => {
    const data = {
      socialMediaType: updateData?.socialMediaId,
      link: updateData?.link
    };
    setPartnerSocialMediaData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const getPartnetSocialmediaList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "socialMediaName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PARTNER_SOCIALMEDIA_LIST, payload, { params: { clientId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllSocialMediaType = async () => {
    const { data } = await postApi(GET_ALL_SOCIAL_MEDIA_TYPES, defaultDropdownConfig);
    setSocialMediaTypesData(data.map(item => ({ label: item.name, value: item.socialMediaId })));
  };

  useEffect(() => {
    getPartnetSocialmediaList();
    getAllSocialMediaType();
  }, [getPartnetSocialmediaList]);

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.clientSocialMediaId ? "Edit" : "Add"} Partner Social Media`}
      backLabel="Go back to Partner Sociak Media List"
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      hideEdit={!addSocialMediaPermission?.canEdit}
      canView={socialMediaListPermission?.canView}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Partner Social Media"
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={socialMediaData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Partner Social Media"
          addButtonAction={addButtonAction}
          hideAddButton={!socialMediaListPermission?.canAdd}
          hideExport={!socialMediaListPermission?.canExport}
          options={{
            getRowId: row => row.clientSocialMediaId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      )}
      {!showGrid && (
        <>
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.socialMediaType}
            options={socialMediaTypesData}
            rules={{ required: "Social Media Type is required!" }}
            permission={addSocialMediaPermission.fieldPerObj[formConfig.socialMediaType.perKey]}
          />
          <UrlField
            {...defaultFormProps}
            {...formConfig.link}
            rules={{ required: "Link is required!" }}
            permission={addSocialMediaPermission.fieldPerObj[formConfig.link.perKey]}
          />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
