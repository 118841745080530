import ToolbarBase from "./ToolbarBase";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'
import GridSerch from "../ProGrid/components/GridSerch";
import { GridToolbarExport } from "@mui/x-data-grid-pro";
import ProButton from "../ProButton";

export default function EmployeeListToolbar({
    importExcelAction,
    searchModel
}) {
    const navigation = useNavigate();

    const addButtonAction = () => {
        navigation("/employees/new");
    };

    const handleDownload = () => {
        const filePath = "/static/Images/Excel/EmployeeImportTemplate.xlsx";
        window.open(filePath, "_blank");
    };

    return () => (
        <ToolbarBase title="Employees">
            <div className="proGrid-search-wrapper">
                <GridSerch {...searchModel} />
            </div>
            <div className="proGrid-filter">
                <GridToolbarExport className="export-icon" />
                <ProButton variant="outlined" onClick={handleDownload}>
                    Employee Import Template
                </ProButton>
                <ProButton variant="outlined" onClick={importExcelAction}>
                    Import Excel
                </ProButton>
                <ProButton onClick={addButtonAction}>+ Add Employee</ProButton>
            </div>
        </ToolbarBase>
    );
}

EmployeeListToolbar.propTypes = {
    importExcelAction: PropTypes.func,
    searchModel: PropTypes.object
};