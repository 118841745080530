import React, { useCallback, useEffect } from "react";
import Layout from "../../../Components/Layout";
import ProGrid from "../../../Components/ProGrid/ProGrid";
import useColumn from "../../../../utils/customHooks/useColumns";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import { successToast, errorToast } from "../../../../utils/toastHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../../utils/constant";
import { ADMIN_RAP_INFO } from "../../../../utils/services/apiPath";
import { deleteApi, getApi } from "../../../../utils/services";
import { createODataQuery } from "../../../../utils/services/oData";
import StandardToolbar from "../../../Components/Toolbars/StandardToolbar";

const getRedirectUrl = params => {
  return `/apprentice-180/rap/info/${params.id}`;
};

export default function RapList() {
  const {
    currentPage,
    pageSize,
    data: rapData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const columns = useColumn(
    [
      {
        field: "approvedJobTitle",
        headerName: "Approved Job Title",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "industryType",
        headerName: "Industry"
      },
      {
        field: "category",
        headerName: "Category"
      },
      {
        field: "rapidCode",
        headerName: "RAPIDS Code"
      },
      {
        field: "programLengthYears",
        headerName: "Program Length"
      },
      {
        field: "rapInfoId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell title={"Delete Rap Info"} params={params} onDelete={onDelete} disabled={params?.row?.readOnly} />
      }
    ],
    true
  );

  const onDelete = async id => {
    const { error } = await deleteApi(`${ADMIN_RAP_INFO}/${id}`);
    if (error) return errorToast(error);
    successToast("Rap Info deleted successfully.");
    getRapInfoList();
  };

  const getRapInfoList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const searchableFields = [
      "approvedJobTitle",
      "industryType",
      "rapidCode",
      "category",
    ];
    const { data, totalRecords, error } = await getApi(ADMIN_RAP_INFO + createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage));
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getRapInfoList();
  }, [getRapInfoList]);

  return (
    <Layout>
      <ProGrid
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.rapInfoId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
        columns={columns}
        rows={rapData}
        searchMode={!!searchString}
        toolbar={
          StandardToolbar({
            toolbarTitle: "Rap Info",
            addButtonUrl: "/apprentice-180/rap/info/new",
            addButtonTitle: "+ Add Rap Info",
            searchModel: { defaultValue: searchString, handleDebounce: setSearchString }
          })
        }
      />
    </Layout>
  );
}