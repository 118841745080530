import { createTheme } from "@mui/material/styles";
import logo from "../Assets/Icons/logo.png";
import logoWhite from "../Assets/Icons/logo-white.png";
import card1 from "../Assets/Icons/card2.png";
import card2 from "../Assets/Icons/card1-alt.png";
import card3 from "../Assets/Icons/card3.png";
import card4 from "../Assets/Icons/card4.png";
import card5 from "../Assets/Icons/card5.png";
import darkCard from "../Assets/Icons/dark-card.png";
import dashboardcard1 from "../Assets/Icons/dashboard-card1.png";
import dashboardcard2 from "../Assets/Icons/dashboard-card2.png";
import dashboardcard3 from "../Assets/Icons/dashboard-card3.png";
import dashboardcard4 from "../Assets/Icons/dashboard-card4.png";
import dashboardcard5 from "../Assets/Icons/dashboard-card5.png";
import dashboardcard6 from "../Assets/Icons/dashboard-card6.png";
import dashboardcard7 from "../Assets/Icons/dashboard-card7.png";
import dashboardcard8 from "../Assets/Icons/dashboard-card8.png";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#fff",
      chip: "rgba(0,0,0,0.08)"
    },
    primary: {
      main: "#1976d2"
    },
    text: {
      chip: "#1976d2",
      title: "#006EC0"
    },
    subheaderv2: {
      icons: "#fff",
      menuLabel: "black"
    },
    compliancenavigator: {
      complianceinsight: {
        border: "#D6DDEA",
        bg1: "#F7F7F7",
        bg2: "#FFDCDC",
        color: "#D70000"
      },
      basetable: {
        toolbarBaseBackground: "#F7F7F7"
      },
      baseuploadmodal: {
        boxBackground: "#EAF6FF",
        boxBorder: "#9F9494",
        typographyColor: "#015899",
        buttonColor: "#8B8B8B",
        buttonBorder: "#A7A7A7"
      },
      compliancesummary: {
        listItemBorder: "#D6DDEA",
        summaryBorder: "#D6DDEA",
        summaryBackground: "#F7F7F7",
        summaryJsonBackground: "#DBFFCA",
        summaryJsonColor: "#007405"
      }
    },
    projects: {
      certifiedpayroll: {
        formLabelColor: "#rgba(0, 0, 0, 0.6)",
        optionsColor: "#000000"
      },
      projectjobs: {
        boxAlertBackground: "#FFDCDC",
        boxAlertColor: "#D70000",
        alertIconColor: "#CB0000"
      }
    },
    progrid: {
      pagination: "#000000",
      cellbuttonborder: "",
      cellbuttonbackgroundcolor: "",
      cellbuttoncolor: "#FF0000",
      celladdiconcolor: "#FF0000"
    },
    propreloginlayout: {
      logo: logo
    },
    rapmanagement: {
      wagescale: {
        callStyle: "#00050B"
      }
    },
    demo: {
      heatmapcolor: "#000000",
      heatmapbackgroundcolor: "#ffffff",
      lostotjcolor: "#615E83",
      lostotjbackgroundcolor: "#FFFFFF",
      lostotjtopcard1color: "#1F88E6",
      lostotjtopcard1bg: card1,
      lostotjtopcard2color: "#BF0000",
      lostotjtopcard2bg: card2,
      lostotjtopcard3color: "#3D7101",
      lostotjtopcard3bg: card3,
      lostotjtopcard4color: "#D68D00",
      lostotjtopcard4bg: card4,
      lostotjtopcard5color: "#5041FF",
      lostotjtopcard5bg: card5,
      rtireportcolor: "#615E83",
      rtireportbackgroundcolor: "#FFFFFF",
      rtireporttopcard1color: "#1F88E6",
      rtireporttopcard1bg: card1,
      rtireporttopcard2color: "#BF0000",
      rtireporttopcard2bg: card2,
      rtireporttopcard3color: "#3D7101",
      rtireporttopcard3bg: card3,
      rtireporttopcard4color: "#D68D00",
      rtireporttopcard4bg: card4,
      rtireporttopcard5color: "#5041FF",
      rtireporttopcard5bg: card5,
      weeklyreportcolor: "#615E83",
      weeklyreportbackgroundcolor: "#FFFFFF",
      weeklyreporttopcard1color: "#1F88E6",
      weeklyreporttopcard1bg: card1,
      weeklyreporttopcard2color: "#BF0000",
      weeklyreporttopcard2bg: card2,
      weeklyreporttopcard3color: "#3D7101",
      weeklyreporttopcard3bg: card3,
      weeklyreporttopcard4color: "#D68D00",
      weeklyreporttopcard4bg: card4,
      weeklyreporttopcard5color: "#5041FF",
      weeklyreporttopcard5bg: card5,
      dashboardjounerytoapprenticecard1color: "#0AB3A9",
      dashboardjounerytoapprenticecard1image: dashboardcard1,
      dashboardjounerytoapprenticecard2color: "#606060",
      dashboardjounerytoapprenticecard2image: dashboardcard2,
      dashboardjounerytoapprenticecard3color: "#3D7101",
      dashboardjounerytoapprenticecard3image: dashboardcard3,
      dashboardjounerytoapprenticecard4color: "#D68D00",
      dashboardjounerytoapprenticecard4image: dashboardcard4,
      dashboardjounerytoapprenticecard5color: "#008CAC",
      dashboardjounerytoapprenticecard5image: dashboardcard5,
      dashboardjounerytoapprenticecard6color: "#000673",
      dashboardjounerytoapprenticecard6image: dashboardcard6,
      dashboardjounerytoapprenticecard7color: "#00437F",
      dashboardjounerytoapprenticecard7image: dashboardcard7,
      dashboardjounerytoapprenticecard8color: "#E77C00",
      dashboardjounerytoapprenticecard8image: dashboardcard8,
      progridtablefill: "#000000"
    },
    inputs: {
      urlField: {
        urlIconFill: "#00050B"
      },
      toggleButton: {
        cellStyles: "#000000"
      },
      radioGroup: {
        cellStyles: "#535353"
      },
      mobileField: {
        mobileIconFill: "#00050B"
      },
      fileUploadField: {
        selectedColor: "#00050B"
      },
      emailField: {
        emailIconFill: "#00050B"
      },
      checkBoxGroup: {
        cellStyles: "#535353"
      }
    },
    pages: {
      validateotp: {
        prologincolor: "#000000",
        prologinbgcolor: "#ffffff"
      },
      forgetpassword: {
        bgcolor: "#ffffff"
      },
      login: {
        color: "#ffffff"
      },
      resetpassword: {
        color: "#000000"
      },
      resetpassword: {
        bgcolor: "#ffffff"
      }
    }
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#121212",
      chip: "rgba(0,0,0,0.08)"
    },
    primary: {
      main: "#bb86fc"
    },
    text: {
      chip: "#1976d2",
      title: "#006EC0"
    },
    subheaderv2: {
      icons: "#fff",
      menuLabel: "black"
    },
    compliancenavigator: {
      complianceinsight: {
        border: "#D6DDEA",
        bg1: "#F7F7F7",
        bg2: "#FFDCDC",
        color: "#D70000"
      },
      basetable: {
        toolbarBaseBackground: "#F7F7F7"
      },
      baseuploadmodal: {
        boxBackground: "#EAF6FF",
        boxBorder: "#9F9494",
        typographyColor: "#015899",
        buttonColor: "#8B8B8B",
        buttonBorder: "#A7A7A7"
      },
      compliancesummary: {
        listItemBorder: "#D6DDEA",
        summaryBorder: "#D6DDEA",
        summaryBackground: "#F7F7F7",
        summaryJsonBackground: "#DBFFCA",
        summaryJsonColor: "#007405"
      }
    },
    projects: {
      certifiedpayroll: {
        formLabelColor: "#ffffff",
        optionsColor: "#ffffff"
      },
      ProjectJobs: {
        boxAlertBackground: "#FFDCDC",
        boxAlertColor: "#D70000",
        alertIconColor: "#CB0000"
      }
    },
    progrid: {
      pagination: "#ffffff",
      cellbuttonborder: "none",
      cellbuttonbackgroundcolor: "#00B0EE",
      cellbuttoncolor: "#15171C",
      elladdiconcolor: "#15171C"
    },
    propreloginlayout: {
      logo: logoWhite
    },
    rapmanagement: {
      wagescale: {
        callStyle: "#ffffff"
      }
    },
    demo: {
      heatmapcolor: "#ffffff",
      heatmapbackgroundcolor: "#2E2E48",
      lostotjcolor: "#FFFFFF",
      lostotjbackgroundcolor: "#2E2E48",
      lostotjtopcard1color: "#2E2E48",
      lostotjtopcard1bg: darkCard,
      lostotjtopcard2color: "#2E2E48",
      lostotjtopcard2bg: darkCard,
      lostotjtopcard3color: "#2E2E48",
      lostotjtopcard3bg: darkCard,
      lostotjtopcard4color: "#2E2E48",
      lostotjtopcard4bg: darkCard,
      lostotjtopcard5color: "#2E2E48",
      lostotjtopcard5bg: darkCard,
      rtireportcolor: "#FFFFFF",
      rtireportbackgroundcolor: "#2E2E48",
      rtireporttopcard1color: "#2E2E48",
      rtireporttopcard1bg: darkCard,
      rtireporttopcard2color: "#2E2E48",
      rtireporttopcard2bg: darkCard,
      rtireporttopcard3color: "#2E2E48",
      rtireporttopcard3bg: darkCard,
      rtireporttopcard4color: "#2E2E48",
      rtireporttopcard4bg: darkCard,
      rtireporttopcard5color: "#2E2E48",
      rtireporttopcard5bg: darkCard,
      rtireportcolor: "#FFFFFF",
      weeklyreportbackgroundcolor: "#2E2E48",
      weeklyreporttopcard1color: "#2E2E48",
      weeklyreporttopcard1bg: darkCard,
      weeklyreporttopcard2color: "#2E2E48",
      weeklyreporttopcard2bg: darkCard,
      weeklyreporttopcard3color: "#2E2E48",
      weeklyreporttopcard3bg: darkCard,
      weeklyreporttopcard4color: "#2E2E48",
      weeklyreporttopcard4bg: darkCard,
      weeklyreporttopcard5color: "#2E2E48",
      weeklyreporttopcard5bg: darkCard,
      dashboardjounerytoapprenticecard1color: "#2E2E48",
      dashboardjounerytoapprenticecard1image: darkCard,
      dashboardjounerytoapprenticecard2color: "#2E2E48",
      dashboardjounerytoapprenticecard2image: darkCard,
      dashboardjounerytoapprenticecard3color: "#2E2E48",
      dashboardjounerytoapprenticecard3image: darkCard,
      dashboardjounerytoapprenticecard4color: "#2E2E48",
      dashboardjounerytoapprenticecard4image: darkCard,
      dashboardjounerytoapprenticecard5color: "#2E2E48",
      dashboardjounerytoapprenticecard5image: darkCard,
      dashboardjounerytoapprenticecard6color: "#2E2E48",
      dashboardjounerytoapprenticecard6image: darkCard,
      dashboardjounerytoapprenticecard7color: "#2E2E48",
      dashboardjounerytoapprenticecard7image: darkCard,
      dashboardjounerytoapprenticecard8color: "#2E2E48",
      dashboardjounerytoapprenticecard8image: darkCard,
      progridtablefill: "#ffffff"
    },
    inputs: {
      urlField: {
        urlIconFill: "#ffffff"
      },
      toggleButton: {
        cellStyles: "#ffffff"
      },
      radioGroup: {
        cellStyles: "#B5B7C8"
      },
      mobileField: {
        mobileIconFill: "#ffffff"
      },
      fileUploadField: {
        selectedColor: "#ffffff"
      },
      emailField: {
        emailIconFill: "#00050B"
      },
      checkBoxGroup: {
        cellStyles: "#B5B7C8"
      }
    },
    pages: {
      validateotp: {
        prologincolor: "#ffffff",
        prologinbgcolor: "#202328"
      },
      forgetpassword: {
        bgcolor: "#202328"
      },
      login: {
        color: "#707070"
      },
      resetpassword: {
        color: "#ffffff"
      },
      resetpassword: {
        bgcolor: "#202328"
      }
    }
  }
});
