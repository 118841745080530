import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailPageLayout from "../../../Components/Layout/DetailPageLayout";
import Layout from "../../../Components/Layout";
import EmployeeProfile from "./EmployeeProfile";
import ESignature from "./ESignature";
import EmployeeNotes from "./EmployeeNotes";
import EmployeeSocialMedia from "./EmployeeSocialMedia";
import EmployeeTimeSheets from "./EmployeeTimeSheets";
import Documents from "./Documents";
import ProfileIcon from "../../../Assets/IconComponents/ProfileIcon";
import ContactIcon from "../../../Assets/IconComponents/ContactIcon";
import { GET_EMPLOYEE } from "../../../../utils/services/apiPath";
import { errorToast } from "../../../../utils/toastHelper";
import { postApi } from "../../../../utils/services";
import { employeesPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import { useSelector } from "react-redux";
import RtiTracker from "../routes/[id]/apprenticeDetail/RtiTracker";
import CourseEnrollment from "../routes/[id]/apprenticeDetail/CourseEnrollment";

export default function EmployeeDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const isAddMode = useMemo(() => id === "add", [id]);
  const [employeeSubTitle, setEmployeeSubTitle] = useState("");
  const [apprentice, setApprentice] = useState(false)
  const { subModulePermissions } = useSelector(employeesPermissionSelector);
  const profilePermission = subModulePermissions?.EMPLOYEES_PROFILE?.canView;
  const documentPermission = subModulePermissions?.EMPLOYEES_DOCUMENT?.canView;
  const notesPermission = subModulePermissions?.EMPLOYEES_NOTES?.canView;
  const socialMediaPermission = subModulePermissions?.EMPLOYEES_SOCIAL_MEDIA?.canView;
  const timeSheetPermission = subModulePermissions?.EMPLOYEES_TIME_SHEET?.canView;
  const tabs = isAddMode
    ? [
      {
        to: "/profile",
        title: "Profile",
        key: "profile",
        isActive: false,
        hasArrow: false,
        icon: ProfileIcon,
        canView: profilePermission
      }
    ]
    : [
      {
        to: "/profile",
        title: "Profile",
        key: "profile",
        isActive: false,
        hasArrow: false,
        icon: ProfileIcon,
        canView: profilePermission
      },
      {
        to: "/esignature",
        title: "E Signature",
        key: "esignature",
        isActive: false,
        hasArrow: false,
        icon: ContactIcon,
        canView: true
      },
      {
        to: "/documents",
        title: "Documents",
        key: "documents",
        isActive: false,
        hasArrow: false,
        icon: ContactIcon,
        canView: documentPermission
      },
      {
        to: "/notes",
        title: "Notes",
        key: "notes",
        isActive: false,
        hasArrow: false,
        icon: ContactIcon,
        canView: notesPermission
      },
      {
        to: "/socialmedia",
        title: "Social Media",
        key: "socialmedia",
        isActive: false,
        hasArrow: false,
        icon: ContactIcon,
        canView: socialMediaPermission
      },
      {
        to: "/timesheets",
        title: "Timesheets",
        key: "timesheets",
        isActive: false,
        hasArrow: false,
        icon: ContactIcon,
        canView: timeSheetPermission
      },
      apprentice ? {
        to: "/rtitracker",
        title: "RTI Tracker",
        key: "rtitracker",
        isActive: false,
        hasArrow: false,
        icon: ContactIcon,
        canView: socialMediaPermission
      } : {},
      apprentice ? {
        to: "/courseenrollment",
        title: "Course Enrollment",
        key: "courseenrollment",
        isActive: false,
        hasArrow: false,
        icon: ContactIcon,
        canView: socialMediaPermission
      } : {},
    ];

  const backAction = () => {
    navigate("/employees");
  };

  const handleTabClick = tab => {
    navigate(`/employees/${id}${tab.to}`);
  };

  const getEmployeeProfileData = async id => {
    const payload = {
      employeeId: id
    };
    const { error, data: employee } = await postApi(GET_EMPLOYEE, payload);
    if (error) return errorToast(error);
    if (employee.employeeTypeId === 1) {
      setApprentice(true)
    }
    setEmployeeSubTitle(`${employee?.employeePKId} - ${employee?.firstName} ${employee?.lastName}`);
  };

  useEffect(() => {
    if (!isAddMode) getEmployeeProfileData(id);
  }, [id]);

  // Tab refresh relies on the apprentice
  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab?.toLowerCase())?.title;
    setActiveTab(selectedTab || "Profile");
  }, [tab, apprentice]);

  return (
    <Layout>
      <DetailPageLayout
        backAction={backAction}
        tabs={tabs.filter(tab => tab.canView)}
        title={isAddMode ? "ADD EMPLOYEE" : "EMPLOYEE INFORMATION"}
        subTitle={isAddMode ? "" : employeeSubTitle}
        backLabel="Go back to Employee"
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      >
        {activeTab === "Profile" && profilePermission && <EmployeeProfile />}
        {activeTab === "E Signature" && <ESignature />}
        {activeTab === "Documents" && <Documents />}
        {activeTab === "Notes" && <EmployeeNotes />}
        {activeTab === "Social Media" && <EmployeeSocialMedia />}
        {activeTab === "Timesheets" && <EmployeeTimeSheets />}
        {activeTab === "RTI Tracker" && <RtiTracker />}
        {activeTab === "Course Enrollment" && <CourseEnrollment />}
      </DetailPageLayout>
    </Layout>
  );
}
