import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { DataGridPro, GridPagination } from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";
import "./index.scss";
import { Box, useTheme } from "@mui/material";
import "../../GlobalStyles/variables.scss";
import "../../GlobalStyles/mixins.scss";

function Pagination({ page, onPageChange, className, count, rowsPerPage }) {
  const pageCount = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);
  const theme = useTheme();
  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      sx={{
        "& .MuiPaginationItem-page": {
          color: theme.palette.progrid.pagination
        },
        "& .MuiPaginationItem-page.Mui-selected": {
          color: "white"
        }
      }}
    />
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function CustomNoRowsOverlay() {
  return (
    <div className="proGrid-no-rows">
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </div>
  );
}
export default function ProGrid({
  columns = [],
  toolbar,
  rows = [],
  loading = false,
  options = {},
  searchMode,
  filterPanel,
  hideIfNoData = false,
  slotProps,
  getRowClassName = ""
}) {
  const proColumns = columns.map(column => ({
    headerClassName: "proGrid-header",
    ...(!column.width && { flex: 1 }),
    ...column
  }));

  function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  if (hideIfNoData && !rows.length) return null;

  return (
    <div className="rs-grid-wrapper v2">
      <DataGridPro
        columnHeaderHeight={44}
        rowHeight={44}
        columns={proColumns}
        rows={rows}
        loading={loading}
        classes={{
          cell: "proGrid-table-cell"
        }}
        sortingOrder={["desc", "asc"]}
        slots={{
          toolbar: toolbar,
          pagination: CustomPagination,
          noRowsOverlay: CustomNoRowsOverlay,
          filterPanel: filterPanel
        }}
        getRowClassName={getRowClassName}
        slotProps={slotProps}
        pagination={rows.length > 0}
        {...options}
      />
    </div>
  );
}

ProGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  toolbar: PropTypes.object,
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  options: PropTypes.object,
  searchMode: PropTypes.bool,
  hideIfNoData: PropTypes.bool
};
