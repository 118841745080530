import React from "react";
import RtiReportIcon from "../../Assets/IconComponents/RtiReportIcon";
import DashboardCard from "../Components/DashboardCard/DashboardCard";
import twentyFour from "../../Assets/Icons/24.png";
import seven from "../../Assets/Icons/7.png";
import application from "../../Assets/Icons/application.png";
import document from "../../Assets/Icons/document.png";
import calender from "../../Assets/Icons/calendar.png";
import Slider from "react-slick";
import CardHeader from "../Components/CardHeader/CardHeader";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function TopCards({ defaultFormProps, formConfig, typeOptions, setValue, rtiReport, cardTitleButton, apprenticeDetails }) {
  const theme = useTheme();
  const RtiReportData = [
    {
      icon: application,
      colorClass: theme.palette.demo.rtireporttopcard1color,
      value: `${Math.round(rtiReport?.remainingRTI) || 0} Hours`,
      label: "Remaining RTI",
      bg: theme.palette.demo.rtireporttopcard1bg
    },
    {
      icon: twentyFour,
      colorClass: theme.palette.demo.rtireporttopcard2color,
      value: `${Math.round(rtiReport?.totalRTI) || 0} Hours`,
      label: "Total RTI (Cumulative)",
      bg: theme.palette.demo.rtireporttopcard2bg
    },
    {
      icon: document,
      colorClass: theme.palette.demo.rtireporttopcard3color,
      value: `${Math.round(rtiReport?.currentWeekRTIHours) || 0} Hours`,
      label: "RTI Hours (Current Week)",
      bg: theme.palette.demo.rtireporttopcard3bg
    },
    {
      icon: calender,
      colorClass: theme.palette.demo.rtireporttopcard4color,
      value: `${Math.round(rtiReport?.monthToDateRTIHours) || 0} Hours`,
      label: "MTD (Month To Date) RTI",
      bg: theme.palette.demo.rtireporttopcard4bg
    },
    {
      icon: seven,
      colorClass: theme.palette.demo.rtireporttopcard5color,
      value: `${Math.round(rtiReport?.remainingRTIWeek) || 0} Hours`,
      label: "Remaining RTI (Week)",
      bg: theme.palette.demo.rtireporttopcard5bg
    }
  ];

  return (
    <>
      <CardHeader
        formProps={defaultFormProps}
        formConfig={formConfig}
        typeOptions={typeOptions}
        buttonLabel={`Total Apprentice ${apprenticeDetails?.length}`}
        buttonIcon={<RtiReportIcon />}
        buttonColor="#1f88e6"
        cardStyle={{ marginTop: "20px" }}
        boxStyle={{ display: "flex", justifyContent: "space-between" }}
        buttonBoxStyle={{ display: "flex", justifyContent: "flex-end", width: "30%" }}
        isBtn={true}
        setValue={setValue}
        title={"RTI Report"}
        cardTitleButton={cardTitleButton}
      />
      <div style={{ marginBottom: "20px", maxWidth: "100%" }}>
        <Slider {...settings}>
          {RtiReportData?.map((data, ind) => (
            <div key={data?.value}>
              <DashboardCard
                title={data?.value}
                value={data?.label}
                color={data?.colorClass}
                icon={data?.icon}
                bg={data?.bg}
                ind={ind}
                length={RtiReportData.length - 1}
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

TopCards.propTypes = {
  defaultFormProps: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  typeOptions: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  rtiReport: PropTypes.object.isRequired
};
