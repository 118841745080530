import React, { useState, useEffect, useRef }  from 'react'
import DashBoardTable from '../ProGridTable/DashBoardTable';
import { getApi } from '../../../../utils/services';
import { GET_PROJECT_DOCUMENTATION_RISK_GAUGE_DATA } from '../../../../utils/services/apiPath';
import { errorToast } from '../../../../utils/toastHelper';

const ProjectDocumentationRisKTable = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const hasFetchedData = useRef(false);
    const columns = [
        { field: "project", headerName: "Project"},
        { field: "company", headerName: "Partner"},
        { field: "totalAndExpectedCPRCount", headerName: "# of CPRs/Expected Number of CPRs"},
        { field: "totalAndExpected671Count", headerName: "# of 671/Expected Number of 671"},
        { field: "totalAndExpectedDavisBaconFormsCount", headerName: "# of Davis Bacon Forms/Expected Number of Davis Bacon Forms"},
      ];
    
      const fetchProjectRiskData = async () => {
        setLoading(true);
        try {
          const { data, error } = await getApi(GET_PROJECT_DOCUMENTATION_RISK_GAUGE_DATA);
          if (error) { throw new Error(error);}
  
          const rowsWithId = data.map((item, index) => ({
            ...item,
            id: item.projectId || `fallback-id-${index}`,
          }));
          setRows(rowsWithId);
        }  
        finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        if (!hasFetchedData.current) {
          hasFetchedData.current = true;
          fetchProjectRiskData();
        }
      }, []);
  
      return (
    <div style={{ marginTop: "20px" }}>
    <DashBoardTable
      columns={columns}
      rows={rows}
      getRowId={(row) => row.projectId}
      loading={loading}
      paginationModel={{ page: 0, pageSize: 5 }}
      hideExport={false}
      checkboxSelection={false}
    />
  </div>
  )
}

export default ProjectDocumentationRisKTable