import React from "react";
import ProTextInput from "./TextField";
import { InputAdornment, useTheme } from "@mui/material";
import EmailIcon from "../../Assets/IconComponents/EmailIcon";
import { EMAIL_REG } from "../../../utils/validationExpression";
import ReadOnlyInput from "./ReadOnlyInput";
import PropTypes from "prop-types";

export default function EmailField({ rules, mode, isAddMode, ...rest }) {
  const theme = useTheme();
  const { canView = false, canAdd = false, canEdit = false } = rest.permission || {};
  if (!canView || (isAddMode && !canAdd)) return null;
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  if (mode === "read" || (mode === "edit" && !editModeAllowed)) return <ReadOnlyInput {...rest} />;
  const validateEmails = (emails) => {
    const emailArray = emails.split(',').map(email => email.trim());
    for (let email of emailArray) {
      if (!EMAIL_REG.test(email)) {
        return false;
      }
    }
    return true;
  };
  return (
    <ProTextInput
    {...rest}
    rules={{
      ...(rules || {}),
      validate: {
        multipleEmails: (value) => validateEmails(value) || "Invalid email(s)"
      }
    }}
    startAdornment={
      <InputAdornment position="start">
        <EmailIcon fill={theme.palette.inputs.emailField.emailIconFill} />
      </InputAdornment>
    }
  />
  );
}

EmailField.propTypes = {
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  rules: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool
};
