import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

HighchartsHeatmap(Highcharts);

const tooltipFormatter = function (heatMapData) {
  return `
    ${heatMapData.companyName[this.point.y]}
    <br/>
    ${heatMapData.formattedDates[this.point.x]}: <b>${this.point.value}</b>`;
};

export default function HeatMapByPartner({ heatMapData, onPointSelect }) {
  const theme = useTheme();
  const [options, setOptions] = useState({
    chart: {
      type: "heatmap",
      marginTop: 40,
      marginBottom: 60,
      backgroundColor: theme.palette.demo.heatmapbackgroundcolor
    },
    title: {
      text: null
    },
    xAxis: {
      categories: [],
      labels: {
        style: {
          color: theme.palette.demo.heatmapcolor,
          fontSize: "10px"
        }
      }
    },
    yAxis: {
      categories: [],
      labels: {
        style: {
          color: theme.palette.demo.heatmapcolor,
          fontSize: "10px"
        }
      },
      title: null
    },
    colorAxis: {
      min: 0,
      max: 40,
      reversed: false,
      stops: [
        [0, "#00437F"],
        [0.33, "#0086FF"],
        [0.56, "#83C5FF"],
        [0.68, "#DCDEDF"],
        [0.8, "#DCDEDF"],
        [1, "#F4F1F1"]
      ]
    },
    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280
    },
    series: [],
    credits: {
      enabled: false
    }
  });

  useEffect(() => {
    if (heatMapData) {
      setOptions(options => ({
        ...options,
        chart: {
          ...options.chart,
          backgroundColor: theme.palette.demo.heatmapbackgroundcolor
        },
        xAxis: {
          ...options.xAxis,
          categories: heatMapData?.formattedDates,
          labels: {
            style: {
              color: theme.palette.demo.heatmapcolor,
              fontSize: "10px"
            }
          }
        },
        yAxis: {
          ...options.yAxis,
          categories: heatMapData?.companyName,
          labels: {
            style: {
              color: theme.palette.demo.heatmapcolor,
              fontSize: "10px"
            }
          }
        },
        tooltip: {
          formatter: function () {
            return tooltipFormatter.call(this, heatMapData);
          }
        },
        series: [
          {
            borderWidth: 1,
            borderColor: "#fff",
            data: heatMapData?.seriesData,
            dataLabels: {
              enabled: true,
              style: {
                textOutline: "none"
              }
            },
            allowPointSelect: true,
            point: {
              events: {
                select: function () {
                  onPointSelect(this);
                }
              }
            }
          }
        ]
      }));
    }
  }, [heatMapData, theme]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

HeatMapByPartner.propTypes = {
  heatMapData: PropTypes.shape({
    formattedDates: PropTypes.arrayOf(PropTypes.string).isRequired,
    companyName: PropTypes.arrayOf(PropTypes.string).isRequired,
    seriesData: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired
      })
    ).isRequired
  }).isRequired,
  onPointSelect: PropTypes.func.isRequired
};
