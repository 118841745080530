import React, { useCallback, useEffect } from "react";
import Layout from "../../../Components/Layout";
import ProGrid from "../../../Components/ProGrid/v2";
import useColumn from "../../../../utils/customHooks/useColumns";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import { useNavigate } from "react-router-dom";
import usePageState from "../../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../../utils/constant";
import { ADMIN_SPONSOR_INFO } from "../../../../utils/services/apiPath";
import { deleteApi, getApi } from "../../../../utils/services";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { createODataQuery } from "../../../../utils/services/oData";

const getRedirectUrl = params => {
  return `/settings/sponsor-info/${params.id}`;
};

export default function SponsorList() {
  const {
    currentPage,
    pageSize,
    data: sponsorInfoListData,
    rowCount,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const navigate = useNavigate();
  const columns = useColumn([
    {
      field: "companyName",
      headerName: "Company Name",
      renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
    },
    {
      field: "contactPhoneNumber",
      headerName: "Contact"
    },
    {
      field: "email",
      headerName: "Email"
    },
    {
      field: "sponsorInformationId",
      headerName: "Actions",
      isDeleteColumn: true,
      disableExport: true,
      width: 80,
      renderCell: params => <DeleteCell params={params} onDelete={onDelete} disabled={params?.row?.readOnly} />
    }
  ]);

  const getSponsorInfoList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const searchableFields = [
      "companyName",
      "contactPhoneNumber",
      "email"
    ]
    const { data, totalRecords, error } = await getApi(ADMIN_SPONSOR_INFO + createODataQuery(searchableFields, null, "", searchString, sortModel, pageSize, currentPage))
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const onDelete = async id => {
    const { error } = await deleteApi(`${ADMIN_SPONSOR_INFO}/${id}`)
    if (error) return errorToast(error);
    successToast("Sponsor's Info deleted successfully");
    getSponsorInfoList();
  };

  useEffect(() => {
    getSponsorInfoList();
  }, [getSponsorInfoList]);

  const addButtonAction = () => {
    navigate(`/settings/sponsor-info/add`);
  };

  return (
    <Layout>
      <ProGrid
        title="Sponsor Information"
        columns={columns}
        rows={sponsorInfoListData}
        addButtonLabel="+ Add Sponsor Info"
        addButtonAction={addButtonAction}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        options={{
          getRowId: row => row.sponsorInformationId,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
