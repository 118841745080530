import React, { useState, useEffect, useRef } from "react";
import DashBoardTable from "../ProGridTable/DashBoardTable";
import { getApi } from '../../../../utils/services'
import { GET_PROJECT_BELOW_15_DATA } from "../../../../utils/services/apiPath";

const formatDate = (dateString) => {
  if (!dateString) return "";
  return new Date(dateString).toISOString().split("T")[0];
};

const ProjectHoursTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const hasFetchedData = useRef(false);
  const columns = [
    { field: "projectName", headerName: "Project Name"},
    { field: "projectStartDate", headerName: "Project Start Date"},
    { field: "projectEndDate", headerName: "Project End Date"},
    { field: "otjHours", headerName: "OTJ Hours"},
    { field: "totalHours", headerName: "Labor Hours"}
  ];

  const fetchProjectHoursTable = async () => {
    setLoading(true);
    try {
      const { data, error } = await getApi(GET_PROJECT_BELOW_15_DATA);
      if (error) {
        throw new Error(error);
      }

      const rowsWithId = data.map((item, index) => ({
        id: `row-${index}`,
        projectName: item.projectName,
        projectStartDate: formatDate(item.projectStartDate),
        projectEndDate: formatDate(item.projectEndDate),
        otjHours: item.otjHours,
        totalHours: item.totalHours,
      }));

      setRows(rowsWithId);
    } 
    catch (error) {} 
    finally {setLoading(false);}
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      hasFetchedData.current = true;
      fetchProjectHoursTable();
    }
  }, []);

  return (
    <div style={{ margin: "20px" }}>
      <DashBoardTable
        title=""
        columns={columns}
        rows={rows}
        loading={loading}
        paginationModel={{ page: 0, pageSize: 5 }}
        hideExport={false}
        checkboxSelection={false}
      />
    </div>
  );
};

export default ProjectHoursTable;
