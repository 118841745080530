import React from "react";
import { Stack, useTheme } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import PropTypes from "prop-types";

const SummaryListItem = ({ details }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        border: `1px solid ${theme.palette.compliancenavigator.compliancesummary.listItemBorder}`,
        borderRadius: 5,
        margin: "5px 10px 10px 10px",
        padding: 10
      }}
    >
      <div>{details}</div>
    </div>
  );
};

SummaryListItem.propTypes = {
  details: PropTypes.string
};

export default function ComplianceSummary({ summaryJson, cardCount, projectCount }) {
  const theme = useTheme();
  return (
    <div
      style={{
        border: theme.palette.compliancenavigator.compliancesummary.summaryBorder,
        height: "100%"
      }}
    >
      <div style={{ backgroundColor: theme.palette.compliancenavigator.compliancesummary.summaryBackground, paddingBottom: 5, marginBottom: 10 }}>
        <div style={{ margin: "0px 10px 10px 15px", paddingTop: 10 }}>
          <h4>Compliance Summary</h4>
        </div>
        {summaryJson && (
          <div
            style={{
              backgroundColor: theme.palette.compliancenavigator.compliancesummary.summaryJsonBackground,
              padding: 5,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              color: theme.palette.compliancenavigator.compliancesummary.summaryJsonColor,
              margin: "0px 15px 15px 15px"
            }}
          >
            {projectCount > 1 ? (
              <>
                <CheckCircle color="success" />
                <span style={{ marginLeft: 5 }}>{`${summaryJson?.completedCount ?? 0} / ${cardCount} Items are completed`}</span>
              </>
            ) : (
              <span style={{ marginLeft: 5 }}>Awaiting project creation</span>
            )}
          </div>
        )}
      </div>
      <Stack spacing={2}>
        {projectCount > 1 ? (
          summaryJson?.summaryList?.map((summary, index) => <SummaryListItem key={Number(index)} details={summary} />)
        ) : (
          <SummaryListItem key={1} details={"No project created. Compliance tracking will start once a project is set up"} />
        )}
      </Stack>
    </div>
  );
}

ComplianceSummary.propTypes = {
  summaryJson: PropTypes.shape({
    completedCount: PropTypes.number,
    total: PropTypes.number,
    summaryList: PropTypes.arrayOf(PropTypes.string)
  })
};
