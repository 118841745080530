import React, { useState, useEffect, useRef } from 'react'
import DashBoardTable from '../ProGridTable/DashBoardTable';
import { getApi } from '../../../../utils/services'
import { GET_PARTNER_RISK_GAUGE_DATA } from '../../../../utils/services/apiPath'


const PartnerRisk = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const hasFetchedData = useRef(false);
    const columns = [
        { field: "projectName", headerName: "Project"},
        { field: "partnerName", headerName: "Partner Name"},
        { field: "documentationRiskLevel", headerName: "Documentation Risk Level"},
        { field: "prevailingWageRiskLevel", headerName: "Prevailing Wage Risk Level"},
        { field: "journeyToApprenticeRiskLevel", headerName: "J to A Risk Level"},
      ];
    
    const fetchPartnerRiskData = async () => {
        setLoading(true);
        try {
          const { data, error } = await getApi(GET_PARTNER_RISK_GAUGE_DATA);
          if (error) { throw new Error(error);}
  
          const rowsWithId = data.map((item, index) => ({
            ...item,
            id: item.projectId || `fallback-id-${index}`,
          }));
          setRows(rowsWithId);
        }  
        finally {
          setLoading(false);
        }
    };  
    useEffect(() => {
      if (!hasFetchedData.current) {
        hasFetchedData.current = true;
        fetchPartnerRiskData();
      }
    }, []);
  return (
   <div style={{ marginTop: "20px" }}>
      <DashBoardTable
        columns={columns}
        rows={rows}
        loading={loading}
        paginationModel={{ page: 0, pageSize: 5 }}
        hideExport={false}
        checkboxSelection={false}
      />
    </div>
  )
}

export default PartnerRisk