import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useNavigate, createSearchParams } from "react-router-dom";

export default function JourneyToApprenticeChart({ chartData, projectGuid }) {
  const navigation = useNavigate();
  const [options, setOptions] = useState({
    chart: {
      backgroundColor: "transparent",
      height: 150
    },
    title: {
      text: null
    },
    xAxis: {
      categories: [],
      labels: {
        style: {
          color: "white",
          fontSize: "6px"
        }
      },
      lineColor: "white"
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPositions: [0, 50, 100],
      labels: {
        style: {
          color: "white",
          fontSize: "10px"
        },
        formatter: function () {
          const valueLabels = { 0: "Low", 50: "Med", 100: "High" };
          return valueLabels[this.value] || this.value;
        }
      },
      tickColor: "white",
      title: {
        text: ""
      },
      gridLineColor: "white",
      lineColor: "white"
    },
    tooltip: {
      formatter: function () {
        return `
          ${this.point.category}
          <br/>
          <b>${this.point.y}</b>`;
      }
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [
      {
        type: "area",
        data: [],
        color: "white",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(255, 255, 255, 0.5)"], // Start with solid white
            [1, "rgba(255, 255, 255, 0.33)"] // Fade to transparent
          ]
        }
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    }
  });

  // Update options when chartData changes
  useEffect(() => {
    if (chartData) {
      setOptions(prevOptions => ({
        ...prevOptions,
        xAxis: {
          ...prevOptions.xAxis,
          categories: chartData.xAxisData
        },
        series: [
          {
            ...prevOptions.series[0],
            data: chartData.seriesData,
            allowPointSelect: true,
            events: {
              click: function (e) {
                const date = e.point.category;
                navigation({
                  pathname: `/projects/${projectGuid}/wps-time-allocation`,
                  search: createSearchParams({
                    date: date
                  }).toString()
                });
              }
            }
          }
        ]
      }));
    }
  }, [chartData, projectGuid, navigation]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

JourneyToApprenticeChart.propTypes = {
  chartData: PropTypes.shape({
    xAxisData: PropTypes.arrayOf(PropTypes.string).isRequired,
    seriesData: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired,
  projectGuid: PropTypes.string.isRequired
};
