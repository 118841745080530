import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Card } from "@mui/material";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { fieldTruePermission } from "../../../../utils/formHelper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import InputWrapper from "../../../Components/Inputs/InputWrapper";
import dayjs from "dayjs";

export default function CardHeader({
  formProps,
  formConfig,
  typeOptions,
  buttonLabel,
  buttonIcon,
  buttonColor,
  cardStyle,
  boxStyle,
  isBtn,
  setValue,
  title,
  cardTitleButton
}) {
  const handleChange = date => {
    const [from, to] = date;
    setValue("dateRange", [from, to]);
  };
  return (
    <div>
      <div className="cardTitle-wrapper">
        <div className="cardTitle" style={{ paddingTop: "16px", fontSize: "28px", fontWeight: "bold" }}>
          {title}
        </div>
        {cardTitleButton}
      </div>
      <Card className="cardHeader" sx={{ marginTop: "16px", ...cardStyle }}>
        <Box
          p={2}
          pl={3}
          pr={3}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            ...boxStyle
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: { xs: "100%", md: "80%" },
              gap: { xs: 2, md: 3 }
            }}
          >
            {Object.keys(formConfig)?.map((key, index) => (
              <Box
                key={Number(index)}
                sx={{
                  width: { xs: "100%", md: "22%" },
                  mb: { xs: 2, md: 0 },
                  "&:last-child": {
                    width: { xs: "100%", md: "35%" }
                  }
                }}
              >
                {key?.includes("dateRange") ? (
                  <InputWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["SingleInputDateRangeField"]}>
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          name="allowedRange"
                          {...formProps}
                          {...formConfig[key]}
                          defaultValue={[dayjs().subtract(16, "day"), dayjs().subtract(1, "day")]}
                          onChange={value => handleChange(value)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </InputWrapper>
                ) : (
                  <Box
                    key={Number(index)}
                    sx={{
                      pt: { xs: 1, md: 1 }
                    }}
                  >
                    <ProSelectField
                      {...formProps}
                      value={typeOptions[key]?.[0] ?? ""}
                      {...formConfig[key]}
                      options={typeOptions[key] ?? []}
                      permission={fieldTruePermission}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>

          {isBtn && (
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                width: { xs: "100%", md: "auto" },
                alignItems: "center",
                flexShrink: 0,
                ml: { xs: 0, md: 2 },
                mt: { xs: 2, md: 0 }
              }}
            >
              <Button
                sx={{
                  background: buttonColor,
                  width: "auto",
                  "&:hover": {
                    background: buttonColor
                  }
                }}
              >
                {buttonIcon}&nbsp;<span style={{ color: "#FFFFFF" }}>{buttonLabel}</span>
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    </div>
  );
}

CardHeader.propTypes = {
  formProps: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  typeOptions: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    )
  ).isRequired,
  buttonLabel: PropTypes.string,
  buttonIcon: PropTypes.element,
  buttonColor: PropTypes.string,
  cardStyle: PropTypes.object,
  boxStyle: PropTypes.object,
  isBtn: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

CardHeader.defaultProps = {
  buttonIcon: null,
  buttonColor: "#1f88e6",
  cardStyle: {},
  boxStyle: {},
  isBtn: false
};
