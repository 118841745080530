import { useState } from "react";

const useFormData = (initialFormData) => {
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (key) => (e) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const handleDateChange = (key) => (newDate) => {
    setFormData({ ...formData, [key]: newDate });
  };

  return {
    formData,
    handleChange,
    handleDateChange,
    setFormData
  };
};

export default useFormData;
