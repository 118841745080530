import React from "react";
import PropTypes from "prop-types";
import ProCheckbox from "../../../Components/Inputs/CheckboxField";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import ProToggleField from "../../../Components/Inputs/ToggleButton";
import ProButton from "../../../Components/ProButton";
import { Grid, Divider, Typography, Link } from "@mui/material";
import InputWrapper from "../../../Components/Inputs/InputWrapper";
import { payTypePositionOption } from "../../../../utils/dropdownHelper";
import FreeSoloAutoComplete from "../../../Components/Inputs/FreeSoloAutoComplete";
import ProCurrencyField from "../../../Components/Inputs/CurrencyField";

const ProjectJobsForm = ({
  formConfig,
  defaultFormProps,
  jobTitle,
  addJobPermisison,
  assignRAPInfoGuids,
  assignRAPWageScaleGuids,
  setValue,
  wageRate,
  watch,
  jobSearchOptions,
  handleOpen,
  isEditModeOn,
  getPWCFilePath,
  getJobSearchOption,
  isFetchLabels,
  isNoRecordOnClassification,
  handleOpenClr,
  clrInfoMessage,
  jobTitlePW
}) => {
  const isCraftTrade = watch("IsCraftTradeLaborPosition");
  const isExternalJobTitle = watch("isExternalJobTitle");
  const payTypePosition = watch("payTypePosition");
  const isApprenticeable = watch("isApprenticeable");
  const externalJobTitle = watch("externalJobTitle");
  const jobTitleId = watch("jobTitleId");
  const footnoteSickDays = watch("footnoteSickDays");
  const footnoteVacationDays = watch("footnoteVacationDays");
  const footnoteHolidays = watch("footnoteHolidays");
  const footnoteOthers = watch("footnoteOthers");
  const SAMFilename = watch("samFileName");
  const SAMFilenamePath = watch("samFilePath");
  const isAdministrative = watch("IsRole");
  const typeOfJob = watch("typeOfJob");
  const jobDescription = watch("jobDescription");

  const getJobTitleLabel = jobTitleId => {
    const jobTitleObj = jobTitle.find(item => item.value === jobTitleId);
    return jobTitleObj?.label;
  };

  const getApprenticeTitle = () => {
    if (isExternalJobTitle && externalJobTitle) return `${externalJobTitle} Apprentice`;
    else if (jobTitleId) return `${getJobTitleLabel(jobTitleId)} Apprentice`;
    return "";
  };
  return (
    <>
      <ProToggleField
        {...defaultFormProps}
        {...formConfig.isExternalJobTitle}
        permission={addJobPermisison.fieldPerObj[formConfig.isExternalJobTitle.perKey]}
      />
      {isExternalJobTitle ? (
        <ProTextInput
          {...defaultFormProps}
          {...formConfig.externalJobTitle}
          permission={addJobPermisison.fieldPerObj[formConfig.externalJobTitle.perKey]}
        />
      ) : (
        <ProSelectField
          {...defaultFormProps}
          {...formConfig.jobTitleId}
          options={jobTitle}
          permission={addJobPermisison.fieldPerObj[formConfig.jobTitleId.perKey]}
        />
      )}
      <ProTextInput
        {...defaultFormProps}
        {...formConfig.jobInternalId}
        permission={addJobPermisison.fieldPerObj[formConfig.jobInternalId.perKey]}
      />
      {/* <InputWrapper size={4}></InputWrapper> */}
      <ProTextInput
        {...defaultFormProps}
        {...formConfig.jobDescription}
        fieldProps={{ onBlur: getJobSearchOption, multiline: true, rows: 4 }}
        permission={addJobPermisison.fieldPerObj[formConfig.jobDescription.perKey]}
      />
      <FreeSoloAutoComplete
        {...defaultFormProps}
        {...formConfig.typeOfJob}
        permission={addJobPermisison.fieldPerObj[formConfig.typeOfJob.perKey]}
        options={jobSearchOptions}
        withLoaderIcon={isFetchLabels}
        infoMessage={clrInfoMessage}
      />
      <ProTextInput
        {...defaultFormProps}
        {...formConfig.numberOfPositions}
        permission={addJobPermisison.fieldPerObj[formConfig.numberOfPositions.perKey]}
      />

      <ProSelectField
        {...defaultFormProps}
        {...formConfig.payTypePosition}
        options={payTypePositionOption}
        permission={addJobPermisison.fieldPerObj[formConfig.payTypePosition.perKey]}
      />

      <ProCurrencyField
        {...defaultFormProps}
        {...formConfig.perDiem}
        permission={addJobPermisison.fieldPerObj[formConfig.perDiem.perKey]}
        fieldProps={{
          onBlur: e => {
            if (e.target.value) setValue("perDiem", parseFloat(e.target.value).toFixed(2));
          }
        }}
      />
      {payTypePosition == "Salary" ? (
        <ProCurrencyField
          {...defaultFormProps}
          {...formConfig.salaryAmount}
          permission={addJobPermisison.fieldPerObj[formConfig.salaryAmount.perKey]}
          fieldProps={{
            onBlur: e => {
              if (e.target.value) {
                setValue("salaryAmount", parseFloat(e.target.value).toFixed(2));
                setValue("actualWageRate", (e.target.value / 2080).toFixed(2));
              }
            }
          }}
        />
      ) : (
        <Grid md={4} xs={12} />
      )}
      <ProToggleField
        {...defaultFormProps}
        {...formConfig.IsCraftTradeLaborPosition}
        permission={addJobPermisison.fieldPerObj[formConfig.IsCraftTradeLaborPosition.perKey]}
      />
      <ProToggleField
        {...defaultFormProps}
        {...formConfig.IsRole}
        fieldProps={{ disabled: true }}
        permission={addJobPermisison.fieldPerObj[formConfig.IsRole.perKey]}
      />
      <InputWrapper size={4}></InputWrapper>
      {isEditModeOn && (
        <>
          <Grid item xs={3} md={1} mt={2} mb={2}>
            <ProButton
              isSaving={isNoRecordOnClassification || isAdministrative || typeOfJob === null || typeOfJob?.length === 0}
              variant="contained"
              onClick={() => {
                handleOpen();
              }}
            >
              Process
            </ProButton>
          </Grid>
          {/* <Grid item xs={6} md={2} mt={2} mb={2}>
            <ProButton
              variant="contained"
              isSaving={!jobDescription || !jobTitlePW || (typeOfJob && typeOfJob?.length > 0)}
              onClick={() => {
                handleOpenClr();
              }}
            >
              Initiate CLR
            </ProButton>
          </Grid> */}
        </>
      )}
      <Grid item xs={12} md={12} mt={2} mb={2}>
        <Divider />
      </Grid>
      {isCraftTrade && wageRate !== null && (
        <>
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.pwAiConfidenceMatchScore}
            fieldProps={{ disabled: true }}
            permission={addJobPermisison.fieldPerObj[formConfig.pwAiConfidenceMatchScore.perKey]}
          />
          <ProCurrencyField
            {...defaultFormProps}
            {...formConfig.wageRate}
            fieldProps={{ disabled: true }}
            permission={addJobPermisison.fieldPerObj[formConfig.wageRate.perKey]}
          />
          <ProCurrencyField
            {...defaultFormProps}
            {...formConfig.fringe}
            fieldProps={{ disabled: true }}
            permission={addJobPermisison.fieldPerObj[formConfig.fringe.perKey]}
          />
          <ProCurrencyField
            {...defaultFormProps}
            {...formConfig.pwTotalHourlyComp}
            fieldProps={{ disabled: true }}
            permission={addJobPermisison.fieldPerObj[formConfig.pwTotalHourlyComp.perKey]}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.generalDecisionNumber}
            fieldProps={{ disabled: true }}
            permission={addJobPermisison.fieldPerObj[formConfig.pwTotalHourlyComp.perKey]}
          />
        </>
      )}

      <ProCurrencyField
        {...defaultFormProps}
        {...formConfig.actualWageRate}
        permission={addJobPermisison.fieldPerObj[formConfig.actualWageRate.perKey]}
        fieldProps={{
          onBlur: e => {
            if (e.target.value) setValue("actualWageRate", parseFloat(e.target.value).toFixed(2));
          }
        }}
      />

      <ProCurrencyField
        {...defaultFormProps}
        {...formConfig.actualFringe}
        permission={addJobPermisison.fieldPerObj[formConfig.actualFringe.perKey]}
        fieldProps={{
          onBlur: e => {
            if (e.target.value) setValue("actualFringe", parseFloat(e.target.value).toFixed(2));
          }
        }}
      />

      <ProCurrencyField
        {...defaultFormProps}
        {...formConfig.actualTotalHourlyComp}
        fieldProps={{ disabled: true }}
        permission={addJobPermisison.fieldPerObj[formConfig.actualTotalHourlyComp.perKey]}
      />
      <Grid item xs={12} md={12}>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            getPWCFilePath(SAMFilenamePath);
          }}
        >
          {SAMFilename}
        </Link>
      </Grid>

      {(footnoteSickDays || footnoteVacationDays || footnoteHolidays || footnoteOthers) && (
        <Grid item xs={12} md={12} mt={2}>
          <Typography variant="h7" fontWeight="400">
            Footnotes
          </Typography>
          <Divider />
        </Grid>
      )}
      {footnoteSickDays && (
        <ProTextInput
          {...defaultFormProps}
          {...formConfig.footnoteSickDays}
          permission={addJobPermisison.fieldPerObj[formConfig.pwTotalHourlyComp.perKey]}
        />
      )}
      {footnoteVacationDays && (
        <ProTextInput
          {...defaultFormProps}
          {...formConfig.footnoteVacationDays}
          permission={addJobPermisison.fieldPerObj[formConfig.pwTotalHourlyComp.perKey]}
        />
      )}
      {footnoteHolidays && (
        <ProTextInput
          {...defaultFormProps}
          {...formConfig.footnoteHolidays}
          permission={addJobPermisison.fieldPerObj[formConfig.pwTotalHourlyComp.perKey]}
        />
      )}
      {footnoteOthers && (
        <ProTextInput
          {...defaultFormProps}
          {...formConfig.footnoteOthers}
          permission={addJobPermisison.fieldPerObj[formConfig.pwTotalHourlyComp.perKey]}
        />
      )}
      <Grid item xs={12} md={12} mt={2}>
        <Divider />
      </Grid>

      <Grid item xs={12} md={6}>
        <ProCheckbox
          {...defaultFormProps}
          {...formConfig.isApprenticeable}
          disabled={isAdministrative}
          permission={addJobPermisison.fieldPerObj[formConfig.isApprenticeable.perKey]}
        />
      </Grid>

      {isApprenticeable && (
        <>
          <Grid item xs={12} md={6}>
            <Typography className="project-job-text" variant="h6" fontWeight="400">
              {getApprenticeTitle()}
            </Typography>
          </Grid>

          <ProSelectField
            {...defaultFormProps}
            {...formConfig.assignRAPInfoGuid}
            permission={addJobPermisison.fieldPerObj[formConfig.assignRAPInfoGuid.perKey]}
            options={assignRAPInfoGuids}
          />
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.assignRAPWageScaleGuid}
            options={assignRAPWageScaleGuids}
            permission={addJobPermisison.fieldPerObj[formConfig.assignRAPWageScaleGuid.perKey]}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.occupationTitle}
            fieldProps={{ disabled: true }}
            permission={addJobPermisison.fieldPerObj[formConfig.occupationTitle.perKey]}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.onetCode}
            fieldProps={{ disabled: true }}
            permission={addJobPermisison.fieldPerObj[formConfig.onetCode.perKey]}
          />
        </>
      )}
    </>
  );
};

export default ProjectJobsForm;

ProjectJobsForm.propTypes = {
  isApprenticeable: PropTypes.bool.isRequired,
  getApprenticeTitle: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  defaultFormProps: PropTypes.object.isRequired,
  formConfig: PropTypes.shape({
    assignRAPInfoGuid: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    assignRAPWageScaleGuid: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    generalDecisionNumber: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    occupationTitle: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    onetCode: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    isExternalJobTitle: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    externalJobTitle: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    jobTitleId: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    numberOfPositions: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    jobDescription: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    payTypePosition: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    perDiem: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    IsCraftTradeLaborPosition: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    IsRole: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    salaryAmount: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    marketRate: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    pwAiConfidenceMatchScore: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    wageRate: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    fringe: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    pwTotalHourlyComp: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    actualWageRate: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    actualFringe: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    actualTotalHourlyComp: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    typeOfJob: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    isApprenticeable: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    footnoteSickDays: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    footnoteVacationDays: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    footnoteOthers: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired,
    footnoteHolidays: PropTypes.shape({
      perKey: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  addJobPermisison: PropTypes.shape({
    fieldPerObj: PropTypes.objectOf(
      PropTypes.shape({
        perKey: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  assignRAPInfoGuids: PropTypes.array.isRequired,
  assignRAPWageScaleGuids: PropTypes.array.isRequired,
  isExternalJobTitle: PropTypes.bool.isRequired,
  jobTitle: PropTypes.array.isRequired,
  payTypePosition: PropTypes.string.isRequired,
  payTypePositionOption: PropTypes.array.isRequired,
  isCraftTrade: PropTypes.bool.isRequired,
  chatBotRates: PropTypes.any,
  wsPayload: PropTypes.shape({
    state: PropTypes.any,
    jobLongitude: PropTypes.any,
    county: PropTypes.any,
    city: PropTypes.any,
    jobLatitude: PropTypes.any
  }).isRequired,
  watch: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  jobSearchOptions: PropTypes.array.isRequired,
  wageRate: PropTypes.string.isRequired
};
