// auth
export const AUTH_LOGIN = "/Authenticate/ClientUserLogin/";
export const IDENTITY_LOGIN = "/Authenticate/IdentityLogin/";
export const IDENTITY_LOGIN_V2 = "[V2]Authenticate/IdentityLogin/";
export const AUTH_FORGOT_PASS = "/Authenticate/ClientUserForgotPassword/";
export const AUTH_VERIFY_LINK = "/Authenticate/ValidateClientUserResetUrl/";
export const AUTH_VALIDATE_OTP = "/Authenticate/ValidateOTP/";
export const AUTH_RESET_PASSWORD = "/Authenticate/ResetClientUserPassword/";
export const GET_BUILD_VERSION = "/Authenticate/GetClientBuildVersion";

// permissions
export const GET_ALL_PERMISSIONS = "/Permission/GetUserPermissionList/";
export const GET_ALL_PERMISSIONS_ADMIN = "/Permission/GetUserPermissionListForAdmin/";
export const UPDATE_ALL_PERMISSIONS_ADMIN = "/Permission/UpdateUserPermissions/";

// jobTitle
export const GET_ALL_JOB_TITLE = "/JobCategory/GetAllJobTitle";
export const ADD_JOB_TITLE = "/JobCategory/AddJobTitle";
export const UPDATE_JOB_TITLE = "/JobCategory/UpdateJobTitle";
export const DELETE_JOB_TITLE = "/JobCategory/DeleteJobTitle";
export const GET_JOB_TITLE_ID = "/JobCategory/GetJobTitle";

// leadsubsource
export const GET_ALL_LEAD_SUBSOURCE = "/LeadSubSourceType/GetAllLeadSubSourceType";
export const ADD_LEAD_SUBSOURCE = "/LeadSubSourceType/AddLeadSubSourceType";
export const UPDATE_LEAD_SUBSOURCE = "/LeadSubSourceType/UpdateLeadSubSourceType";
export const DELETE_LEAD_SUBSOURCE = "/LeadSubSourceType/DeleteLeadSubSourceType";
export const GET_LEAD_SUBSOURCE_ID = "/LeadSubSourceType/GetLeadSubSourceType";

// jobCategory
export const GET_ALL_JOB_CATEGORY = "/JobCategory/GetAllJobCategory";
export const ADD_JOB_CATEGORY = "/JobCategory/AddJobCategory";
export const UPDATE_JOB_CATEGORY = "/JobCategory/UpdateJobCategory";
export const DELETE_JOB_CATEGORY = "/JobCategory/DeleteJobCategory";
export const GET_JOB_CATEGORY_ID = "/JobCategory/GetJobCategory";

// users
export const GET_ALL_USERS = "User/GetClientUsers";
export const ADD_USER = "/User/AddClientUser";
export const UPDATE_USER = "/User/UpdateClientUser";
export const DELETE_USER = "/User/DeleteClientUser";
export const GET_USER = "/User/GetClientUserByGuid";
export const GET_USER_ROLE_DROPDOWN = "/DropDown/GetRoleTypeDropDown";
export const GET_ALL_ROLES = "/DropDown/GetRoleTypeDropDown/";
export const VERIFY_ACCOUNT = "/User/VerifyAccount";

// employees
export const GET_ALL_EMPLOYEES = "/ProScoreEmployee/GetAllProScoreEmployees";
export const GET_ALL_EMPLOYEES_V2 = "[V2]/employees";
export const DELETE_EMPLOYEE = "/ProScoreEmployee/DeleteProScoreEmployee";
export const GET_ALL_DOCUMENTS = "/ProScoreEmployee/GetAllDocumentByEmployee?";
export const GET_ALL_EMPLOYEE_DOCUMENTS = "/EmployeeDocument/GetAllEmployeeDocuments?";
export const GET_EMPLOYEE_DOCUMENTS_BY_ID = "/EmployeeDocument/GetEmployeeDocument";
export const DELETE_EMPLOYEE_DOCUMENT = "/EmployeeDocument/DeleteEmployeeDocument";
export const GET_ALL_EMPLOYEE_DOCUMENT_TYPE = "/EmploymentDocumentType/GetAllEmploymentDocumentTypes";
export const ADD_EMPLOYEE_DOCUMENT = "/EmployeeDocument/AddEmployeeDocument";
export const UPDATE_EMPLOYEE_DOCUMENT = "/EmployeeDocument/UpdateEmployeeDocument";
export const ADD_EMPLOYEE = "/ProScoreEmployee/AddProScoreEmployee";
export const SEND_APPRENTICE_APPLICATION_DOCUMENT = "/ProScoreEmployee/SendApprenticeApplicationDocument";
export const UPDATE_EMPLOYEE = "/ProScoreEmployee/UpdateProScoreEmployee";
export const GET_EMPLOYEE = "/ProScoreEmployee/GetProScoreEmployee";
export const UPLOAD_EXCEL_EMPLOYEES = "/ExcelManagement/UploadExcelEmployees";

export const GET_ALL_NOTES = "/EmployeeNote/GetAllEmployeeNotes?";
export const ADD_EMPLOYEE_NOTE = "/EmployeeNote/AddEmployeeNote";
export const UPDATE_EMPLOYEE_NOTE = "/EmployeeNote/UpdateEmployeeNote";
export const DELETE_EMPLOYEE_NOTE = "/EmployeeNote/DeleteEmployeeNote";
export const GET_NOTES = "/EmployeeNote/GetEmployeeNote";

export const GET_ALL_SOCIALMEDIA = "/ProScoreEmployeeSocialMedia/GetAllProScoreEmployeeSocialMedia?";
export const ADD_EMPLOYEE_SOCIALMEDIA = "/ProScoreEmployeeSocialMedia/AddProScoreEmployeeSocialMedia";
export const UPDATE_EMPLOYEE_SOCIALMEDIA = "/ProScoreEmployeeSocialMedia/UpdateProScoreEmployeeSocialMedia";
export const DELETE_EMPLOYEE_SOCIALMEDIA = "/ProScoreEmployeeSocialMedia/DeleteProScoreEmployeeSocialMedia";
export const GET_EMPLOYEE_SOCIALMEDIA = "/ProScoreEmployeeSocialMedia/GetProScoreEmployeeSocialMedia";

export const GET_ALL_TIMESHEETS = "/EmployeeTimesheet/GetEmployeeDailyTimeSheetDetails?";

//employees dropdown
export const GET_ALL_NOTE_TYPES = "/NoteType/GetAllNoteTypes";
export const GET_ALL_SOCIAL_MEDIA_TYPES = "/SocialMedia/GetAllSocialMediaByClient";

export const GET_ALL_LANGUAGE_TYPES = "/LanguageType/GetAllLanguageType";
export const GET_ALL_MANAGER_TYPES = "/DropDown/GetAllEmployees";
export const GET_ALL_NOTIFICATION_TYPES = "/NotificationType/GetAllNotificationType";
export const GET_ALL_EMPLOYEE_STATUS_TYPES = "/EmployeeStatusType/GetAllEmployeeStatusType";
export const GET_ALL_EMPLOYEE_TERM = "/EmploymentTermType/GetAllEmploymentTermType";
export const GET_ALL_FRINGE_PAYMENT_METHOD = "/FringePaymentMethodType/GetAllFringePaymentMethodType";
export const GET_ALL_ETHNICITY_TYPES = "/DropDown/GetAllEthnicityTypes";
export const GET_ALL_VETERAN_STATUS_TYPES = "/DropDown/GetAllVeteranStatusTypes";
export const GET_ALL_RACE_TYPES = "/DropDown/GetAllRaceTypes";
export const GET_ALL_EDUCATION_LEVEL_TYPES = "/DropDown/GetAllEducationLevelTypes";
export const GET_ALL_DISABILITY_TYPES = "/DropDown/GetAllDisabilityTypes";
export const GET_ALL_RAP_INFO = "/DropDown/GetAllRAPInfo";
export const GET_ALL_APPRENTICE_STATUS_TYPES = "/DropDown/GetAllApprenticeStatusTypes";
export const GET_ALL_CAREER_CONNECTION_TYPES = "/DropDown/GetAllCareerConnectionTypes";

// rap mgmt
export const GET_ALL_RAPMGMT_LIST = "/RAP/GetAllAssignRAP";
export const GET_ALL_RAPAPPRENTICE_LIST = "/Rap/GetAllEmployeeApprenticeshipByAssignRapInfoId";
export const GET_ALL_RAPSTATUS_LIST = "/Rap/GetRAPStatusDetail";
export const GET_ALL_RAPSTATUS_LIST_SECTION = "/Rap/GetRapCardsTopSectionDetail";
export const GET_ALL_RAPWAGESCALE_LIST = "Rap/GetAllAssignRAPWageScale";
export const DELETE_RAPWAGESCALE_LIST = "/Rap/DeleteAssignRAPWageScale";
export const GET_ALL_WAGE_RATE_DETAILS = "/Rap/GetAllAssignRAPWageScaleDetail";

// partnerNote
export const GET_ALL_PARTNER_NOTE_LIST = "ClientNote/GetAllClientNotes";
export const ADD_PARTNER_NOTE = "ClientNote/AddClientNote";
export const GET_PARTNER_NOTE = "ClientNote/GetClientNote";

//partnerSocialMedia
export const GET_ALL_PARTNER_SOCIALMEDIA_LIST = "ClientSocialMedia/GetAllClientSocialMedia";
export const ADD_PARTNER_SOCIAL_MEDIA = "ClientSocialMedia/AddClientSocialMedia";
export const UPDATE_PARTNER_SOCIAL_MEDIA = "ClientSocialMedia/UpdateClientSocialMedia";
export const DELETE_PARTNER_SOCIAL_MEDIA = "/ClientSocialMedia/DeleteClientSocialMedia";
export const GET_PARTNER_SOCIAL_MEDIA = "ClientSocialMedia/GetClientSocialMedia";

export const UPDATE_PARTNER_NOTE = "ClientNote/UpdateClientNote";
export const DELETE_PARTNER_NOTE = "/ClientNote/DeleteClientNote";

//partnerLocation
export const GET_ALL_PARTNER_LOCATION_LIST = "ClientLocation/GetAllClientLocations";
export const ADD_PARTNER_LOCATION = "ClientLocation/AddClientLocation";
export const UPDATE_PARTNER_LOCATION = "ClientLocation/UpdateClientLocation";
export const DELETE_PARTNER_LOCATION = "/ClientLocation/DeleteClientLocation";
export const GET_PARTNER_LOCATION = "ClientLocation/GetClientLocation";

// Partners
export const GET_ALL_PARTNERS = "/ProScoreClient/GetAllProScoreClients/";
export const ADD_PARTNER = "/ProScoreClient/AddProScoreClient/";
export const UPDATE_PARTNER = "/ProScoreClient/UpdateProScoreClient/";
export const GET_PARTNER_BY_ID = "/ProScoreClient/GetProScoreClient/";
export const DELETE_PARTNER = "/ProScoreClient/DeleteProScoreClient/";
export const GET_PARTNER_COMPANY_NAME = "/ProScoreClient/CheckProScoreClientCompanyName/";
export const GET_ALL_PARTNERS_TYPES = "ClientType/GetAllClientTypes";

// PartnerContacts
export const GET_ALL_PARTNERS_CONTACTS = "/ClientContact/GetAllClientContacts";
export const GET_PARTNERS_CONTACT_BY_ID = "/ClientContact/GetClientContact/";
export const ADD_PARTNERS_CONTACT = "/ClientContact/AddClientContact/";
export const UPDATE_PARTNERS_CONTACTS = "/ClientContact/UpdateClientContact/";
export const DELETE_PARTNERS_CONTACT = "/ClientContact/DeleteClientContact/";
export const GET_PARTNER_CONTACT = "ClientContact/GetClientContact";

// PartnerContactsDocument
export const GET_PARTNERS_CONTACT_DOCUMENT_BY_ID = "/api/ClientContact/GetClientContactDocument";
export const GET_ALL_PARTNERS_CONTACTS_DOCUMENTS = "/ClientContact/GetAllClientContactDocuments";
export const ADD_PARTNERS_CONTACT_DOCUMENT = "/ClientContact/AddClientContactDocument";
export const UPDATE_PARTNERS_CONTACT_DOCUMENT = "/ClientContact/UpdateClientContactDocument";
export const DELETE_PARTNERS_CONTACT_DOCUMENT = "/ClientContact/DeleteClientContactDocument";
export const GET_PARTNER_DOCUMENT = "ClientDocument/GetClientDocument";

// PartnerContactsNote
export const GET_PARTNERS_CONTACT_NOTE_BY_ID = "/ClientContact/GetClientContactNote";
export const GET_ALL_PARTNERS_CONTACTS_NOTES = "/ClientContact/GetAllClientContactNotes";
export const ADD_PARTNERS_CONTACT_NOTE = "/ClientContact/AddClientContactNote";
export const UPDATE_PARTNERS_CONTACT_NOTE = "/ClientContact/UpdateClientContactNote";
export const DELETE_PARTNERS_CONTACT_NOTE = "/ClientContact/DeleteClientContactNote";

// PartnerDocument
export const GET_PARTNERS_DOCUMENT_BY_ID = "/ClientDocument/GetClientDocument";
export const GET_ALL_PARTNERS_DOCUMENTS = "/ClientDocument/GetAllClientDocuments";
export const ADD_PARTNERS_DOCUMENT = "/ClientDocument/AddClientDocument";
export const UPDATE_PARTNERS_DOCUMENT = "/ClientDocument/UpdateClientDocument";
export const DELETE_PARTNERS_DOCUMENT = "/ClientDocument/DeleteClientDocument";

// ContactType
export const GET_ALL_CONTACT_TYPES = "/ContactType/GetAllContactTypes";
export const GET_ALL_DOCUMENTS_TYPES = "/ClientDocumentType/GetAllClientDocumentTypes";
export const GET_CONTACT_TYPE_BY_ID = "/ContactType/GetContactType/";
export const ADD_CONTACT_TYPE = "/ContactType/AddContactType/";
export const UPDATE_CONTACTS_TYPE = "/ContactType/UpdateContactType/";
export const DELETE_CONTACT_TYPE = "/ContactType/DeleteContactType/";

//PartnerProfile
export const GET_ALL_PARTNER_PROFILE = "/ProScore/GetAllProScoreClient";
export const ADD_PARTNER_PROFILE = "/ProScoreClient/AddProScoreClient";
export const UPDATE_PARTNER_PROFILE = "/ProScoreClient/UpdateProScoreClient";
export const DELETE_PARTNER_PROFILE = "/ProScoreClient/DeleteProScoreClient";
export const GET_PARTNER_PROFILE = "/ProScoreClient/GetProScoreClient";

// projects
export const GET_ALL_PROJECTS = "/Project/GetAllProject/";
export const UPDATE_PROJECT_INFO = "/Project/UpdateProjectInfo/";
export const ADD_PROJECT_INFO = "/Project/AddProjectInfo/";
export const GET_PROJECT_INFO = "/Project/GetProjectInfo";
export const ADD_PROJECT = "/Project/AddProject/";
export const UPDATE_PROJECT = "/Project/UpdateProject/";
export const DELETE_PROJECT = "/Project/DeleteProject/";
export const GETALL_WORKTYPE = "/WorkType/GetAllWorkType/";
export const GETALL_PROJECTDOCUMENTTYPE = "/ProjectDocumentType/GetAllProjectType/";
export const GETALL_CLIENTS = "/DropDown/GetAllClients/";
export const GET_CLIENTS = "/Client/GetAllClients/";
export const GET_PROJECTS_DROPDWON = "/DropDown/GetAllPojects/";
export const GET_ALL_CLIENTLIST = "/ProScoreClient/GetAllProScoreClients/";
export const GET_ENVERUS_PROJECTLIST = "/DropDown/GetEnverusProjects/";
export const ADD_ENVERUS_PROJECTLIST = "/Project/AddEnvProjectByEnvId/";
export const GET_ENVERUS_PROJECT_BY_GUID = "/Project/v1/GetEnverusProjectByGuId";
export const GET_PROJECT = "/Project/GetProject";
export const GET_ALL_PROJECTSCHEDULES = "/Project/GetAllProjectSchedules/";
export const GET_PROJECTSCHEDULES = "/Project/GetProjectSchedule";
export const ADD_PROJECTSCHEDULES = "/Project/AddProjectSchedule/";
export const UPDATE_PROJECTSCHEDULES = "/Project/UpdateProjectSchedule/";
export const DELETE_PROJECTSCHEDULES = "/Project/DeleteProjectSchedule/";
export const GET_ALL_PROJECTPARTNER = "/Project/GetAllProjectClientContractor";
export const ADD_PROJECTPARTNER = "/Project/AddProjectClientContractor";
export const UPDATE_PROJECTPARTNER = "/Project/UpdateProjectClientContractor";
export const DELETE_PROJECTPARTNER = "/Project/DeleteProjectClientContractor";
export const GET_PROJECTPARTNER = "/Project/GetProjectClientContractor";
export const GET_ALL_CONTRACTTYPE = "/ClientContractType/GetAllClientContractType";

//project Employee
export const GET_ALL_PROJECTEMPLOYEE = "/Project/GetAllProjectEmployee";
export const GET_ALL_PROJECTEMPLOYEE_LOG = "/Project/GetProjectEmployeeLog";
export const GET_PROJECT_JOBTITLE = "/Project/GetProjectJobTitle";
export const ADD_PROJECT_PROJECTEMPLOYEE = "/Project/AddProjectEmployee";
export const UPDATE_PROJECT_PROJECTEMPLOYEE = "/Project/UpdateProjectEmployee";
export const DELETE_PROJECT_PROJECTEMPLOYEE = "/Project/DeleteProjectEmployee";
export const GET_PROJECTEMPLOYEE = "/Project/GetProjectEmployee";
export const GET_ALL_PROJECTJOBTITLES = "/Project/GetAllProjectJobTitles";
export const GET_ALL_EMPLOYEETERMTYPE = "/EmploymentTermType/GetAllEmploymentTermType";
export const GET_ALL_MENTOR = "/Project/GetAllMentors";
export const GET_NonMappedEmployee = "/Project/GetAllNonMappedEmployee";
export const GET_EMPLOYEEWAGESCALELIST = "/Rap/GetEmployeeWageScale";
export const GET_EMPLOYEEWAGESCALEDETAIL = "/Rap/GetEmployeeWageScaleDetail";
export const UPLOAD_PROJECT_EMPLOYEES = "/ExcelManagement/UploadExcelProjectEmployees";
export const APPRENTICE_MENTOR_HISTORY = "/ApprenticeMentorMapping/ApprenticeMentorHistory";
export const APPRENTICE_MENTOR_HISTORY_BY_PROJECT = "/ApprenticeMentorMapping/ApprenticeMentorHistoryByProject";
export const GET_APPRENTICE_MENTOR_DROPDOWN = "/ApprenticeMentorMapping/GetMentorAndApprenticeListByProject";
export const ASSIGN_APPRENTICE_MENTOR = "/ApprenticeMentorMapping/AssignApprenticeMentor";
export const DELETE_APPRENTICE_MENTOR_HISTORY = "/ApprenticeMentorMapping/DeleteProjectApprenticeMentor";
export const DAILYATTENDANCEPAIRING = "[V2]/mentorApprenticePairings";
export const DAILYATTENDANCEPAIRINGOUTLIER = "[V2]/mentorApprenticePairings/outliers";

//project Job Title
export const GET_ALL_PROJECT_JOB = "/Project/GetAllProjectJobTitles";
export const ADD_PROJECT_JOB = "/Project/AddProjectJobTitle";
export const UPDATE_PROJECT_JOB = "/Project/UpdateProjectJobTitle";
export const DELETE_PROJECT_JOB = "/Project/DeleteProjectJobTitle";
export const GET_PROJECT_JOB = "/Project/GetProjectJobTitle";
export const GET_PROJECTWISE_COUNTRYANDSTATE = "/Project/GetProjectWiseCountryAndState";
export const GET_PWC_TOKEN = "/Authenticate/GetChatBotToken";
export const GET_PWC_FILE_PATH = "/Project/GetPWFilePresignedUrl";

//Project Contact Info
export const GET_ALL_PROJECT_CONTACT_INFO = "/Project/GetAllProjectInfoContacts";
export const ADD_PROJECT_CONTACT_INFO = "/Project/AddProjectInfoContact";
export const UPDATE_PROJECT_CONTACT_INFO = "/Project/UpdateProjectInfoContact";
export const DELETE_PROJECT_CONTACT_INFO = "/Project/DeleteProjectInfoContact";
export const GET_ALL_CONTACT_TYPE = "/DropDown/GetContactDropDown";

//RAP
export const GET_ALL_ASSIGNRAPDROPDOWN = "/Rap/GetAllAssignRAPDropDown";
export const GET_ALL_ASSIGNRAPWAGESCALEDROPDOWN = "/Rap/GetAllAssignRAPWageScaleDropDown";
export const GET_ALL_ASSIGNRAPJOBINFO = "/Rap/GetAssignedRAPJobInfo";
export const ADD_WAGE_SCALE = "/Rap/AddAssignRAPWageScale";
export const UPDATE_WAGE_SCALE = "/Rap/UpdateAssignRAPWageScale";
export const ADD_WAGE_SCALE_DETAILS = "/Rap/AddAssignRAPWageScaleDetail";
export const DELETE_WAGE_SCALE_DETAILS = "/Rap/DeleteAssignRAPWageScaleDetail";
export const RAP_INFOS_DROPDOWNS = "[V2]/dropdowns/assignRapInfos";
export const ASSIGN_RAP_DROPDOWNS = "[V2]/dropdowns/assignRaps";
export const RAP_RTI_DROPDOWN = "[V2]/dropdowns/rapRtis";
export const APPRENTICE_RAP_ASSIGNMENT = "[V2]/employees/:employee_id/rapAssignment";
export const APPRENTICE_RTI_PROGRESS_TRACKER = "[V2]/employees/:employee_id/rtiProgressTracker";

//Reports
export const GET_ALL_WORKER_DETAIL_SUMMARY = "/Reports/GetWorkerDetailSummary";
export const GET_ALL_WORKER_SUB_DETAIL = "/Reports/GetWorkerSubDetail";
export const GET_WORKER_DETAIL_REPORT = "/DemoDashboard/GetWorkerDetailReports";
export const GET_ALL_APPRENTICE_LIST = "/DashboardV1/GetAllActiveApprenticesV1";
export const GET_WAGE_CHANGE_EMPLOYEE_DETAILS = "/DashboardV1/GetWageChangingEmployeeDetails";
export const GET_PAY_PERIOD_SUMMARY_DETAILS = "/DashboardV1/apprenticepayperiodsummarylist";
export const GET_WEEKLY_COMPLIANCE_REPORT = "/DashboardV1/GetWeeklyComplianceReport";
export const GET_LOST_OTJ_HOURS_DETAILS = "/DashboardV1/GetLostOtjHoursDetail";
export const GET_RTI_PROGRESSION = "/DashboardV1/rtiprogression";
export const GET_RTI_REPORT = "/DashboardV1/rtireport";
export const GET_LOST_OTJ_REPORT = "/DashboardV1/lostotjreport";
export const GET_MONTHLY_CADENCE = "/DashboardV1/monthlycadencerti";
export const GET_APPRENTICE_COURSES_LIST = "/DashboardV1/apprenticecourses";
export const GET_DEMO_APPRENTICE_DETAILS = "/DashboardV1/apprenticertidetail";
export const GET_RTI_DAILY_REPORT = "/DashboardV1/rtiandrtiexpecteddailyreport";
export const GET_LOST_OTJ = "/DashboardV1/lostotjlist";
export const GET_WORKFORCE_HOURS = "/DashboardV1/totalworkforcehourslist";
export const GET_APPRENTICE_HOURS_REPORT_LIST = "/DashboardV1/apprenticehoursreportlist";
export const GET_DASHBOARD_FEED = "/DashboardV1/GetDashboardFeed";

//project TimeSheet
export const GET_PROJECT_TIMESHEET = "/Project/GetProjectPayrollTimeSheetReports";
export const GET_PROJECT_TIMESHEETDETAILS = "/Project/GetProjectEmployeeDailyTimeSheetDetails";
export const GET_PROJECT_SUBMITTIMESHEETEXCEL = "/ExcelManagement/UploadEmployeeProjectEmployeeTimesheet";
export const GET_PROJECT_SUBMITWEEKLYTIMESHEETEXCEL = "/ExcelManagement/UploadProjectEmployeeDailyTimesheetByDay";
export const GET_PROJECTPAYROLL_SUBMITTIMESHEETEXCEL = "/ExcelManagement/UploadExcelProjectEmployeePayrollTimeSheet";
export const GET_PROJECTPAYROLL_TIMESHEET = "/Project/GetProjectPayrollTimeSheet";
export const UPLOAD_EMPLOYEE_PAYROLLREPORT = "/PdfManagement/UploadEmployeePayRollReportPDF";
export const GET_ALL_PROJECTREPORT = "/PdfManagement/GetAllProjectReports";

//project Payroll
export const GET_CERTIFIED_PAYROLL_DETAILS = "/Project/GetAllCertifiedPayrollDetails";
export const GET_PROJECT_EMPLOYEE_DAILY_TIMESHEET = "/Project/GetProjectEmployeeDailyTimeSheetDetailsByWeekEdingDate";
export const GET_PROJECT_EMPLOYEE_DAILY_PAYROLL = "/Project/GetProjectPayrollTimeSheetByWeekEndingDate";
export const GET_PROJECT_EMPLOYEE_DAILY_CERTIFIED_PAYROLL = "/Project/GetProjectCertifiedPdfReportsByWeekEndingDate";
export const PROJECT_EMPLOYEE_DAILY_CERTIFIED_PAYROLL = "[V2]/projects/{projectGuid}/certifiedPdfReports";

//dashboard
export const GET_USER_DASHBOARD_DETAILS = "/Dashboard/GetUserDashboardDetail";
export const GET_PROJECT_DASHBOARD_DETAILS = "/Dashboard/GetProjectDashboardDetail";
export const GET_ALL_PROJECT_COMPLIANCE = "/Dashboard/GetAllProjectCompliance";
export const GET_ALL_PROJECT = "/Dashboard/GetAllActiveProjects";
export const GET_DASHBOARD_DETAIL_BY_ID = "/Dashboard/GetDashboardDetailById";
export const GET_DASHBOARD_PROJECT_RISK_DATA = "/DashboardV1/projectRisk";
export const GET_OVERALL_JOURNEY_TO_APPRENTICE_RATIO = "/DashboardV1/overalljourneytoapprenticeratio";
export const GET_PROJECT_JOURNEY_TO_APPRENTICE_LIST = "/DashboardV1/projectJournetoapprenticelist";
export const GET_JOURNEY_TO_APPRENTICE_BY_PARTNER_LIST = "/DashboardV1/journeytoapprenticebypartnerlist";
export const GET_PROJECT_OVERVIEW_LIST = "/DashboardV1/projectoverviewlist";
export const GET_ALL_PARTNERS_DROPDOWN = "/ComplianceNavigator/GetProjectPartners";
export const GET_ALL_PARTNERS_RAP_DROPDOWN = "/DashboardV1/GetAllPartnerRAP";
export const COMPLIANCE_DOCUMENTS = "[V2]/complianceNavigator/complianceDocument";
export const GET_ALL_COMPLIANCE_TABLE_DATA = "[V2]/ComplianceNavigator/GetComplianceDocumentDetail";
export const GET_ALL_COMPLIANCE_INSIGHT_DATA = "/ComplianceNavigator/GetComplianceDocumentDetailSummary";
export const GET_ALL_HISTORY_OF_UNDERPAYMENT = "[V2]/complianceNavigator/historyOfUnderpaymentDetail";
export const HISTORY_OF_UNDERPAYMENT_CORRECTION_INFO = "[V2]/complianceNavigator/HistoryofUnderpaymentCorrectionInfo";
export const HISTORY_OF_UNDERPAYMENT_CORRECTION_REVIEW = "[V2]/complianceNavigator/HistoryofUnderpaymentCorrectionReview";
export const HISTORY_OF_UNDERPAYMENT_SUMMARY = "[V2]/complianceNavigator/complianceDocumentDetailSummary";
export const RATIO_MANAGEMENT_DETAILS = "[V2]/complianceNavigator/ratioManagementDetails";
export const COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES = "[V2]/complianceNavigator/complianceDocumentDetailSummary";
export const COMPLIANCE_NAVIGATOR_APPENDIXA = "[V2]/complianceNavigator/appendixADetails";
export const COMPLIANCE_NAVIGATOR_MISSING_APPENDIXA = "[V2]/complianceNavigator/missingAppendixARap";
export const GET_ALL_COMPLIANCE_TABLE = "[V2]/ComplianceNavigator/ComplianceDocumentDetail";
export const DASHBOARD_FEED = "[V2]/dashboard/feed";
export const DAVIS_BACON_FORM_DETAIL = "[V2]/complianceNavigator/davisBaconFormDetail";
export const ADD_DAVIS_BACON_FORM = "[V2]/complianceNavigator/addDavisBaconForm";
export const ADD_COMPLIANCE_NAVIGATOR_APPENDIXA = "[V2]/complianceNavigator/addAppendixAForm";
export const ADD_APPENDIX_D_FORM = "[V2]/complianceNavigator/addAppendixDForm";

//dropdowns
export const DYNAMIC_DROPDOWN = "/DropDown/GetTypeDropDown";
export const GET_ALL_STATES = "/DropDown/GetAllStates";
export const GET_CLIENT_SUBSOURCE = "/DropDown/GetClientSubSourceDropDown";
export const RESEND_VERIFYLINK_USER = "/User/ResendVerifyLink";
export const GET_ALL_VIDEOS = "/Video/GetAllVideos";
export const GET_ALL_CLIENTS_DROPDOWN = "[V2]/dropdowns/globalClients";
export const GET_CLIENT_JOB_TITLES = "[V2]/dropdowns/clientJobTitles";
export const GET_RAP_JOB_TITLES = "[V2]/dropdowns/rapJobTitles";
export const GET_ALL_INDUSTRIES = "[V2]/types/industries";
export const DROPDOWN_BY_TYPE = "[V2]dropdowns/types";
export const GET_SSA_STATES = "[V2]/dropdowns/ssaStates";
export const GET_SPONSOR_INFORMATION = "[V2]/dropdowns/sponsorInformations";
export const GET_ADMIN_INDUSTRY_TYPES = "[V2]/dropdowns/adminIndustryTypes";
export const GET_ADMIN_JOB_CATEGORIES = "[V2]/dropdowns/adminJobCategories";
export const PROJECT_JOB_TITLES = "[V2]/dropdowns/projectJobTitles";

// Admin Rap info
export const ADMIN_RAP_INFO = "[V2]/admin/rapInfos";
export const RAP_INFO_BYID = "[V2]/admin/rapInfos";

//WPS SETTINGS
export const POST_WPS_EMPLOYEE_MENTOR_SETTING = "/EmployeeWPSAllocationSetting/AddEmpoloyeeWPSAllocationSetting";
export const GET_WPS_EMPLOYEE_MENTOR_SETTING = "/EmployeeWPSAllocationSetting/GetEmpoloyeeWPSAllocationSetting";

// WPS Daily Time Allocation
export const GET_PROJECT_WPS = "/Project/GetAllEmployeeWpsTimeAllocation";
export const GET_EMPLOYEE_TIME_ALL_ACTIVITY = "/ProScoreEmployee/GetEmployeeWPSActivity";
export const ADD_EMPLOYEE_WPS_TIME_ALLOCATION = "/ProScoreEmployee/AddEmployeeWpsTimeAllocation";
export const UPLOAD_EXTERNAL_WPS_TIME_ALLOCATION = "/Project/UploadExternalOtjHours";

//Apprentice 180 Wage Rate
export const ADMIN_WAGE_RATES = "[V2]/admin/rapWageRates";

// Apprentice 180 admin rap wps activities
export const ADMIN_RAP_WPS_ACTIVITIES = "[V2]/admin/rapWpsActivities";

// Apprentice 180 admin rap rti
export const ADMIN_RAP_RTI = "[V2]/admin/rapRtis";
export const ADMIN_RAP_RATIOS = "[V2]/admin/rapRatios";
export const ADMIN_RAP_MENTOR_POOLS = "[V2]/admin/mentorRapPools";

// Apprentice 180 selection procedures
export const ADMIN_SELECTION_PROCEDURES = "[V2]/admin/rapSelectionProcedures";

// Apprentice 180 Documents
export const ADMIN_RAP_INFO_DOCUMENTS = "[V2]/admin/rapDocuments";
export const RAP_DOCUMENT_TYPES = "[V2]/dropdowns/rapDocumentTypes";

// Apprentice 180 Rap Info Appendix A
export const ADMIN_RAP_INFO_APPENDIX_A = "[V2]/apprenticeManagement/appendixAFileInfo";

// LMS
export const ADMIN_STUDENT_COURSES = "[V2]/employees/:employee_id/courses";

// Admin Sponsor Info
export const ADMIN_SPONSOR_INFO = "[V2]/admin/sponsorInformations";

//identity
export const IDENTITY_LOGIN_REDIRECT = "identity/account/login";

// reports
export const REPORTS = "[V2]/reports";

//Report Scheduler
export const REPORT_SCHEDULES = "[V2]/reportSchedules";

// Logo API
export const GET_CLIENT_LOGO = "public/clientlogo/";

//MENTOR AND APPRENTICE DROPDOWN
export const GET_ALL_APPRENTICE_PROGRAM_DROPDOWN = "/Project/GetRapsByClientId";
export const GET_WPS_RAP_RATIO_DROPDOWN = "/Project/GetWpsRapInfo";

//Dashboard JTA Risk Gauge Table Data
export const GET_JTA_RISK_GAUGE_DATA = "[V2]/dashboard/jtaRiskData";
//Dashboard Project Risk Gauge Data
export const GET_PROJECT_DOCUMENTATION_RISK_GAUGE_DATA = "[V2]/dashboard/projectDocumentationRiskData";
//Dashboard JTA Average Gauge Table Data
export const GET_JTA_AVERAGE_GAUGE_DATA = "[V2]/dashboard/jtaTotalByProjectPartners";
//Partner Risk Gauge Data
export const GET_PARTNER_RISK_GAUGE_DATA = "[V2]/dashboard/partnerRiskData";
//Prevailing Wage Risk Data
export const GET_PREVAILING_WAGE_GAUGE_DATA = "[V2]/dashboard/prevailingWageRisk";

export const ACTIVE_PROJECTS_DROPDOWN = "[V2]/dashboard/activeProjects";
export const PROJECT_PARTNERS = "[V2]/dropdowns/projectPartners";

//0 Project Below 15%
export const GET_PROJECT_BELOW_15_DATA = "[V2]/dashboard/projectsBelowTargetJta";
