import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";

const ViewFile = ({ openModal, handleCloseModal, path }) => {
  const url = path?.split("?")[0];
  const extentions = url?.split(".");
  const extentionName = extentions[extentions?.length - 1]
  return (
    <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="xl">
      <DialogTitle>
        View file
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {extentionName === "pdf" && (
          <iframe src={path} width="100%" height="560px" style={{ border: "none" }} title="file-viewer" />
        )}
        {(extentionName === "jpg" || extentionName === "png") && (
          <img src={path} style={{width:"100%"}} alt="file"  />
        )}
      </DialogContent>
    </Dialog>
  );
};

ViewFile.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired
};

export default ViewFile;
