import React, { useState, useEffect, useMemo } from "react";
import BottomTable from "./BottomTable.jsx";
import MiddleCharts from "./MiddleCharts.jsx";
import TopCards from "./TopCards.jsx";
import Layout from "../../Components/Layout";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  GET_ALL_APPRENTICE_LIST,
  GET_ALL_PROJECT,
  GET_LOST_OTJ_HOURS_DETAILS,
  GET_PAY_PERIOD_SUMMARY_DETAILS,
  GET_WAGE_CHANGE_EMPLOYEE_DETAILS,
  GET_WEEKLY_COMPLIANCE_REPORT
} from "../../../utils/services/apiPath";
import { getApi, postApi } from "../../../utils/services";
import { errorToast } from "../../../utils/toastHelper";
import { Link } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import ProButton from "../../Components/ProButton/index.jsx";
import ScheduleReportModal from "../../Components/ScheduleReportModal/index.jsx";
import { useSelector } from "react-redux";
import { routerSelector } from "../../store/routerSlice.js";

const WeeklyReportFormConfig = {
  projectId: {
    label: "Project",
    perKey: "Project",
    placeholder: "All Project"
  },
  apprenticeId: {
    label: "Apprentice",
    perKey: "Apprentice",
    placeholder: "All Apprentice"
  },
  dateRange: {
    label: "Date Range",
    perKey: "DateRange",
    placeholder: "Select Date Range"
  }
};

export default function DemoWeeklyReport() {
  const { id } = useParams();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(WeeklyReportFormConfig));
  const { control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(WeeklyReportFormConfig) });
  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));
  const [projectsList, setProjectsList] = useState([]);
  const [apprenticeList, setApprenticeList] = useState([]);
  const [wageChangeDetails, setWageChangeDetails] = useState([]);
  const [pageState, setPageState] = useState({
    wageChange: { pageIndex: 0, pageSize: 5, totalRecord: 0, loading: true },
    lostOTJHours: { pageIndex: 0, pageSize: 5, totalRecord: 0, loading: true },
    payPeriodSummary: { pageIndex: 0, pageSize: 5, totalRecord: 0, loading: true }
  });
  const [lostOTJHoursDetails, setLostOTJHoursDetails] = useState([]);
  const [payPeriodSummaryData, setPayPeriodSummaryData] = useState([]);
  const [weeklyComplianceReport, setWeeklyComplianceReport] = useState({});
  const [reportLoader, setReportLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const project = watch("projectId");
  const apprentice = watch("apprenticeId");
  const dateRange = watch("dateRange");

  const onEdit = () => setFormConfig(changeMode("edit"));

  const typeOptions = {
    projectId: projectsList,
    apprenticeId: apprenticeList
  };
  const { defaultReportRoutes } = useSelector(routerSelector)
  const { reportId, path } = defaultReportRoutes.filter((route) => route.path == "weekly-compliance-report")?.[0];

  useEffect(() => {
    if (!isAddMode) onEdit();
  }, [id]);

  const fetchProjects = async () => {
    const { data } = await getApi(GET_ALL_PROJECT);
    setValue("projectId", data[0]?.projectId);
    setProjectsList(data?.map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const fetchApprentice = async () => {
    const { data } = await getApi(`${GET_ALL_APPRENTICE_LIST}?projectGuid=${project}`);
    setValue("apprenticeId", data[0]?.apprenticeId);
    setApprenticeList(data?.map(item => ({ label: item.apprenticeName, value: item.apprenticeId })));
  };

  const fetchWageChangeDetails = async (page, pageSize) => {
    const payload = {
      pageIndex: page ?? pageState.wageChange.pageIndex + 1,
      pageSize: pageSize ?? pageState.wageChange.pageSize,
      orderByAscending: true,
      searchString: "",
      orderCol: ""
    };
    setPageState(prevState => ({ ...prevState, wageChange: { ...prevState.wageChange, loading: true } }));
    const { data, totalRecords, error } = await postApi(
      `${GET_WAGE_CHANGE_EMPLOYEE_DETAILS}?projectId=${project}&apprenticeId=${apprentice}`,
      payload
    );
    setPageState(prevState => ({ ...prevState, wageChange: { ...prevState.wageChange, loading: false } }));
    if (error) return errorToast(error);
    const formattedData = (data || []).map((item, index) => ({ ...item, id: index + 1 }));
    setPageState(prevState => ({ ...prevState, wageChange: { ...prevState.wageChange, loading: false, totalRecord: totalRecords } }));
    setWageChangeDetails(formattedData);
  };

  const fetchPayPeriodSummaryDetails = async (page, pageSize) => {
    const payload = {
      pageIndex: page ?? pageState.payPeriodSummary.pageIndex + 1,
      pageSize: pageSize ?? pageState.payPeriodSummary.pageSize,
      orderByAscending: true,
      searchString: "",
      orderCol: ""
    };
    setPageState(prevState => ({ ...prevState, payPeriodSummary: { ...prevState.payPeriodSummary, loading: true } }));
    const { data, totalRecords, error } = await getApi(`${GET_PAY_PERIOD_SUMMARY_DETAILS}?projectId=${project}&apprenticeId=${apprentice}`, payload);
    setPageState(prevState => ({ ...prevState, payPeriodSummary: { ...prevState.payPeriodSummary, loading: false } }));
    if (error) return errorToast(error);
    const formattedData = (data || []).map((item, index) => ({ ...item, id: index + 1 }));
    setPageState(prevState => ({ ...prevState, payPeriodSummary: { ...prevState.payPeriodSummary, loading: false, totalRecord: totalRecords } }));
    setPayPeriodSummaryData(formattedData);
  };

  const onPageChange = (type, { page, pageSize }) => {
    if (type === "wageChange") {
      setPageState(prevState => ({ ...prevState, wageChange: { ...prevState.wageChange, pageIndex: page, pageSize } }));
      fetchWageChangeDetails(page + 1, pageSize);
    } else if (type === "lostOTJHours") {
      setPageState(prevState => ({ ...prevState, lostOTJHours: { ...prevState.lostOTJHours, pageIndex: page, pageSize } }));
      fetchLostOtjHoursDetails(page + 1, pageSize);
    } else if (type === "payPeriodSummary") {
      setPageState(prevState => ({ ...prevState, payPeriodSummary: { ...prevState.payPeriodSummary, pageIndex: page, pageSize } }));
      fetchPayPeriodSummaryDetails(page + 1, pageSize);
    }
  };

  const fetchLostOtjHoursDetails = async (page, pageSize) => {
    const payload = {
      pageIndex: page ?? pageState.lostOTJHours.pageIndex + 1,
      pageSize: pageSize ?? pageState.lostOTJHours.pageSize,
      orderByAscending: true,
      searchString: "",
      orderCol: ""
    };
    const [startDate, endDate] = dateRange;
    setPageState(prevState => ({ ...prevState, lostOTJHours: { ...prevState.lostOTJHours, loading: true } }));
    const { data, error, totalRecords } = await postApi(
      `${GET_LOST_OTJ_HOURS_DETAILS}?projectId=${project}&apprenticeId=${apprentice}&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}`,
      payload
    );
    setPageState(prevState => ({ ...prevState, lostOTJHours: { ...prevState.lostOTJHours, loading: false } }));
    if (error) return errorToast(error);
    const formattedData = (data || []).map((item, index) => {
      const lostOtjDate = (item?.data || []).map(subItem => ({
        date: subItem?.workedDate || null,
        time: subItem?.lostOTJHours || null
      }));
      const mentorNameList = (item?.data || []).map(subItem => subItem?.mentorName || null);
      const lostOTJReasonList = (item?.data || []).map(subItem => subItem?.lostOTJReason || null);

      return {
        ...item,
        id: index + 1,
        lostOtjDate: lostOtjDate,
        mentorNameList: mentorNameList,
        lostOTJReasonList: lostOTJReasonList
      };
    });
    setPageState(prevState => ({ ...prevState, lostOTJHours: { ...prevState.lostOTJHours, loading: false, totalRecord: totalRecords } }));
    setLostOTJHoursDetails(formattedData);
  };

  const fetchWeeklyComplianceReport = async () => {
    setReportLoader(true);
    const [startDate, endDate] = dateRange;
    const { data } = await getApi(
      `${GET_WEEKLY_COMPLIANCE_REPORT}?projectId=${project}&apprenticeId=${apprentice}&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(
        endDate
      )}`
    );
    setWeeklyComplianceReport(data);
    setReportLoader(false);
  };

  const exportLink = () => {
    const [startDate, endDate] = dateRange


    return {
      pathname: `/${path}`,
      search: createSearchParams({
        projectId: project,
        apprenticeId: apprentice,
        startDate: startDate,
        endDate: endDate
      }).toString()
    }
  }

  const formatDate = timestamp => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const from = dayjs().subtract(16, "day");
    const to = dayjs().subtract(1, "day");
    const weekEndingDate = [from, to];
    setValue("dateRange", weekEndingDate);
    fetchProjects();

  }, []);

  useEffect(() => {
    if (project) {
      fetchApprentice();
    }
  }, [project]);

  useEffect(() => {
    if (project && apprentice) {
      fetchWageChangeDetails();
    }
  }, [project, apprentice]);

  useEffect(() => {
    if (project && apprentice && dateRange) {
      fetchWeeklyComplianceReport();
      fetchLostOtjHoursDetails();
      fetchPayPeriodSummaryDetails();
    }
  }, [project, apprentice, dateRange]);

  const defaultFormProps = { control, formValues: {}, isAddMode, handleReadValueClick };


  return (
    <Layout>
      <div className="demo-container">
        <TopCards
          defaultFormProps={defaultFormProps}
          formConfig={formConfig}
          typeOptions={typeOptions}
          setValue={setValue}
          weeklyComplianceReport={weeklyComplianceReport}
          cardTitleButton={
            <div style={{ display: "flex", gap: 5 }}>
              <ProButton onClick={() => setIsModalOpen(true)}>Schedule</ProButton>
              <Link style={{ color: "white" }} target="_blank" to={exportLink()}><ProButton>Export</ProButton></Link>
            </div>
          }
        ></TopCards>
        <MiddleCharts weeklyComplianceReport={weeklyComplianceReport} loader={reportLoader}></MiddleCharts>
        <BottomTable
          wageChangeDetails={wageChangeDetails}
          lostOTJHoursDetails={lostOTJHoursDetails}
          payPeriodSummaryData={payPeriodSummaryData}
          pageState={pageState}
          onPageChange={onPageChange}
        ></BottomTable>

        <ScheduleReportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} reportId={reportId} />
      </div>
    </Layout>
  );
}
