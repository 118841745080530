import React from "react";
import TableComponent from "../Components/ProGridTable/TableComponent";
import { formatCurrency } from "../../Components/ProGrid/components/FormatCell";
import ReportingNameCell from "../Components/ProGridTable/ReportingNameCell";
import PropTypes from "prop-types";

const wageColumns = [
    { field: "employeeName", headerName: "Name", flex: 1, minWidth: 150, 
      renderCell: params => <ReportingNameCell employeeGuid={params.row.employeeId} value={params.value} />
    },
    { field: "jobRole", headerName: "Role", flex: 1, minWidth: 150 },
    { field: "employeeId", headerName: "Employee ID", flex: 1, minWidth: 150 },
    { field: "totalOtjHours", headerName: "Total Hrs (Current Wage Scale)", flex: 1, minWidth: 150 },
    { field: "remainingHours", headerName: "Remaining Hrs (Current Wage Scale)", flex: 1, minWidth: 150 },
    { field: "overdueHours", headerName: "Overdue Hrs (New Wage Scale)", flex: 1, minWidth: 150 },
    { field: "newRate", headerName: "New Rate (New Wage Scale)", flex: 1, minWidth: 150,
      renderCell: params => (
        formatCurrency(params?.value)
      )
     }
  ];

function WageChangingTable({ wageChangeDetails, pageState, onPageChange }) {
  return (
    <TableComponent
      columns={wageColumns}
      rows={wageChangeDetails}
      title={"Wage Changing Employee Details"}
      hasBorder={false}
      loading={pageState.loading}
      toolSetButton={true}
      paginationProps={{
        rowCount: pageState.totalRecord,
        paginationMode: "server",
        paginationModel: { pageSize: pageState.pageSize, page: pageState.pageIndex },
        onPaginationModelChange: onPageChange
      }}
    />
  );
}

WageChangingTable.propTypes = {
  wageChangeDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageState: PropTypes.shape({
    loading: PropTypes.bool,
    totalRecord: PropTypes.number,
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number
  }).isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default WageChangingTable;
