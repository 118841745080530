import React from "react";
import PropTypes from "prop-types";
import FeedTable from "../ProjectOverviewTable/FeedTable";
import { columns } from "./FeedTableColumns";
import GenericModal from "../../../Components/Modals/GenericModal";

export default function ProjectDetailModal({ feedData, open, setOpen }) {
  return (
    <GenericModal open={open} setOpen={setOpen} modalTitle={feedData?.eventDescription}>
      <FeedTable projectOverviewData={feedData?.notificationDetails} columns={columns[feedData?.eventCategoryTypeId]} />
    </GenericModal>
  );
}
ProjectDetailModal.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};
