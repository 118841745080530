import { getApi } from "./services";
import { GET_BUILD_VERSION } from "./services/apiPath";

let watcher;

const matchVersion = async () => {
  const version = localStorage.getItem("clientBuildVersion");
  const currentVersion = await getApi(GET_BUILD_VERSION);
  if (!version) {
    localStorage.setItem("clientBuildVersion", currentVersion);
  } else if (version !== currentVersion) {
    localStorage.setItem("clientBuildVersion", currentVersion);
    window.location.reload();
  }
};

export const watchBuildVersion = () => {
  clearInterval(watcher);
  watcher = setInterval(matchVersion, 600000);
  matchVersion();
};
