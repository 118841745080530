import { STAUTES } from "../../../utils/constant";
import ProGrid from "../ProGrid/ProGrid";
import ToolbarBase from "../Toolbars/ToolbarBase";
import GridSerch from "../ProGrid/components/GridSerch";
import { Button, Grid, useTheme } from "@mui/material";
import ComplianceInsight from "./ComplianceInsight";
import { FileDownload } from "@mui/icons-material";
import PropTypes from "prop-types";

function BaseToolBar({ title = "", searchModel = {}, uploadPopUp }) {
  const theme = useTheme();
  return () => (
    <>
      <div className="proGrid-search-wrapper" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px" }}>
        <GridSerch {...searchModel} />
        <div style={{ display: "flex", gap: "10px" }}>
          {uploadPopUp}
          {/* <Button id="download-files" color="primary" variant="contained" size="small">
            Download Zip
            <FileDownload />
          </Button> */}
        </div>
      </div>
      <div style={{ backgroundColor: theme.palette.compliancenavigator.basetable.toolbarBaseBackground }}>
        <ToolbarBase title={title} titleSize="h6">
          <div style={{ display: "flex", flexDirection: "row", lineHeight: "normal", gap: "10px" }}></div>
        </ToolbarBase>
      </div>
    </>
  );
}

const ComplianceNavigatorBaseTable = ({
  columns,
  rows,
  title,
  searchString,
  setSearchString,
  status,
  rowCount,
  pageSize,
  currentPage,
  setPagination,
  setSortModel,
  insightData,
  tableKey,
  uploadPopUp,
  getRowId,
  getRowClassName = ""
}) => {
  return (
    <Grid container direction={"row"}>
      <Grid item xs={9}>
        <ProGrid
          columns={columns}
          rows={rows}
          loading={status === STAUTES.LOADING}
          searchMode={!!searchString}
          toolbar={BaseToolBar({
            title: title,
            searchModel: { defaultValue: searchString, handleDebounce: setSearchString },
            uploadPopUp: uploadPopUp
          })}
          sx={{ ".MuiDataGrid-row:nth-of-type(odd)": { backgroundColor: "lightgrey" } }}
          options={{
            getRowId: getRowId,
            rowCount: rowCount,
            checkboxSelection: false,
            paginationMode: "server",
            searchModel: { defaultValue: searchString, handleDebounce: setSearchString },
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          getRowClassName={getRowClassName}
        />
      </Grid>
      <Grid item xs={3}>
        <ComplianceInsight insightJson={insightData} tableKey={tableKey} />
      </Grid>
    </Grid>
  );
};

ComplianceNavigatorBaseTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  title: PropTypes.string,
  searchString: PropTypes.string,
  setSearchString: PropTypes.func,
  status: PropTypes.string,
  rowCount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  setPagination: PropTypes.func,
  setSortModel: PropTypes.func,
  insightData: PropTypes.array,
  tableKey: PropTypes.string,
  uploadPopUp: PropTypes.node,
  getRowId: PropTypes.func
};

export default ComplianceNavigatorBaseTable;
