import { createSlice } from "@reduxjs/toolkit";
import { createBrowserRouter } from "react-router-dom";
import { LOCAL_ROUTES } from "../RouterComponent/RouterComponent";
import ProtectedRoute from "../RouterComponent/ProtectedRoute";
import ODataReportViewer from "../Components/ReportViewers/ODataReportViewer";
import HTTPReportViewer from "../Components/ReportViewers/HTTPReportViewer";
import { REPORTS } from "../../utils/services/apiPath";
import { getApi } from "../../utils/services";
import { createODataQuery } from "../../utils/services/oData";
import ODataReportDesigner from "../Components/ReportViewers/ODataReportDesigner";
import HTTPReportDesigner from "../Components/ReportViewers/HTTPReportDesigner";

const initialState = {
  routes: LOCAL_ROUTES,
  router: createBrowserRouter(LOCAL_ROUTES),
  defaultReportRoutes: [],
  customReportRoutes: [],
  apiRoutesAdded: false,
  designerComponents: []
};

const handleCustomReportInitialize = async dispatch => {
  try {
    const apiRoutes = [];
    const routes = localStorage.getItem("customReports");
    if (!routes) {
      const localStorageRoutes = [];
      const { data } = await getApi(
        REPORTS + createODataQuery(["isDefault"], [], "isDefault eq false", null, { orderCol: "reportCategory", orderByAscending: true }, 0, 0, false)
      );
      data.forEach(report => {
        const path = report?.reportName.toLowerCase().trim().split(" ").join("-");
        const filePath = `/${report?.reportFile?.path}/${report?.reportFile?.fileName}`;
        const routeData = {
          path: `${path}-custom`,
          element: <ProtectedRoute Component={report.dataType === "odata" ? ODataReportViewer : HTTPReportViewer} props={{ fileName: filePath }} />
        };
        apiRoutes.push(routeData);
        localStorageRoutes.push({
          ...routeData,
          designerPath: `designer-${path}-custom`,
          reportId: report.reportId,
          reportCategoryId: report.reportCategoryId,
          type: report.dataType,
          filePath: filePath,
          category: report.reportCategory,
          title: report?.reportName,
          isDefault: report.isDefault
        });
      });
      localStorage.setItem("customReports", JSON.stringify(localStorageRoutes));
      dispatch(setCustomReportRoutes(localStorageRoutes));
      dispatch(setRoutes(LOCAL_ROUTES.concat(apiRoutes)));
    } else {
      const parsed = JSON.parse(routes);
      const apiRoutes = [];
      parsed.forEach(report => {
        const routeData = {
          path: report.path,
          element: <ProtectedRoute Component={report.type === "odata" ? ODataReportViewer : HTTPReportViewer} props={{ fileName: report.filePath }} />
        };
        apiRoutes.push(routeData);
      });
      dispatch(setCustomReportRoutes(parsed));
      dispatch(setRoutes(LOCAL_ROUTES.concat(apiRoutes)));
    }
  } catch (e) {
    console.log(e);
  }
};

const handleStandardReportsInitialize = async (dispatch, getState) => {
  try {
    const apiRoutes = [];
    const inStorage = localStorage.getItem("standardReports");
    const { router: routerState } = getState();
    const { routes } = routerState;
    if (!inStorage) {
      const localStorageRoutes = [];
      const { data } = await getApi(
        REPORTS + createODataQuery(["isDefault"], [], "isDefault eq true", null, { orderCol: "reportCategory", orderByAscending: true }, 0, 0, false)
      );
      data.forEach(report => {
        const path = report?.reportName.toLowerCase().trim().split(" ").join("-");
        const filePath = `${report?.reportFile?.path}/${report?.reportFile?.fileName}`;
        const routeData = {
          path: path,
          element: <ProtectedRoute Component={report.dataType === "odata" ? ODataReportViewer : HTTPReportViewer} props={{ fileName: filePath }} />
        };
        apiRoutes.push(routeData);
        localStorageRoutes.push({
          ...routeData,
          designerPath: `designer-${path}`,
          reportId: report.reportId,
          type: report.dataType,
          reportCategoryId: report.reportCategoryId,
          filePath: filePath,
          category: report.reportCategory,
          title: report?.reportName,
          isDefault: report.isDefault
        });
      });
      localStorage.setItem("standardReports", JSON.stringify(localStorageRoutes));
      dispatch(setDefaultReportRoutes(localStorageRoutes));
      dispatch(setRoutes(routes.concat(apiRoutes)));
    } else {
      const parsed = JSON.parse(inStorage);
      const apiRoutes = [];
      parsed.forEach(report => {
        const routeData = {
          path: report.path,
          element: <ProtectedRoute Component={report.type === "odata" ? ODataReportViewer : HTTPReportViewer} props={{ fileName: report.filePath }} />
        };
        apiRoutes.push(routeData);
      });
      dispatch(setDefaultReportRoutes(parsed));
      dispatch(setRoutes(routes.concat(apiRoutes)));
    }
  } catch (e) {
    console.log(e);
  }
};

const initializeDesignerRoutes = (dispatch, getState) => {
  const { router: routerState } = getState();
  const { customReportRoutes, defaultReportRoutes } = routerState;
  const allRoutes = customReportRoutes.concat(defaultReportRoutes);

  const designerRoutes = allRoutes.map(route => {
    const isOData = route.type == "odata";
    const component = isOData ? ODataReportDesigner : HTTPReportDesigner;
    return {
      path: `/designer-${route.path}`,
      element: <ProtectedRoute Component={component} props={{ fileName: route.filePath }} />
    };
  });
  dispatch(setDesignerRoutes(designerRoutes));
};

export const handleApiRouterInitialize = () => {
  return async (dispatch, getState) => {
    handleCustomReportInitialize(dispatch);
    handleStandardReportsInitialize(dispatch, getState);
    initializeDesignerRoutes(dispatch, getState);

    const { router: routerState } = getState();
    const { routes, designerComponents } = routerState;
    dispatch(setRouter(createBrowserRouter(routes.concat(designerComponents))));
  };
};

export const reloadCustomReports = () => {
  return async dispatch => {
    const apiRoutes = [];
    const localStorageRoutes = [];
    const { data } = await getApi(
      REPORTS + createODataQuery(["isDefault"], [], "isDefault eq false", null, { orderCol: "reportCategory", orderByAscending: true }, 0, 0, false)
    );
    data.forEach(report => {
      const path = report?.reportName.toLowerCase().trim().split(" ").join("-");
      const filePath = `/${report?.reportFile?.path}/${report?.reportFile?.fileName}`;
      const routeData = {
        path: `${path}-custom`,
        element: <ProtectedRoute Component={report.dataType === "odata" ? ODataReportViewer : HTTPReportViewer} props={{ fileName: filePath }} />
      };
      apiRoutes.push(routeData);
      localStorageRoutes.push({
        ...routeData,
        designerPath: `designer-${path}-custom`,
        reportId: report.reportId,
        reportCategoryId: report.reportCategoryId,
        type: report.dataType,
        filePath: filePath,
        category: report.reportCategory,
        title: report?.reportName,
        isDefault: report.isDefault
      });
    });
    localStorage.setItem("customReports", JSON.stringify(localStorageRoutes));
    dispatch(setCustomReportRoutes(localStorageRoutes));
    dispatch(setRoutes(LOCAL_ROUTES.concat(apiRoutes)));
  };
};

const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    setRoutes: (state, action) => {
      state.routes = action.payload;
    },
    setDefaultReportRoutes: (state, action) => {
      state.defaultReportRoutes = action.payload;
    },
    setCustomReportRoutes: (state, action) => {
      state.customReportRoutes = action.payload;
    },
    setDesignerRoutes: (state, action) => {
      state.designerComponents = action.payload;
    },
    setRouter: (state, action) => {
      state.router = action.payload;
      state.apiRoutesAdded = true;
    }
  }
});

export const { setRoutes, setRouter, setDefaultReportRoutes, setCustomReportRoutes, setDesignerRoutes } = routerSlice.actions;
export const routerSelector = state => state.router;
export default routerSlice.reducer;
