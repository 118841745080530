import React from "react";
import DashboardCard from "../Components/DashboardCard/DashboardCard";
import twentyFour from "../../Assets/Icons/24.png";
import percentage from "../../Assets/Icons/percentage.png";
import past from "../../Assets/Icons/return-to-the-past.png";
import Slider from "react-slick";
import CardHeader from "../Components/CardHeader/CardHeader";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function TopCards({ defaultFormProps, formConfig, typeOptions, setValue, lostOTJReport, cardTitleButton }) {
  const theme = useTheme();
  const LostOtjData = [
    {
      icon: twentyFour,
      colorClass: theme.palette.demo.lostotjtopcard1color,
      value: `${Math.round(lostOTJReport?.totalRTI) || 0} Hours`,
      label: "Total RTI (Week)",
      bg: theme.palette.demo.lostotjtopcard1bg
    },
    {
      icon: percentage,
      colorClass: theme.palette.demo.lostotjtopcard2color,
      value: `${lostOTJReport?.journeyToApprenticeRatio ?? 0} %`,
      label: "Journey to Apprentice Ratio",
      bg: theme.palette.demo.lostotjtopcard2bg
    },

    {
      icon: past,
      colorClass: theme.palette.demo.lostotjtopcard3color,
      value: `${Math.round(lostOTJReport?.lostOTJ) || 0} Hours`,
      label: "Lost OTJ",
      bg: theme.palette.demo.lostotjtopcard3bg
    },
    {
      icon: twentyFour,
      colorClass: theme.palette.demo.lostotjtopcard4color,
      value: `${Math.round(lostOTJReport?.totalApprenticeOTJ) || 0} Hours`,
      label: "Total Apprentice OTJ",
      bg: theme.palette.demo.lostotjtopcard4bg
    },
    {
      icon: twentyFour,
      colorClass: theme.palette.demo.lostotjtopcard5color,
      value: `${Math.round(lostOTJReport?.totalMentorHours) || 0}`,
      label: "Total Mentor Hours",
      bg: theme.palette.demo.lostotjtopcard5bg
    }
  ];

  return (
    <>
      <CardHeader
        formProps={defaultFormProps}
        formConfig={formConfig}
        typeOptions={typeOptions}
        cardStyle={{ marginTop: "20px" }}
        boxStyle={{ display: "flex", justifyContent: "space-between" }}
        setValue={setValue}
        title={"OTJ Report"}
        cardTitleButton={cardTitleButton}
      />
      <div style={{ marginBottom: "20px", maxWidth: "100%" }}>
        <Slider {...settings}>
          {LostOtjData?.map((data, ind) => (
            <div key={data?.value}>
              <DashboardCard
                title={data?.value}
                value={data?.label}
                color={data?.colorClass}
                icon={data?.icon}
                bg={data?.bg}
                ind={ind}
                length={LostOtjData.length - 1}
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

TopCards.propTypes = {
  defaultFormProps: PropTypes.object.isRequired,
  formConfig: PropTypes.object.isRequired,
  typeOptions: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  lostOTJReport: PropTypes.object.isRequired
};
