import React, { useCallback, useEffect, useState } from "react";
import ProGrid from "../../../Components/ProGrid/v2";
import usePageState from "../../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../../utils/constant";
import { getApi, postApi } from "../../../../utils/services";
import {
  ADMIN_RAP_WPS_ACTIVITIES,
  GET_ALL_APPRENTICE_PROGRAM_DROPDOWN,
  GET_PROJECT_WPS,
  GET_WPS_RAP_RATIO_DROPDOWN,
  UPLOAD_EXTERNAL_WPS_TIME_ALLOCATION
} from "../../../../utils/services/apiPath";
import { errorToast } from "../../../../utils/toastHelper";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DailyTimeAllocationFormModal from "../../../Components/ModuleComponent/wps/DailyTimeAllocationFormModal";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import { useParams, useSearchParams } from "react-router-dom";
import { fieldTruePermission, formatPayloadDate, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import DateRangeSelect from "../../../Components/ProGrid/components/DateRangeSelect";
import { R_PROJECTS, R_PROJECTS_WPS_TIME_ALLOCATION } from "../../../../utils/permissionReferenceConfig";
import { subModPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import ReportsLayout from "../../../Components/ProForms/ProFormsLayout/ReportsFormsLayout";
import { ErrorBox } from "../../../Components/Helpers/ErrorBox";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import ProSelectField from "../../../Components/Inputs/SelectField";
import ProButton from "../../../Components/ProButton";
import * as XLSX from "xlsx";
import "./DailyWpsTimeAllocation.scss";

//Search params is received from journey to apprentice card
const defaultConfig = {
  apprenticeProgramId: {
    label: "Apprentice Program Name",
    rules: { required: "Apprentice Program Name is required!" }
  },
  fromDate: {
    label: "From Date",
    defaultValue: dayjs()
  },
  toDate: {
    label: "To Date",
    defaultValue: dayjs()
  }
};

const getWpsConfig = searchParams => {
  if (searchParams.has("date")) {
    const currentDay = dayjs(searchParams.get("date"));
    return {
      ...defaultConfig,
      fromDate: {
        ...defaultConfig.fromDate,
        defaultValue: currentDay
      },
      toDate: {
        ...defaultConfig.toDate,
        defaultValue: currentDay.add(1, "day")
      }
    };
  }
  return defaultConfig;
};

export default function DailyTimeAllocation() {
  const [searchParams] = useSearchParams();
  const { id: projectId } = useParams();
  const [showTimeAllocationModal, setShowTimeAllocationModal] = useState(false);
  const [apprenticeProgramOptions, setApprenticeProgramOptions] = useState([]);
  const [actionData, setActionData] = useState(null);
  const [wpsTrackedByUpload, setWpsTrackedByUpload] = useState(false);
  const { control, watch, setValue } = useForm({ defaultValues: prepareDefaultValues(getWpsConfig(searchParams)) });
  const [formConfig] = useState(prepareInitialConfig(getWpsConfig(searchParams), "edit"));
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const apprenticeProgramId = watch("apprenticeProgramId");

  const { canView, canEdit } = useSelector(subModPermissionSelector(R_PROJECTS, R_PROJECTS_WPS_TIME_ALLOCATION));
  const {
    currentPage,
    pageSize,
    data: wpsTimeData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const openTimeAllocationModal = (params, wpsTrackedByUpload) => {
    if (wpsTrackedByUpload) return;
    setActionData(params.row);
    setShowTimeAllocationModal(true);
  };

  const columns = [
    {
      field: "apprenticeName",
      headerName: "Apprentice Name",
      renderCell: params => (
        <NameCell
          params={params}
          onClick={params => openTimeAllocationModal(params, wpsTrackedByUpload)}
          // We will need the apprentice id here
          // getRedirectUrl={params => `/employees/${params.row.projectApprenticeMentorId}`}
        />
      )
    },
    {
      field: "mentorName",
      headerName: "Mentor Name"
      // renderCell: params => <NameCell params={params}
      //   // We will need the an actual mentor's id here from the api or point to a real mentor
      //   // getRedirectUrl={params => `/employees/${params.row.projectApprenticeMentorId}`}
      // />
    },
    {
      field: "estimatedHours",
      headerName: "Estimated Hours",
      renderCell: params => <NameCell params={params} onClick={params => openTimeAllocationModal(params, wpsTrackedByUpload)} />
    },
    {
      field: "otjHours",
      headerName: "OTJ / WPS Hours Worked"
    },
    dateColumnDef({
      field: "workDate",
      headerName: "Work Date"
    }),
    {
      field: "wpsStatus",
      headerName: "Status"
    }
  ];

  const getWpsTimeAllocationList = useCallback(async () => {
    if (!apprenticeProgramId) return setPageState(prevPageInfo => ({ ...prevPageInfo, data: [], rowCount: 0 }));
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "workDate",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_PROJECT_WPS, payload, {
      params: { projectId: projectId, rapGuid: apprenticeProgramId, dateFrom: formatPayloadDate(fromDate), dateTo: formatPayloadDate(toDate) }
    });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [apprenticeProgramId, currentPage, pageSize, sortModel, searchString, fromDate, toDate, setPageState, setStatus]);

  const fetchRapRatio = async rapGuid => {
    try {
      const { data } = await getApi(`${GET_WPS_RAP_RATIO_DROPDOWN}?rapGuid=${rapGuid}`);
      if (data) setWpsTrackedByUpload(data.isOtjTrackedByThirdParty && data.rapRatio !== "1:1");
    } catch (error) {
      errorToast("Error fetching RAP Ratio");
    }
  };

  const fetchApprenticeProgramDropdown = async id => {
    try {
      const { data } = await getApi(`${GET_ALL_APPRENTICE_PROGRAM_DROPDOWN}?projectId=${id}`);
      if (data && Array.isArray(data)) {
        setApprenticeProgramOptions(data.map(item => ({ label: item.rapTitle, value: item.rapGuid })));
      }
    } catch (error) {
      errorToast("Error fetching Apprentice Program Dropdown:", error);
    }
  };

  const handleClose = refresh => {
    setShowTimeAllocationModal(false);
    setActionData(null);
    if (refresh) getWpsTimeAllocationList();
  };

  const generateWPSTemplate = async () => {
    try {
      const { data, error } = await getApi(ADMIN_RAP_WPS_ACTIVITIES + `?rapInfoId=${apprenticeProgramId}`);
      if (error) return errorToast(error);
      const excelHeaders = ["Apprentice Name", "Employee ID", "Work Date", "Total OTJ Hour"];
      data?.forEach(item => {
        if (item.wpsTitle) excelHeaders.push(item.wpsTitle);
      });

      const ws = XLSX.utils.json_to_sheet([], { header: excelHeaders });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "template_file.xlsx");
    } catch (error) {
      console.log(error);
    }
  };

  const uploadWpsAllocation = async e => {
    try {
      const file = e.target.files[0];
      if (!file) return;
      const fileData = new FormData();
      fileData.append("file", file);
      const { data, error } = await postApi(
        `${UPLOAD_EXTERNAL_WPS_TIME_ALLOCATION}?projectGuid=${projectId}&rapGuid=${apprenticeProgramId}`,
        fileData
      );
      if (error) return errorToast(error);
      getWpsTimeAllocationList();
      successToast("OTJ Hours uploaded successfully.");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (projectId) fetchApprenticeProgramDropdown(projectId);
  }, [projectId]);

  useEffect(() => {
    if (apprenticeProgramId) fetchRapRatio(apprenticeProgramId);
  }, [apprenticeProgramId]);

  useEffect(() => {
    getWpsTimeAllocationList();
  }, [getWpsTimeAllocationList]);

  const finalColumns = columns.filter(col => col.field !== "mentorName" || !wpsTrackedByUpload);

  useEffect(() => {
    if (apprenticeProgramOptions.length === 1) {
      const singleProgram = apprenticeProgramOptions[0];
      setValue("apprenticeProgramId", singleProgram.value);
    }
  }, [apprenticeProgramOptions, control]);

  return (
    <ReportsLayout>
      {canView ? (
        <>
        {apprenticeProgramOptions.length > 1 && (
          <Grid sx={{ pb: 2, borderBottom: 1, borderColor: "divider" }} container spacing={2}>
            <ProSelectField
              control={control}
              {...formConfig.apprenticeProgramId}
              options={apprenticeProgramOptions}
              permission={fieldTruePermission}
            />
          </Grid>
        )}

        {(apprenticeProgramOptions.length === 0 || apprenticeProgramId) && (
          <>
            {wpsTrackedByUpload && (
              <Grid sx={{ py: 2 }} container spacing={2}>
                <Grid item xs={12}>
                  WPS Time Allocation
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", gap: "16px" }}>
                  <div className="wps-time-allocation__upload">
                    <ProButton>Upload WPS Allocation</ProButton>
                    <input type="file" accept=".xls,.xlsx" onChange={uploadWpsAllocation} />
                  </div>
                  <ProButton onClick={generateWPSTemplate}>WPS Allocation Template</ProButton>
                </Grid>
              </Grid>
            )}
            <TabGridAndFormLayout showGrid>
            {/* TODO: In the new pro grid component, add an optional row grouping prop to enable collapsible rows */}
            <ProGrid
            title={wpsTrackedByUpload ? "" : "WPS Time Allocation"}
            columns={finalColumns}
            loading={status === STAUTES.LOADING}
            rows={wpsTimeData}
            searchMode={Boolean(searchString)}
            searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
            hideAddButton={true}
            options={{
            getRowId: row => row.projectApprenticeMentorId,
            rowCount,
            paginationMode: "server",
            paginationModel: { pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel,
            }}
            additionButtons={<DateRangeSelect control={control} formConfig={formConfig} fromDate={fromDate} toDate={toDate} />}
            />
            </TabGridAndFormLayout>
          </>
        )}
        <DailyTimeAllocationFormModal
          show={showTimeAllocationModal}
          handleClose={handleClose}
          actionData={actionData}
          canEdit={canEdit}
        />
      </>
    ) : (
      <ErrorBox message="You don't have permission to view this page." />
    )}
  </ReportsLayout>
  );
}