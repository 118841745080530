import React from "react";
import { Paper, Stack, useTheme } from "@mui/material";
import { Error, CheckCircle } from "@mui/icons-material";
import PropTypes from "prop-types";

const InsightListItem = ({ details, theme }) => {
  return (
    <div
      style={{
        border: `1px solid ${theme.palette.compliancenavigator.complianceinsight.border}`,
        borderRadius: 5,
        margin: "5px 10px 10px 10px",
        padding: 10
      }}
    >
      <div>{details}</div>
    </div>
  );
};

InsightListItem.propTypes = {
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default function ComplianceInsight({ insightJson, tableKey }) {
  const theme = useTheme();
  const errorMessage =
    tableKey === "RatioManagement"
      ? `${insightJson?.length} Issues Found.`
      : `${insightJson?.length} ${tableKey?.replace(/([a-z])([A-Z])/g, "$1 $2")} Missing.`;
  const isShowError = insightJson && (tableKey === "RatioManagement" || !insightJson?.length) ? true : false;
  return (
    <div
      style={{
        border: `1px solid ${theme.palette.compliancenavigator.complianceinsight.border}`,
        height: "calc(100vh - 200px)",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div style={{ backgroundColor: theme.palette.compliancenavigator.complianceinsight.bg1, paddingBottom: 5, marginBottom: 10 }}>
        <div style={{ margin: "0px 10px 10px 15px", paddingTop: 10 }}>
          <h3 style={{ margin: "5px" }}>Insight</h3>
        </div>
        {isShowError && (
          <div
            style={{
              backgroundColor: theme.palette.compliancenavigator.complianceinsight.bg2,
              padding: 5,
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              color: theme.palette.compliancenavigator.complianceinsight.color,
              margin: "0px 15px 15px 15px"
            }}
          >
            <Error color="error" />
            <span style={{ marginLeft: 5 }}>{errorMessage}</span>
          </div>
        )}
      </div>

      <Paper sx={{ flexGrow: 1, overflow: "auto" }}>
        {Array.isArray(insightJson) &&
          insightJson.map((insight, index) => {
            return (
              insight.notificationSummary && (
                <Stack spacing={2} key={Number(index)}>
                  <InsightListItem details={insight.notificationSummary} theme={theme} />
                </Stack>
              )
            );
          })}
        {insightJson?.length === 0 && (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", gap: "10px" }}>
            <CheckCircle color="success" sx={{ fontSize: "100px" }} />
            <div>You're all covered here</div>
          </div>
        )}
      </Paper>
    </div>
  );
}

ComplianceInsight.propTypes = {
  insightJson: PropTypes.arrayOf(
    PropTypes.shape({
      notificationSummary: PropTypes.string
    })
  ),
  tableKey: PropTypes.string
};
