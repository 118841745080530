import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import { DateCell } from "../../../Components/ProGrid/components/FormatCell";

const renderCell = type => params => {
  if (params.row.isDummy) return <span className="centerAlign"></span>;
  if (type === "hours") return <span className="centerAlign">{params.value ?? 0} Hours</span>;
  if (type === "number") return <span className="centerAlign">{params.value ?? 0}</span>;
  if (type === "percentage") return <span className="centerAlign">{params.value ?? 0}%</span>;
  if (type === "formatDecimal") return <span className="centerAlign">{parseFloat(params.value).toFixed(2) ?? 0}</span>;
  return <span className="centerAlign">{params.value}</span>;
};

const sortComparator = (v1, v2, param1, param2) => {
  const sortModifier = param1.api.getSortModel()[0]?.sort === "asc" ? 1 : -1;
  if (param1.api.getRowParams(param1.id).row.isDummy) return sortModifier * 1;
  if (param2.api.getRowParams(param2.id).row.isDummy) return sortModifier * -1;
  return gridStringOrNumberComparator(v1, v2, param1, param2);
};

export const columns = {
  1: [],
  2: [
    {
      field: "workforceStartDate",
      headerName: "Workforce Start Date",
      headerAlign: "center",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "workforceEndDate",
      headerName: "Workforce End Date",
      headerAlign: "center",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "numberOfWeeksOnProject",
      headerName: "Number of Weeks on Project",
      headerAlign: "center"
    },
    {
      field: "numberOfCPRsExecuted",
      headerName: "Number of CPRs Executed",
      headerAlign: "center"
    },
    {
      field: "numberOfMissingCPRs",
      headerName: "Number of Missing CPRs",
      headerAlign: "center"
    },
    {
      field: "partnerContactName",
      headerName: "Project Partner Contact Name",
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "partnerContactPhone",
      headerName: "Partner Contact Number",
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "partnerContactEmail",
      headerName: "Partner Contact Email",
      headerAlign: "center",
      minWidth: 130
    }
  ],
  3: [],
  4: [],
  5: [],
  6: [
    {
      field: "numberOfActiveApprentices",
      headerName: "# of Active Apprentices",
      renderCell: renderCell(""),
      headerAlign: "center"
    },
    {
      field: "numberOfActiveMentors",
      headerName: "# Active Mentors",
      renderCell: renderCell(""),
      headerAlign: "center"
    },
    {
      field: "numberOfActiveLaborers",
      headerName: "# of Active Laborers",
      renderCell: renderCell(""),
      headerAlign: "center"
    },
    {
      field: "totalOtjHours",
      headerName: "Total OTJ Hours",
      sortComparator,
      headerAlign: "center",
      renderCell: renderCell("hours")
    },
    {
      field: "totalLaborHours",
      headerName: "Total Labor Hours",
      renderCell: renderCell("hours"),
      sortComparator,
      headerAlign: "center",
      minWidth: 90
    },
    {
      field: "totalLostOTJ",
      headerName: "Total Lost OTJ",
      renderCell: renderCell("hours"),
      sortComparator,
      headerAlign: "center"
    },
    {
      field: "projectPartnerContactName",
      headerName: "Project Partner Contact Name",
      renderCell: params => <NameCell params={params} getRedirectUrl={params => `/partners/${params.row.partnerId}/contacts`} />,
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "partnerContactNumber",
      headerName: "Partner Contact Number",
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "partnerContactEmail",
      headerName: "Partner Contact Email",
      headerAlign: "center",
      minWidth: 130
    }
  ],
  7: [],
  8: [
    {
      field: "payPeriodEndingDate",
      headerName: "Pay Period Ending Date",
      headerAlign: "center",
      renderCell: params => <DateCell value={params.value} />,
      minWidth: 50
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "laborClassification",
      headerName: "Labor Classification",
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "prevailingWageRateAndFringe",
      headerName: "Prevailing Wage Rate/Prevailing Wage Fringe",
      headerAlign: "center",
      renderCell: renderCell(""),
      minWidth: 50
    },
    {
      field: "prevailingWageCombinedRate",
      headerName: "Prevailing Wage Combined Rate",
      headerAlign: "center",
      renderCell: renderCell(""),
      minWidth: 50
    },
    {
      field: "actualWageRateAndFringe",
      headerName: "Actual Wage Rate/Fringe",
      headerAlign: "center",
      renderCell: renderCell(""),
      minWidth: 130
    },
    {
      field: "actualCombinedRate",
      headerName: "Actual Combined Rate",
      headerAlign: "center",
      renderCell: renderCell(""),
      minWidth: 50
    },
    {
      field: "backPaymentAmount",
      headerName: "Back Payment Amount",
      headerAlign: "center",
      renderCell: renderCell("formatDecimal"),
      minWidth: 50
    }
  ],
  9: [
    {
      field: "totalEmployeeCount",
      headerName: "Total # Labor Employee",
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "apprenticeCount",
      headerName: "# of Apprentices",
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "mentorCount",
      headerName: "# of Mentors",
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "contactName",
      headerName: "Project Partner Contact Name",
      renderCell: params => <NameCell params={params} getRedirectUrl={params => `/partners/${params.row.partnerId}/contacts`} />,
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "contactEmail",
      headerName: "Partner Contact Email",
      headerAlign: "center",
      minWidth: 160
    },
    {
      field: "contactPhone",
      headerName: "Partner Contact Number",
      headerAlign: "center",
      minWidth: 130
    }
  ],
  10: [
    {
      field: "projectName",
      headerName: "Project Name",
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "totalJToA",
      headerName: "Total J to A",
      headerAlign: "center",
      renderCell: renderCell(""),
      minWidth: 50
    },
    {
      field: "totalHours",
      headerName: "Total Apprentice hours",
      headerAlign: "center",
      renderCell: renderCell("hours"),
      sortComparator,
      minWidth: 50
    },
    {
      field: "totalOtjHours",
      headerName: "Total OTJ",
      renderCell: renderCell(""),
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "totalRtiHours",
      headerName: "Total RTI",
      renderCell: renderCell(""),
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "totalLaborHours",
      headerName: "Total Labor Hours",
      renderCell: renderCell("hours"),
      sortComparator,
      headerAlign: "center",
      minWidth: 50
    },
    {
      field: "projectPartnerContactName",
      headerName: "Partner Contact Name",
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "partnerContactNumber",
      headerName: "Partner Contact Phone",
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "partnerContactEmail",
      headerName: "Partner Contact Email",
      headerAlign: "center",
      minWidth: 130
    }
  ],
  11: [
    {
      field: "firstname",
      headerName: "Employee First Name",
      headerAlign: "center",
      minWidth: 100
    },
    {
      field: "lastname",
      headerName: "Employee Last Name",
      headerAlign: "center",
      minWidth: 100
    },
    {
      field: "projectName",
      headerName: "Project",
      headerAlign: "center",
      minWidth: 130
    },
    {
      field: "createdDate",
      headerName: "Date Added to Project",
      headerAlign: "center",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "is671Complete",
      headerName: "671 Complete?",
      headerAlign: "center",
      renderCell: params => <span className="centerAlign">{params.value ? "Yes" : "No"}</span>
    },
    {
      field: "is90DayCertificateValid",
      headerName: "90 Day Certificate Valid?",
      headerAlign: "center",
      renderCell: params => <span className="centerAlign">{params.value ? "Yes" : "No"}</span>
    },
    {
      field: "isRapidsRegistrationComplete",
      headerName: "RAPIDS Registration Complete?",
      headerAlign: "center",
      renderCell: params => <span className="centerAlign">{params.value ? "Yes" : "No"}</span>
    }
  ],
  12: [
    {
      field: "payPeriodEndingDate",
      headerName: "Pay Period Ending Date",
      renderCell: params => <DateCell value={params.value} />,
      headerAlign: "center"
    },
    {
      field: "projectName",
      headerName: "Project",
      sortComparator,
      headerAlign: "center",
      minWidth: 125
    },
    {
      field: "partnerName",
      headerName: "Partner",
      headerAlign: "center"
    },
    {
      field: "totalApprenticeHours",
      headerName: "Total Apprentice Hours",
      sortComparator,
      headerAlign: "center",
      renderCell: renderCell("hours")
    },
    {
      field: "totalLostOjtHours",
      headerName: "Total Lost OJT",
      sortComparator,
      headerAlign: "center",
      renderCell: renderCell("hours")
    },
    {
      field: "totalLaborHours",
      headerName: "Total Labor Hours",
      renderCell: renderCell("hours"),
      sortComparator,
      headerAlign: "center",
      minWidth: 90
    },
    {
      field: "journeyToApprenticeRatio",
      headerName: "J to A",
      renderCell: renderCell("percentage"),
      sortComparator,
      headerAlign: "center"
    }
  ]
};
