import React, { useCallback, useEffect, useMemo, useState } from "react";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { useForm } from "react-hook-form";
import ProCheckbox from "../../../Components/Inputs/CheckboxField";
import ProTextInput from "../../../Components/Inputs/TextField";
import {
  ADD_PARTNER_NOTE,
  DELETE_PARTNER_NOTE,
  GET_ALL_NOTE_TYPES,
  GET_ALL_PARTNER_NOTE_LIST,
  GET_PARTNER_NOTE,
  UPDATE_PARTNER_NOTE
} from "../../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../../utils/services";
import { useParams } from "react-router-dom";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import usePageState from "../../../../utils/customHooks/usePageState";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import { STAUTES } from "../../../../utils/constant";
import { partnersNotesPermissionSelector } from "../../../store/features/permissions/permissionsSlice";
import useColumn from "../../../../utils/customHooks/useColumns";
import usePermission from "../../../../utils/customHooks/usePermission";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import { PartnersNotesFormConfing } from "../PartnerData";

const requiredFormNames = ["Add Note", "Note List"];

export default function PartnerNotes() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [actionData, setActionData] = useState({});
  const [notesTypes, setNotesTypes] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const { "Add Note": addNotePermission, "Note List": notesListPermission } = usePermission(partnersNotesPermissionSelector, requiredFormNames);

  const [formConfig, setFormConfig] = useState(prepareInitialConfig(PartnersNotesFormConfing));
  const { handleSubmit, control, setValue, clearErrors } = useForm({ defaultValues: prepareDefaultValues(PartnersNotesFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const {
    currentPage,
    pageSize,
    data: notesData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "notes",
        headerName: "Notes Name",
        renderCell: params => <NameCell params={params} onClick={onNoteClick} />
      },
      {
        field: "noteType",
        headerName: "Notes"
      },
      {
        field: "createdByName",
        headerName: "Created By"
      },
      dateColumnDef({
        field: "createdDate",
        headerName: "Created Date"
      }),
      {
        field: "clientNoteId",
        headerName: "Actions",
        width: 80,
        isDeleteColumn: true,
        disableExport: true,
        renderCell: params => <DeleteCell title={"Delete Partner Note"} params={params} onDelete={onDelete} />
      }
    ],
    notesListPermission.canDelete
  );

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };

  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
    clearErrors();
  };

  const onCancel = () => {
    resetForm();
  };

  const onNoteClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    Object.keys(formConfig).forEach(field => setValue(field, params.row[field]));
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
  };

  const onSave = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const { clientNoteId } = actionData;
    const payload = { ...data, clientId: id, isPrivate: data.isPrivate || false, clientNoteId: clientNoteId || null };
    const { error, data: PartnerNotes } = await postApi(clientNoteId ? UPDATE_PARTNER_NOTE : ADD_PARTNER_NOTE, payload);
    setIsSaving(false); 
    if (error) return errorToast(error);
    getAllNotes();
    getPartnerNoteById(PartnerNotes?.guidId);
    setShowGrid(false);
    successToast(`Partner Note ${clientNoteId ? "updated" : "added"} successfully.`);
  };

  const getPartnerNoteById = async id => {
    setFormConfig(changeMode("read"));
    const payload = {
      clientNoteId: id
    };
    const { error, data: clientNoteId } = await postApi(GET_PARTNER_NOTE, payload);
    if (error) return errorToast(error);
    const data = {
      noteTypeId: clientNoteId?.noteTypeId,
      notes: clientNoteId?.notes,
      isPrivate: clientNoteId?.isPrivate
    };
    setActionData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_PARTNER_NOTE, { data: [{ clientNoteId: id }] });
    if (error) return errorToast(error);
    getAllNotes();
    setShowGrid(true);
    successToast("Partner Note deleted successfully.");
  };

  const getAllNotes = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "createdDate",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_ALL_PARTNER_NOTE_LIST, payload, { params: { clientId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getAllNoteTypes = async () => {
    const { data } = await postApi(GET_ALL_NOTE_TYPES, defaultDropdownConfig);
    setNotesTypes(data.map(item => ({ label: item.noteType, value: item.noteTypeId })));
  };

  useEffect(() => {
    getAllNotes();
    getAllNoteTypes();
  }, [getAllNotes]);

  const defaultFormProps = { control, formValues: actionData, handleReadValueClick, isAddMode: !actionData.clientNoteId };

  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData.clientNoteId || actionData?.noteTypeId ? "View" : "Add"} Partner Note`}
      backLabel="Go back to Partner Notes List"
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      hideEdit={true}
      canView={notesListPermission?.canView}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Partner Notes"
          columns={columns}
          loading={status === STAUTES.LOADING}
          rows={notesData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Partner Note"
          addButtonAction={addButtonAction}
          hideAddButton={!notesListPermission?.canAdd}
          hideExport={!notesListPermission.canExport}
          options={{
            getRowId: row => row.clientNoteId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
        />
      )}
      {!showGrid && (
        <>
          <ProSelectField
            {...defaultFormProps}
            {...formConfig.noteTypeId}
            options={notesTypes}
            permission={{ ...addNotePermission.fieldPerObj[formConfig.noteTypeId.perKey], canEdit: false }}
          />
          <ProCheckbox
            {...defaultFormProps}
            {...formConfig.isPrivate}
            permission={{ ...addNotePermission.fieldPerObj[formConfig.isPrivate.perKey], canEdit: false }}
          />
          <ProTextInput
            {...defaultFormProps}
            {...formConfig.notes}
            permission={{ ...addNotePermission.fieldPerObj[formConfig.notes.perKey], canEdit: false }}
          />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
