import React from "react";
import InputWrapper from "./InputWrapper";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";

export default function CheckBoxGroup({
  mode,
  name,
  rules,
  label,
  options,
  control,
  isAddMode,
  handleReadValueClick,
  permission = {},
  size = DEFAULT_SIZE
}) {
  const { canView = false, canEdit = false, canAdd = false } = permission;
  const theme = useTheme();
  if (!canView || (isAddMode && !canAdd)) return null;

  function handleSelect(idChecked, values = []) {
    return values?.includes(idChecked) ? values?.filter(id => id !== idChecked) : [...(values || []), idChecked];
  }

  const handleValueClick = () => {
    if (mode === "read" && canEdit) handleReadValueClick(name);
  };

  const cellStyles = { color: theme.palette.inputs.checkBoxGroup.cellStyles };
  const editModeAllowed = isAddMode ? canAdd : canEdit;
  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl className="proCheckbox" variant="standard">
            <FormLabel sx={cellStyles}>{label}</FormLabel>
            <FormGroup>
              {options.map(({ id, label }, index) => {
                return (
                  <FormControlLabel
                    key={Number(index)}
                    control={
                      <Checkbox
                        checked={(value || []).includes(id)}
                        disabled={!editModeAllowed}
                        onChange={() => onChange(handleSelect(id, value))}
                        size="small"
                      />
                    }
                    label={label}
                    onClick={handleValueClick}
                  />
                );
              })}
            </FormGroup>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </InputWrapper>
  );
}

CheckBoxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  isAddMode: PropTypes.bool,
  handleReadValueClick: PropTypes.func,
  size: PropTypes.string,
  options: PropTypes.array.isRequired,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  })
};
