import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material";

const RTIMonthlyCadence = ({ monthlyCadenceData }) => {
  const theme = useTheme();
  const options = {
    chart: {
      type: "column",
      marginTop: 50,
      backgroundColor: theme.palette.demo.rtireportbackgroundcolor
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: monthlyCadenceData?.monthNames,
      gridLineWidth: 0,
      labels: {
        style: {
          color: theme.palette.demo.rtireportcolor
        }
      },
      lineColor: "#F8F8FF"
    },
    yAxis: {
      min: 0,
      title: {
        text: ""
      },
      labels: {
        formatter: function () {
          return this.value;
        },
        style: {
          color: theme.palette.demo.rtireportcolor
        }
      },
      gridLineWidth: 1,
      gridLineColor: "#e6e6e6",
      gridLineDashStyle: "Dot"
    },
    series: [
      {
        name: "RTI Actuals",
        data: monthlyCadenceData?.totalRTIHours,
        color: "#1F88E6",
        borderRadius: 8,
        borderWidth: 0
      },
      {
        name: "RTI Expected",
        data: monthlyCadenceData?.estimatedHours,
        color: "#0507B9",
        borderRadius: 8,
        borderWidth: 0
      }
    ],
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false
        }
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      layout: "horizontal",
      align: "right",
      verticalAlign: "top",
      floating: true,
      y: -10,
      itemStyle: {
        color: theme.palette.demo.rtireportcolor,
        fontWeight: "normal"
      },
      itemHoverStyle: {
        color: theme.palette.demo.rtireportcolor
      }
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

RTIMonthlyCadence.propTypes = {
  monthlyCadenceData: PropTypes.shape({
    monthNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalRTIHours: PropTypes.arrayOf(PropTypes.number).isRequired,
    estimatedHours: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired
};

export default RTIMonthlyCadence;
