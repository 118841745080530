import React, { useState, useEffect, useMemo } from "react";
import BottomTable from "./BottomTable.jsx";
import MiddleCharts from "./MiddleCharts.jsx";
import TopCards from "./TopCards.jsx";
import Layout from "../../Components/Layout";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  GET_ALL_APPRENTICE_LIST,
  GET_ALL_PROJECT,
  GET_RTI_REPORT,
  GET_RTI_PROGRESSION,
  GET_MONTHLY_CADENCE,
  GET_APPRENTICE_COURSES_LIST,
  GET_DEMO_APPRENTICE_DETAILS,
  GET_ALL_PARTNERS_DROPDOWN,
  GET_ALL_PARTNERS_RAP_DROPDOWN
} from "../../../utils/services/apiPath";
import { getApi } from "../../../utils/services";
import ProButton from "../../Components/ProButton/index.jsx";
import { errorToast } from "../../../utils/toastHelper.js";
import ScheduleReportModal from "../../Components/ScheduleReportModal/index.jsx";
import { urlBuilder } from "../../../utils/urlBuilder.js";
import { useSelector } from "react-redux";
import { routerSelector } from "../../store/routerSlice.js";

const RtiReportFormConfig = {
  projectId: {
    label: "Project",
    perKey: "Project",
    placeholder: "All Project"
  },
  apprenticeId: {
    label: "Apprentice",
    perKey: "Apprentice",
    placeholder: "All Apprentice"
  },
  courseId: {
    label: "Course",
    perKey: "Course",
    placeholder: "All Course"
  },
  partnerId: {
    label: "Partner",
    perKey: "Partner",
    placeholder: "All Partners"
  },
  rapId: {
    label: "Rap",
    perKey: "Rap",
    placeholder: "All Raps"
  },
  dateRange: {
    label: "Date Range",
    perKey: "DateRange",
    placeholder: "Select Date Range"
  }
};

export default function DemoRtiReport() {
  const { id } = useParams();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(RtiReportFormConfig));
  const { control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(RtiReportFormConfig) });
  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));
  const [projectsList, setProjectsList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [rapList, setRapList] = useState([]);
  const [apprenticeList, setApprenticeList] = useState([]);
  const [apprenticeCoursesList, setApprenticeCoursesList] = useState([]);
  const [rtiProgression, setRtiProgression] = useState([]);
  const [monthlyCadence, setMonthlyCadence] = useState([]);
  // const [dailyReport, setDailyReport] = useState({});
  const [rtiReport, setRtiReport] = useState({});
  const [apprenticeDetails, setApprenticeDetails] = useState([]);
  const [progressionLoader, setProgressionLoader] = useState(true);
  const [cadenceLoader, setCadenceLoader] = useState(true);
  const [pageState, setPageState] = useState({ pageIndex: 0, pageSize: 50, totalRecord: 0, loading: true });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const project = watch("projectId");
  const partner = watch("partnerId");
  const rap = watch("rapId");
  const apprentice = watch("apprenticeId");
  const dateRange = watch("dateRange");
  const course = watch("courseId");
  const { defaultReportRoutes } = useSelector(routerSelector)
  const { reportId } = defaultReportRoutes.filter((route) => route.path == "apprentice-progress-by-rap-report")?.[0];

  const onEdit = () => setFormConfig(changeMode("edit"));

  const typeOptions = {
    projectId: projectsList,
    apprenticeId: apprenticeList,
    partnerId: partnersList,
    rapId: rapList,
    courseId: apprenticeCoursesList
  };

  const formatDate = timestamp => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const onPageChange = ({ page, pageSize }) => {
    setPageState(prevState => ({ ...prevState, pageIndex: page, pageSize }));
    fetchRTIApprenticeDetails(page + 1, pageSize);
  };

  useEffect(() => {
    if (!isAddMode) onEdit();
  }, [id]);

  const fetchProjects = async () => {
    const { data } = await getApi(GET_ALL_PROJECT);
    setValue("projectId", data[0]?.projectId);
    setProjectsList(data?.map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const fetchApprentice = async () => {
    const { data } = await getApi(`${GET_ALL_APPRENTICE_LIST}?projectGuid=${project}`);
    setValue("apprenticeId", data[0]?.apprenticeId);
    setApprenticeList(data?.map(item => ({ label: item.apprenticeName, value: item.apprenticeId })));
  };

  const fetchApprenticeCourses = async () => {
    const { data } = await getApi(`${GET_APPRENTICE_COURSES_LIST}?projectGuid=${project}&apprenticeGuid=${apprentice}`);
    setValue("courseId", data[0]?.raprtiGuid);
    setApprenticeCoursesList(data?.map(item => ({ label: item.rtiTitle, value: item.raprtiGuid })));
  };

  const fetchRTIProgression = async () => {
    setProgressionLoader(true);
    setRtiProgression([]);
    const [startDate, endDate] = dateRange;
    const url = urlBuilder(GET_RTI_PROGRESSION, {
      projectGuid: project,
      courseGuid: course,
      partnerGuid: partner,
      rapGuid: rap,
      apprenticeGuid: apprentice,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data } = await getApi(
      url
    );
    setRtiProgression(data);
    setProgressionLoader(false);
  };
  const fetchMonthlyCadence = async () => {
    setCadenceLoader(true);
    setMonthlyCadence([]);
    const [startDate, endDate] = dateRange;
    const url = urlBuilder(GET_MONTHLY_CADENCE, {
      projectGuid: project,
      courseGuid: course,
      partnerGuid: partner,
      rapGuid: rap,
      apprenticeGuid: apprentice,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data } = await getApi(
      url
    );
    setMonthlyCadence(data);
    setCadenceLoader(false);
  };

  const fetchRTIReport = async () => {
    const [startDate, endDate] = dateRange;
    const url = urlBuilder(GET_RTI_REPORT, {
      projectGuid: project,
      courseGuid: course,
      partnerGuid: partner,
      rapGuid: rap,
      apprenticeGuid: apprentice,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data } = await getApi(
      url
    );
    setRtiReport(data);
  };

  // const fetchDailyReport = async () => {
  //   const [startDate, endDate] = dateRange;
  //   const { data } = await getApi(
  //     `${GET_RTI_DAILY_REPORT}?projectGuid=${project}&courseGuid=${course}&apprenticeGuid=${apprentice}&dateFrom=${formatDate(
  //       startDate
  //     )}&dateTo=${formatDate(endDate)}`
  //   );
  //   setDailyReport(data[0]);
  // };

  const fetchRTIApprenticeDetails = async (page, pageSize) => {
    const [startDate, endDate] = dateRange;
    const payload = {
      pageIndex: page ?? pageState.pageIndex + 1,
      pageSize: pageSize ?? pageState.pageSize,
      orderByAscending: true,
      searchString: "",
      orderCol: ""
    };
    setPageState(prevState => ({ ...prevState, loading: true }));
    const url = urlBuilder(GET_DEMO_APPRENTICE_DETAILS, {
      projectGuid: project,
      courseGuid: course,
      partnerGuid: partner,
      rapGuid: rap,
      apprenticeGuid: apprentice,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data, error, totalRecords } = await getApi(
      url,
      payload
    );
    setPageState(prevState => ({ ...prevState, loading: false }));
    if (error) return errorToast(error);
    const formattedData = (data || []).map((item, index) => ({ ...item, id: index + 1 }));
    setPageState(prevState => ({ ...prevState, loading: false, totalRecord: totalRecords }));
    setApprenticeDetails(formattedData);
  };

  useEffect(() => {
    const from = dayjs().subtract(16, "day");
    const to = dayjs().subtract(1, "day");
    const weekEndingDate = [from, to];
    setValue("dateRange", weekEndingDate);
    fetchProjects();
  }, []);

  const fetchPartners = async () => {
    const res = await getApi(`${GET_ALL_PARTNERS_DROPDOWN}/${project}`);
    setValue("partnerId", res[0]?.clientGuid);
    setPartnersList(res?.map(item => ({ label: item.companyName, value: item.clientGuid })));
  };

  const fetchPartnerRap = async () => {
    const { data } = await getApi(`${GET_ALL_PARTNERS_RAP_DROPDOWN}?projectGuid=${project}&partnerGuid=${partner}`);
    setValue("rapId", data[0]?.value);
    setRapList(data?.map(item => ({ label: item.label, value: item.value })));
  };

  useEffect(() => {
    if (project) {
      fetchApprentice();
      fetchPartners();
    }
  }, [project]);

  useEffect(() => {
    if (project && partner) {
      fetchPartnerRap();
    }
  }, [project, partner]);

  useEffect(() => {
    if (project && apprentice) {
      fetchApprenticeCourses();
    }
  }, [project, apprentice]);

  useEffect(() => {
    if (project && apprentice && dateRange && course) {
      fetchRTIProgression();
      fetchRTIReport();
      fetchMonthlyCadence();
      fetchRTIApprenticeDetails();
      // fetchDailyReport();
    }
  }, [project, apprentice, partner, dateRange, course, rap]);

  const defaultFormProps = { control, formValues: {}, isAddMode, handleReadValueClick };


  //TODO: FIll this out with filters
  const exportLink = () => {
    return {
      pathName: "/reports/apprentice-progress-by-rap-report"
    }.toString();
  }

  return (
    <Layout>
      <div className="demo-container">
        <TopCards
          defaultFormProps={defaultFormProps}
          formConfig={formConfig}
          typeOptions={typeOptions}
          cardStyle={{ marginTop: "20px" }}
          boxStyle={{ display: "flex", justifyContent: "space-between" }}
          setValue={setValue}
          rtiReport={rtiReport}
          apprenticeDetails={apprenticeDetails}
          cardTitleButton={
            <div style={{ display: "flex", gap: 5 }}>
              <ProButton onClick={() => setIsModalOpen(true)}>Schedule</ProButton>
              <Link style={{ color: "white" }} target="_blank" to={"/apprentice-progress-by-rap-report"}>
                <ProButton>Export</ProButton>
              </Link>
            </div>
          }
        />
        <MiddleCharts
          rtiProgression={rtiProgression}
          monthlyCadence={monthlyCadence}
          // dailyReport={dailyReport}
          progressionLoader={progressionLoader}
          cadenceLoader={cadenceLoader}
        ></MiddleCharts>
        <BottomTable apprenticeDetails={apprenticeDetails} pageState={pageState} onPageChange={onPageChange}></BottomTable>

        <ScheduleReportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} reportId={reportId} />

      </div>
    </Layout>
  );
}
