import { useNavigate } from "react-router-dom";

export default function ReportingNameCell({ employeeGuid, value, children }) {
  const navigate = useNavigate()
  const navigateToEmployee = () => {
    navigate(`/employees/${employeeGuid}`)
  }

  return (
    <div style={{ lineHeight: "1.5", display: "flex", flexDirection: "column" }}>
      <span onClick={navigateToEmployee} style={{ textDecoration: "underline", fontWeight: "bold" }}>
        {value}
      </span>
      {children}
    </div>
  );
};