import React, { useEffect, useState } from "react";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, loginSelector } from "../../store/features/auth/authSlice";
import { BASE_IDENTITY_API_URL, IDENTITY_CLIENT_ID, STAUTES, USE_IDENTITY } from "../../../utils/constant";
import { IDENTITY_LOGIN_REDIRECT } from "../../../utils/services/apiPath";
import { CircularProgress, FormHelperText, IconButton, InputAdornment, useTheme } from "@mui/material";
import "./proLogin.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { fieldTruePermission } from "../../../utils/formHelper";

export const loginInfoConfig = {
  username: {
    name: "username",
    label: "Username",
    rules: { required: "Username is required!" }
  },
  password: {
    name: "password",
    rules: { required: "Password is required!" },
    label: "Password",
    type: "password"
  }
};

const combineURL = (baseURL, ...segments) => {
  // Join the base URL and segments with '/'
  let url = [baseURL, ...segments].join('/');

  // Normalize the URL by removing any double slashes (except after the protocol)
  url = url.replace(/([^:]\/)\/+/g, '$1');

  return url;
}

const Login = () => {
  const navigate = useNavigate();
  const { status, isAuthenticated } = useSelector(loginSelector);
  const [helperText, setHelperText] = useState("");
  const { control, handleSubmit } = useForm({});
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const onLogin = async data => {
    dispatch(
      handleLogin(data, error => {
        setHelperText(error);
      })
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  console.log("Login -> isAuthenticated", isAuthenticated)

  useEffect(() => {
    if (isAuthenticated) {
      if (location.state?.from) navigate(location.state.from.pathname + location.state.from.search, { replace: true });
      else navigate("/dashboard");
    } else if (USE_IDENTITY) {
      const redirectUrl = new URL(combineURL(BASE_IDENTITY_API_URL, IDENTITY_LOGIN_REDIRECT));
  
      redirectUrl.searchParams.append("clientid", IDENTITY_CLIENT_ID);
      redirectUrl.searchParams.append("returnUrl", combineURL(location.origin, "identity-callback"));
  
      location.href = redirectUrl.href;
      return;
    }
  }, [isAuthenticated, navigate]);
  const loading = status === STAUTES.LOADING;

  if (USE_IDENTITY)
    return <></>;

  return (
    <ProPreLoginLayout heading="Login">
      <div className="proLogin">
        <form className="proLogin_form" onSubmit={handleSubmit(onLogin)}>
          <div className="proLogin_form_input">
            <ProTextInput {...loginInfoConfig.username} permission={fieldTruePermission} control={control} mode="edit" />
          </div>
          <div className="proLogin_form_input">
            <ProTextInput
              {...loginInfoConfig.password}
              control={control}
              permission={fieldTruePermission}
              mode="edit"
              fieldProps={{
                type: showPassword ? "text" : "password",
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff sx={{ color: theme.palette.pages.login.color }} />
                        ) : (
                          <Visibility sx={{ color: theme.palette.pages.login.color }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }
              }}
            />
          </div>
          {helperText && (
            <FormHelperText error className="proLogin_form_error">
              {helperText}
            </FormHelperText>
          )}
          <div className="proLogin_form_button">
            <ProButton
              type="submit"
              style={{ width: "100%" }}
              disabled={loading}
              {...(loading ? { endIcon: <CircularProgress color="inherit" /> } : {})}
            >
              Login
            </ProButton>
          </div>
          <div className="proLogin_form_link">
            <NavLink to="/forgot-password">Forgot password?</NavLink>
          </div>
        </form>
      </div>
    </ProPreLoginLayout>
  );
};

export default Login;
