import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../Components/Layout";
import PersonIcon from "@mui/icons-material/Person";
import RapInfoProfile from "../RapInfoProfile";
import WageRate from "../WageRate";
import WpsActivities from "../WpsActivities";
import RelatedInstructionDescriptions from "../RelatedInstructionDescription";
import SelectionProcedures from "../SelectionProcedures";
import RAPDocuments from "../RapDocuments";
import DetailPageLayout from "../../../../Components/Layout/DetailPageLayout";
import RapRatio from "../RapRatio";
import MentorPool from "../MentorPool";
import RTIHours from "../RTIHours";

export default function RapDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const isAddMode = useMemo(() => id === "add", [id]);
  const [rapSubTitle, setRapSubTitle] = useState("");
  const tabs = isAddMode
    ? [
        {
          to: "/rapinfo",
          title: "RAP Info",
          key: "rapinfo",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        }
      ]
    : [
        {
          to: "/rapinfo",
          title: "RAP Info",
          key: "rapinfo",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/rap-ratio",
          title: "RAP Ratio",
          key: "rap-ratio",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/wagerate",
          title: "Wage Rate",
          key: "wagerate",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/wps-activities",
          title: "WPS Activities",
          key: "wps-activities",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/related-instruction-descriptions",
          title: "Related Instruction Descriptions",
          key: "related-instruction-descriptions",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/selection-procedures",
          title: "Selection Procedures",
          key: "selection-procedures",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/rap-documents",
          title: "RAP Documents",
          key: "rap-documents",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/mentor-pool",
          title: "Mentor Pool",
          key: "mentor-pool",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        },
        {
          to: "/rti-hours",
          title: "RTI Hours",
          key: "rti-hours",
          isActive: false,
          hasArrow: false,
          icon: PersonIcon
        }
      ];

  const backAction = () => {
    navigate("/apprentice-180/rap");
  };

  const handleTabClick = tab => {
    navigate(`/apprentice-180/rap/info/${id}${tab.to}`);
  };

  const getRapInfoProfileData = async id => {
    setRapSubTitle("");
  };

  useEffect(() => {
    if (!isAddMode) getRapInfoProfileData(id);
  }, [id]);

  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab?.toLowerCase())?.title;
    setActiveTab(selectedTab || "RAP Info");
  }, [tab]);

  return (
    <Layout>
      {activeTab && (
        <DetailPageLayout
          backAction={backAction}
          tabs={tabs}
          title={isAddMode ? "ADD RAP INFO" : "Registered Apprenticeship Program"}
          subTitle={isAddMode ? "" : rapSubTitle}
          backLabel="Go back to Rap Info"
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        >
          {activeTab === "RAP Info" && <RapInfoProfile />}
          {activeTab === "RAP Ratio" && <RapRatio />}
          {activeTab === "Wage Rate" && <WageRate />}
          {activeTab === "WPS Activities" && <WpsActivities />}
          {activeTab === "Related Instruction Descriptions" && <RelatedInstructionDescriptions />}
          {activeTab === "Selection Procedures" && <SelectionProcedures />}
          {activeTab === "RAP Documents" && <RAPDocuments />}
          {activeTab === "Mentor Pool" && <MentorPool />}
          {activeTab === "RTI Hours" && <RTIHours />}
        </DetailPageLayout>
      )}
    </Layout>
  );
}
