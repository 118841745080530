import React, { useCallback, useState, useEffect } from "react";
import ComplianceNavigatorBaseTable from "./ComplianceNavigatorBaseTable";
import useColumn from "../../../utils/customHooks/useColumns";
import usePageState from "../../../utils/customHooks/usePageState";
import PropTypes from "prop-types";
import { DateCell } from "../ProGrid/components/FormatCell";
import { RATIO_MANAGEMENT_DETAILS, COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES } from "../../../utils/services/apiPath";
import { STAUTES } from "../../../utils/constant";
import { getApi } from "../../../utils/services";
import { createODataQuery } from "../../../utils/services/oData";
import { makeStyles } from "@mui/styles";

const rowStyles = makeStyles({
  issueRow: {
    backgroundColor: "#FFE0E0"
  }
});

const RatioManagement = ({ tableKey, projectId, partnerId }) => {
  const {
    currentPage,
    pageSize,
    data: ratioData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const [insightSummary, setInsightSummary] = useState([]);
  const rowClasses = rowStyles();

  const columns = useColumn([
    {
      field: "projectName",
      headerName: "Project Name"
    },
    {
      field: "partnerName",
      headerName: "Partner"
    },
    {
      field: "workDate",
      headerName: "Work Date",
      renderCell: params => <DateCell value={params.value} />
    },
    {
      field: "rapName",
      headerName: "RAP Name",
      width: 200
    },
    {
      field: "targetHeadCountRatio",
      headerName: "RAP Target Ratio"
    },
    {
      field: "totalMentors",
      headerName: "Mentor Headcount for the Day",
      width: 200
    },
    {
      field: "totalApprentices",
      headerName: "Apprentice Headcount for the Day",
      width: 200
    },
    {
      field: "actualHeadCountRatio",
      headerName: "Actual Headcount Ratio"
    },
    {
      field: "totalMentorHours",
      headerName: "Mentor Hours for the Day"
    },
    {
      field: "totalApprenticeHours",
      headerName: "Apprentice Hours for the Day"
    },
    {
      field: "actualTimeRatio",
      headerName: "Actual Time Ratio"
    }
  ]);

  const getRatioManagementList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const searchableFields = ["projectName", "partnerName","rapName"];
    const { data, totalRecords, error } = await getApi(
      RATIO_MANAGEMENT_DETAILS +
        createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage) +
        `&projectId=${projectId}&partnerId=${partnerId}`
    );
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const getRatioManagementSummary = useCallback(async () => {
    const summary = await getApi(`${COMPLIANCE_DOCUMENT_DETAIL_SUMMARIES}?key=${tableKey}&projectId=${projectId}&partnerId=${partnerId}`);
    setInsightSummary(summary);
  }, []);

  useEffect(() => {
    getRatioManagementList();
    getRatioManagementSummary();
  }, [getRatioManagementList, getRatioManagementSummary]);

  return (
    <>
      <ComplianceNavigatorBaseTable
        columns={columns}
        title="Ratio Management"
        rows={ratioData}
        insightData={insightSummary}
        tableKey={tableKey}
        rowCount={rowCount}
        status={status}
        setPagination={setPagination}
        setSortModel={setSortModel}
        searchString={searchString}
        setSearchString={setSearchString}
        getRowId={row => row.dailyAttendanceTrackerId}
        pageSize={pageSize}
        currentPage={currentPage}
        getRowClassName={params => (params.row.isIssueIdentified ? rowClasses.issueRow : "")}
      />
    </>
  );
};

RatioManagement.propTypes = {
  tableKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired
};

export default RatioManagement;
