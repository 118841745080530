// module
export const R_DASHBOARD = "DASHBOARD";
export const R_TOP_NAVIGATION = "TOP_NAVIGATION";
export const R_PARTNERS = "PARTNERS";
export const R_PROJECTS = "PROJECTS";
export const R_EMPLOYEES = "EMPLOYEES";
export const R_RAP_MGMT = "RAP_MGMT";
export const R_REPORTS = "REPORTS";
export const R_SETTINGS = "SETTINGS";
export const R_APPRENTICE_180 = "APPRENTICE_180";
export const R_PAYROLL_REPORTS = "REPORTS_CERTIFIED_PAYROLL_REPORTS";
export const R_AUDIT = "DASHBOARD";

// navigation submodule
export const R_TOP_NAVIGATION_DARK_MODE = "TOP_NAVIGATION_DARK_MODE";
export const R_TOP_NAVIGATION_NOTIFICATIONS = "TOP_NAVIGATION_NOTIFICATIONS";
export const R_TOP_NAVIGATION_VIDEO = "TOP_NAVIGATION_VIDEO";

// settings submodule
export const R_JOB_TITLE = "SETTINGS_JOB_TITLE";
export const R_USERS = "SETTINGS_USERS";
export const R_ROLES = "SETTINGS_USER_ROLES";
export const R_USER_PERMISSION = "SETTINGS_USER_PERMISSIONS";
export const R_ADDRESS_TYPE = "SETTINGS_ADDRESS_TYPE";
export const R_BONUS_TYPE = "SETTINGS_BONUS_TYPE";
export const R_COMPENSATION_TYPE = "SETTINGS_COMPENSATION_TYPE";
export const R_CLIENT_DOCUMENT_TYPE = "SETTINGS_CLIENT_DOCUMENT_TYPE";
export const R_PROJECT_TYPE = "SETTINGS_PROJECT_TYPE";
export const R_INDUSTRY_TYPE = "SETTINGS_INDUSTRY_TYPE";
export const R_DEPARTMENT_TYPE = "SETTINGS_DEPARTMENT_TYPE";
export const R_SOCIALMEDIA_TYPE = "SETTINGS_SOCIALMEDIA_TYPE";
export const R_CONTRACT_TYPE = "SETTINGS_CONTRACT_TYPE";
export const R_EMPLOYEE_STATUS_TYPE = "SETTINGS_EMPLOYEE_STATUS_TYPE";
export const R_EMPLOYEE_DOCUMENT_TYPE = "SETTINGS_EMPLOYEE_DOCUMENT_TYPE";
export const R_EMPLOYEE_TERM_TYPE = "SETTINGS_EMPLOYEE_TERM_TYPE";
export const R_FRINGE_PAYMENT_METHOD_TYPE = "SETTINGS_FRINGE_PAYMENT_METHOD_TYPE";
export const R_JOB_CATEGORY = "SETTINGS_JOB_CATEGORY";
export const R_LANGUAGE_TYPE = "SETTINGS_LANGUAGE_TYPE";
export const R_LEADSOURCE_TYPE = "SETTINGS_LEADSOURCE_TYPE";
export const R_LEADSUBSOURCE_TYPE = "SETTINGS_LEADSUBSOURCE_TYPE";
export const R_NOTIFICATION_TYPE = "SETTINGS_NOTIFICATION_TYPE";
export const R_WORK_TYPE = "SETTINGS_WORK_TYPE";
export const R_PROJECT_CONTACT_TYPE = "SETTINGS_PROJECT_CONTACT_TYPE";
export const R_SPONSOR_INFO = "SETTINGS_SPONSOR_INFO";
export const R_WPS_SETTINGS = "SETTINGS_WPS";

// employees submodule
export const R_NOTES = "EMPLOYEES_NOTES";
export const R_DOCUMENTS = "EMPLOYEES_DOCUMENT";
export const R_PROFILE = "EMPLOYEES_PROFILE";
export const R_SOCIAL_MEDIA = "EMPLOYEES_SOCIAL_MEDIA";
export const R_EMPLOYEES_LIST = "EMPLOYEES_EMPLOYEES_LIST";

// partners submodule
export const R_PARTNERS_PROFILE = "PARTNERS_PROFILE";
export const R_PARTNERS_NOTES = "PARTNERS_NOTES";
export const R_PARTNERS_SOCIALMEDIA = "PARTNERS_SOCIAL_MEDIA";
export const R_PARTNERS_LOCATION = "PARTNERS_LOCATIONS";
export const R_PARTNERS_LIST = "PARTNERS_PARTNERS_LIST";
export const R_PARTNERS_CONTACTS = "PARTNERS_CONTACT";
export const R_PARTNERS_DOCUMENTS = "PARTNERS_DOCUMENTS";

// rap management submodule
export const R_TIME_SHEET = "EMPLOYEES_TIME_SHEET";
export const R_RAP_MGMT_LIST = "RAP_MGMT_ASSIGNED_RAP_MANAGEMENT_LIST";
export const R_RAP_MGMT_APPRENTICE = "RAP_MGMT_APPRENTICE";
export const R_RAP_MGMT_STATUS = "RAP_MGMT_RAP_STATUS";
export const R_RAP_MGMT_WAGE_SCALE = "RAP_MGMT_WAGE_SCALE";

// Projects submodule
export const R_PROJECTS_LIST = "PROJECTS_PROJECTS_LIST";
export const R_PROJECTS_COMPLIANCE_NAVIGATOR = "PROJECTS_COMPLIANCE_NAVIGATOR";
export const R_PROJECTS_EMPLOYEE = "PROJECTS_EMPLOYEES";
export const R_PROJECTS_INFO = "PROJECTS_MY_PROJECT_INFO";
export const R_PROJECTS_JOBS = "PROJECTS_JOBS";
export const R_PROJECTS_PROFILE = "PROJECTS_PROFILE";
export const R_PROJECTS_CERTIFIED_PAYROLL = "PROJECTS_CERTIFIED_PAYROLL";
export const R_PROJECTS_MENTOR_APPRENTICE = "PROJECTS_MENTOR-APPRENTICE";
export const R_PROJECTS_WPS_TIME_ALLOCATION = "PROJECTS_WPS_TIME_ALLOCATION";


// Apprentice 180 submodule
export const R_APPRENTICE_180_RAP = "APPRENTICE_180_RAP";
export const R_APPRENTICE_180_EMPLOYEE = "APPRENTICE_180_EMPLOYEE";