import React, { useState } from "react";
import { Box, Typography, Modal, Button, Stack, Divider, Grid, Paper, IconButton, Alert, CircularProgress } from "@mui/material";
import ClrTextInput from "./TextField";
import PropTypes from "prop-types";
import "./InitiateClr.scss";
import CloseIcon from "@mui/icons-material/Close";
import Erroricon from "../../../Assets/IconComponents/Erroricon";
import { BASE_WS_API_TOKEN, BASE_PWC_API_URL } from "../../../../utils/constant";
import axios from "axios";
import { InputAdornment } from "@mui/material";
import DollerIcon from "../../../Assets/IconComponents/DollerIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "80%", md: 700, lg: 900 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 3
};

export const InitiateClr = ({ open, onClose, searchInput, onSaveClr, closeCrlEmailModel }) => {
  const [isEmailView, setIsEmailView] = useState(false);
  const [classification, setClassification] = useState("");
  const [proposedWageRate, setProposedWageRate] = useState("");
  const [proposedFringe, setProposedFringe] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [explanation, setExplanation] = useState("");
  const [isRecommendRatesLoader, setIsRecommendRatesLoader] = useState(false);
  const [filenameDecisionNumbers, setfilenameDecisionNumbers] = useState([]);

  const closeClrModel = () => {
    if (isEmailView) {
      closeCrlEmailModel();
    } else {
      setIsRecommendRatesLoader(false);
      setIsEmailView(false);
      setClassification("");
      setProposedWageRate("");
      setProposedFringe("");
      setWarningMessage("");
      onClose();
    }
  };

  const projectLocation = [searchInput?.wsPayload?.city, searchInput?.wsPayload?.county, searchInput.wsPayload?.state]
    .filter(s => s?.length > 0)
    .join(", ");

  const facilityLocation = [searchInput?.wsPayload?.address1, searchInput?.wsPayload?.address2].filter(s => s?.length > 0).join(", ");

  const getRecommendRates = async () => {
    setIsRecommendRatesLoader(true);
    setProposedWageRate("");
    setProposedFringe("");
    setWarningMessage("");
    setfilenameDecisionNumbers([]);
    const body = {
      is_external_job: searchInput?.values?.isExternalJobTitle || false,
      state: searchInput?.wsPayload?.state,
      county: searchInput?.wsPayload?.county,
      constructionType: searchInput?.constructionType,
      input_job_names: [classification]
    };
    try {
      const result = await axios.post(`${BASE_PWC_API_URL}get-nearby-rate-for-clr`, body, {
        headers: {
          Authorization: `Bearer ${BASE_WS_API_TOKEN}`
        }
      });
      if (result?.is_final_answer && result?.total_number_of_results) {
        const results = [];
        result?.results?.forEach(res =>
          results.push({ generalDecisionNumber: res?._source?.generalDecisionNumber, filename: res?._source?.filename })
        );
        setfilenameDecisionNumbers(results);
        setProposedWageRate(result?.avg_final_rate);
        setProposedFringe(result?.avg_fringe);
        setExplanation(result?.explanation);
      } else {
        setWarningMessage(result?.message);
      }
      setIsRecommendRatesLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveClrDetails = async () => {
    const { jobDescription, onetCode, occupationTitle } = searchInput.values;
    const filenameDecisionNumberExplanationRecommendation = [];
    filenameDecisionNumbers.forEach(fdn => {
      filenameDecisionNumberExplanationRecommendation.push(
        `<li class="subHeadingStyle">Decision Number: ${fdn.generalDecisionNumber} from ${fdn.filename}</li>`
      );
    });
    const proposedExplaination = explanation
      ? `<p class="fontStyle"><strong>Rationale for the Proposed Classification:</strong></p>
  <p class="subHeadingStyle">${explanation}</p>
  <p class="subHeadingStyle">The data used for this recommendation is: <br /> 
  <ul class="subHeadingStyle">
  ${filenameDecisionNumberExplanationRecommendation.join("")}
  </ul>
  </p>
  <br />`
      : "";

    const body = `<div>
  <style>
    .fontStyle {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      line-height: 1.5;
    }
    .subHeadingStyle {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      line-height: 1.5;
      position: relative;
      margin-left: 2em;
    }
  </style>
  <p class="fontStyle"><strong>Subject:</strong> Conformance Labor Classification Request – Applicable Job & Wage Determination</p>
  <br />
  <p class="fontStyle">Dear DOL Team,</p>
  <br />
  <p class="fontStyle">I trust this email finds you in good health.</p>
  <br />
  <p class="fontStyle">
    We are reaching out on behalf of our ProScore client, to submit a Conformance Labor Classifications Request to the Department of Labor
    (DOL), specifically to the Wage & Hour Division. We kindly request your assistance in obtaining the necessary approvals for a job
    title and applicable wage determination for a specific project.
  </p>
  <br />
  <p class="fontStyle">Project Details:</p>
  <p class="fontStyle" style="margin-left: 16px;">
    Project Name: ${searchInput?.wsPayload?.projectName}<br />
    Project Location: ${projectLocation}<br/ >
    Job Name: ${classification}
  </p>
  <br />
  <p class="fontStyle">Details for Classification Add Request:</p>
  <br />
  <p class="fontStyle"><strong>Type of Facility:</strong></p>
  <p class="subHeadingStyle">${searchInput?.wsPayload?.projectType}</p>
  <br />
  <p class="fontStyle"><strong>Facility Location:</strong></p>
  <p class="subHeadingStyle">${facilityLocation}</p>
  <br />
  <p class="fontStyle"><strong>Proposed Labor Classification:</strong></p>
  <p class="subHeadingStyle">${classification}</p>
  <br />
  <p class="fontStyle"><strong>Proposed Prevailing Wage Rate(s):</strong></p>
  <p class="subHeadingStyle">Utilizing the US Dept of Labor and Statistics rate information data, the Proposed Rate is $${proposedWageRate}, Fringed Rate is $${proposedFringe}, and a Total Compensation of $${
      Math.round((parseFloat(proposedWageRate) + parseFloat(proposedFringe)) * 100) / 100
    } per market data.</p>
  <br />
  <p class="fontStyle"><strong>Job Description and Duties:</strong></p>
  <p class="subHeadingStyle">${jobDescription}</p>
  <br />
  ${proposedExplaination}
  <p class="fontStyle">
    We appreciate your prompt attention to this matter. If further information or clarification is required, please do not hesitate to
    reach out.
  </p>
  <br />
  <p class="fontStyle">Thank you for your consideration.</p>
  <br />
  <p class="fontStyle">Kindest Regards,</p>
  <br />
  <p class="fontStyle">ProScore Team</p>
</div > `;
    setEmailBody(body);
    const payload = {
      ...searchInput?.values,
      isCLRInitiated: true,
      conformanceLabourRequest: {
        jobClassification: classification,
        proposedWageRate: proposedWageRate,
        proposedFringe: proposedFringe,
        clrStatus: "In Progress",
        emailTo: "dol",
        emailSubject: "Conformance Labor Classification Request – Applicable Job & Wage Determination",
        emailBody: body
      }
    };
    onSaveClr(payload);
    setIsEmailView(true);
  };

  const closeClr = async () => {
    closeCrlEmailModel();
  };

  const getCommaValue = arrList => {
    let stringComma = "";
    arrList?.map((item, index) => {
      if (index === 0) stringComma = item;
      else stringComma = `${stringComma}, ${item}`;
    });
    return stringComma;
  };

  return (
    <Modal open={open} onClose={closeClrModel}>
      <Box sx={style}>
        <Typography variant="h6" className="model-title">
          Initiate CLR
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => closeClrModel()}
          aria-label="close"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            marginRight: "10px",
            marginTop: "10px",
            "&:hover": {
              background: "none"
            }
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
        <Divider sx={{ mt: 1, mb: 2 }} />
        {!isEmailView ? (
          <>
            <Grid item xs={12} md={12}>
              <Paper
                sx={{
                  padding: "10px 0px",
                  backgroundColor: " #D3F3FF",
                  boxShadow: "none"
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Grid
                    item
                    sm={3}
                    sx={{
                      paddingLeft: "6% !important",
                      borderRight: { xs: "none", sm: "1px solid #00B0EE" },
                      mt: 2
                    }}
                  >
                    <Typography variant="subtitle2" className="title">
                      State: <strong>{searchInput.wsPayload.state}</strong>
                    </Typography>
                    <Typography variant="subtitle2" className="title">
                      Category: <strong>{searchInput.constructionType}</strong>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    sx={{
                      paddingLeft: "3% !important ",
                      borderRight: { xs: "none", sm: "1px solid #00B0EE" },
                      mt: 2
                    }}
                  >
                    <Typography variant="subtitle2" className="title">
                      County : <strong>{getCommaValue(searchInput.wsPayload.county)}</strong>
                    </Typography>
                    <Typography className="title">
                      {searchInput?.values?.isExternalJobTitle ? "External Job Title" : "Job Title"} : <strong>{searchInput.jobTitlePW}</strong>
                    </Typography>
                  </Grid>

                  <Grid item sm={5} sx={{ paddingLeft: "3% !important", mt: 2 }}>
                    <Typography variant="subtitle2" className="title">
                      Job Description : <strong>{searchInput?.values?.jobDescription}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Grid item xs={12} sm={4}>
                <ClrTextInput
                  label={"Provide the proposed job classification"}
                  value={classification}
                  onChange={e => setClassification(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ClrTextInput
                  label={"What is the Proposed Wage for this Position?"}
                  value={proposedWageRate}
                  onChange={e => setProposedWageRate(e.target.value)}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      <DollerIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ClrTextInput
                  label={"What is the Proposed Fringe for this Position?"}
                  value={proposedFringe}
                  onChange={e => setProposedFringe(e.target.value)}
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      <DollerIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              disabled={!classification}
              sx={{ mt: 3, backgroundColor: "#DDF6FF" }}
              onClick={getRecommendRates}
            >
              Recommend rates
            </Button>
            {isRecommendRatesLoader && (
              <Box sx={{ m: 5, height: 10, textAlign: "center" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </Box>
            )}
            {warningMessage && (
              <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                <Alert
                  severity="warning"
                  icon={<Erroricon />}
                  className="warningicon"
                  sx={{
                    backgroundColor: "#FFE1B4",
                    borderRadius: "4px",
                    color: " #935800",
                    padding: "0px 10px !important"
                  }}
                >
                  {warningMessage}
                </Alert>
              </Grid>
            )}
            <Divider sx={{ my: 2, mt: 15 }} />
            <Stack direction="row" justifyContent="space-between">
              <Button variant="outlined" onClick={closeClrModel}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={saveClrDetails}>
                Send Email
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Box sx={{ p: 2, maxHeight: "400px", overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: emailBody }} />
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ textAlign: "right", pt: 2 }}>
              <Button className="cancel-btn" variant="outlined" color="secondary" sx={{ marginRight: "10px" }} onClick={closeClr}>
                Close
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
InitiateClr.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  searchInput: PropTypes.object,
  onSaveClr: PropTypes.func.isRequired,
  closeCrlEmailModel: PropTypes.func.isRequired
};
