import React from "react";
import InputWrapper from "./InputWrapper";
import "./ReadOnlyInput.scss";
import { FormControl, FormControlLabel, FormHelperText, Switch, Tooltip, useTheme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { Controller } from "react-hook-form";
import { DEFAULT_SIZE } from "../../../utils/constant";
import PropTypes from "prop-types";

export default function ToggleField({
  name,
  mode,
  rules,
  label,
  control,
  isAddMode,
  infoMessage,
  withInfoIcon,
  handleReadValueClick,
  permission = {},
  size = DEFAULT_SIZE,
  onChange: customOnChange
}) {
  const { canView = false, canEdit = false, canAdd = false } = permission;
  const theme = useTheme();
  if (!canView || (isAddMode && !canAdd)) return null;
  const handleValueClick = () => {
    if (mode === "read" && canEdit) handleReadValueClick(name);
  };

  const cellStyles = { color: theme.palette.inputs.toggleButton.cellStyles };
  const editModeAllowed = isAddMode ? canAdd : canEdit;

  return (
    <InputWrapper size={size}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const handleChange = event => {
            const newValue = event.target.checked;
            onChange(newValue);
            if (customOnChange) {
              customOnChange(event, newValue);
            }
          };
          return (
            <FormControl className="proInputWithMessage proSwitch" variant="standard">
              <div className="proInputWithMessage-content">
                <div className="proInputWithMessage-input">
                  <FormControlLabel
                    label={label}
                    control={
                      <Switch
                        sx={cellStyles}
                        className="mui-switch"
                        disabled={!editModeAllowed}
                        checked={value}
                        onChange={handleChange}
                        focusVisibleClassName=".Mui-focusVisible"
                      />
                    }
                    onClick={handleValueClick}
                  />
                </div>
                {withInfoIcon && (
                  <div className="proInputWithMessage-info">
                    <Tooltip title={infoMessage} placement="top">
                      <HelpIcon color="primary" />
                    </Tooltip>
                  </div>
                )}
              </div>
              <FormHelperText error={!!error}>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </InputWrapper>
  );
}

ToggleField.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["read", "edit"]).isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  infoMessage: PropTypes.string,
  isAddMode: PropTypes.bool,
  withInfoIcon: PropTypes.bool,
  handleReadValueClick: PropTypes.func,
  permission: PropTypes.shape({
    canView: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool
  }),
  size: PropTypes.string
};
