import React, { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import "./index.scss";
import JourneyToApprenticeChart from "../../Charts/JourneyToApprenticeChart";
import { GET_PROJECT_JOURNEY_TO_APPRENTICE_LIST } from "../../../../utils/services/apiPath";
import { getApi } from "../../../../utils/services";
import { v4 as uuidV4 } from "uuid";
import dayjs from "dayjs";
import { roundNumber } from "../../../../utils/formHelper";
import { errorToast } from "../../../../utils/toastHelper";
import { useWindowSize } from "../../../../utils/customHooks/useWindowSize";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import { Skeleton, Stack } from "@mui/material";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
};

const JourneyToApprenticeCard = ({ projectName, backgroundColor, chartData, backgroundImage, projectGuid }) => {
  return (
    <div className="dashboard-journey-card">
      <div className="dashboard-journey-background" style={{ backgroundColor: backgroundColor, backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="dashboard-journey-card-content">
        <div className="dashboard-journey-card-title">Journey to Apprentice</div>
        <div className="dashboard-journey-card-subtitle">{projectName}</div>
        <div className="dashboard-journey-card-chart">
          <JourneyToApprenticeChart chartData={chartData} projectGuid={projectGuid} />
        </div>
      </div>
    </div>
  );
};

JourneyToApprenticeCard.propTypes = {
  projectName: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  chartData: PropTypes.shape({
    xAxisData: PropTypes.array.isRequired,
    seriesData: PropTypes.array.isRequired
  }).isRequired,
  backgroundImage: PropTypes.string.isRequired,
  projectGuid: PropTypes.string.isRequired
};

const JourneyToCardWrapper = ({ wrapSlick, journeyData, journeyDataLoading }) => {
  if(journeyDataLoading){
    return (
      <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Skeleton variant="rectangular" width={366} height={219} />
        <Skeleton variant="rectangular" width={366} height={219} />
        <Skeleton variant="rectangular" width={366} height={219} />
      </Stack>
    )
  }
  return wrapSlick ? (
    <Slider {...settings}>
      {journeyData?.map((data, index) => (
        <JourneyToApprenticeCard key={data.projectId} index={index} {...data} />
      ))}
    </Slider>
  ) : (
    <>
      {journeyData?.map((data, index) => (
        <JourneyToApprenticeCard key={data.projectId} index={index} {...data} />
      ))}
    </>
  );
};

JourneyToCardWrapper.propTypes = {
  wrapSlick: PropTypes.bool.isRequired,
  journeyData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default function JourneyToApprentice({ journeyWrapperRef }) {
  const [journeyData, setJourneyData] = useState([]);
  const theme = useTheme();
  const [journeyDataLoading, setJourneyDataLoading] = useState(true);
  const { width } = useWindowSize(true);
  const journeyDataLength = journeyData?.length;

  const bgConfig = {
    0: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard1color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard1image
    },
    1: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard2color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard2image
    },
    2: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard3color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard3image
    },
    3: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard4color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard4image
    },
    4: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard5color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard5image
    },
    5: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard6color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard6image
    },
    6: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard7color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard7image
    },
    7: {
      color: theme.palette.demo.dashboardjounerytoapprenticecard8color,
      image: theme.palette.demo.dashboardjounerytoapprenticecard8image
    }
  };

  const getData = async () => {
    const { data, error } = await getApi(GET_PROJECT_JOURNEY_TO_APPRENTICE_LIST);
    if (error) return errorToast(error);
    setJourneyData(
      data.map((item, index) => {
        const chartData = { xAxisData: [], seriesData: [] };
        const background = bgConfig[index % 8];
        item.data.forEach(dataItem => {
          chartData.xAxisData.push(dayjs(dataItem.workedDate, "YYYY-MM-DD").format("YYYY DD MMM"));
          chartData.seriesData.push(roundNumber(+dataItem.journeyToApprenticeRatio));
        });
        return { ...item, chartData, backgroundColor: background.color, projectId: uuidV4(), backgroundImage: background.image };
      })
    );
    setJourneyDataLoading(false);
  };

  useEffect(() => {
    getData();
  }, [theme]);

  const wrapSlick = useMemo(() => {
    let numberOfSlides = 4;
    if (width <= 1400) numberOfSlides = 3;
    if (width <= 1200) numberOfSlides = 2;
    if (width <= 480) numberOfSlides = 1;
    return journeyDataLength > numberOfSlides;
  }, [journeyDataLength, width]);
  return (
    <div className="dashboard-journey-wrapper" ref={journeyWrapperRef}>
      <div className={`dashboard-journey ${wrapSlick ? "" : "no-slick"}`}>
        <JourneyToCardWrapper wrapSlick={wrapSlick} journeyData={journeyData} journeyDataLoading={journeyDataLoading} />
      </div>
    </div>
  );
}

JourneyToApprentice.propTypes = {
  journeyWrapperRef: PropTypes.object.isRequired
};
