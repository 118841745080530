import React, { useEffect, useMemo, useState } from "react";
import { changeMode, fieldTruePermission, prepareDefaultValues, prepareInitialConfig, checkEditEnabled } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { getApi, postApi, putApi } from "../../../../utils/services";
import { ADMIN_SELECTION_PROCEDURES } from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";

const SelectionFormConfig = {
  occupationProcedures: {
    label: "Occupation Procedure",
    perKey: "occupationProcedures",
    fieldProps: { multiline: true, rows: 4 },
    rules: { required: "Occupation Procedure is required!" }
  }
};
export default function SelectionProcedures() {
  const { id } = useParams();
  const readOnly = localStorage.getItem("viewingReadOnlyRapInfo") == "true"
  const navigate = useNavigate();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(SelectionFormConfig));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(SelectionFormConfig) });
  const [selectionProcedure, setSelectionProcedure] = useState({})
  const defaultFormProps = { control, formValues: selectionProcedure };
  const [status, setStatus] = useState(STAUTES.IDLE);
  const isAddMode = false;
  const [isSaving, setIsSaving] = useState(false);

  const onSave = async formData => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = {
      rapInfoId: id,
      occupationProcedures: formData?.occupationProcedures
    };
    const method = selectionProcedure ? putApi : postApi
    const url = selectionProcedure ? `${ADMIN_SELECTION_PROCEDURES}/${selectionProcedure.rapSelectionProcedureId}` : ADMIN_SELECTION_PROCEDURES
    const { error } = await method(url, payload);
    if (error) return errorToast(error);
    getAllSelectionProcedure();
    setFormConfig(changeMode("read"))
    setIsSaving(false);
    successToast(`Selection Procedure updated successfully.`);
    setStatus(STAUTES.IDLE);
  };

  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);
  const onEdit = () => setFormConfig(changeMode("edit"));

  const getAllSelectionProcedure = async () => {
    const { data, error } = await getApi(`${ADMIN_SELECTION_PROCEDURES}?rapInfoId=${id}`);
    if (error) return errorToast(error);
    setSelectionProcedure(data[0])
    setValue("occupationProcedures", data[0]?.occupationProcedures);
  };

  const backAction = () => { navigate(`/apprentice-180/rap/info/${id}/selection-procedures`) }

  const onCancel = () => {
    setFormConfig(changeMode("read"));
    setValue("occupationProcedures", selectionProcedure?.occupationProcedures);
  };

  useEffect(() => {
    getAllSelectionProcedure();
  }, []);

  return (
    <ProFormsLayout
      status={status}
      backAction={backAction}
      title={`Selection Procedure`}
      backLabel="Go Back to Rap Info"
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      onCancel={onCancel}
      onSave={handleSubmit(onSave)}
      isSaving={isSaving}
      hideEdit={readOnly}
    >
      <ProTextInput {...defaultFormProps} {...formConfig.occupationProcedures} permission={fieldTruePermission} size={12} />
    </ProFormsLayout>
  );
}