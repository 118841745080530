import React, { useState } from "react";
import { Box, Divider, Typography, Modal, Button, IconButton } from "@mui/material";
import ProButton from "../../../Components/ProButton";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { formatKeys } from "../../../../utils/formHelper";
import ProGrid from "../../../Components/ProGrid/ProGrid";
import useColumn from "../../../../utils/customHooks/useColumns";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import RTIViewIcon from "../../../Assets/IconComponents/RTIViewIcon";
import CloseIcon from "@mui/icons-material/Close";

const RTIHoursJson = ["Apprentice Name", "Employee ID", "Work Date", "Module Name", "Module ID", "Lesson Name", "Lesson ID", "RTI Hours"];

const fields = RTIHoursJson.map(fieldName => ({
  label: fieldName,
  key: fieldName,
  fieldType: {
    type: "input"
  }
}));

const RTIHours = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const columns = useColumn([
    {
      field: "apprentice",
      headerName: "Apprentice",
      renderCell: params => <Typography sx={{ textDecoration: "underline" }}>{params?.row.apprentice}</Typography>,
    },
    {
      field: "employeeId",
      headerName: "Employee ID"
    },
    {
      field: "totalRTIHours",
      headerName: "Total RTI Hours",
      renderCell: params => (
        <div
          style={{ display: "flex", alignItems: "center", gap: "8px", width: "30px !important", justifyContent: "space-between", cursor: "pointer" }}
        >
          <Box>{params.value} Hours</Box>
          <Box onClick={() => handleOpenModal(params.row)}>
            <RTIViewIcon />
          </Box>
        </div>
      )
    }
  ]);

  const RTIHoursData = [
    { id: 1, apprentice: "David Willey", employeeId: "27484", totalRTIHours: 144 },
    { id: 2, apprentice: "Travis Head", employeeId: "34343", totalRTIHours: 110 },
    { id: 3, apprentice: "Mitch Marsh", employeeId: "56454", totalRTIHours: 115 }
  ];

  const handleOpenDialogPopup = () => {
    setIsOpen(true);
  };

  const handleCloseDialogPopup = () => {
    setIsOpen(false);
  };

  const handleOpenModal = employee => {
    setSelectedEmployee(employee);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleRTIHoursDownload = () => {
    const filePath = "/static/Images/Excel/RTIHours.xlsx";
    window.open(filePath, "_blank");
  };

  const handleSubmit = async ({ validData }) => {
    const excelData = formatKeys(validData);
    console.log(excelData);
  };

  const RTIViewColumns = useColumn([
    {
      field: "workDate",
      headerName: "workDate",
      renderCell: params => <Typography sx={{ textDecoration: "underline" }}>{params?.row.workDate}</Typography>,
      width: 150
    },
    {
      field: "moduleName",
      headerName: "Module Name",
      renderCell: params => <Typography sx={{ textDecoration: "underline" }}>{params?.row.moduleName}</Typography>
    },
    {
      field: "lessonName",
      headerName: "Lesson Name",
      renderCell: params => <Typography sx={{ textDecoration: "underline" }}>{params?.row.lessonName}</Typography>
    },
    {
      field: "rtiHours",
      headerName: "RTI Hours",
      width: 150
    }
  ]);

  const RTIViewData = [
    { id: "1", workDate: "1/16/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "System Upgrades and Repairs", rtiHours: 4 },
    { id: "2", workDate: "1/15/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "System Upgrades and Repairs", rtiHours: 1 },
    { id: "3", workDate: "1/14/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "System Upgrades and Repairs", rtiHours: 2 },
    { id: "4", workDate: "1/13/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "Troubleshooting Common Issues", rtiHours: 1 },
    { id: "5", workDate: "1/12/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "Troubleshooting Common Issues", rtiHours: 2 },
    { id: "6", workDate: "1/11/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "Maintenance Procedure", rtiHours: 2 },
    { id: "7", workDate: "1/10/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "Maintenance Procedure", rtiHours: 2 },
    { id: "8", workDate: "1/09/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "Maintenance Procedure", rtiHours: 1 },
    { id: "9", workDate: "1/08/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "Maintenance Procedure", rtiHours: 1 },
    { id: "10", workDate: "1/07/2025", moduleName: "Maintenance and Troubleshooting", lessonName: "Maintenance Procedure", rtiHours: 1 }
  ];

  return (
    <Box sx={{ m: 4 }}>
      <Box>
        <Typography variant="h6" align="left">
          RTI Hours
        </Typography>
        <Divider />
      </Box>
      <div style={{ display: "flex", gap: "1rem", padding: "1rem" }}>
        <ProButton className="primary pro_button" onClick={handleOpenDialogPopup}>
          Upload RTI Hours
        </ProButton>
        <ProButton className="primary pro_button" onClick={handleRTIHoursDownload}>
          RTI Hours Template
        </ProButton>
      </div>
      <ProGrid title="Employees" columns={columns} rows={RTIHoursData} />
      <ReactSpreadsheetImport isOpen={isOpen} onClose={handleCloseDialogPopup} onSubmit={handleSubmit} fields={fields} />

      <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="rti-hours-modal">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 24
          }}
        >
          <Box
            sx={{
              bgcolor: "#E4F8FF",
              borderRadius: "8px 8px 0 0",
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "600" }} gutterBottom>
              RTI hours | {selectedEmployee?.apprentice} | {selectedEmployee?.employeeId}
            </Typography>
            <IconButton onClick={handleCloseModal} sx={{ color: "#575757" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ height: "400px", overflowY: "scroll" }}>
            <ProGrid title="RTI Hours Details" columns={RTIViewColumns} rows={RTIViewData} pageSize={5} />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, p: 2 }}>
            <Button variant="outlined" onClick={handleCloseModal}>
              Cancel
            </Button>
            <ProButton className="primary pro_button" onClick={handleCloseModal}>
              Done
            </ProButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RTIHours;
