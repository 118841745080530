import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import { convertFileToBase64Model, downloadFile, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import DownloadCell from "../../../Components/ProGrid/components/DownloadCell";
import usePageState from "../../../../utils/customHooks/usePageState";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import FileUploadField from "../../../Components/Inputs/FileUploadField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { postApi, getApi, deleteApi, putApi } from "../../../../utils/services";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import { createODataQuery } from "../../../../utils/services/oData";
import { ADMIN_RAP_INFO_DOCUMENTS, RAP_DOCUMENT_TYPES } from "../../../../utils/services/apiPath";

const RAPDocumentsFormConfing = {
  documentTypeId: {
    label: "Document Type",
    perKey: "SelectDocument",
    rules: { required: "Required!" }
  },
  documents: {
    label: "Select Documents",
    rules: { required: "Documents is required!" }
  }
};

export default function RAPDocuments() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(RAPDocumentsFormConfing));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(RAPDocumentsFormConfing) });
  const readOnly = localStorage.getItem("viewingReadOnlyRapInfo") == "true"
  const [documentTypesData, setDocumentTypesData] = useState([]);
  const [actionData, setActionData] = useState({});
  const [formData, setFormData] = useState();
  const [isAddMode, setIsAddMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    currentPage,
    pageSize,
    data: rapDocumentsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "documentName",
        headerName: "Document Name",
        renderCell: params => <NameCell params={params} onClick={onDocumentClick} disabled={readOnly} />
      },
      {
        field: "rapDocumentType",
        headerName: "Document Type"
      },
      {
        field: "rapDocumentTypeId",
        headerName: "Download",
        renderCell: params => <DownloadCell params={params} onOpen={() => downloadFile(params.row?.logo?.path)} />
      },
      {
        field: "rapDocumentId",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} disabled={readOnly} />
      }
    ],
    true
  );

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "edit" } }), {}));
  };
  const handleReadValueclick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const resetForm = () => {
    setFormData({});
    setShowGrid(true);
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "read" } }), {}));
  };

  const onCancel = () => {
    resetForm();
  };

  const onDocumentClick = useCallback(async params => {
    setShowGrid(false);
    setActionData(params.row);
    const res = await getApi(`${ADMIN_RAP_INFO_DOCUMENTS}/${params.row.rapDocumentId}`);
    const data = {
      documents: res.documentName,
      documentTypeId: res.rapDocumentTypeId
    };
    setFormData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  });

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
  };

  const getAllDocumentTypes = async () => {
    const { data, error } = await getApi(RAP_DOCUMENT_TYPES);
    if (error) return errorToast("Unable to fetch document types. Please try again or contact us.")
    setDocumentTypesData(data?.map(item => ({ label: item.label, value: item.value })));
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const base64ProfilePicture = await convertFileToBase64Model(data.documents);
    const { rapDocumentId } = actionData;
    const payload = {
      rapDocumentTypeId: data?.documentTypeId,
      documentName: data.documents.name,
      rapInfoId: id,
      logo: base64ProfilePicture ?? null,
    };
    const methodApi = rapDocumentId ? putApi : postApi;
    const { error } = await methodApi(rapDocumentId ? `${ADMIN_RAP_INFO_DOCUMENTS}/${rapDocumentId}` : ADMIN_RAP_INFO_DOCUMENTS, payload);
    if (error) return errorToast(error);
    successToast(`Partners Documents ${rapDocumentId ? "Updated" : "Addeed"}successfully.`);
    getDocumentList();
    setIsSaving(false);
    resetForm();
  };

  const onDelete = async id => {
    const { error } = await deleteApi(`${ADMIN_RAP_INFO_DOCUMENTS}/${id}`);
    if (error) return errorToast(error);
    successToast("RAP Document deleted successfully");
    getDocumentList();
  };

  const getDocumentList = useCallback(async () => {
    const searchableFields = [
      "documentName",
      "rapDocumentType",
    ];
    const { data, totalRecords, error } = await getApi(ADMIN_RAP_INFO_DOCUMENTS + `${createODataQuery(searchableFields, [], "", searchString, sortModel, pageSize, currentPage)}&rapInfoId=${id}`);
    if (error) return errorToast(error);

    setPageState(prevPageInfo => ({
      ...prevPageInfo,
      data:
        data?.map((item, index) => {
          return { ...item, id: index };
        }) || [],
      rowCount: totalRecords
    }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getDocumentList();
    getAllDocumentTypes();
  }, [getDocumentList]);

  const defaultFormProps = { control, formValues: formData, isAddMode, handleReadValueclick };
  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.rapDocumentsGuid ? "Edit" : "Add"} Document`}
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={true}
      backLabel={"Go Back to Documents"}
      hideEdit={readOnly}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Documents"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.rapDocumentId,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          rows={rapDocumentsData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Document"
          hideExport={false}
          hideAddButton={false}
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%", marginTop: "5px" }}>
          <FileUploadField {...defaultFormProps} {...formConfig.documents} permission={fieldTruePermission} />
          <ProSelectField {...defaultFormProps} {...formConfig.documentTypeId} options={documentTypesData} permission={fieldTruePermission} />
        </div>
      )}
    </TabGridAndFormLayout>
  );
}
