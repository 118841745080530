import React, { useEffect, useMemo, useState } from "react";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { createSearchParams, generatePath, Link, useNavigate, useParams } from "react-router-dom";
import BottomTable from "./BottomTable.jsx";
import MiddleCharts from "./MiddleCharts.jsx";
import TopCards from "./TopCards.jsx";
import Layout from "../../Components/Layout";
import dayjs from "dayjs";
import {
  GET_ALL_APPRENTICE_LIST,
  GET_ALL_PROJECT,
  GET_LOST_OTJ,
  GET_WORKFORCE_HOURS,
  GET_LOST_OTJ_REPORT,
  GET_APPRENTICE_HOURS_REPORT_LIST,
  GET_ALL_PARTNERS_DROPDOWN,
  GET_ALL_PARTNERS_RAP_DROPDOWN
} from "../../../utils/services/apiPath";
import { getApi, postApi } from "../../../utils/services";
import { errorToast } from "../../../utils/toastHelper.js";
import ProButton from "../../Components/ProButton/index.jsx";
import ScheduleReportModal from "../../Components/ScheduleReportModal/index.jsx";
import { urlBuilder } from "../../../utils/urlBuilder.js";
import { useSelector } from "react-redux";
import { routerSelector } from "../../store/routerSlice.js";

const LostOtjConfig = {
  projectId: {
    label: "Project",
    perKey: "Project",
    placeholder: "All Project",
    defaultValue: "All"
  },
  partnerId: {
    label: "Partner",
    perKey: "Partner",
    placeholder: "All Partners",
    defaultValue: "All"
  },
  apprenticeId: {
    label: "Apprentice",
    perKey: "Apprentice",
    placeholder: "All Apprentice",
    defaultValue: "All"
  },
  rapId: {
    label: "Rap",
    perKey: "Rap",
    placeholder: "All Raps",
    defaultValue: "All"
  },
  dateRange: {
    label: "DateRange",
    perKey: "DateRange",
    placeholder: "Select Date Range"
  }
};

export default function DemoLostOtjHours() {
  const { id } = useParams();
  const navigator = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(LostOtjConfig));
  const { control, setValue, watch } = useForm({ defaultValues: prepareDefaultValues(LostOtjConfig) });
  const handleReadValueClick = name => setFormConfig(changeModeForField(name, "edit"));
  const [projectsList, setProjectsList] = useState([]);
  const [apprenticeList, setApprenticeList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [rapList, setRapList] = useState([]);
  const [lostOtj, setLostOtj] = useState([]);
  const [workforceHours, setWorkforceHours] = useState([]);
  const project = watch("projectId");
  const apprentice = watch("apprenticeId");
  const partner = watch("partnerId");
  const rap = watch("rapId");
  const dateRange = watch("dateRange");
  const [lostOTJReport, setLostOTJReport] = useState({});
  const [apprenticeHoursReportList, setApprenticeHoursReportList] = useState([]);
  const [pageState, setPageState] = useState({ pageIndex: 0, pageSize: 25, totalRecord: 0, loading: true });
  const [showExportPreview, setShowExportPreview] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onEdit = () => setFormConfig(changeMode("edit"));

  const typeOptions = {
    projectId: projectsList,
    apprenticeId: apprenticeList,
    partnerId: partnersList,
    rapId: rapList
  };

  useEffect(() => {
    if (!isAddMode) onEdit();
  }, [id]);

  const fetchProjects = async () => {
    const { data } = await getApi(GET_ALL_PROJECT);
    setValue("projectId", data[0]?.projectId);
    setProjectsList(data?.map(item => ({ label: item.projectName, value: item.projectId })));
  };

  const fetchPartners = async () => {
    const res = await getApi(`${GET_ALL_PARTNERS_DROPDOWN}/${project}`);
    setValue("partnerId", res[0]?.clientGuid);
    setPartnersList(res?.map(item => ({ label: item.companyName, value: item.clientGuid })));
  };

  const fetchApprentice = async () => {
    const { data } = await getApi(`${GET_ALL_APPRENTICE_LIST}?projectGuid=${project}&partnerGuid=${partner}`);
    setValue("apprenticeId", data[0]?.apprenticeId);
    setApprenticeList(data?.map(item => ({ label: item.apprenticeName, value: item.apprenticeId })));
  };

  const fetchPartnerRap = async () => {
    const { data } = await getApi(`${GET_ALL_PARTNERS_RAP_DROPDOWN}?projectGuid=${project}&partnerGuid=${partner}`);
    if (data?.length > 0) {
      setValue("rapId", data[0]?.value);
      setRapList(data?.map(item => ({ label: item.label, value: item.value })));
    }
  };

  const formatDate = timestamp => {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchLostOTJ = async () => {
    const [startDate, endDate] = dateRange;
    const url = urlBuilder(GET_LOST_OTJ, {
      projectGuid: project,
      apprenticeGuid: apprentice,
      partnerGuid: partner,
      rapGuid: rap,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data } = await getApi(
      url
    );
    setLostOtj(data);
  };


  const fetchWorkforceHours = async () => {
    const [startDate, endDate] = dateRange;
    const url = urlBuilder(GET_WORKFORCE_HOURS, {
      projectGuid: project,
      apprenticeGuid: apprentice,
      partnerGuid: partner,
      rapGuid: rap,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data } = await getApi(
      url
    );
    setWorkforceHours(data);
  };

  const { defaultReportRoutes } = useSelector(routerSelector)
  const { reportId, path } = defaultReportRoutes.filter((route) => route.path == "lost-otj-hours-report")?.[0];
  useEffect(() => {
    const from = dayjs().subtract(16, "day");
    const to = dayjs().subtract(1, "day");
    const weekEndingDate = [from, to];
    setValue("dateRange", weekEndingDate);
    fetchProjects();
  }, []);

  const fetchLostOTJReport = async () => {
    const [startDate, endDate] = dateRange;
    const url = urlBuilder(GET_LOST_OTJ_REPORT, {
      projectGuid: project,
      apprenticeGuid: apprentice,
      partnerGuid: partner,
      rapGuid: rap,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data } = await getApi(
      url
    );
    setLostOTJReport(data);
  };

  const onPageChange = ({ page, pageSize }) => {
    setPageState(prevState => ({ ...prevState, pageIndex: page, pageSize }));
    fetchApprenticeHoursReportList(page + 1, pageSize);
  };

  const fetchApprenticeHoursReportList = async (page, pageSize) => {
    const payload = {
      pageIndex: page ?? pageState.pageIndex + 1,
      pageSize: pageSize ?? pageState.pageSize,
      orderByAscending: true,
      searchString: "",
      orderCol: ""
    };
    setPageState(prevState => ({ ...prevState, loading: true }));
    const [startDate, endDate] = dateRange;
    const url = urlBuilder(GET_APPRENTICE_HOURS_REPORT_LIST, {
      projectGuid: project,
      apprenticeGuid: apprentice,
      partnerGuid: partner,
      rapGuid: rap,
      dateFrom: formatDate(startDate),
      dateTo: formatDate(endDate)
    })
    const { data, error, totalRecords } = await postApi(
      url,
      payload
    );
    setPageState(prevState => ({ ...prevState, loading: false }));
    if (error) return errorToast(error);
    const formattedData = (data || []).map((item, index) => ({ ...item, id: index + 1 }));
    setPageState(prevState => ({ ...prevState, loading: false, totalRecord: totalRecords }));
    setApprenticeHoursReportList(formattedData);
  };

  const exportLink = () => {
    const [startDate, endDate] = dateRange;

    return {
      pathname: `/${path}`,
      search: createSearchParams({
        "$filter": `?projectGuid=${project}&apprenticeGuid=${apprentice}&dateFrom=${formatDate(startDate)}&dateTo=${formatDate(endDate)}`
      }).toString()
    };
  };

  useEffect(() => {
    if (project) {
      fetchPartners();
    }
  }, [project]);

  useEffect(() => {
    if (project && partner) {
      fetchApprentice();
      fetchPartnerRap();
    }
  }, [project, partner]);

  useEffect(() => {
    if (project && apprentice && partner && dateRange) {
      fetchLostOTJ();
      fetchWorkforceHours();
      fetchLostOTJReport();
      fetchApprenticeHoursReportList();
    }
  }, [project, apprentice, partner, dateRange]);

  const defaultFormProps = { control, formValues: {}, isAddMode, handleReadValueClick };

  return (
    <Layout>
      <div className="demo-container">
        <TopCards
          defaultFormProps={defaultFormProps}
          formConfig={formConfig}
          typeOptions={typeOptions}
          setValue={setValue}
          lostOTJReport={lostOTJReport}
          cardTitleButton={
            <div style={{ display: "flex", gap: 5 }}>
              <ProButton onClick={() => setIsModalOpen(true)}>Schedule</ProButton>
              <Link style={{ color: "white" }} target="_blank" to={exportLink()}>
                <ProButton>Export</ProButton>
              </Link>
            </div>
          }
        ></TopCards>
        <MiddleCharts lostOtj={lostOtj} workforceHours={workforceHours}></MiddleCharts>
        <BottomTable apprenticeHoursReportList={apprenticeHoursReportList} pageState={pageState} onPageChange={onPageChange}></BottomTable>

        <ScheduleReportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} reportId={reportId} />

      </div>
    </Layout>
  );
}
