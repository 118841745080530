import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material";

const LostOTJ = ({ data }) => {
  const theme = useTheme();
  const options = {
    chart: {
      type: "area",
      backgroundColor: theme.palette.demo.lostotjbackgroundcolor
    },
    title: {
      text: ""
    },
    xAxis: {
      categories: data.xAxisData,
      gridLineWidth: 1,
      gridLineColor: "#e6e6e6",
      gridLineDashStyle: "Dot",
      labels: {
        style: {
          color: theme.palette.demo.lostotjcolor
        }
      },
      lineColor: "#F8F8FF"
    },
    yAxis: {
      title: {
        text: ""
      },
      gridLineWidth: 1,
      gridLineColor: "#e6e6e6",
      gridLineDashStyle: "Dot",
      labels: {
        style: {
          color: theme.palette.demo.lostotjcolor
        }
      },
      lineColor: "#F8F8FF"
    },
    series: [
      {
        name: "Lost OTJ",
        data: data.lostOtjData,
        color: "#1F88E6",
        borderWidth: 0,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(0, 123, 255, 0.5)"],
            [1, "rgba(0, 123, 255, 0)"]
          ]
        },
        marker: {
          enabled: false,
          lineWidth: 2,
          lineColor: "#1F88E6"
        }
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      area: {
        fillOpacity: 1
      }
    }
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

LostOTJ.propTypes = {
  data: PropTypes.shape({
    xAxisData: PropTypes.arrayOf(PropTypes.string).isRequired,
    lostOtjData: PropTypes.arrayOf(PropTypes.number).isRequired
  }).isRequired
};

export default LostOTJ;
