import React from "react";
import PropTypes from "prop-types";
import DashBoardTable from "../ProGridTable/DashBoardTable";

export default function FeedTable({ projectOverviewData, loading, columns }) {
  return (
    <div style={{ padding: "20px 10px 0" }}>
      <DashBoardTable
        loading={loading}
        columns={columns}
        rows={projectOverviewData}
        title={""}
        hasBorder={false}
        hideExport={true}
        minColumnWidth={null}
      />
    </div>
  );
}

FeedTable.propTypes = {
  projectOverviewData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired
};
