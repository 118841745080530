import React from "react";

const NotificationWarningIcon = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.9227 23.5844H2.07723C1.32781 23.5844 0.656003 23.1973 0.280192 22.549C-0.087928 21.9138 -0.0935553 21.1646 0.264717 20.4933L10.1876 1.90571C10.5429 1.24009 11.2204 0.842712 12 0.842712C12.7795 0.842712 13.4571 1.24009 13.8124 1.90571L23.7352 20.4933C24.0936 21.1645 24.0879 21.9138 23.7198 22.549C23.344 23.1973 22.6722 23.5844 21.9227 23.5844Z"
      fill="#FF4C04"
    />
    <path
      d="M23.7353 20.4932L13.8125 1.90565C13.4571 1.24003 12.7796 0.842651 12 0.842651V23.5844H21.9228C22.6723 23.5844 23.3441 23.1973 23.7198 22.5489C24.0879 21.9138 24.0936 21.1644 23.7353 20.4932Z"
      fill="#E10000"
    />
    <path
      d="M12 20.5371C10.9657 20.5371 10.1243 19.6957 10.1243 18.6614C10.1243 17.6271 10.9657 16.7856 12 16.7856C13.0343 16.7856 13.8758 17.6271 13.8758 18.6614C13.8758 19.6957 13.0343 20.5371 12 20.5371Z"
      fill="#FFF3F3"
    />
    <path
      d="M12.0001 14.9099C10.9657 14.9099 10.1243 14.0685 10.1243 13.0342V8.97781C10.1243 7.94351 10.9657 7.10204 12 7.10204C13.0343 7.10204 13.8758 7.94351 13.8758 8.97781V13.0342C13.8758 14.0685 13.0344 14.9099 12.0001 14.9099Z"
      fill="#FFF3F3"
    />
    <path
      d="M12 7.10204H12V14.9099H12.0001C13.0344 14.9099 13.8758 14.0685 13.8758 13.0342V8.97781C13.8758 7.94355 13.0343 7.10204 12 7.10204Z"
      fill="#DAD0CB"
    />
    <path d="M12 16.7856H12V20.5371H12C13.0343 20.5371 13.8758 19.6957 13.8758 18.6614C13.8758 17.6271 13.0343 16.7856 12 16.7856Z" fill="#DAD0CB" />
  </svg>
);

export default NotificationWarningIcon;
