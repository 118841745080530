import dayjs from "dayjs";

export const getInitialFormData = (type) => {
  switch (type) {
    case "AppendixA":
      return {
        approvedJobTitle: '',
        sponsor: '',
        rapidCode: '',
        programLengthYears: '',
      };
    case "AppendixD":
      return {
        project: "",
        partner: "",
        jobTitle: "",
        sponsor: "",
        onetCode: ""
      };
    case "CLRFiled":
      return {
        project: "",
        partner: "",
        jobTitle: "",
        state: "",
        county: ""
      };
    case "CPR":
      return {
        project: "",
        partner: "",
        weekStartDate: dayjs(),
        weekEndDate:dayjs()
      };
    case "PrevailingWageSummary":
      return {
        project: "",
        partner: "",
        jobTitle: "",
        state: "",
        county: ""
      };
    case "SixSevenOne":
      return {
        project: "",
        partner: "",
        jobTitle: "",
        sponsor: "",
        apprenticeName:""
      };
    default:
      return {
        project: "",
        partner: "",
        jobTitle: "",
        sponsor: "",
        dateField: dayjs()
      };
  }
};
  