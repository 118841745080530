import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";

export default function JourneyRatioChart({ ratio = 0, onChartClick}) {
  const [rotateDegree, setRotateDegree] = useState(-90);

  useEffect(() => {
    const activeRatio = ratio > 30 ? 30 : ratio; 
    const degree = (activeRatio / 30) * 180 - 90;
    setRotateDegree(degree);
  }, [ratio]);

  const handleClick = () => {
    if (onChartClick) {
      onChartClick(ratio);
    }
  };

  return (
    <div className="journey-ratio-chart" onClick={handleClick} style={{ cursor: "pointer" }}>
      <div className="journey-ratio-ring-wrapper">
        <div className="journey-ratio-standard">15&#37;</div>
        <div className="journey-ratio-standard-ring"></div>

        <div className="journey-ratio-ring-outer">
          <div className="journey-ratio-ring-gap">
            <div className="journey-ratio-ring-inner"></div>
          </div>
        </div>
        <div
          className="journey-ratio-inner-arrow"
          style={{ transform: `translate(-50%, 78%) rotateZ(${rotateDegree}deg)` }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width={20} height={180} viewBox="0 0 20 180">
            <path d="M10,0L0,180l20,.424144L10,0Z" stroke="#000" strokeWidth="0.5" />
          </svg>
        </div>
        <div className="journey-ratio-inner-circle">{ratio}&#37;</div>
      </div>
    </div>
  );
}

JourneyRatioChart.propTypes = {
  ratio: PropTypes.number
};
